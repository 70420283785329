import React, { useContext } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Zoom,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import useSWR from 'swr';
import { AppContext } from 'Context';
import { dataFetcher, endpoints, axios } from '../../Api';
import { Button } from '../button';
import styles from './index.module.css';

export const ConfirmationModal = ({
  cancelButton,
  continueButton,
  isVisible,
  onHide,
  title,
  content,
  id,
  name,
  setSnackbar,
  removeType,
  setDeleting,
}) => {
  const { getUserConnections, removeProjectorTextChange, setRemoveProjectorTextChange } = useContext(AppContext);

  const deleteGroup = async () => {
    await axios
      .post(endpoints.deleteMyGroups, { id })
      .then(({ data }) => {
        if (data?.success) {
          setDeleting(true);
          setSnackbar({ isVisible: true, message: `${name} removed` });
        } else {
          setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
        }
        onHide();
      })
      .catch(() => {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      });
  };

  const { isValidating: deletingUser, mutate: deleteUser } = useSWR([endpoints.deleteFromViewer, id], {
    fetcher: (url, id) => dataFetcher(url, { id }),
    onSuccess: ({ success }) => {
      if (success) {
        getUserConnections();
        setSnackbar({ isVisible: true, message: `${name} removed` });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
      setRemoveProjectorTextChange(false);
      onHide();
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  return (
    <>
      <Dialog
        open={isVisible}
        TransitionComponent={Zoom}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <div className={styles.dialogContainer}>
          <DialogTitle>
            <div className={styles.title}>
              {title}
            </div>
          </DialogTitle>
          <DialogContent className={styles.mainContent}>
            <DialogContentText>
              {
                content ? (
                  <div className={styles.content}>
                    {content}
                  </div>
                ) : null
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className={styles.actionContainer}>
              <Button
                size="small"
                className={styles.continueButton}
                color="primary"
                isDisabled={removeProjectorTextChange}
                onClick={() => {
                  if (removeType === 'group') {
                    deleteGroup();
                  } else if (!removeProjectorTextChange) {
                    setRemoveProjectorTextChange(true);
                    deleteUser();
                  }
                }}
              >
                {continueButton}
              </Button>
              <Button size="small" className={styles.cancelButton} color="primary" onClick={onHide}>
                {cancelButton}
              </Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};
