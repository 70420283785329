import React, { useState, useContext } from 'react';
import useSWR from 'swr';
import { Modal, Snackbar } from 'Components';
import { AppContext } from 'Context';
import { dataFetcher, endpoints } from 'Api';
import { Input } from '../Input';
import styles from './styles.module.css';

const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };

const PhoneNumberEditModal = ({ isVisible, onHide: onClose, title, phoneNumerLabel }) => {
  const { userDetails, setUserDetails, langText } = useContext(AppContext);
  const commonText = langText?.common;
  const [mobile, setMobile] = useState('');

  const [snackbar, setSnackbar] = useState(defaultSnackbar);

  const onHide = () => {
    setMobile('');
    setSnackbar(defaultSnackbar);
    onClose();
  };

  const { isValidating: updatingMobile, mutate: updateMobile } = useSWR([endpoints.updateMyProfile, mobile], {
    fetcher: (url, inputMobile) => dataFetcher(url, { mobile: inputMobile }),
    onSuccess: ({ success }) => {
      if (success) {
        setSnackbar({ isVisible: true, type: 'success', message: 'Phone number was updated successfully' });
        setTimeout(onHide, 1500);
        setUserDetails({ ...userDetails, mobile });
      } else {
        setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating phone number' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating phone number' });
    },
  });

  return (
    <>
      <Modal
        isVisible={isVisible}
        title={title}
        onHide={onHide}
        cancelButton={commonText?.cancel || 'Cancel'}
        cancelButtonClassName={styles.actionButton}
        onCancelButtonClick={onHide}
        continueButton={updatingMobile ? `${commonText?.updating || 'Updating'}...` : (commonText?.update || 'Update')}
        continueButtonClassName={styles.actionButton}
        onContinueButtonClick={updateMobile}
        continueButtonProps={{ isDisabled: (mobile?.length < 1) || updatingMobile }}
      >
        <br />
        <Input
          className={styles.inputField}
          containerClassName={styles.inputFieldContainer}
          labelClassName={styles.inputFieldLabel}
          label={phoneNumerLabel}
          onInput={(e) => {
            const { target: { value: inputMobile } } = e;

            setMobile(inputMobile);
          }}
          type="code"
        />
      </Modal>

      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
    </>
  );
};
export default PhoneNumberEditModal;
