/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import { Pathname } from 'Routes';
import { AppContext, AuthContext } from 'Context';
import { dataFetcher, endpoints } from 'Api';
import { AuthPage } from '../components/page';
import styles from './index.module.css';
import authStyles from '../auth.module.css';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important'
  }
})(MuiCircularProgress);

export const EmailVerificationLink = () => {
  const { langText, setAppSnackbar, setUserDetails, userDetails, setReferralStatus } = useContext(AppContext);
  const { removeToken, setUserSignInStatus, setToken, forceAutoSignin } = useContext(AuthContext);
  const signInText = langText?.signInUp;
  const emailValidationText = langText?.emailValidation;
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState(undefined);
  const [otp, setOtp] = useState(undefined);
  const [verified, verify] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState(undefined);
  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      setOtp(searchParams.get('verification_code'));
      setEmail(searchParams.get('access_key'));
    }
  }, [location?.search]);

  const { isValidating: verifyingEmail, mutate: verifyEmail } = useSWR([endpoints.verifyEmail, email, otp], {
    fetcher: (url, access_key, code) => dataFetcher(url, { access_key, code }),
    onSuccess: ({ success, message, token, has_logged_in, data, referral_status, is_skip }) => {
      if (success) {
        verify('yes');
        setUserDetails({ ...userDetails, isNewUser: 1 });
        setToken(token);
        setAppSnackbar({ message: 'Logged in successfully', isVisible: true });
        setTimeout(() => {
          if (parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1) {
            setReferralStatus(1);
            navigate(Pathname.plans);
          } else {
            navigate(Pathname.authentication.mobileVerification);
          }
        }, 500);
        forceAutoSignin();
      } else {
        setErrorMsg(message);
        verify('no');
      }
    },
    onError: (error) => {
      setErrorMsg(error?.response?.data?.message);
      verify('no');
    },
  });

  useEffect(() => {
    if (otp && email && !verified) {
      removeToken();
      setUserSignInStatus(false);
      verifyEmail();
    }
  }, [otp, email, verified]);

  return (
    <AuthPage>
      <div className={styles.emailVerifyWrapper}>
        {
          verifyingEmail ? (
            <div>
              <CircularProgress size={60} className={styles.loader} />
              <div className={styles.verifyingEmail}>{emailValidationText?.verifyingEmail || 'Verifying Email'}</div>
            </div>
          ) : null
        }
        <div className={authStyles.suggestionText}>
          <div className={styles.notVerified}>
            {verified === 'no' ? `${errorMsg}` : null}
            &nbsp;
          </div>
          <Link className={authStyles.suggestionLink} to={Pathname.authentication.newSignUp}>
            {signInText?.signUp || 'Sign Up'}
          </Link>
        </div>
      </div>
    </AuthPage>
  );
};
