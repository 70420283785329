import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const uploadSlice = createSlice({
  name: 'upload',
  initialState: {},
  reducers: {
    saveUploadInfo: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { saveUploadInfo } = uploadSlice.actions;

const didCardAddedSlice = createSlice({
  name: 'didCardAdded',
  initialState: false,
  reducers: {
    setDidCardAdded: (state, action) => action.payload,
  },
});

// Extract action creator
export const { setDidCardAdded } = didCardAddedSlice.actions;

const titleBulkSlice = createSlice({
  name: 'titleBulk',
  initialState: {},
  reducers: {
    saveTitle: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    removeTitle: (state, action) => {
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    },
  },
});

export const { saveTitle, removeTitle } = titleBulkSlice.actions;

const deleteUploadSlice = createSlice({
  name: 'deleteUpload',
  initialState: {},
  reducers: {
    saveDeleteUpload: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { saveDeleteUpload } = deleteUploadSlice.actions;

const addVideoSlice = createSlice({
  name: 'addVideo',
  initialState: {},
  reducers: {
    setAddVideo: (state, action) => ({
      ...state,
      [action.payload.draftId]: action.payload.value,
    }),
  },
});

export const { setAddVideo } = addVideoSlice.actions;

const uploadDetailsSlice = createSlice({
  name: 'uploadDetails',
  initialState: {},
  reducers: {
    saveUploadDetails: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    clearUploadDetails: () => ({}),
  },
});

export const { saveUploadDetails, clearUploadDetails } = uploadDetailsSlice.actions;

const uploadSizesSlice = createSlice({
  name: 'uploadSizes',
  initialState: {},
  reducers: {
    saveUploadSizes: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { saveUploadSizes } = uploadSizesSlice.actions;

const dummySlice = createSlice({
  name: 'dummy',
  initialState: {},
  reducers: {
    saveDummy: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    removeDummy: (state, action) => {
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    },
    clearAllDummys: () => ({}),
  },
});

export const { saveDummy, removeDummy, clearAllDummys } = dummySlice.actions;

const store = configureStore({
  reducer: {
    upload: uploadSlice.reducer,
    titleBulk: titleBulkSlice.reducer,
    uploadDetails: uploadDetailsSlice.reducer,
    didCardAdded: didCardAddedSlice.reducer,
    deleteUploadSlice: deleteUploadSlice.reducer,
    addVideo: addVideoSlice.reducer,
    uploadSizes: uploadSizesSlice.reducer,
    dummy: dummySlice.reducer
  },
});

export default store;
