/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import {
  DialogContent,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import { Radio, DialogFooter } from './Material-reDesign';
import styles from '../index.module.css';
import '../loader.css';
import { ContentBox, ContentBoxSection } from './ContentBox';
import { Button } from '../../button';
import ImagePreviewDetails from './ImagePreviewDetails';
import { Text } from '../../text';
import { GroupSelector } from '../../group-selector';

const AlbumSummary = ({ textOfUpload, settextOfUpload, loading, videoVisibilityOptions, setAlbumVisibility, albumVisibility, selectGroup, albumPhotoCount,
  videoGroup, defaultAlbumThumbnail, setChooseAlbumStep, publishAlbum, albumSize, addingPhotos, uploadText, commonText, visibilityText, chooseAlbumStep, isUpdating }) => (
    <>
      <DialogContent className={styles.content}>
        <ContentBox flex>
          <ContentBoxSection
            // title={uploadText?.previousDetails || 'Previous Details'}
            // description={uploadText?.previousDetailVideoDesc || 'Video preview how the audience will see this'}
            className={styles.summaryBoxSection}
          >
            <div className={styles.visibilityContainer}>
            <div className={styles.req}>
            <Text.SectionTitle className={styles.visibilityTitle}>
               {visibilityText?.title || 'Visibility'}
            </Text.SectionTitle>
            <span className={styles.optionalInput}> (Required)</span>
            </div>
              <p className={styles.visibilityDescription}>{visibilityText?.description || 'Choose when to publish and who can see your video.'}</p>
              <RadioGroup
                className={styles.visibilityRadio}
                defaultValue={videoVisibilityOptions[0].value}
                name="visibility"
                value={albumVisibility}
                onChange={(e) => { setAlbumVisibility(e?.target?.value); }}
              >
                {videoVisibilityOptions.map(({ name, value }, idx) => (
                  <FormControlLabel
                    value={value}
                    key={idx}
                    control={<Radio color="primary" />}
                    label={name}
                  />
                ))}
              </RadioGroup>
              {albumVisibility === videoVisibilityOptions[2].value && (
                <GroupSelector
                  id="group-selector"
                  onSelect={(val) => { selectGroup(val); }}
                  selectedOption={videoGroup}
                />
              )}
            </div>
            <ImagePreviewDetails
              defaultImageThumbnail={defaultAlbumThumbnail.url}
              count={albumPhotoCount}
              size={albumSize}
              uploadText={uploadText}
              isUpdating={isUpdating}
            />
          </ContentBoxSection>
        </ContentBox>
      </DialogContent>
      <DialogFooter>
        <Button
          className={styles.navButton}
          isBlue
          isOutlined
          onClick={() => { setChooseAlbumStep(chooseAlbumStep - 1); }}
        >
          {commonText?.previous || 'Previous'}
        </Button>
        <Button
          className={styles.navButton}
          isBlue
          isOutlined
          isDisabled={addingPhotos}
          onClick={() => { publishAlbum(); }}
        >
   {textOfUpload === 'Done' ? (
  <svg className="loading-circle" viewBox="0 0 50 50">
    <circle className="circle" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
  </svg>
) : (uploadText?.publish || 'Publish')}
        </Button>
      </DialogFooter>
    </>
);
export default AlbumSummary;
