import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
// ...
import Audio from '../../Assets/notification0.mp3';

export default function index() {
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPlaying(true);
    }, 2000);
  }, []);
  return (
    <>
      <ReactAudioPlayer
        // style={{ display: 'none' }}
        src={Audio}
        autoPlay
        // loop
        // controls
      />
      {/* <button onClick={() => setPlaying(!playing)}>play</button> */}
    </>
  );
}
