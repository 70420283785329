import React, { useContext, useState } from 'react';
import './videoPopup.css';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrow } from 'Assets/backArrowIcon.svg';
import { Pathname } from 'Routes';
import { AppContext } from 'Context';
import useMyAxios from 'hooks/useMyAxios';
import { getAllActivePlans } from 'Api/apiFunctions';
import { ALLOWED_UPLOADS_COUNT } from 'Commons';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import image from '../../Assets/thumbnail1.png';
import video from '../../Assets/sample-video.mp4';
import videoModalContent from '../videoPlaybackModal/videoPlaybackModal';

function videoPopupContent({ isVisible, onHide, showVideo }) {
  const { userDetails } = useContext(AppContext);
  const navigate = useNavigate();
  const [videoSkipped, setVideoSkipped] = useState(false);
  const {
    response: plans,
    error: plansErr,
    loading: plansLoading,
    refetch: refetchPlans,
  } = useMyAxios(getAllActivePlans, null, 'ALL_ACTIVE_PLANS');
  const promotionVideoUrl = 'https://projector-optimized.s3.us-west-2.amazonaws.com/production/background-videos/Web.mp4';
  const handleBackClick = () => {
    // if video already skipped show previous content
    if (videoSkipped) {
      setVideoSkipped(false);
    } else {
      onHide();
    }
  };

  const handleSkip = () => {
    setVideoSkipped(true);
    // !videoSkipped && onHide();
  };

  const amountOfYear = plans?.data?.filter((obj) => obj?.id === '11');
  const remainingUploads = Math.max(ALLOWED_UPLOADS_COUNT - userDetails.totalContentUploaded, 0);

  return (
    <Box className="main" borderRadius="10px">
      <Box
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        width="100%"
        zIndex="1"
        padding="5"
        marginTop="5"
      >
        <Button className="top-btn" onClick={!videoSkipped ? handleSkip : onHide}>
          {!videoSkipped ? 'Continue' : <CloseIcon />}
        </Button>
      </Box>
      <ReactPlayer
        className="videoPlayer"
        url={promotionVideoUrl}
        playing
        muted
        loop
      />
      {videoSkipped && (
        <Box position="absolute" bottom="0" width="100%" height="65%" className="text-box" borderRadius="10px">
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
            height="100%"
          >
            <p className="ultimate">
              Your Very Own Video
              <br />
              Streaming App For
            </p>
            <span className="pricingLabel">
              $
              {amountOfYear?.length > 0 && amountOfYear[0]?.amount}
            </span>
            <button
              className="continue"
              onClick={() => {
                navigate(Pathname.plans);
              }}
            >
              START NOW
            </button>
            <div className="stream">
              Unlock the Ultimate Personal
              <br />
              Viewing Experience
            </div>
            <div className="featuresLabel">Unlimited Upload</div>
            <div className="featuresLabel"> Unlimited Cloud Storage</div>
            <div className="featuresLabel"> Unlimited Enjoyment</div>
          </Box>
        </Box>
      )}
    </Box>

  );
}
export default videoPopupContent;
