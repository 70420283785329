import React, { useContext } from 'react';
import { Dialog, Slide, makeStyles } from '@material-ui/core';
import videoPopupContent from './videoPopup';
// import videoPopup from './videoPopup';

export const VideoPopupModal = ({ onHide, isVisible, showVideo }) => {
  const useStyles = makeStyles({
    dots: true,
  });
  const classes = useStyles();
  return (
    <Dialog
      classes={{ root: classes.content }}
      disableBackdropClick
      onClose={onHide}
      open={isVisible}
      PaperProps={{ onHide, isVisible, showVideo }}
      TransitionComponent={Slide}
      PaperComponent={videoPopupContent}
    />
  );
};
