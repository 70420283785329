import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import { ReactComponent as PersonIcon } from 'Assets/person-profile.svg';
import { ReactComponent as HomeIcon } from 'Assets/home-header.svg';
import { ReactComponent as SearchIcon } from 'Assets/search.svg';
import { ReactComponent as WatchlistIcon } from 'Assets/watchlistIcon.svg';
import { HeaderIconButton } from '../HeaderIconButton';
import styles from '../../index.module.css';

export const Header = ({ layoutType, profileDetails, revealProfileMenu, revealUploadModal, storageFullCondition, setStorageFull }) => {
  const { langText, isInActiveUser } = useContext(AppContext);
  const headerText = langText?.header;
  const { profileID } = useParams();
  // eslint-disable-next-line no-script-url
  window.location.href = 'javascript: $zopim.livechat.button.hide()';
  return (
    <>
      <header className={cn({ [styles.profileHeader]: true, [styles.videoBannerHeader]: true })}>
        <div className={styles.profileHeaderContent}>
          <div className={styles.leftContent} style={{ flex: '1' }}>
            <button
              aria-controls="profile-menu"
              className={cn({
                [styles.profileButton]: true,
                [styles.regularView]: true,
                [styles.profileHeaderIconButton]: true,
              })}
              onClick={revealProfileMenu('projectors')}
            >
              {
                profileDetails.image ? (
                  <img
                    src={profileDetails?.image}
                    className={cn({
                      [styles.profileMenuCurrentProfileDetailsIcon]: true,
                      [styles.border]: true,
                      [styles.profileIconPosition]: true,
                    })}
                  />
                ) : (
                  <PersonIcon
                    className={cn({
                      [styles.profileMenuCurrentProfileDetailsIcon]: true,
                      [styles.border2]: true,
                      [styles.profileIconPosition]: true,
                    })}
                  />
                )
              }
              <div className={`${styles.profileHeaderIconButtonText} ${styles.namePosition}`}>
                {profileDetails.firstname?.substring(0, 10) || profileDetails.email?.split('@')[0]?.substring(0, 10)}
              </div>
            </button>
            <HeaderIconButton
              icon={HomeIcon}
              text={headerText?.home || 'Home'}
              link={profileID ? Pathname.getFriendProfilePath(profileID) : Pathname.getProfilePath()}
            />
            <HeaderIconButton icon={SearchIcon} text={headerText?.search || 'Search'} link={Pathname.getFriendSearch(profileID)} />
            <HeaderIconButton icon={WatchlistIcon} text={headerText?.watchlist || 'Watchlist'} link={Pathname.getFriendWatchList(profileID)} />
          </div>
          <div className={styles.rightContent}>
            {
              !isInActiveUser ? (
                <HeaderIconButton
                  text={headerText?.upload || 'UPLOAD'}
                  onClick={() => { storageFullCondition ? setStorageFull(true) : revealUploadModal(); }}
                  link="#"
                />
              ) : null
            }
            <HeaderIconButton
              aria-controls="profile-menu"
              text={headerText?.account || 'ACCOUNT'}
              onClick={revealProfileMenu('account')}
              link="#"
            />
          </div>
        </div>
      </header>
    </>
  );
};
