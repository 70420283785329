import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useMediaQuery } from '@mui/material';
import useSWR from 'swr';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import { dataFetcher, endpoints } from 'Api';
import { ProfilePage, ProjectorShimmer } from 'Components';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import ImageGroup from 'Components/ImageGroup/ImageGroup';
import FixedProgressBar from 'Components/header/components/FixedProgressBar';
import BottomBar from '../profile/BottomBar';
import styles from './index.module.css';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important',
  },
})(MuiCircularProgress);

export const Watchlist = () => {
    // eslint-disable-next-line no-script-url
    window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const { setAppSnackbar, userDetails, langText } = useContext(AppContext);
  const { profileID } = useParams();
  const watchText = langText?.viewProjector;
  const [profileName, setProfileName] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [videosGroup, setVideosGroup] = useState([]);
  const isBelow750px = useMediaQuery('(max-width:750px)');
  useEffect(() => {
    if (userDetails?.firstname) {
      setProfileName(userDetails?.firstname?.substring(0, 10));
    } else if (userDetails?.email) {
      setProfileName(userDetails?.email?.split('@')[0]?.substring(0, 10));
    }
  }, [userDetails]);

  const { mutate: getMyVideos } = useSWR([endpoints.getMyWatchList, profileID || userDetails.myId], {
    fetcher: (url, user_id) => dataFetcher(url, { user_id }),
    onSuccess: ({ success, videos }) => {
      if (success) {
        setVideosGroup([{ title: ' ', id: '-', videos }]);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }

      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  useEffect(() => { getMyVideos(); }, []);

  return (
    <ProfilePage
      containerClassName={styles.pageContainer}
      mainClassName={cn({ [styles.mainContent]: true, [styles.loading]: loading })}
      userName={profileName}
      userProfileLink={Pathname.getFriendProfilePath(profileID)}
      noHeader
      noDefaultBg
      isProfileHeader
    >
      {loading
        ? <FixedProgressBar />
        : (
          <>
            <div className={styles.heading}>
              <div className={styles.headingBGFade} />
              <div className={styles.headingText}>
                <>{watchText?.watchListTitle || 'Your Watchlist'}</>
                {(videosGroup?.length === 0) && ' is \'empty\''}
              </div>
            </div>

            <br />
            <br />

            {loading
              ? (
                <div className={styles.categoriesLoader}>
                  <ProjectorShimmer />
                </div>
              )
              : (!!videosGroup?.length && (
                videosGroup?.map((videoGroup) => (
                  !!videoGroup?.videos?.length > 0 ? (
                    <div className={styles.watchlistContainer}>
                      <ImageGroup
                        isBelow750px={isBelow750px}
                        isGrid
                        videos={videoGroup?.videos}
                        key={videoGroup?.id}
                        title={videoGroup?.title}
                      />
                    </div>
                  ) : (
                  <h2 style={{ margin: '0', textAlign: 'center', color: 'white', fontSize: '1.5rem', fontWeight: '500' }}>No Content...</h2>
                  )
                ))
              ))}
          </>
        )}
      <BottomBar />
    </ProfilePage>
  );
};
