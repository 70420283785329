import React from 'react';
import AlterImage from 'Assets/alter-image.png';
import tvImg from '../assets/img/tv.svg';
import webImg from '../assets/img/web.svg';
import mobileImg from '../assets/img/mobile.svg';

const Access = () => (
  <div className="section">
    <div className="container">
      <h2
        data-aos="fade-down"
        className="title title-margin access-title"
      >
        Access your videos anytime anywhere
      </h2>

      <div className="access">
        <div data-aos="fade-up-right" className="col mob-spacing">
          <img
            src={tvImg || AlterImage}
            alt=""
            onError={(e) => {
              e.target.src = AlterImage;
            }}
          />
          <div>TV</div>
          <p className="hide">Amazon Fire TV</p>
          <p className="hide">AppleTV</p>
          <p className="hide">Roku</p>
          <p className="hide">Android TV Devices</p>
        </div>

        <div data-aos="fade-up" className="col mob-spacing">
          <img
            src={webImg || AlterImage}
            alt=""
            onError={(e) => {
              e.target.src = AlterImage;
            }}
          />
          <div>Web</div>
          <p className="hide">Chrome</p>
          <p className="hide">Mac</p>
          <p className="hide">Windows</p>
        </div>

        <div data-aos="fade-up-left" className="col mob-spacing">
          <img
            src={mobileImg || AlterImage}
            alt=""
            onError={(e) => {
              e.target.src = AlterImage;
            }}
          />
          <div>Mobile & Tablet</div>
          <p className="hide">iPhone and iPad</p>
          <p className="hide">Android Phones & Tablets</p>
          <p className="hide">Amazon Fire Tablets</p>
        </div>
      </div>
    </div>
  </div>
);

export default Access;
