import React from 'react';
import cn from 'classnames';

import styles from '../index.module.css';

const ProgressTracker = ({ steps = [], currentStep, }) => (
  currentStep
    ? (
      <>
        <div className={styles.currentStepName}>{steps[currentStep - 1]}</div>
        <div style={{ border: 'none' }} className={styles.progressTracker}>
          {steps.map((stepName, idx) => (
            <React.Fragment key={idx}>
              {idx ? <div className={styles.progressTrackerStepBar} /> : null}
              <div
                className={cn({
                  [styles.progressTrackerStepContainer]: true,
                  [styles.currentProgressStep]: (idx + 1) <= currentStep
                })}
              >
                <div className={styles.progressTrackerStepCount}>{idx + 1}</div>
                <div className={styles.progressTrackerStepName}>{stepName}</div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </>
    )
    : null
);
export default ProgressTracker;
