import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import {
  IconButton,
  Fade,
  makeStyles,
} from '@material-ui/core';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import { Button } from 'Components/button';
import { dataFetcher, endpoints } from 'Api';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import './promotion.css';
import styles from './index.module.css';

const promotionModalContent = ({ isVisible, onHide, promoCode, loginCode, showCloseButton, promoValue }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAppSnackbar } = useContext(AppContext);
  const setPromoSkipped = (isSkipped) => localStorage.setItem('isPromoSkipped', isSkipped);

  const { isValidating: applyingPromotion, mutate: applyPromotion } = useSWR([endpoints.addPromotion, promoCode], {
    fetcher: (url, inputPromotion) => dataFetcher(url, { promo_code: inputPromotion, }),
    onSuccess: ({ success, buy_plan, message }) => {
      setAppSnackbar({ type: 'success', message: message || 'Applying promotion failed', isVisible: true });
      setTimeout(
        () => {
          if (buy_plan === 1) navigate(Pathname.plans, { state: { promocode: promoCode } });
          else navigate(`${Pathname.access}${loginCode ? `?email=${loginCode}` : ''}`);
        },
        500
      );
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ type: 'error', message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: skippingPromotion, mutate: skipPromotion } = useSWR([endpoints.updatePromoCodeSkipped], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, message }) => {
      onHide();
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ type: 'error', message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const doCancelAction = () => {
    setPromoSkipped(true);
    skipPromotion();
  };

  // useEffect(() => {
  //   if (promoCode !== '') applyPromotion();
  // }, [promoCode]);

  return (
    <>
      <Fade in={isVisible} timeout={isVisible ? 300 : 250}>
        <div className="body">
          <form className={styles.alignForm} onSubmit={(e) => { e.preventDefault(); applyPromotion(); }}>
            <div style={{ textAlign: 'end' }}>
              {
                showCloseButton ? (
                  <CloseIcon onClick={doCancelAction} />
                ) : (
                  <div />
                )
              }
              <div style={{ textAlign: 'center' }}>
                <div className="headerContent">
                  <h2 className="headerText" style={{ fontWeight: 'normal', fontSize: '29px' }}>Upgrade Now and Save</h2>
                </div>
                <br />
                <p className="promotionDescriptiontext" style={{ fontWeight: 'bold', fontSize: '32px' }}>
                  Get
                  <span>
                    {` ${promoValue} `}
                  </span>
                  FREE months of
                  <br />
                  an upgraded package today!
                </p>
                <br />
                <p style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '4px' }}>
                  Store, share and stream all your memories, pictures
                  <br />
                  and videos in one spot
                </p>
                <br />
                <div className={styles.buttonBlock}>
                  <Button blockText isDisabled={applyingPromotion} isBlue isLarge submit>
                    {applyingPromotion ? 'Applying Promotion' : `GET ${promoValue} FREE MONTHS NOW!`}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Fade>
    </>
  );
};
export default promotionModalContent;
