/* eslint-disable max-len */
import React from 'react';
import './termsAndCondition.css';

export default function TermsAndCondition({ isDark }) {
  return (
    <div className="TermsAndCondition">
      {/* eslint-disable-next-line */}
      <style jsx>
        {`.TermsAndCondition
        {
          color :${isDark ? 'white' : 'black'};
          padding-top :${isDark ? '5%' : null};
          background:transparent
        }`}
      </style>
      {/* <p className="c6">

        <span className="c4">
          Website Terms of Use
        </span>

      </p> */}

      {/* <p className="c6 c10">
        <span className="c4" />
      </p>
      <p className="c6 c10">
        <span className="c4" />
      </p>
      <p className="c6">

        <span className="c4 c19">
          Last Update: September 2020
        </span>

      </p>

      <p className="c6 c10">

        <span className="c4" />

      </p> */}

      <p className="c5">

        <span className="c1">
          THIS WEBSITE IS OWNED AND OPERATED BY PROJECTOR, LLC (“PROJECTOR”, “WE”, “OUR”, AND “US”). ACCESS AND USE OF THIS WEBSITE (THE “WEBSITE”) AND THE SERVICES OFFERED ON THIS WEBSITE (COLLECTIVELY, THE “PROJECTOR SERVICES”) ARE PROVIDED BY PROJECTOR TO YOU ON CONDITION THAT YOU ACCEPT THESE TERMS OF USE (“TERMS”, “TERMS OF USE”, OR “AGREEMENT”). PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE PROJECTOR SERVICE. IF YOU DO NOT AGREE TO ACCEPT AND ABIDE BY THESE TERMS OF USE, YOU SHOULD NOT ACCESS OR USE THIS WEBSITE OR THE PROJECTOR SERVICES.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You agree that the agreement formed by these Terms of Use is like any written negotiated agreement signed by you, and you agree to be bound by, and fully comply with, its terms. You represent and warrant that you have all necessary right, power and authority to enter into this agreement and to perform and otherwise discharge all of your obligations hereunder.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          We reserve the right at any time to change: (i) these Terms of Use; (ii) this Website, including terminating, eliminating, supplementing, modifying, adding or discontinuing any Projector Services or other content, feature, data, or service on or available through this Website or the hours that they are available; (iii) the equipment, hardware or software required to use and access this Website and use the Projector Services, and (iv) prices for the Projector Services. We shall not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Website or the Projector Services.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Any changes we make to these Terms of Use will be effective immediately upon notice, which we may provide by any means including, without limitation, posting on this Website. Your continued use of this Website after such notice will be deemed acceptance of such changes. Be sure to return to this Website periodically to ensure you are familiar with the most current version of these Terms of Use.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You may not interfere with the security of, or otherwise abuse this Website or any system resources, services or networks connected to or accessible through this Website. You may only use this Website for lawful purposes.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          Jurisdiction
        </span>

      </p>

      <p className="c5">

        <span className="c1">
          If you are residing in a jurisdiction that restricts the ability to enter into agreements such as set out in these Terms of Use, you may not enter into this agreement or use this Website.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Furthermore, if you are residing in a jurisdiction where it is forbidden by law to participate in the activities or services offered by this Website, you may not enter into this agreement or use this Website. By using this Website you are explicitly stating that you have verified in your own jurisdiction if your use of this Website is allowed.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          We make no representation that materials on this Website or the Projector Services are appropriate or available for use in all locations. Those who choose to access the Website do so on their own initiative and at their own risk, and are responsible for compliance with local laws, if and to the extent local laws are applicable.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          We reserve the right to limit the availability of the Website, Projector Services, materials, or other products described or offered thereon to any person, geographic area, or jurisdiction we so desire, at any time and in our sole discretion, and to limit the quantities of any such services, materials, or other product provided.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          GENERAL CONDITIONS AND DISCLAIMERS
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          We reserve the right to refuse service to anyone at any time.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Projector shall have no liability for interruptions or omissions in Internet, network or hosting services and does not warrant that the Website or the services which make this Website available or electronic communications sent by Projector are free from viruses or any other harmful elements.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Any material downloaded or otherwise obtained through the use of this Website is done at your own discretion and risk. You will be solely responsible for any damage to your computer system or loss of data that results from the download of any such material.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Occasionally there may be information on our Website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information if any information in the Website or on any related website is inaccurate at any time without prior notice (including after you have submitted payment for a Projector Service).
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          We undertake no obligation to update, amend or clarify information in the Website or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Website or on any related website, should be taken to indicate that all information in the Website or on any related website has been modified or updated.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          PRIVACY
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Projector is committed to respecting the privacy of the personal information of the individuals with whom we interact. We have developed a Privacy Policy to describe our privacy practices and how we collect, use and disclose the personal information of those individuals who visit this Website. Please see our projector.app/privacypolicy for further information.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          USER ACCOUNTS AND ACCURACY OF INFORMATION
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          In order to access and use certain Projector Services available on this Website, you may be required to open and maintain an account (&quot;Account&quot;) with us. During the registration process, we will collect personal information that you provide. All of the information you provide to us or that we collect from you in connection with your use of the Website will be governed by these Terms of Use and our projector.app/privacypolicy .You agree at all times to: (i) provide accurate, current and complete information about yourself as prompted by our registration form or otherwise; and (ii) maintain and update your information (including your e-mail address) to keep it accurate, current and complete. You acknowledge that, if any information provided by you is untrue, inaccurate, not current or incomplete, we may terminate your Account and your ability to use this Website and, in our sole discretion, to report you to the appropriate authorities.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You understand and agree that, in addition to the foregoing restrictions, we reserve the right to change, remove, alter or delete any Account at any time and for any reason in our sole discretion. You will be responsible for the confidentiality and use of your username and password and agree not to transfer your right to use or access this Website via your username or password to any third person, except in connection with the succession function available on the Website.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You agree to notify us immediately of any unauthorized use, theft or misappropriation of your Account, username, or password. We shall not be liable for any loss that you incur as a result of someone else using your username or password, either with or without your knowledge.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          USER ACCOUNTS AND ACCURACY OF INFORMATION
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          In order to access and use certain Projector Services available on this Website, you may be required to open and maintain an account (&quot;Account&quot;) with us. During the registration process, we will collect personal information that you provide. All of the information you provide to us or that we collect from you in connection with your use of the Website will be governed by these Terms of Use and our projector.app/privacypolicy .You agree at all times to: (i) provide accurate, current and complete information about yourself as prompted by our registration form or otherwise; and (ii) maintain and update your information (including your e-mail address) to keep it accurate, current and complete. You acknowledge that, if any information provided by you is untrue, inaccurate, not current or incomplete, we may terminate your Account and your ability to use this Website and, in our sole discretion, to report you to the appropriate authorities.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You understand and agree that, in addition to the foregoing restrictions, we reserve the right to change, remove, alter or delete any Account at any time and for any reason in our sole discretion. You will be responsible for the confidentiality and use of your username and password and agree not to transfer your right to use or access this Website via your username or password to any third person, except in connection with the succession function available on the Website.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You agree to notify us immediately of any unauthorized use, theft or misappropriation of your Account, username, or password. We shall not be liable for any loss that you incur as a result of someone else using your username or password, either with or without your knowledge.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          USER CONTRIBUTIONS
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          The Website allows you to submit, communicate, upload, or otherwise make available videos, photographs, and audio, video, and other content (“User Content”), which you may choose to make accessible to and viewable by other users of the Website (each, an “Authorized User”). We may also use your User Content in providing you the Projector Services. You alone are responsible for your User Content, and once published, it cannot always be withdrawn. You assume all risks associated with your User Content, including anyone’s reliance on its accuracy and your right to publish it. You represent that you own, or have the necessary permissions to use and authorize the use of your User Content as described herein. You may not imply that your User Content is in any way sponsored or endorsed by Projector.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You may not submit or upload User Content that is defamatory, harassing, threatening, bigoted, hateful, violent, vulgar, lewd, offensive, indecent, obscene, pornographic, or otherwise offensive or that harms or can reasonably be expected harm any person or entity (such User Content, “Offensive User Content”), whether or not such material is protected by law. You agree that you will not use your Account or any Website to publish or disseminate, or allow any other person to use your Account to publish, republish, or disseminate, any Offensive User Content, nor use profanity in any posting to any Website.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You may also expose yourself to liability to Projector or third parties if, for example, you hold an Account and User Content contains material that is false, intentionally misleading, or defamatory; violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or pornography; exploits or otherwise harms minors; or violates or advocates the violation of any law or regulation. If you comment about an experience with Projector or any third party, or write defamatory or inflammatory comments through the Website (or repeat, copy, or redistribute the defamatory or inflammatory comments of others), you could also expose yourself to liability.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You hereby irrevocably grant us world-wide, non-exclusive, royalty-free, transferable rights to use your User Content for any purpose associated with the operation of the Website and for our internal tracking, reporting, and data analysis purposes, without limit as to time, manner, and frequency of use, without further notice to you, with or without attribution, and without the requirement of permission from or payment to you or any other person or entity. You also irrevocably grant Authorized Users the right to access your User Content in connection with their use of the Website and permit us to make your User Content available to such Authorized Users. Finally, you irrevocably waive, and cause to be waived, against Projector and its users, any claims and assertions of moral rights or attribution with respect to User Content. By “use” we mean use, copy, publicly perform or display, distribute, modify, translate, and create derivative works of User Content.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          We reserve the right to monitor, remove, screen, edit, or reinstate your User Content from time to time at our sole discretion and without notice to you, but you acknowledge and agree that we have no obligation to do so. By submitting User Content to Projector through its suggestion or feedback webpages, via email, or otherwise, you acknowledge and agree that: (a) User Content does not contain confidential or proprietary information; (b) Projector may have something similar to the User Content already under consideration or in development; (c) We have no obligation to retain or provide you with copies of User Content, nor do we guarantee any confidentiality with respect to your User Content. You expressly acknowledge or agree that we may turn over any User Content to law enforcement authorities if we have a reasonable, good faith belief that such User Content violates laws.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You understand that User Content (not including credit card information) and account information may be transferred unencrypted and involve (a) transmissions over various networks, and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          PERSONAL USE ONLY
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          This Website and the Projector Services are only for personal use. You may not use this Website or any Projector Services in any way that is unlawful, or harms us or any other person or entity, as determined in our sole discretion.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          CODE OF CONDUCT
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You agree that you will not attempt to, nor permit any third party to, enter restricted areas of Projector&apos;s computer systems or perform functions that you are not authorized to perform pursuant to these Terms of Use. While using this Website you agree to comply with all applicable laws, rules and regulations (including, but not limited to, export/import laws and laws relating to privacy, obscenity, copyright, trademark, confidential information, trade secret, libel, slander or defamation). You shall not interfere with or disrupt the Website, our servers or our networks, or take any action that imposes unreasonably or disproportionately large load on our infrastructure.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          COPYRIGHT INFRINGEMENT
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          If you know or suspect that any of the materials on the Website have been used or copied in a way that constitutes copyright infringement, please send notice to our designated agent identified below. According to the U.S. Digital Millennium Copyright Act, 17 U.S.C. § 512(c)(3), your notice must comply with the following requirements:
        </span>

      </p>
      <ul className="c16 lst-kix_list_1-0 start">
        <li className="c9 li-bullet-0">

          <span className="c1">
            A physical or electronic signature of the copyright owner or person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a notification, a representative list of such works at that site;
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            Information reasonably sufficient to permit us to contact you, such as address, telephone number, and, if available, an electronic mail address at which you may be contacted;
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            A statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owners, its agent or the law; and
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            A statement that the information in the notification is accurate and under penalty of perjury, that you are the copyright owner or authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
          </span>

        </li>
      </ul>
      <p className="c5">

        <span className="c1">
          The designated agent for notice of copyright infringement claims may be reached as follows:
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          800-410-6134
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          The preceding information is provided exclusively for notifying us that your copyrighted material may have been infringed. All other inquiries, such as product related questions and requests or concerns regarding improper postings and/or content, will not receive a response through this process.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          AVAILABILITY
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          While we endeavor to keep downtime to a minimum, we cannot promise that this Website will be uninterrupted, secure or error-free. We reserve the right to interrupt/suspend this Website, or any part thereof, with or without prior notice for any reason and you shall not be entitled to any refunds of fees for interruption of this Website.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          CURRENCY OF WEBSITE
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Projector updates the information on this Website periodically. However, Projector cannot guarantee or accept any responsibility or liability for the accuracy, currency or completeness of the information on this Website. Projector may revise, supplement or delete information, services and/or the resources contained in this Website and reserves the right to make such changes without prior notification to past, current or prospective visitors.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          LINKED WEBSITES
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          This Website may provide links to third party websites for your convenience only. The inclusion of these links does not imply that Projector monitors or endorses these websites or their respective practices. Projector does not accept any responsibility for such websites. Projector shall not be responsible or liable, directly or indirectly, for any damage or loss, caused or alleged to be caused by or in connection with the use of or the reliance upon any information, content, goods or services available on or through any third party websites or linked resources. These Terms of Use and our projector.app/privacypolicy do not apply to your use of any third party websites, so be sure to review any applicable terms and policies of third party sites.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          INTERNET SOFTWARE OR COMPUTER VIRUSES
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Due to technical difficulties with the Internet, Internet software or transmission problems could produce inaccurate or incomplete copies of information contained on this Website. Due to the ability to share certain content and materials, computer viruses or other destructive programs may also be inadvertently downloaded from this Website.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Projector shall not be responsible or liable for any software, computer viruses or other destructive, harmful or disruptive files or programs that may infect or otherwise impact your use of your computer equipment or other property on account of your access to, use of, or browsing on this Website or your downloading of any user materials or other content from this Website. Projector recommends that you install appropriate anti-virus or other protective software.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          OUR MATERIALS
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          This Website is owned and operated by Projector in conjunction with others pursuant to contractual arrangements.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Unless otherwise specified, all materials appearing on this site, including the text, site design, logos, graphics, icons, and images, as well as the selection, assembly and arrangement thereof, are the sole property of Projector and its licensors. ALL RIGHTS RESERVED.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Projector may make certain content, software or other electronic materials (including all files, text, URLs, video, audio and images contained in or generated by such materials, and accompanying data) (collectively &quot; Projector Materials&quot;) available to you from this Website, from time to time. If you download or use Projector Materials, you agree that such materials are licensed for your limited personal use only and not sold or assigned and: (i) may only be used for your personal and non-commercial use, (ii) may not be modified, used to create a derivative work, incorporated into any other work or otherwise exploited without Projector &apos;s prior written permission, and (iii) shall be only be used in compliance with any additional license terms accompanying such materials. Projector does not transfer either the title or the intellectual property rights to the Projector Materials, and retains full and complete title to the Projector Materials as well as all intellectual property rights therein. You agree not to sell, share, redistribute, or reproduce the Projector Materials. You further agree not to decompile, reverse-engineer, disassemble, or otherwise convert any compiled Projector Materials into a human-perceivable form. Projector or its licensors own all related trademarks and logos, and you agree not to copy or use them in any manner.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          All rights not expressly granted herein are reserved by Projector. Any unauthorized use of the materials appearing on this Website may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          This Website, (including its organization and presentation and Projector Materials) is the property of Projector and its licensors and may be protected by intellectual property laws including laws relating to copyrights, trade-marks, trade-names, internet domain names and other similar rights.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          DISCLAIMER OF WARRANTIES
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          THIS SITE, THE PROJECTOR SERVICES, AND THE PROJECTOR MATERIALS ARE PROVIDED &quot;AS IS&quot; WITHOUT REPRESENTATION, WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED REPRESENTATIONS, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. PROJECTOR DOES NOT REPRESENT OR WARRANT THAT THIS SITE, THE PROJECTOR SERVICES, OR THE PROJECTOR MATERIALS WILL MEET YOUR REQUIREMENTS OR THAT THEIR USE WILL BE SECURE, UNINTERRUPTED OR ERROR FREE. SOME STATES AND JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. WHEN THE IMPLIED WARRANTIES ARE NOT ALLOWED TO BE EXCLUDED IN THEIR ENTIRETY, THEY WILL BE LIMITED TO THE SHORTEST DURATION PERMITTED BY LAW. YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE-TO-STATE.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          LIMITATION OF LIABILITY
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THIS SITE, THE PROJECTOR SERVICES, AND THE PROJECTOR MATERIALS INCLUDING WITHOUT LIMITATION ANY OF THE INFORMATION CONTAINED THEREIN.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          IN NO EVENT SHALL PROJECTOR OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, PARTNERS, OR AGENTS BE LIABLE FOR ANY DIRECT, INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, CONSEQUENTIAL OR OTHER SIMILAR DAMAGES WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) IN CONNECTION WITH ANY CLAIM, LOSS, DAMAGE, ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THESE TERMS OF USE, INCLUDING WITHOUT LIMITATION YOUR USE OF, RELIANCE UPON, ACCESS TO, OR EXPLOITATION OF THIS SITE, THE PROJECTOR SERVICES, OR THE PROJECTOR MATERIALS OR ANY PART THEREOF, OR ANY RIGHTS GRANTED TO YOU HEREUNDER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE. SOME STATES AND JURISDICTIONS DO NOT ALLOW FOR THE LIMITATION OR EXCLUSION OF LIABILITY, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          INDEMNIFICATION
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You agree to, upon request, defend, indemnify and hold us harmless from any and all liabilities, claims, losses and expenses, including attorneys&apos; fees, which arise directly or indirectly from, or relate to: (1) any breach of these Terms of Use for which you are responsible; (2) your use of this Site, the Projector Services, or the Projector Materials; or (3) your violation, infringement, or misappropriation of the intellectual property, proprietary or other rights of any third party. Projector reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and you shall not in any event settle any such matter without the prior written consent of Projector. This indemnification section will survive any termination or expiration of these Terms of Use.
        </span>

      </p>
      <p className="c5">

        <span className="c4">
          CONTACTING US
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          If you need to contact us regarding this Website or these Terms of Use, please e-mail us at&nbsp;
        </span>
        <span className="c4">
          support@projectorstream.com
        </span>
        <span className="c1">
          . You can also contact us via telephone at 800-410-6134.
        </span>
      </p>
      <p className="c5">

        <span className="c4">
          MISCELLANEOUS
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          These Terms of Use are governed by and construed in accordance with the laws of the State of Ohio and the United States of America, without regards to their respective principles of conflicts of law. You agree to the exclusive personal jurisdiction by the federal and state courts located in Cleveland, Ohio, United States of America, in any matter arising from or related to these Terms of Use and your use of the Website and you waive any jurisdictional, venue, or inconvenient forum objections to such courts.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          If any provision of these Terms of Use is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any remaining provisions.
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          These Terms of Use set out the entire agreement between us relating to the subject matter herein and supersede any and all prior or contemporaneous written or oral agreements between us. These Terms of Use are not assignable, transferable or sub-licensable by you except with Projector’s prior written consent. These Terms of Use provide only for the use of this Website. These Terms of Use shall not be construed as a teaming, joint venture, or other such arrangement, unless the parties expressly and explicitly execute an agreement to that effect. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. Any heading, caption or section title contained in these Terms of Use is inserted only as a matter of convenience and in no way defines or explains any section or provision hereof.
        </span>

      </p>
    </div>
  );
}
