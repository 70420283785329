/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import cn from 'classnames';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { Grid, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ReactComponent as CreateCategory } from 'Assets/create-category.svg';
import AlterImage from 'Assets/alter-image.png';
import styles from './index.module.css';

const CreateCategoryPage = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { setAppSnackbar, langText } = useContext(AppContext);
  const layoutText = langText?.layout;
  const commonText = langText?.common;
  const [val, setVal] = useState(undefined);
  const [selecting, setSelecting] = useState(false);
  const [backGroundImages, setBackgroundImages] = useState(undefined);
  const [selectedBackGroundImage, selectBackGroundImage] = useState(undefined);
  const [defaultBackGroundImage, setDefaultBackGroundImage] = useState(undefined);
  const [categoryID, setCategoryID] = useState(undefined);
  const [categories, setCategories] = useState(undefined);
  const [editing, setEditing] = useState(false);
  const { isValidating: gettingBackgroundImages, mutate: getBackGroundImages } = useSWR(endpoints.getBackgroundImages, {
    onSuccess: ({ success, data }) => {
      if (success) {
        setBackgroundImages(data.filter((item) => parseInt(item.is_default, 10) === 0));
        setDefaultBackGroundImage(data.filter((item) => parseInt(item.is_default, 10) === 1)[0]);
      }
    },
    onError: () => { },
  });

  const { isValidating: addEditingCategory, mutate: addEditCategory } = useSWR([endpoints.addEditCategory, val, categoryID, selectedBackGroundImage], {
    fetcher: (url, categoryName, category_id, icon) => dataFetcher(url, {
      title: categoryName,
      category_id,
      icon
    }),
    onSuccess: ({ success }) => {
      if (success) {
        setMenuOpen(false);
        setAppSnackbar({
          isVisible: true,
          message: `Category ${val} was ${categoryID ? 'updated' : 'created'} `,
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your category' });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data?.message;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData || 'Oops! Something went wrong while creating your category' });
    },
  });

  const { isValidating: gettingCategories, mutate: getCategories } = useSWR([endpoints.getMyCategory], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, data }) => {
      if (success) {
        setCategories(data?.map(({ id, title, icon_id }) => ({ name: title, value: id, icon: icon_id })));
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your categories',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching your categories' });
    },
  });

  const { isValidating: deleting, mutate: deleteGroup } = useSWR([
    endpoints.deleteMyCategory, categoryID
  ], {
    fetcher: (url, id) => dataFetcher(url,
      { catId: id }),
    onSuccess: ({ success }) => {
      if (success) {
        setAppSnackbar({
          isVisible: true,
          message: 'Deleted Successfully',
        });
        setMenuOpen(false);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong',
        });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data?.message;
      setAppSnackbar({
        isVisible: true,
        type: 'error',
        message: responseData || 'Oops! Something went wrong',
      });
    },
  });

  useEffect(() => {
    if (selecting) {
      getBackGroundImages();
    } else {
      setBackgroundImages(undefined);
    }
  }, [selecting]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (!isMenuOpen) {
      setVal(undefined);
      setSelecting(false);
      setEditing(false);
    }
    getCategories();
  }, [isMenuOpen]);

  const editCategory = (name, value, icon) => {
    setVal(name);
    setCategoryID(value);
    setEditing(true);
    selectBackGroundImage(icon);
  };

  const removeCategory = (name, value) => {
    setVal(name);
    setCategoryID(value);
    if (categoryID) {
      deleteGroup();
    }
  };
  return (
    <>
      <div className={cn({ [styles.buttonWithText]: true, [styles.placeEnd]: window.screen.width <= 1350 })}>
        {/* {
          window.screen.width < 800 ? (<span className={styles.createTitle}>{'Categories' || layoutText?.createCategory}</span>) : <span />
        } */}
        <span className={styles.createTitle}>{'Categories' || layoutText?.createCategory}</span>
        <Tooltip title={layoutText?.createCategory || 'Create Categories'} aria-label="Create Categories" disableHoverListener={!(window.screen.width <= 1350)}>
          <CreateCategory onClick={() => { setMenuOpen(true); }} className={styles.playlistMe} />
        </Tooltip>
      </div>
      {isMenuOpen && (
        <div className={styles.createMenuContainer}>
          <div
            className={styles.createButtonScrim}
            onClick={() => { setMenuOpen(false); }}
            onKeyPress={undefined}
            role="button"
            tabIndex={0}
          />
          <div className={styles.createMenu}>
            <div className={styles.headerDiv}>
              <div className={styles.addTitle}>
                {' '}
                {editing ? (layoutText?.editCategory || 'Edit Category') : (layoutText?.addNewCategory || 'Add New Category')}
              </div>
              <div className={styles.backButton} onClick={() => { setMenuOpen(false); }}>
                {' '}
                {commonText?.back || 'Back'}
              </div>
            </div>
            <input
              className={styles.createMenuInput}
              value={val}
              onChange={(e) => { setVal(e.target.value); }}
              maxLength="16"
            />
            <div className={styles.headerDiv}>
              <div className={styles.selectionBox}>
                <div className={styles.addTitle}>
                  {' '}
                  {layoutText?.selectBackGround || 'Select Background'}
                </div>
                {selecting ? <ExpandLess className={styles.arrowIcon} onClick={() => { setSelecting(false); }} /> : <ExpandMore className={styles.arrowIcon} onClick={() => { setSelecting(true); }} />}
              </div>
              <div
                className={styles.addButton}
                onClick={() => { addEditCategory(); }}
              >
                {' '}
                {editing ? (commonText?.save || 'Save') : (commonText?.add || 'Add')}
              </div>
            </div>
            {/* <div className={styles.backgroundContainer}> */}
            <div className={styles.backgroundImageList}>
              {
                gettingBackgroundImages ? <div className={styles.loaderContent}>Loading ...</div> : (
                  backGroundImages?.map(({ imagekit_image, image, id }) => ((
                    <div
                      className={styles.backGroundImage}
                      onClick={() => { selectBackGroundImage(id); }}
                    >
                      <img
                        src={imagekit_image || image || AlterImage}
                        style={{
                          cursor: 'pointer',
                          border: `${selectedBackGroundImage === id ? '4px solid #5aa5ef' : ''}`,
                          width: '177px',
                          height: '62px',
                          borderRadius: '1vh',
                          margin: '5px auto'
                        }}
                        onError={(e) => {
                          e.target.src = AlterImage;
                        }}
                      />
                    </div>
                  )))
                )
              }
            </div>
            <div className={styles.existingSubCatWrap}>
              {
                categories?.map(({ name, value, icon }) => (
                  <Grid container className={styles.subCatDiv}>
                    <Grid item xs={7}>
                      {name}
                    </Grid>
                    <Grid onClick={() => { editCategory(name, value, icon); }} item xs={2} className={styles.editSubCatButton}>
                      {commonText?.edit || 'Edit'}
                    </Grid>
                    <Grid onClick={() => { removeCategory(name, value); }} item xs={3} className={styles.removeSubCatButton}>
                      {commonText?.remove || 'Remove'}
                    </Grid>
                  </Grid>
                ))
              }
            </div>
          </div>

        </div>
      )}
    </>

  );
};

export default CreateCategoryPage;
