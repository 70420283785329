import React from 'react';
import { Link } from 'react-router-dom';
import footerImg from '../assets/img/footer-img.png';
import cube from '../assets/img/cube.svg';
import logo from '../assets/img/logo.svg';
import Button from '../common/Button';

const Footer = () => {
  const streamingServiceText = 'Your Personal Streaming Service !'.toUpperCase();
  const openLink = (e) => {
    window.open(e);
  };
return (
<footer>
  <div className="container">
    <div className="row">
      <div data-aos="fade-right" className="col hide">
        <img src={footerImg} alt="" />
      </div>

      <div data-aos="zoom-in-up" className="col">
        <div className="footer-content">
          <div className="content">
            <div>Get access to Projector.</div>
            <p>
              Now you and your family and friends can
              stream your home movies the way you watch
              your favorite Movies, TV Shows, and
              Documentaries.
            </p>
            <p>
              It&apos;s
              {' '}
              {streamingServiceText}
            </p>
            <Button
              text="Sign Up Now"
              link="/newSign-Up"
              btnClass="blue"
            />

            <img src={cube} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="footer-bottom">
    <div>
      <img src={logo} alt="" />
      <p>
        &#169;
        {`${new Date().getFullYear()} Projector, LLC. All Rights Reserved.`}
      </p>
    </div>

    <ul>
      <li>
        <Link to="/privacy">Privacy Policy</Link>
      </li>
      <li>
        <Link to="/terms-of-use">
          Terms of Use
        </Link>
      </li>
      <li>
        <Link to="/subscriberAgreement">
          Subscriber Agreement
        </Link>
      </li>
      <li>
      <button className="socialLinks" onClick={() => openLink('https://www.facebook.com/projectorstreamingapp/')}>
        Facebook
      </button>
      </li>
      <li>
      <button className="socialLinks" onClick={() => openLink('https://www.linkedin.com/company/79791229/admin/feed/posts/')}>
        LinkedIn
      </button>
      </li>
      <li>
      <button className="socialLinks" onClick={() => openLink('https://www.youtube.com/@ProjectorVideoStreaming')}>
        YouTube
      </button>
      </li>
    </ul>
  </div>
</footer>
);
};

export default Footer;
