/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import cn from 'classnames';
import pt from 'prop-types';
import { dataFetcher, endpoints } from 'Api';
import { AppContext } from 'Context';
import useSWR from 'swr';
import AlterImage from 'Assets/alter-image.png';
import styles from '../index.module.css';

const PersonButton = ({ data, isDisabled, isSelected, onClick, fetchAccessRequests }) => {
  const { setAppSnackbar, subscriptionDetails } = useContext(AppContext);
  const { email, firstname, lastname, image: dpURL, id } = data;
  const name = `${firstname} ${lastname}`;

  const { isValidating: deletingUser, mutate: deleteUser } = useSWR([endpoints.deleteMyViewer, id], {
    fetcher: (url, userId) => dataFetcher(url, { id: userId }),
    onSuccess: ({ success }) => {
      if (success) {
        fetchAccessRequests();
        // setVisibility(false);
        setAppSnackbar({
          isVisible: true,
          message: `${firstname ? `${firstname}` : 'User'} removed`
        });
      } else {
        setAppSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
      // setVisibility(false);
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  return (
    <div
      className={cn({ [styles.personButton]: true, [styles.selected]: isSelected })}
      disabled={isDisabled}
      onClick={onClick}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >

        <div className={styles.personButtonDP}>
          {dpURL
            ? <img onError={(e) => { e.target.src = AlterImage; }} alt={`person - ${name}`} className={styles.personDPImage} src={dpURL || AlterImage} />
            : <div className={styles.personDPText}>{name.charAt(0)}</div>}
        </div>
        <div className={styles.personButtonTextContent}>
          <div className={styles.personButtonName}>{name}</div>
          <div className={styles.personButtonEmail}>{email}</div>
        </div>
      </div>
      <div className={styles.userRequestActionsContainer}>
        <button
          className={cn({ [styles.userRequestActionButton]: true, [styles.delete]: true })}
          onClick={deleteUser}
        >
          {deletingUser ? 'Deleteing...' : 'Delete'}
        </button>
      </div>
    </div>
  );
};

PersonButton.defaultProps = {
  isDisabled: false,
  isSelected: false,
  onClick: undefined,
};

PersonButton.propTypes = {
  data: pt.object.isRequired,
  isDisabled: pt.bool,
  isSelected: pt.bool,
  onClick: pt.func,
};

export default PersonButton;
