/* eslint-disable max-len */
import React from 'react';
import './ppLLC.css';

export default function PPLLC({ isDark }) {
  return (
    <div className="PPLLC">
      {/* eslint-disable-next-line */}
      <style jsx>
        {`.PPLLC
        {
          color :${isDark ? 'white' : 'black'};
          padding-top :${isDark ? '5%' : null};
          background:transparent
        }`}
      </style>
      {/* <p className="c6">

        <span className="c4">
          Privacy Policy
        </span>

      </p> */}

      {/* <p className="c6 c10">
        <span className="c4" />
      </p>
      <p className="c6">

        <span className="c4 c19">
          Last Update: September 2020
        </span>

      </p>

      <p className="c6 c10">

        <span className="c4" />

      </p>

      <p className="c6">

        <span className="c4">
          Scope of This Policy
        </span>

      </p> */}

      <p className="c5">

        <span className="c1">
          Projector, LLC (“Projector,” “we,” “us,” and “our”) respects your concerns about privacy and personal data protection, and we value our relationship with you. This privacy policy describes the treatment of information provided or collected on our website and any other online services, applications where this privacy policy is posted, referenced, or incorporated (collectively, the “Website”), whether on our digital properties or on applications we make available on third-party sites or platforms, and whether accessed via computer, mobile, or other device (collectively, “Device”).
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          Please read this policy carefully because by accessing and using the Website, you are acknowledging that you understand and agree to the terms of this policy.
        </span>

      </p>

      <p className="c6">

        <span className="c4">
          Consent
        </span>

      </p>

      <p className="c5">

        <span className="c1 c19">
          Consent to Data Collection and Processing
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          You hereby agree with and consent to the collection and processing of your personal information as described in this policy.
        </span>

      </p>
      <p className="c0">

        <span className="c1 c19">
          Consent to International Data Transfers
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          Your personal information may be collected, processed and stored by Projectors or its service providers in the United States and other countries where our servers, or the servers of our service providers, reside. Please be aware that the privacy protections and legal requirements, including the rights of authorities to access your personal information, in some of these countries may not be equivalent to those in your country. You hereby agree with and consent to the transfer of your personal data to the United States and other countries where we or our service providers operate and where our servers, and the servers of our service providers, reside.
        </span>

      </p>
      <p className="c0">

        <span className="c1 c19">
          Withdrawal of Consent
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          You have the right, at any time, to withdraw your consent to Projector’s collection and processing of your information, or to the transfer of your personal data to the United States and other countries in accordance with the preceding paragraph. You may withdraw your consent by emailing us at&nbsp;
        </span>
        <span className="c1 c27">
          <a href="mailto:support@projectorstream.com">support@projectorstream.com</a>
          .
        </span>

      </p>
      <p className="c6">

        <span className="c4">
          Collection and Use of Personal Information
        </span>

      </p>
      <p className="c5">

        <span className="c1 c19">
          Information We Collect
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          As you interact with Projector on the Website, there will be opportunities for us to collect, and for you to provide us with, your information. The categories of information that we collect about you may include, but are not limited to:
        </span>

      </p>

      <p className="c0">

        <span className="c2 c3">
          Identifiers and contact information,
        </span>

        <span className="c1">
&nbsp;such as name, physical address, email address, unique personal identifier, online identifier, or IP address.
        </span>

      </p>

      <p className="c0">

        <span className="c2 c3">
          Payment information,
        </span>

        <span className="c1">
&nbsp;such as your credit card number, CVV code, and expiration date.
        </span>

      </p>

      <p className="c0">

        <span className="c2 c3">
          Your “stuff,”
        </span>

        <span className="c1">
&nbsp;including any information provided in the videos you upload to and share on the Website and the size of the file, the time it was uploaded, collaborations, and usage activity.
        </span>

      </p>

      <p className="c0">

        <span className="c2 c3">
          Usage information.
        </span>

        <span className="c1">
&nbsp;which is related to how you use the Website, including actions you take in your account (like sharing, editing, viewing, creating and moving files or folders).
        </span>

      </p>

      <p className="c0">

        <span className="c2 c3">
          Device information.
        </span>

        <span className="c1">
&nbsp;and how you interact with the Website and our products, services, and apps, such as browser type, unique device identifier, cookie data, and associated identifying and usage information.
        </span>

      </p>

      <p className="c0">

        <span className="c2 c3">
          Inferences drawn from other personal information,
        </span>

        <span className="c2">
&nbsp;such as personal preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.&nbsp;
        </span>

      </p>
      <p className="c5">

        <span className="c1 c19">
          Sources of Information
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          We obtain the personal information listed above directly from you as described herein and indirectly from activity on the Website.
        </span>

      </p>
      <p className="c5">

        <span className="c1 c19">
          How We Use Information and How We Communicate with You
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          The information we collect about you and your devices, and the information you provide, may be used:
        </span>

      </p>
      <ul className="c16 lst-kix_list_1-0 start">
        <li className="c9 li-bullet-0">

          <span className="c1">
            To provide our products and services and maintain customer relationships
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            To improve our products and services, including their quality, safety and security
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            To administer your account(s) and process your payments
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            To operate our websites and applications, including online registration processes
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            To autofill data fields on our websites or applications to improve your online experience
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            To develop new products and services
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            To provide customer support and service
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            To provide information and product and service updates
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            For research, evaluation of use, and troubleshooting purposes
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            For marketing and analytics purposes
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            To customize and improve communication content
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            To comply with legal, regulator, or contractual requirements
          </span>

        </li>
      </ul>

      <p className="c0">

        <span className="c1">
          Communications with you in connection with these uses may be via mail, telephone, e-mail, text message, social medial, or other electronic messages, or via our websites and applications.
        </span>

      </p>
      <p className="c5">

        <span className="c1 c19">
          Passive Information Collection and Use
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          As you navigate around the Website, certain information can be passively collected (that is, gathered without you actively providing the information), using various technologies. We passively collect and use information in a variety of ways, including:
        </span>

      </p>
      <ul className="c16 lst-kix_list_1-0 start">
        <li className="c9 li-bullet-0">

          <span className="c1">
            Through your browser and Device: Certain information is collected by most browsers, such as your Media Access Control (MAC) address, computer type (Windows or Macintosh), screen resolution, operating system version, and Internet browser type and version. We collect Device-specific information (such as your hardware model, operating system version, unique device identifiers, and mobile network information including phone number). We may associate your device identifiers or phone number with your Projector account.
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            Cookies: Cookies are pieces of information stored directly on the computer you are using. Cookies allow us to collect information such as browser type, time spent on the Website, pages visited, and language preferences. We and our service providers use the information for security purposes, to facilitate navigation, and display information more effectively. In addition, we use cookies to gather statistical information about usage of the Website in order to continually improve its design and functionality, understand how individuals use it, and to assist us with resolving questions regarding it.
          </span>
        </li>
      </ul>
      <p className="c20">

        <span className="c1">
          You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. If you would prefer not to accept cookies, most browsers will allow you to: (i) change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) to disable existing cookies; or (iii) to set your browser to automatically reject any cookies. If you set your browser to reject cookies, part of the Website may not work for you.
        </span>

      </p>
      <ul className="c16 lst-kix_list_1-0 start">
        <li className="c9 li-bullet-0">

          <span className="c1">
            Pixel tags, web beacons, clear GIFs, or other similar technologies: These may be used in connection with some Website pages to, among other things, track the actions of Website users and compile statistics about Website usage and response rates.
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            IP Address: Your IP Address is a number that is automatically assigned to the computer that you are using by your Internet Service Provider. An IP Address is identified and logged automatically in our server log files whenever a user visits this Website, along with the time of the visit and the page(s) that were visited. Collecting IP Addresses is standard practice on the Internet and is done automatically by many websites. We use IP Addresses for purposes such as calculating Website usage levels, helping diagnose server problems, and administering this Website.
          </span>
        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            Do Not Track Requests: Your selection of the “Do Not Track” option provided by your browser may not have any effect on our collection of cookie information for analytic and internal purposes. The only way to completely opt out of the collection of any information through cookies or other tracking technology is to actively management the settings on your browser or Device to delete and disable cookies and other tracking/recording tools. Please note, depending on your type of Device or browser, it may not be possible to delete or disable all tracking mechanisms on your Device.
          </span>
        </li>
      </ul>
      <p className="c5">

        <span className="c1 c19">
          Integrated Accounts and Social Media Information
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          We may implement applications or &quot;widgets&quot; from third party providers (e.g., Facebook, Twitter, Instagram, LinkedIn, etc.) that allow interaction, integration or content sharing with third party services. These widgets or applications may be visible on a page you visit on our Website or may require log-in through the third party provider’s service. The third party providing the widget or integration may offer controls for you to manage your interactions. Any interaction with a third party widget or application typically allows the third party to collect some information about you (whether through cookies, registration information or otherwise). These third parties may even track you when you do not interact with the widget. If you have engaged any of these third party service providers, their respective privacy policies will govern their tracking and collection of information. You should review these third party policies to understand these practices.
        </span>

      </p>
      <p className="c6">

        <span className="c4">
          Disclosure of Personal Information
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          We may share information as discussed below, but we won’t sell it to advertisers or other third parties.
        </span>
      </p>
      <p className="c0">

        <span className="c2 c3">
          Others working for and with Projector.
        </span>

        <span className="c1">
        &nbsp;Projector uses certain trusted third parties (for example, providers of customer support and IT services, website hosting, data analysis, credit card processing, e-mail services, and auditing services) for the business purposes of helping us provide, improve, protect, and promote our Services. These third parties will access your information to perform tasks on our behalf, and we’ll remain responsible for their handling of your information per our instructions.
        </span>

      </p>
      <p className="c0">

        <span className="c2 c3">
          Law & Order and the Public Interest.
        </span>

        <span className="c1">
        &nbsp;We may disclose your information to third parties if we determine that such disclosure is reasonably necessary to: (a) comply with any applicable law, regulation, legal process, or appropriate government request; (b) protect any person from death or serious bodily injury; (c) prevent fraud or abuse of Projector or our users; (d) protect Projector’s rights, property, safety, or interest; or (e) perform a task carried out in the public interest.
        </span>

      </p>
      <p className="c0">

        <span className="c2 c3">
          Investors and Corporate Transactions.
        </span>

        <span className="c1">
        &nbsp;We may disclose your information to our investors or a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings).
        </span>

      </p>
      <p className="c0">
        <span className="c1">
          Stewardship of your data is critical to us and a responsibility that we embrace. We believe that your data should receive the same legal protections regardless of whether it’s stored on our Services or on your home computer’s hard drive. We’ll abide by the following Government Request Principles when receiving, scrutinizing, and responding to government requests (including national security requests) for your data:
        </span>
      </p>
      <ul className="c16 lst-kix_list_1-0 start">
        <li className="c9 li-bullet-0">

          <span className="c1">
            Be transparent
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            Fight blanket requests
          </span>
        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            Protect all users, and
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c1">
            Provide trusted services
          </span>
        </li>
      </ul>
      <p className="c6">

        <span className="c4">
          International Transfer of Personal Information and Compliance with Local Laws
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          We may transfer your personal information to third parties in locations around the world for the purpose described in this privacy policy. Wherever your personal information is transferred, stored or processed by us, we will take reasonable steps to safeguard the privacy of your personal information. Additionally, when using or disclosing personal information transferred from the European Union, we use standard contractual clauses approved by the European Commission, adopt other means under European Union law for ensuring adequate safeguards, or obtain your consent. We also apply the substantive requirements of standard contractual clauses when transferring personal information from Australia.
        </span>
      </p>
      <p className="c0">

        <span className="c1">
          While this privacy policy applies to personal information generally, the local laws, rules, and regulations of jurisdictions that are applicable to us (“Local Laws”) may require standards which are stricter than this policy and, in such event, we will comply with applicable Local Laws. Specific privacy policies may be adopted to address the specific privacy requirements of particular jurisdictions.
        </span>
      </p>
      <p className="c6">
        <span className="c4">
          Use of Information for Marketing Purposes
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          We may send you regular promotional communications about our products, services, events and promotions. Such promotional materials may use the “Projector” brand and may be sent to you via different channels, such as: email, phone, SMS text messages, postal mailings, and third party social networks.
        </span>
      </p>
      <p className="c0">

        <span className="c1">
          In order to provide you with the best personalized experience, sometimes these communications may be tailored to your preferences (for example, as you indicate these to us, as we may have inferred from your Website visits, or based on the links you click in our emails). When required by applicable law, we will obtain your consent before starting these activities. In addition, you may withdraw your consent (opt-out) at any time.
        </span>
      </p>
      <p className="c0">

        <span className="c1">
          In particular, you can opt-out from receiving marketing-related emails by following the unsubscribe instructions provided in each email. You may also be able to change your communication preferences under the relevant section of the Website. You can also contact us using the contact details provided below to opt-out from receiving marketing-related communications.
        </span>
      </p>
      <p className="c0">

        <span className="c1">
          Please note that even if you opt-out from receiving marketing communications, you might still receive administrative communications from us, such as technical updates of our products, order confirmations, notifications about your account activities, and other important notices.
        </span>
      </p>
      <p className="c6">
        <span className="c4">
          General Principles for the Processing of Personal Information
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          Personal information will be collected, stored, processed and transmitted in accordance with Projector’s established policies and applicable federal, state, local and foreign laws, rules and regulations.
        </span>
      </p>
      <p className="c0">

        <span className="c1">
          The principles of Projector with respect to the processing of personal information are as follows: (1) personal information will be processed fairly and lawfully, (2) personal information will be collected for specified, explicit, and legitimate purposes and not further processed for incompatible purposes, (3) personal information collected by Projector will be adequate, relevant, and limited to what is necessary in relation to the purposes for which they are collected and processed, (4) personal information collected by Projector will be accurate and, where necessary, kept up to date to the best of our ability, (5) personal information collected by Projector will be retained as identifiable data for no longer than necessary to serve the purposes for which the personal information was collected, and (6) personal data will be processed in a manner that ensures appropriate security of the personal data, including protection against unauthorized or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organizational measures.
        </span>
      </p>
      <p className="c0">

        <span className="c1">
          If Projector engages in the processing of personal information for purposes other than those specified in this Policy, Projector will provide notice of these changes, the purposes for which the personal information will be used, and the recipients of personal information.
        </span>
      </p>
      <p className="c6">
        <span className="c4">
          Third Party Websites and Services
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          This policy does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third party operating any site or web property (including, without limitation, any application) that is available through this Website or to which this Website contains a link. The availability of, or inclusion of a link to, any such site or property on this Website does not imply endorsement of it by us or by our affiliates.
        </span>
      </p>
      <p className="c0">

        <span className="c1">
          Third parties may offer products and services on the Websites and collect information about you by requesting certain information necessary to provide a particular product or service (including, for example, insurance coverage for your Projector products or reports of insurance claims). Projector may also obtain certain information about its users from third party customer research groups (e.g., demographic information) which Projector uses to learn more about its customers. Projector does not control the policies and practices of third parties in relation to the use and disclosure of any information about users collected by third parties on the Website or in market research programs conducted by such third parties and any use or disclosure of such information by third parties is not governed by this policy.
        </span>
      </p>
      <p className="c6">
        <span className="c4">
          Accuracy
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          It is your responsibility to provide Projector with accurate personal information. Except as otherwise set forth in this Policy, Projector shall only use personal information in ways that are compatible with the purposes for which it was collected or subsequently authorized by you. To the extent necessary for these purposes, Projector shall take reasonable steps to ensure that personal information is accurate, complete, current and relevant to its intended use
        </span>
      </p>
      <p className="c6">
        <span className="c4">
          Your Control and Access of Your Information
        </span>

      </p>
      <p className="c5">

        <span className="c1">
          You have control over your personal information and how it’s collected, used, and shared. For example, you can:
        </span>
      </p>
      <ul className="c16 lst-kix_list_1-0 start">
        <li className="c9 li-bullet-0">

          <span className="c2 c3">
            Delete your “stuff” in your Projector account.
          </span>

        </li>
        <li className="c9 li-bullet-0">
          <span className="c2 c3">
            Change or correct personal data.
          </span>

          <span className="c1">
  &nbsp;You can manage your account and the content contained in it, as well as edit some of your personal data, through your account.
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c2 c3">
            Access and take your data elsewhere.
          </span>

          <span className="c1">
  &nbsp;You can access your personal data from you Projector account. You can also ask us for a copy of personal data you provided to us or that we’ve collected, the business or commercial purpose for collecting it, the types of sources we got it from, and types of third parties we’ve shared it with.
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c2 c3">
            Object to the processing of your personal data.
          </span>

          <span className="c1">
  &nbsp;Depending on the processing activity, you can request that we stop or limit processing of your personal data.
          </span>
        </li>
      </ul>
      <p className="c5">

        <span className="c1">
          If you would like to submit a data access request, request that your personal data be deleted, or object to the processing of your personal data, please email us at&nbsp;
        </span>
        <span className="c1 c27">
          <a href="mailto:privacy@Projectorstream.com">privacy@Projectorstream.com</a>
          .&nbsp;
        </span>
        <span className="c1">
          We will try to comply with your request as soon as reasonably practicable.
        </span>
      </p>
      <p className="c11">

        <span className="c4">
          Your Rights Over Your Information
        </span>

      </p>

      <p className="c5">

        <span className="c1">
          By law, you have a number of important rights. In summary, those include the following:
        </span>

      </p>
      <ul className="c16 lst-kix_list_1-0 start">
        <li className="c9 li-bullet-0">

          <span className="c1">
            fair processing of your personal data and transparency over how we use your personal data;
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            access to your personal data and to certain other supplementary information that this privacy policy is already designed to address;
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c1">
            require us to correct any mistakes in your information which we hold;
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c1">
            require the erasure of personal data concerning you, in certain situations;
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c1">
            receive the personal data concerning you that you have provided to us, in a structured, commonly used and machine readable format, and have the right to transmit that data to a third party, in certain situations;
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c1">
            object at any time to processing of personal data concerning you for direct marketing;
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c1">
            object to decisions being taken by automated means that produce legal effects concerning you or similarly significantly affect you; and
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c1">
            object in certain other situations to our continued processing of your personal data.
          </span>
        </li>
      </ul>

      <p className="c5">

        <span className="c1 c19">
          California Privacy Rights
        </span>

      </p>
      <p className="c0">
        <span className="c1">
          The California Consumer Privacy Act (the “CCPA”) grants users of our Website that are California residents certain rights in regards to our collection of personal information. These rights include:
        </span>
      </p>
      <ul className="c16 lst-kix_list_1-0 start">
        <li className="c9 li-bullet-0">

          <span className="c1">
            The right to request the disclosures of (1) what personal information has been collected; (2) the categories of sources from which the personal information was collected; (3) the business or commercial purpose for collection or selling personal information; (4) the categories of third parties with whom the business shares personal information; and (5) the specific pieces of personal information we have collected;
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            The right to request the deletion of their personal information that has been collected; and
          </span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c1">
            The right to opt-out of the sale of their personal information.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1">
          Under the CCPA, a data subject may only make a personal information request twice in a 12-month period. We will respond to such personal information request within 45 days of receiving the personal information request.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Additionally, if you are a California resident, you have the right to request information from us regarding the manner in which we share certain categories of your personal information with third parties, for the third parties’ direct marketing purposes. California law provides that you have the right to submit a request to us at our designated address and receive the following information:
        </span>
      </p>
      <ul className="c16 lst-kix_list_1-0 start">
        <li className="c9 li-bullet-0">

          <span className="c1">
            The categories of information we disclosed to third parties for the third parties’ direct marketing purposes during the preceding calendar year; and
          </span>

        </li>
        <li className="c9 li-bullet-0">

          <span className="c1">
            The names and address of third parties that received such information, or if the nature of their business cannot be determined from the name, then examples of the products or services marketed.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1">
          You are entitled to receive a copy of this information in a standardized format and the information will not be specific to you individually. You may make such a request by calling 800-410-6134
        </span>
      </p>
      <p className="c5">

        <span className="c1 c19">
          Exercising Your Rights
        </span>

      </p>
      <p className="c0">
        <span className="c1">
          If you would like to exercise any of these rights, please contact us via the contact methods described herein with the following: (1) enough information to identify you; (2) proof of your identity and address; and (3) the information to which your request relates. We will not discriminate in any way against any users of the Website that exercise their rights provided by law.
        </span>
      </p>
      <p className="c6">

        <span className="c4">
          Security
        </span>

      </p>

      <p className="c5">

        <span className="c1">
          We are dedicated to keeping your information secure and testing for vulnerabilities. We have implemented security measures designed to protect personal information provided by you against unauthorized access and use. We use commercially reasonable efforts to establish security connections with your web browser for any transactions conducted online using a credit card or other financial information you provide to us. We continue to work on features to keep your information safe in addition to things like two-factor authentication, encryption of files at rest, and alerts when new devices and apps are linked to your account.
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          Please be aware, however, that no data transmission over the internet is 100% security and any information disclosed online can potentially be collected and used by parties other than the intended recipient. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us of the problem using the contact information provided herein.
        </span>

      </p>
      <p className="c6">

        <span className="c4">
          Retention
        </span>

      </p>

      <p className="c5">

        <span className="c1">
          We will retain your personal information for as long as your account exists, or as long as we need it to provide you the products and services to you or otherwise fulfill the purposes outlined in this policy, unless a longer retention period is required or allowed by law, or to otherwise fulfill a legal obligation. If you delete your account, we will initiate deletion of your personal information after 60 days.
        </span>

      </p>
      <p className="c6">

        <span className="c4">
          Children’s Privacy
        </span>

      </p>

      <p className="c5">

        <span className="c1">
          We recognize the need to provide further privacy protections with respect to personal information we may collect from children on our sites and applications. This Website is not directed to individuals under the age of 13, and we request that these individuals not provide personal information through this Website. Some of the features on our sites and applications are age-gated so that they are not available for use by children under 13, and we do not knowingly collect personal information from children under 13 in connection with those features.
        </span>

      </p>
      <p className="c6">

        <span className="c4">
          Changes
        </span>

      </p>

      <p className="c5">

        <span className="c1">
          We may revise this privacy policy from time to time and will post the most current version on our website. Any changes to this policy will become effective when we post the revised policy on this website. Your use of the Website following these changes means that you accept the revised policy.
        </span>

      </p>
      <p className="c6">

        <span className="c4">
          Contacting Us
        </span>

      </p>

      <p className="c5">

        <span className="c1">
          If you have any questions about this policy, please contact us:
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          Projector, LLC
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          800-410-6134
        </span>

      </p>
      <p className="c0">

        <span className="c1">
          support@projectorstream.com
        </span>

      </p>

      <p className="c11 c10">

        <span className="c18" />

      </p>
    </div>
  );
}
