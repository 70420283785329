import React, { useState, useEffect } from 'react';
import 'Pages/userManagement/UserM.css';
import CloseIcon from '@mui/icons-material/Close';

const SimpleSnackBar = ({ message, setOpen }) => {
    const [isVisible, setIsVisible] = useState(true);
    const handleClose = () => {
      setOpen(false);
    };
  return (
    <div className={`theDissmisal ${isVisible ? 'show' : ''}`}>
      <div
        className="closerX"
        onKeyPress={undefined}
        role="button"
        tabIndex={0}
        onClick={() => handleClose()}
      >
          <CloseIcon />
      </div>
      {message}
    </div>
  );
};
export default SimpleSnackBar;
