import React from 'react';
import cn from 'classnames';
import logoWBG from 'Assets/logo-white-bg.png';
import { Link } from 'react-router-dom';
import logo from 'Assets/Login Logo.png';
import logoBBG from 'Assets/logo-black-bg.png';
import logoImage from 'Assets/logo.png';
import styles from './index.module.css';

export const Logo = ({ className, isLight, isLarge, footerLogo }) => (
  <div className={cn({ [styles.logo]: true, [styles.large]: isLarge, [className]: className, [styles.footerLogo]: footerLogo })}>
    <Link to="/">
    <img className={styles.logoImage} src={logo} alt="projector logo" />
    </Link>
  </div>

);
