/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import cn from 'classnames';
import { Slider, withStyles, Tooltip } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TransformIcon from '@material-ui/icons/Transform';
import { ReactComponent as LinkMusic } from 'Assets/link-music.svg';
import { ReactComponent as AppleMusic } from 'Assets/apple-music.svg';
import { ReactComponent as Spotify } from 'Assets/spotify.svg';
import styles from './index.module.css';

const SlideShowSettings = ({ transitionSpeed, transitionSpeedHandler, transitionType, transitionChange, selectedTransitionType, setTransitionSpeed, savedSettings, updateAllAlbumSettings }) => {
  const { setAppSnackbar, langText } = useContext(AppContext);
  const layoutText = langText?.layout;
  const commonText = langText?.common;
  const regExpressionHandler = (e) => {
    if (/\D/g.test(e.target.value)) {
      // Filter non-digits from input value.
      setTransitionSpeed(e.target.value.replace(/\D/g, ''));
    }
  };

  return (
    <div className={styles.slideShowContainer}>
      <div className={styles.chooseOrder}>
        {layoutText?.slideShowSettings || 'Slide Show Settings'}
      </div>
      <div className={styles.albumSettingsBundle}>
        <div className={styles.albumSettingsDiv}>
          <div>
            <Tooltip title={layoutText?.slideShowSpeed || 'Slide Show Speed'} aria-label={layoutText?.slideShowSpeed || 'Slide Show Speed'} disableHoverListener={!(window.screen.width <= 500)}>
              <span style={{ cursor: 'pointer' }}>
                <AccessTimeIcon className={styles.addMorePhotoIcon} />
              </span>
            </Tooltip>
<span className={styles.addMorePhoto}>{layoutText?.slideShowSpeed || 'Slide Show Speed'}</span>
          </div>
          <div className={styles.selectTransition}>
            <input
              pattern="[0-9]+"
              step="1"
              onKeyUp={regExpressionHandler}
              type="number"
              id="transitionSpeedInput"
              className={styles.slideShowTransitions}
              onChange={transitionSpeedHandler}
              value={transitionSpeed}
              name="quantity"
              min={4}
              max={120}
            />
            {/* <select name="transition-time" className={styles.slideShowTransitions} onChange={transitionSpeedHandler} value={transitionSpeed}>
              {
                transitionTimeArray?.map((time) => (
                  <option value={time}>{time}</option>
                ))
              }
            </select> */}
            {/* <div
              className={cn({
                [styles.saveButtonForSettings]: true,
                [styles.saveButtonForSettingDisabled]: settingsType !== 1,
              })}
              onClick={() => { setUpdatingSettings(true); saveSettings(); }}
            >
              {updatingSettings && settingsType === 1 ? `${commonText?.updating || 'Updating'}...` : commonText?.update}
            </div> */}
          </div>
        </div>
        <div className={styles.albumSettingsDiv}>
          <div>
            <Tooltip title={layoutText?.slideShowTransition || 'Slide Show Transition'} aria-label={layoutText?.slideShowTransition || 'Slide Show Transition'} disableHoverListener={!(window.screen.width <= 500)}>
              <span style={{ cursor: 'pointer' }}>
                <TransformIcon className={styles.addMorePhotoIcon} />
              </span>
            </Tooltip>
        <span className={styles.addMorePhoto}>{layoutText?.slideShowTransition || 'Slide Show Transition'}</span>
          </div>
          <select name="transition-type" className={styles.slideShowTransitions} disabled onChange={transitionChange} value={selectedTransitionType}>
            {
              transitionType?.map((type) => (
                <option value={type.class}>{type.name}</option>
              ))
            }
          </select>
          {/* <div
            className={cn({
              [styles.saveButtonForSettings]: true,
              [styles.saveButtonForSettingDisabled]: settingsType !== 2,
            })}
            onClick={() => { setUpdatingSettings(true); saveSettings(); }}
          >
            {updatingSettings && settingsType === 2 ? `${commonText?.updating || 'Updating'}...` : commonText?.update}
          </div> */}
        </div>
        {/* <div className={styles.betaComingSoonWrap}>
          <LinkMusic className={styles.comingSoonIcon} />
          <AppleMusic className={styles.comingSoonIcon} />
          <Spotify className={styles.comingSoonIcon} />
          <div className={styles.betaComingSoonButton}>{layoutText?.betaComingSoon || 'Beta coming soon'}</div>
        </div> */}
        <div className={styles.updateButtonContainer}>
          <div
            onClick={() => { updateAllAlbumSettings(); }}
            className={cn({
              [styles.updateButton]: true,
              [styles.changeValue]: (savedSettings.type !== selectedTransitionType || savedSettings.speed !== transitionSpeed),
              [styles.saveButtonForSettingDisabled]: savedSettings.type === selectedTransitionType && savedSettings.speed === transitionSpeed
            })}
          >
            {commonText?.update || 'Update'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideShowSettings;
