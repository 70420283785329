/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import cn from 'classnames';
import { ReactComponent as RightArrow } from 'Assets/video-right-arrow.svg';
import { ReactComponent as LeftArrow } from 'Assets/video-left-arrow.svg';
import AlterImage from 'Assets/alter-image.png';
import { dataFetcher, endpoints } from 'Api';
import { ProfilePage, Button } from 'Components';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import { Image } from 'semantic-ui-react';
import { Box } from '@material-ui/core';
import { CONTENT_TYPES } from 'Pages/private/profile';
import VideoAutoPlayer from './video-autoplayer';
import styles from './index.module.css';
import { TitleLabel } from './video-autoplayer/TitleLabel';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
};

const isTrueValue = true;
const isFalseValue = false;
const ImageGroupTitle = ({ title, marginTitle, resumeWatching, setLoading, clearResumeWatching }) => (
  <div className={styles.headingWrapper}>
    <h3
      className={cn({ [styles.imageGroupTitle]: true, [styles.marginTitle]: !marginTitle })}
    >
      {title}
    </h3>
    {
      resumeWatching ? (
        <div onClick={() => { setLoading(true); clearResumeWatching(); }} className={styles.clearAll}>Clear All</div>
      ) : null
    }
  </div>
);

const CustomRightArrow = ({ onClick }) => ((
  <RightArrow
    fontSize="large"
    onClick={onClick}
    aria-label="Go to next slide"
    className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right custom_arrow"
  // className="right-arrow"
  // style={{ zIndex: '1000', cursor: 'pointer', height: '40px' }}
  />
));

const CustomLeftArrow = ({ onClick }) => ((
  <LeftArrow
    onClick={onClick}
    aria-label="Go to next slide"
    className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left custom_arrow"
  // className="left-arrow"
  // style={{ zIndex: '1000', cursor: 'pointer', height: '40px', marginLeft: '-100%', background: 'none' }}
  />
));
const SearchGroup = ({ center, title, videos, isGrid, searchTerm, type, albums, catG, resumeWatching, setLoading, clearResumeWatching, isPreview, handleCarousel }) => {
  const navigate = useNavigate();
  const [isMoving, setMoving] = useState(false);
  const [showArrow, setArrows] = useState(false);
  const [showAutoPlayDiv, setAutoPlayDiv] = useState(undefined);
  let myTimeout;
  const [dimensions, setDimensions] = useState({
    // top: '0px',
    // left: '0px',
  });
  const [videoDetails, setVideoDetails] = useState({
    videoURL: '',
    thumbNail: '',
    title: '',
    subCategory: ''
  });
  const mouseOverHandler = (event, id, video_file, thumbnails, title, subCategory) => {
    const rect = event.target.getBoundingClientRect();
    setDimensions({});
    setAutoPlayDiv(id);
    setVideoDetails({ videoURL: video_file, thumbNail: thumbnails, title, subCategory });
  };

  const mouseLeaveHandler = () => {
    clearInterval(myTimeout);
    setAutoPlayDiv(undefined);
    setVideoDetails({ videoURL: '', thumbNail: '', title: '', subCategory: '' });
  };

  useEffect(() => {
    const autoPlayElement = document?.getElementById('autoplay-div');
    const thumbnailLinkElement = document?.getElementById('thumbnail-link');
    autoPlayElement?.addEventListener('mouseleave', mouseLeaveHandler);
    return () => autoPlayElement?.removeEventListener('mouseleave', mouseLeaveHandler);
  });

  function beforeChange(index, type) {
    setMoving(true);
    if (handleCarousel) {
      handleCarousel(index, type);
    }
  }

  function changetouniform(imageData) {
      // Regular expression to match various image URL formats
      const regex = /["[]*([^"\]]+)["\]]*/;

      // Extracting the URL from the imageData
      if (imageData) {
        const match = imageData.match(regex);

        if (match && match.length >= 2) {
            // Return the uniform format
            return `"${match[1]}"`.replace(/"/g, '');
        }
      }
      // If no match found, return empty string or original data
      return '';
  }

  const carouselStyle = {
    // Define your inline styles here
    backgroundColor: 'blue',
    color: 'white',
    // Add any other CSS properties you want
  };
  return (
    <div
      className={cn({ [styles.paddingTop0]: true, [styles.imageGroup]: true, [styles.paddingTopForImage]: catG })}
    >
      {
        title
        && <ImageGroupTitle title={title} marginTitle={catG} resumeWatching={resumeWatching} setLoading={setLoading} clearResumeWatching={clearResumeWatching} />
      }
      {/* {searchTerm && <ImageGroupTitle title={`Search results for "${searchTerm}"`} />} */}
      <div className={styles.imageGroupImagesAllVideo}>
        <div className={cn({ [styles.imageGroupImagesContainer]: true, [styles.isGrid]: isGrid })}>
          {/* {searchTerm && (
            <h6 style={{ fontSize: '12pt', color: 'white' }}>
              Results for
              {' '}
              {searchTerm || '[Search]'}
            </h6>
          )} */}
          {
          albums && albums?.length > 0 && (
                <div
                  onMouseEnter={() => setArrows(true)}
                  onMouseLeave={() => setArrows(false)}
                >
                <div className={styles.gridz}>
                {
                      type === CONTENT_TYPES.photo_albums && (
                        isPreview ? (
                          albums?.length > 0 && albums?.map(({ id, photos, icon, photo_file, title: albumTitle, album_id }, idx) => (
                            <Box className={styles.hoverZoom}>
                              <Link className={styles.imageGroupImageLink} key={idx} to={`${Pathname.getAlbumPlayerPath(album_id, id)}`}>
                                <Image
                                  src={photo_file}
                                  className={`${styles.imageGroupImage} ${styles.imageGroupImageCarousel}`}
                                />
                              </Link>
                            </Box>
                          ))
                        ) : (
                          albums?.map(({ id, photos, icon, photo_file, title: albumTitle }, idx) => (
                            photos?.length > 0 && (
                              <Box className={styles.hoverZoom}>
                                <Link
                                  className={cn({ [styles.imageGroupImageLink]: true, [styles.albumTransition]: true })}
                                  key={idx}
                                  to={Pathname.getAlbumPath(id)}
                                // style={{ width: '80%' }}
                                >
                                  <Image
                                    src={icon}
                                    className={`${styles.imageGroupImage}`}
                                    onError={(e) => {
                                      e.target.src = AlterImage;
                                    }}
                                  />
                                  <TitleLabel title={albumTitle} />
                                </Link>
                              </Box>
                            )
                          ))
                        )
                      )
                    }
                </div>
                </div>
              )
          }
          {
          videos && videos?.length > 0 && (
                <div
                  onMouseEnter={() => setArrows(true)}
                  onMouseLeave={() => setArrows(false)}
                >
                  <div className={styles.gridz}>
                  {
                      type !== CONTENT_TYPES.photo_albums && (
                        videos?.length > 0 && videos?.map(({ id, thumbnails, draft_thumbnails, title: videoTitle, SubCategory, video_file }, idx) => (
                          <Box className={styles.hoverZoom}>
                            <Link
                              onMouseEnter={(event) => mouseOverHandler(event, id, video_file, thumbnails, videoTitle, SubCategory)}
                              onMouseLeave={mouseLeaveHandler}
                              className={styles.imageGroupImageLink}
                              key={idx}
                              to={isPreview ? `${Pathname.getPlayerPath(id, 'play')}` : Pathname.getVideoPath(id)}
                              // to={Pathname.getVideoPath(id)}
                              id="thumbnail-link"
                            >
                              <VideoAutoPlayer
                                dimensions={dimensions}
                                videoDetails={videos[idx]}
                                thumbNail={changetouniform(draft_thumbnails) || thumbnails || AlterImage}
                                videoID={showAutoPlayDiv}
                                autoPlay={showAutoPlayDiv === id}
                              />
                              <TitleLabel title={videoTitle} category={SubCategory} />

                            </Link>
                          </Box>
                        ))
                      )
                    }
                  </div>
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
};
export default SearchGroup;
