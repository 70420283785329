import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { AppContext } from 'Context';
import { baseUrl } from 'Api';
import Req from '../../Assets/req.png';

const AddBusiness = ({ setOpenBusinessModal, name }) => {
    const [businessName, setBusinessName] = useState(null);
    const [loading, setLoading] = useState(false);

    const { fetchData } = useContext(AppContext);

    useEffect(() => {
        setBusinessName(name);
    }, [name]);

    const handleClose = () => {
        setOpenBusinessModal(false);
    };

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const getToken = () => localStorage.getItem('token');

    const handleAdd = async () => {
        const requestBody = {
            token: getToken(),
            business_name: businessName,
        };

        setLoading(true);
        try {
            const response = await Axios.post(`${baseUrl}/saveBusinessName`, requestBody);
            if (response.data.success === true) {
                fetchData();
                handleClose();
                setBusinessName(null);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className="fullFledge"
            role="button"
            tabIndex={0}
            onClick={handleClose}
            onKeyDown={() => { }}
        >
            <div
                className="modal"
                // ref={modalRef}
                role="button"
                tabIndex={0}
                onClick={handleModalClick}
                onKeyDown={() => { }}
            >
                <div className="modelFelx">
                    <img className="req" src={Req} alt="" />
                    <p className="userName noMARGIN">Add New Business</p>
                </div>
                <div className="chipToInputBox">
                    <input
                        type="mail"
                        className="searcher"
                        placeholder="Enter Business Name"
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                    />
                </div>
                <div style={{ width: '100%', display: 'flex' }}>
                    <button
                        className="adME"
                        disabled={businessName?.length === 0 || loading}
                        onClick={handleAdd}
                        style={{
                            cursor: (businessName?.length === 0 || loading) ? 'not-allowed' : '',
                            opacity: (businessName?.length === 0 || loading) ? '0.5' : ''
                        }}
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddBusiness;
