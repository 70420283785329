/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import OtpTimer from 'otp-timer';
import { Pathname } from 'Routes';
import { AppContext, AuthContext } from 'Context';
import { dataFetcher, endpoints } from 'Api';
import { isMobile } from 'react-device-detect';
import { AuthPage } from '../components/page';
import styles from './index.module.css';
import authStyles from '../auth.module.css';

export const EmailVerification = () => {
  const { setAppSnackbar, plansPage, setPlansPage, langText } = useContext(AppContext);
  const { setToken, forceAutoSignin, setEmailVerified } = useContext(AuthContext);
  const signUpText = langText?.signInUp;
  const emailValidationText = langText?.emailValidation;
  const navigate = useNavigate();
  const [email, setEmail] = useState(sessionStorage.getItem('emailForValidation_projectoruser'));
  const [otp, setOtp] = useState(undefined);
  const [encodedParams, setEncodedParams] = useState({
    code: '',
    email: ''
  });
  const password = sessionStorage.getItem('password_projectoruser');
  const [expireInSec, setExpirySec] = useState(0);
  const [expireInMin, setExpiryMin] = useState(0);
  const [errorMsg, setErrorMsg] = useState(`Verify your email address. Check your email ${email} for the link to verify your email, if you don't see it check your spam.`);
  const { isValidating: skippingEmailVerification, mutate: skipEmailVerification } = useSWR([endpoints.signin, email, password], {
    fetcher: (url, inputEmail, inputPassword) => dataFetcher(url, { email: inputEmail, password: inputPassword }),
    onSuccess: ({ success, data }) => {
      sessionStorage.removeItem('emailForValidation_projectoruser');
      sessionStorage.removeItem('password_projectoruser');
      if (success) {
        setToken(data?.token);
        setAppSnackbar({ message: 'Logged in successfully', isVisible: true });
        forceAutoSignin();
        if (plansPage) {
          setPlansPage(false);
          navigate(Pathname.plans);
        } else {
          // navigate(Pathname.gettingStarted);
          navigate(Pathname.access);
        }
      } else {
        setAppSnackbar({ type: 'error', message: 'Incorrect password', isVisible: true });
      }
    },
    onError: () => {
      setAppSnackbar({ type: 'error', message: 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: gettingEmailVerifyingStatus, mutate: getEmailVerifyStatus } = useSWR([endpoints.getEmailVerifyStatus, email], {
    fetcher: (url, email) => dataFetcher(url, { email }),
    onSuccess: ({ success, is_email_verified }) => {
      if (success) {
        setEmailVerified(parseInt(is_email_verified, 10));
        if (parseInt(is_email_verified, 10)) {
          forceAutoSignin();
          navigate(Pathname.access);
        }
      } else {
        // setAppSnackbar({ type: 'error', message: 'Incorrect password', isVisible: true });
      }
    },
    onError: () => {
      // setAppSnackbar({ type: 'error', message: 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: verifyingEmail, mutate: verifyEmail } = useSWR([endpoints.verifyEmail, encodedParams.email, encodedParams.code], {
    fetcher: (url, access_key, code) => dataFetcher(url, { access_key, code }),
    onSuccess: ({ success, message }) => {
      if (success) {
        // signInUser();
      } else {
        setAppSnackbar({ type: 'error', message, isVisible: true });
      }
    },
    onError: (error) => {
      setAppSnackbar({ type: 'error', message: error?.response?.data?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: generatingCode, mutate: generateVerificationCode } = useSWR([endpoints.generateVerificationCode, email], {
    fetcher: (url, email) => dataFetcher(url, { email }),
    onSuccess: ({ success, message, expire_in }) => {
      if (success) {
        const expireTimeInt = parseInt(expire_in, 10);
        setExpiryMin(Math.floor(expireTimeInt / 60));
        setExpirySec(expireTimeInt % 60 ? expireTimeInt % 60 : 0);
        setErrorMsg(message);
      } else {
        setExpiryMin(0);
        setExpirySec(0);
        setErrorMsg(message);
      }
    },
    onError: (error) => {
      setExpiryMin(0);
      setExpirySec(0);
      setErrorMsg(error?.response?.data?.message);
      // setAppSnackbar({ type: 'error', message: error?.response?.data?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  useEffect(() => {
    generateVerificationCode();
  }, []);

  useEffect(() => {
    if (otp) {
      setEncodedParams({ code: Buffer.from(otp).toString('base64'), email: Buffer.from(email).toString('base64') });
    }
  }, [otp]);

  useEffect(() => {
    const interval = setInterval(() => {
      getEmailVerifyStatus();
    }, 15000);
    return () => clearInterval(interval);
    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  return (
    <>
      <AuthPage>
        {
          !isMobile
        && <div aria-hidden="true" onClick={() => { navigate(-1); }} className={styles.backLink}>{signUpText?.back || 'BACK'}</div>
        }
        <div className={authStyles.suggestionText}>
          <div className={styles.notifyText}>
            {errorMsg}
            &nbsp;
          </div>
          {
            expireInSec !== 0 && expireInMin !== 0 ? (
              <div className={styles.timer}>
                <OtpTimer seconds={expireInSec} minutes={expireInMin} resend={generateVerificationCode} textColor="#fff" text={`${emailValidationText?.expireIn} : ` || 'Link expires in : '} />
              </div>
            ) : null
          }
        </div>
      </AuthPage>
      <div
        onClick={() => { skipEmailVerification(); }}
        className={styles.skipDiv}
      >
        Skip for now
      </div>
    </>
  );
};
