import React, { useState } from 'react';
import cn from 'classnames';
import styles from '../index.module.css';

const InputField = ({ alignLeft, className, defaultValue, icon: Icon, id, isDisabled, label, onChange, placeholder, type, isOptional, isRequired }) => {
  const [isFocused, setFocus] = useState(false);

  const focus = () => { setFocus(true); };
  const blur = () => { setFocus(false); };

  return (
    <label
      className={cn({
        [styles.inputField]: true,
        [styles.alignLeft]: alignLeft,
        [styles.inputFieldFocus]: isFocused,
        [className]: className,
      })}
      htmlFor={id}
    >
      <div className={styles.inputFieldText}>
        {type === 'textarea'
          ? (
            <textarea
              className={styles.input}
              value={defaultValue}
              disabled={isDisabled}
              id={id}
              onBlur={blur}
              onChange={({ target: { value } }) => { onChange(value); }}
              onFocus={focus}
              placeholder={placeholder}
              rows="3"
            />
          )
          : (
            <input
              className={styles.input}
              defaultValue={defaultValue}
              disabled={isDisabled}
              id={id}
              onBlur={blur}
              onChange={({ target: { value } }) => { onChange(value); }}
              onFocus={focus}
              placeholder={placeholder}
              type={type}
            />
          )}
        <div className={styles.inputFieldLabel}>
          {label}
          {isOptional && <span className={styles.optionalInput}> (Optional)</span>}
          {isRequired && <span className={styles.optionalInput}> (Required)</span>}
        </div>
      </div>
      {Icon && <Icon />}
    </label>
  );
};
export default InputField;
