/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { Pathname } from 'Routes';
import PlayerControls from './playerControls';
import styles from './index.module.css';
import { TitleLabel } from './TitleLabel';

const VideoAutoPlayer = ({ dimensions, videoDetails, videoID, autoPlay, thumbNail }) => {
  const playerRef = useRef(null);
  const isTrueValue = true;
  const [state, setState] = useState({
    playing: true,
    played: 0,
    seeking: false,
    buffering: true,
    muted: true,
  });
  const navigate = useNavigate();

  const { playing, played, seeking, buffering, muted } = state;

  const format = (seconds) => {
    if (Number.isNaN(seconds)) {
      return '00:00';
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const currentTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : '00:00';
  const duration = playerRef.current
    ? playerRef.current.getDuration()
    : '00:00';
  const elapsedTime = playerRef.current ? format(currentTime) : '00:00';
  const totalDuration = playerRef.current ? format(duration) : '00:00';

  useEffect(() => {
    if (elapsedTime === '0:20') {
      setTimeout(() => {
        setState({ ...state, playing: false, buffering: true });
      }, 500);
    }
  }, [elapsedTime]);

  const onEndBuffering = () => {
    setState({ ...state, buffering: false });
  };

  const onEndedHandler = () => {
    setState({ ...state, buffering: true });
  };

  const handleSeekChange = (newValue) => {
    setState({ ...state, played: Math.floor(newValue) });
  };

  const handleSeekMouseUp = (newValue) => {
    setState({ ...state, seeking: false });
    playerRef.current.seekTo(newValue);
  };

  const handleProgress = (changeState) => {
    if (!seeking) {
      setState({ ...state, ...changeState });
    }
  };

  return (
    <div onClick={() => { navigate(Pathname.getVideoPath(videoID)); }} id="autoplay-div" className={styles.videoPlayer}>
      {autoPlay ? (
        <ReactPlayer
          ref={playerRef}
          width="100%"
          height="200px"
          url={videoDetails?.video_file}
          className={styles.react_player}
          playing={playing}
          muted={muted}
          onBufferEnd={onEndBuffering}
          onProgress={handleProgress}
          autoPlay={autoPlay}
          progressInterval={100}
          onEnded={onEndedHandler}
        />
      )
        : <img className={styles.thumbNail} src={thumbNail} alt="" width="100%" height="200px" />}
    </div>
  );
};
export default VideoAutoPlayer;
