import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { ReactComponent as RightArrow } from 'Assets/video-right-arrow.svg';
import { ReactComponent as LeftArrow } from 'Assets/video-left-arrow.svg';
import 'react-multi-carousel/lib/styles.css';
import cn from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import { Pathname } from 'Routes';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import AlterImage from 'Assets/alter-image.png';
import { Image } from 'semantic-ui-react';
import styles from '../index.module.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const isTrueValue = true;
const isFalseValue = false;
const ImageGroupTitle = ({ title }) => (<h3 className={styles.imageGroupTitle}>{title}</h3>);

const CustomRightArrow = ({ onClick }) => ((
  <RightArrow
    fontSize="large"
    onClick={onClick}
    aria-label="Go to next slide"
    style={{ top: '26%', right: '15px' }}
    className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right custom_arrow"
  // className="right-arrow"
  // style={{ zIndex: '1000', cursor: 'pointer', height: '40px' }}
  />
));

const CustomLeftArrow = ({ onClick }) => ((
  <LeftArrow
    onClick={onClick}
    style={{ top: '26%' }}
    aria-label="Go to next slide"
    className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left custom_arrow"
  // className="left-arrow"
  // style={{ zIndex: '1000', cursor: 'pointer', height: '40px', marginLeft: '-100%', background: 'none' }}
  />
));

const PhotoList = ({ photos, albumId }) => (
  <div className={styles.photolistWrapper}>
    { photos?.length > 0
    ? (
      <Carousel
        responsive={responsive}
        swipeable={isFalseValue}
        draggable={isFalseValue}
        ssr={isTrueValue} // means to render carousel on server-side.
        autoPlaySpeed={1000}
        keyBoardControl={isTrueValue}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container-www"
        itemClass="album-image-item"
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {
          photos?.map(({ photo_file, imagekit_url, id }) => ((
            <Image
              draggable={false}
              onError={(e) => {
                e.target.src = `${imagekit_url || photo_file || AlterImage}?tr=orig`;
              }}
              src={imagekit_url || photo_file || AlterImage}
              className={cn({ [styles.previewImage]: true, [styles.showImage]: true })}
            />
          )))
        }
      </Carousel>
      )
    : null }
    {/* <Link onClick={() => { localStorage.setItem('content_type', 3); }} to={Pathname.getContentLayoutPath('SDFCdTBqNmVGZU4wbGhKckN6MDh5UT09', albumId)}> */}
    <Link to={Pathname.getYourVideoLayoutPath('3', albumId)}>
      <span className={styles.addMorePhotoContainer}>
        <span className={styles.addMorePhoto}>Edit album</span>
        <AddIcon className={styles.addMoreIcon} />
      </span>
    </Link>
  </div>
);
export default PhotoList;
