import cn from 'classnames';
import styles from './index.module.css';

function InputGrid({ className, children, ...props }) {
  return (
    <div
      className={cn({
        [styles.inputGrid]: true,
        [className]: className,
      })}
      {...props}
    >
      {children}
    </div>
  );
}

export default InputGrid;
