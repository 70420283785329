import React from 'react';
import cn from 'classnames';
import styles from './index.module.css';

export const Text = {
  PageTitle: ({ children, className: CN, ...props }) => (
    <h2 className={cn({ [styles.pageTitle]: true, [CN]: CN })} {...props}>{children}</h2>
  ),
  SectionTitle: ({ children, className: CN, ...props }) => (
    <h2 className={cn({ [styles.sectionTitle]: true, [CN]: CN })} {...props}>{children}</h2>
  ),
};
