import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from 'Context';
import { Logo } from 'Components';
import { Pathname } from 'Routes';
import styles from './index.module.css';

export const Footer = () => {
  const { langText } = useContext(AppContext);
  const footerText = langText?.landing?.footer;
  const links = [
    { name: footerText?.list1item1 || 'Privacy Policy', link: Pathname.getSettingsPath(Pathname.settings.privacy), target: '_blank', email: false },
    // { name: footerText?.list1item2 || '800-410-6134', link: Pathname.terms, email: false },
    // { name: footerText?.list1item3 || 'Info@projectorstream.com', link: Pathname.userRights, email: true },
    // { name: 'About Us', link: Pathname.about },
    {
      name: footerText?.list1item4 || 'Terms and Conditions',
      link: Pathname.getSettingsPath(Pathname.termsAndConditions),
      target: '_blank',
      email: false
    },
    {
      name: 'Subscriber Agreement',
      link: Pathname.getSettingsPath(Pathname.settings.subscriberAgreement),
      target: '_blank',
      email: false
    }
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        {/* <div className={styles.linksContainer}> */}
        <div className={styles.linkWrapper}>
          {links.map(({ name, link, target, email }, idx) => (
            email ? (
              <a className={styles.link} href="mailto:Info@projectorstream.com">{name}</a>
            ) : (
              <Link className={styles.link} target={target} key={idx} to={link}>{name}</Link>
            )
          ))}
        </div>
        <div className={styles.copyright}>{`${(footerText?.list1item5 || 'Copyright Projector, LLC')} ${new Date().getFullYear()}`}</div>
      </div>
    </footer>
  );
};
