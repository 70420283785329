import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { debounce } from 'lodash';
import { Collapse, Fade } from '@material-ui/core';
import { validate as isValidEmail } from 'email-validator';
import PasswordValidator from 'password-validator';
import { Pathname } from 'Routes';
import { Button, Snackbar } from 'Components';
import { AuthContext } from 'Context';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { Input } from '../components/input-field';
import { AuthPage } from '../components/page';
import { StepStatus } from '../components/step-status';
import styles from './index.module.css';
import authStyles from '../auth.module.css';

const passwordSchema = (new PasswordValidator()).is().min(12).has()
  .symbols(1)
  .has()
  .digits(1)
  .has()
  .uppercase(1)
  .lowercase(1);
const transitionTimeout = 300;
const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { preferredEmail, setPreferredEmail } = useContext(AuthContext);

  const [email, setEmail] = useState(isValidEmail(preferredEmail) ? preferredEmail : '');
  const [resetCode, setResetCode] = useState('');
  const [password, setPassword] = useState('');
  const [resetCodeIsSent, setResetCodeStatus] = useState(false);
  const [passwordIsReset, setPasswordResetStatus] = useState(false);
  const [emailIsFixed, fixEmail] = useState(false);
  const [emailIsValid, setEmailValidity] = useState(false);
  const [accountExists, setAccountExistence] = useState(undefined);
  const [checkingAccountExistence, setAccountExistenceCheckingStatus] = useState(false);
  const [cancelToken, setCancelToken] = useState(undefined);

  const [snackbar, setSnackbar] = useState(defaultSnackbar);
  const hasUpperCase = /[A-Z]/.test(password);
  const passwordHealth = (
    password
      ? password.length < 12 || !hasUpperCase
        ? 'weak'
        : passwordSchema.validate(password)
          ? password.length >= 12
            ? 'strong'
            : 'medium'
          : 'weak'
      : ''
  );
  const { isValidating: sendingResetCode, mutate: sendResetCode } = useSWR([endpoints.forgotPasswordEmail, email], {
    fetcher: (url, inputEmail) => dataFetcher(url, { email: inputEmail }),
    onSuccess: ({ success }) => {
      if (success) {
        setResetCodeStatus(true);
        fixEmail(true);
      } else {
        setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while sending Reset Code' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while sending Reset Code' });
    },
  });

  const {
    isValidating: resettingPassword,
    mutate: resetPassword,
  } = useSWR([endpoints.updatePasswordWithCode, email, password, resetCode], {
    fetcher: (url, inputEmail, inputPassword, inputResetCode) => dataFetcher(url, {
      email: inputEmail,
      password: inputPassword,
      reset_code: inputResetCode,
    }),
    onSuccess: ({ success, message }) => {
      if (success) {
        setPasswordResetStatus(true);
      } else {
        setSnackbar({ isVisible: true, message, type: 'error' });
      }
    },
    onError: (error) => {
      setSnackbar({ isVisible: true, message: error.response.data.message, type: 'error' });
    },
  });

  const { mutate: checkAccountExistence } = useSWR([endpoints.signinEmail, email, cancelToken?.token], {
    fetcher: (url, inputEmail, uniqueCancelToken) => dataFetcher(url, { email: inputEmail }, uniqueCancelToken),
    onSuccess: ({ success }) => {
      setAccountExistenceCheckingStatus(false);

      if (success) {
        setAccountExistence(false);

        if (isValidEmail(preferredEmail)) { fixEmail(true); }
      } else {
        setAccountExistence(true);
      }

      setPreferredEmail(undefined);
    },
    onError: () => {
      setAccountExistenceCheckingStatus(false);
    },
  });

  const emailInputHandler = debounce(({ target: { value: inputEmail } }) => {
    if (isValidEmail(inputEmail)) {
      setEmail(inputEmail);
      setEmailValidity(true);
    } else {
      setEmail('');
      setAccountExistence(undefined);
      setEmailValidity(false);
    }
  }, 750, { trailing: true });

  useEffect(() => {
    if (email) {
      setAccountExistenceCheckingStatus(true);
      checkAccountExistence();
    }
  }, [email, setAccountExistenceCheckingStatus, checkAccountExistence]);

  useEffect(() => {
    if (preferredEmail && isValidEmail(preferredEmail)) {
      setAccountExistenceCheckingStatus(true);
      setEmailValidity(true);
      checkAccountExistence();
    }
  }, [preferredEmail, setAccountExistenceCheckingStatus, checkAccountExistence]);
  const handlePasswordInput = (event) => {
    const inputPassword = event.target.value;
    if (inputPassword.length > 30) {
      // alert('Password must be 30 characters or less.');
    } else {
      setPassword(inputPassword);
    }
  };

  return (
    <AuthPage>
      {!emailIsFixed
        ? (
          <>
            <StepStatus currentStep="1" totalSteps="2" />
            <Input
              defaultValue={email}
              isDisabled={(email === preferredEmail) && checkingAccountExistence}
              isLoading={checkingAccountExistence}
              label="Enter your email"
              onChange={(e) => {
                e.persist();
                emailInputHandler(e);
                setAccountExistenceCheckingStatus(false);

                if (cancelToken) { cancelToken.cancel(); }

                if (!e?.target?.value) {
                  setEmail('');
                  setAccountExistence(undefined);
                  setEmailValidity(false);
                } else {
                  setCancelToken(AxiosCancelToken.source());
                }
              }}
              placeholder="Email"
              type="email"
            />
            <br />
            <Collapse
              in={(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
              timeout={transitionTimeout}
            >
              <Fade
                in={(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
                timeout={transitionTimeout}
              >
                <>
                  <div className={authStyles.recommendationText}>
                    <>We couldn&apos;t find your account.</>
                    <br />
                    <>New to Projector?</>
                  </div>
                  <br />
                </>
              </Fade>
            </Collapse>
            {accountExists === true
              ? (
                <Button
                  blockText
                  isBlue
                  isDisabled={checkingAccountExistence
                    || !(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
                  isFullWidth
                  isLarge
                  onClick={() => { setPreferredEmail(email); navigate(Pathname.authentication.signUp); }}
                >
                  Sign Up
                </Button>
              )
              : (
                <Button
                  blockText
                  isBlue
                  isDisabled={checkingAccountExistence
                    || sendingResetCode || !(emailIsValid && (accountExists !== undefined) && (accountExists === false))}
                  isFullWidth
                  isLarge
                  onClick={sendResetCode}
                >
                  {sendingResetCode ? 'Sending Reset Code...' : 'Continue'}
                </Button>
              )}
            <Collapse
              in={!(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
              timeout={transitionTimeout}
            >
              <Fade
                in={!(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
                timeout={transitionTimeout}
              >
                <div className={authStyles.suggestionText}>
                  <Link
                    className={cn({ [authStyles.suggestionLink]: true, [styles.signInLink]: true })}
                    to={Pathname.authentication.signIn}
                  >
                    Remember&nbsp;Password?
                  </Link>
                </div>
              </Fade>
            </Collapse>
          </>
        )
        : (!passwordIsReset && resetCodeIsSent
          ? (
            <>
              <StepStatus currentStep="2" totalSteps="2" />
              <Input
                auto-complete="one-time-code"
                label="Enter reset code"
                maxLength={5}
                onlyNumber="yes"
                message="Enter the Reset Code received in your Email"
                minLength="5"
                onInput={(event) => {
                  const inputResetCode = event.target.value.slice(0, 5); // Limit input to 5 characters
                  setResetCode(inputResetCode);
                  if (event.target.value.length > 5) {
                    // eslint-disable-next-line
                    event.target.value = event.target.value.slice(0, 5);
                  }
                }}
                placeholder="Reset Code"
                type="code"
              />
              <br />
              <br />
              <Input
                label="Enter new password"
                minLength={12}
                maxLength={password && 30}
                onInput={handlePasswordInput}
                onPaste={(e) => {
                  const pastedText = (e.clipboardData || window.clipboardData).getData('text');
                  // Check if the pasted text exceeds 30 characters
                  if (pastedText.length > 30) {
                      // alert('Password cannot exceed 30 characters.');
                      e.preventDefault();
                  }
                  const totalLength = e.target.value.length + pastedText.length;
                  // Check if the total length (including pasted text) exceeds 30 characters
                  if (totalLength > 30) {
                    // alert('Password cannot exceed 30 characters.');
                    e.preventDefault();
                  }
              }}
                onChange={(e) => {
                  const inputPassword = e.target.value;
                  if (inputPassword.length > 30) {
                    const trimmedText = inputPassword.slice(0, 30);
                    e.target.value = trimmedText;
                    setPassword(trimmedText);
                  }
                }}
                // onInput={({ target: { value: inputPassword } }) => { setPassword(inputPassword); }}
                placeholder="Password"
                type="password"
              />
            <div className={authStyles.conditons}>
              <p style={{ color: password && password.length >= 12 ? '#23e223' : 'white' }}>12 - 30 Characters</p>
              <p style={{ color: password && hasUpperCase ? '#23e223' : 'white' }}>Minimum of one uppercase</p>
              <p style={{ color: password && password.match(/[a-z]/) ? '#23e223' : 'white' }}>Minimum of one lowercase</p>
              <p style={{ color: password && password.match(/[0-9]/) ? '#23e223' : 'white' }}>Minimum of one number</p>
              <p style={{ color: password && password.match(/[!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/) ? '#23e223' : 'white' }}>Minimum of one special character</p>
            </div>
              {/* <div className={authStyles.passwordStrengthBar}>
                <div
                  className={cn({
                    [authStyles.passwordStrengthBarContent]: true,
                    ...passwordHealth && { [authStyles[passwordHealth]]: true }
                  })}
                  style={{
                    width: passwordHealth === 'weak'
                      ? '30%'
                      : passwordHealth === 'medium'
                        ? '55%'
                        : passwordHealth === 'strong'
                          ? '100%'
                          : '0%'
                  }}
                />
              </div>
              <div className={authStyles.passwordStrengthNotes}>
              <div>Use a minimum of 12 characters (case sensitive) & maximum of 30 with at least one [a-z, A-Z], [0-9] and special character.</div>
                <div
                  className={cn({
                    [authStyles.currentPasswordStrength]: true,
                    ...passwordHealth && { [authStyles[passwordHealth]]: true }
                  })}
                >
                  {passwordHealth}
                </div>
              </div> */}
              <br />
              <br />
              <form onSubmit={(e) => { e.preventDefault(); resetPassword(); }}>
                <input className={authStyles.hiddenFormField} name="email" onChange={() => { }} type="email" value={email} />
                <input
                  className={authStyles.hiddenFormField}
                  name="password"
                  onChange={() => { }}
                  type="password"
                  value={password}
                />
                <Button
                  blockText
                  isBlue
                  isDisabled={(
                    (resetCode?.length !== 5)
                    || (!((passwordHealth === 'strong') || (passwordHealth === 'medium')))
                    || resettingPassword
                  )}
                  isFullWidth
                  isLarge
                  submit
                >
                  {resettingPassword ? 'Resetting Password...' : 'Reset Password'}
                </Button>
              </form>
            </>
          )
          : (
            <>
              <div className={authStyles.recommendationText} style={{ textAlign: 'center' }}>Password reset successfully</div>
              <br />
              <br />
              <Button
                blockText
                isBlue
                isFullWidth
                isLarge
                link={Pathname.authentication.signIn}
              >
                Sign In
              </Button>
            </>
          )
        )}

      <br />
      <br />
      <br />
      <br />

      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
    </AuthPage>
  );
};
