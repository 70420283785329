import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import pt from 'prop-types';
import useSWR from 'swr';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  LinearProgress as MuiLinearProgress,
  withStyles,
  Fade,
} from '@material-ui/core';
import { AxiosCancelToken, dataFetcher } from 'Api';
import { AppContext } from 'Context';
import { ReactComponent as AddPersonIcon } from 'Assets/add-person.svg';
import { ReactComponent as LinkIcon } from 'Assets/link.svg';
import AlterImage from 'Assets/alter-image.png';

import { Snackbar } from '../snackbar/index';
import { Button } from '../button';
import styles from './index.module.css';
import SearchInput from './components/SearchInput';
import NewSearchInput from './components/NewSearchInput';
import PersonButton from './components/PersonButton';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress
} from './components/CustomDesigns';

export const ShareScreen = ({
  toastForGroup,
  actionButtonLoadingText,
  actionButtonText,
  apiEndpoint,
  errorText,
  hasLink,
  hideContent,
  imageEditable,
  inputPlaceholder,
  isVisible,
  linkDescription,
  linkTitle,
  multipleSelect,
  notes,
  noUsersFoundText,
  onContinue,
  onHide: closeHandler,
  requireTitle,
  successText,
  title,
  titleEditable,
  titlePlaceholder,
  tempGroupTitle,
  oldGroupMember,
  grandAccess,
  subTitle
}) => {
  const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };
  const { setAppSnackbar } = useContext(AppContext);
  const [snackbar, setSnackbar] = useState(defaultSnackbar);
  const [titleName, setTitleName] = useState('');
  const [imageBase64, setImageBase64] = useState('');
  const [emailToSearch, setEmailToSearch] = useState('');
  const [searchingUsers, setUserSearchingStatus] = useState(false);
  const [cancelToken, setCancelToken] = useState(undefined);
  const [selectedUsers, updateSelectedUsers] = useState([]);
  const [operationStatus, setOperationStatus] = useState(undefined);
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    let oldMembers = [];
    oldMembers = oldGroupMember || [];
    updateSelectedUsers([...oldMembers]);
  }, [oldGroupMember]);
  const [handlingAction, setActionHandlingStatus] = useState(false);
  const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const location = useLocation();
  const onHide = () => {
    setOperationStatus(undefined);
    if (!handlingAction) {
      setEmailToSearch('');
      setImageBase64('');
      updateSelectedUsers([]);
      setUserSearchingStatus(false);
      setUserList([]);
      if (closeHandler) { closeHandler(); }
    }
  };
  const selectUser = (userData) => {
    updateSelectedUsers(multipleSelect ? [...selectedUsers, userData] : [userData]);
  };
  const removeUser = (userData) => {
    updateSelectedUsers(selectedUsers.filter((selectedUserData) => selectedUserData.id !== userData.id));
  };

  const { mutate: searchUsersByEmail } = useSWR([apiEndpoint, emailToSearch, cancelToken?.token], {
    fetcher: (url, inputEmail, uniqueCancelToken) => dataFetcher(url, { email: inputEmail }, uniqueCancelToken),
    onSuccess: ({ success, data, message }) => {
      setUserSearchingStatus(false);
      if (success) {
        if (emailToSearch !== '') {
          const ids = selectedUsers?.map((user) => user.id);
          const newData = data?.filter((item) => !ids.includes(item.id));
          setUserList(newData ? newData?.filter((userData) => ((userData.status === null)
            || (userData.status === undefined)))?.slice(0, 5) : []);
          if ((message === 'No Matching user found' || message === 'User Not Exist')
            && grandAccess && emailRegEx.test(emailToSearch)) {
            const list = { 'email': emailToSearch, 'id': emailToSearch, 'status': null, 'firstname': '', 'lastname': '' };
            setUserList([list]);
          }
        }
      } else {
        setUserList([]);
      }
    },
    onError: () => {
      setUserSearchingStatus(false);
      setUserList([]);
    },
  });

  const searchInputHandler = debounce(({ target: { value: inputEmail } }) => {
    if (inputEmail) {
      setEmailToSearch(inputEmail);
    } else {
      setEmailToSearch('');
      setUserList(undefined);
    }
  }, 750, { trailing: true });

  const searchUsers = ({ target: { value: inputEmail } }) => {
    if (inputEmail && (location.pathname.includes('/successors') || location.pathname.includes('/groups') || location?.search?.includes('create-group'))) {
      setEmailToSearch(inputEmail);
    } else if (inputEmail && emailRegEx.test(inputEmail)) {
      setEmailToSearch(inputEmail);
    } else {
      setEmailToSearch('');
      setUserList([]);
    }
  };
  const removeUserFromList = (selectedUser) => {
    updateSelectedUsers(selectedUsers.filter((selectedUserData) => selectedUserData.id !== selectedUser.id));
  };

  const selectUserFromList = (selectedUser) => {
    updateSelectedUsers(multipleSelect ? [...selectedUsers, selectedUser] : [selectedUser]);
  };
  useEffect(() => {
    if (emailToSearch) {
      setUserSearchingStatus(true);
      searchUsersByEmail();
    }
  }, [emailToSearch, setUserSearchingStatus, searchUsersByEmail]);

  useEffect(() => {
    if (operationStatus === true) {
      setAppSnackbar({ isVisible: true, message: successText });
    } else if (operationStatus === false) {
      setAppSnackbar({ isVisible: true, type: 'error', message: errorText });
    }
  }, [operationStatus, errorText, successText, setAppSnackbar]);

  return (
    <Dialog className={styles.shareScreen} onClose={onHide} open={isVisible} TransitionComponent={Fade}>
      <DialogTitle className={styles.shareScreenHeading}>
        <div className={styles.shareScreenHeadingContent}>
          {imageEditable
            ? (
              <>
                {hideContent && (
                  <input
                    accept="image/*"
                    className={styles.hiddenFormField}
                    disabled={handlingAction || !hideContent}
                    id="share-screen-image-input"
                    onChange={({ target: { files } }) => {
                      const reader = new FileReader();
                      const file = files[0];
                      if (file) { reader.readAsDataURL(file); }
                      reader.addEventListener('load', () => { setImageBase64(reader.result); }, false);
                    }}
                    type="file"
                  />
                )}
                <div className={styles.addPersonIconContainer}>
                  {imageBase64
                    ? (
                      <>
                        <label className={styles.addPersonImageContainer} htmlFor="share-screen-image-input">
                          <img
                            alt="Group Icon"
                            className={styles.imagePreview}
                            src={imageBase64 || AlterImage}
                            onError={(e) => {
                              e.target.src = AlterImage;
                            }}
                          />
                        </label>
                        {(!handlingAction && hideContent) && (
                          <button
                            className={styles.imageDeleteButton}
                            onClick={() => {
                              document.getElementById('share-screen-image-input').value = '';
                              setImageBase64(undefined);
                            }}
                          />
                        )}
                      </>
                    )
                    : (
                      <label
                        className={cn({ [styles.addPersonImageContainer]: true, [styles.hasBG]: true })}
                        htmlFor="share-screen-image-input"
                      >
                        <AddPersonIcon className={styles.addPersonIcon} />
                      </label>
                    )}
                </div>
              </>
            )
            : (
              <div className={styles.addPersonIconContainer}>
                <div className={cn({ [styles.addPersonImageContainer]: true, [styles.hasBG]: true })}>
                  <AddPersonIcon className={styles.addPersonIcon} />
                </div>
              </div>
            )}
          {titleEditable
            ? (
              <input
                className={styles.titleInput}
                disabled={handlingAction || !hideContent}
                onInput={({ target: { value: inputTitle } }) => { setTitleName(inputTitle); }}
                placeholder={titlePlaceholder}
                type="text"
              />
            )
            : (subTitle ? (
              <div>
                <div>{title}</div>
                <div className={styles.subTitle}>{subTitle}</div>
              </div>
            ) : <>{title}</>)}
        </div>
        {!hideContent && (
          <NewSearchInput
            handlingAction={handlingAction}
            searchInputHandler={searchInputHandler}
            setUserSearchingStatus={setUserSearchingStatus}
            cancelToken={cancelToken}
            setEmailToSearch={setEmailToSearch}
            setUserList={setUserList}
            setCancelToken={setCancelToken}
            AxiosCancelToken={AxiosCancelToken}
            inputPlaceholder={inputPlaceholder}
            selectedUsers={selectedUsers}
            isDisabled={handlingAction}
            removeUser={removeUser}
            isSelected
            grandAccess
            searchUsers={searchUsers}
            userList={userList}
            selectUser={selectUser}
            selectUserFromList={selectUserFromList}
            removeUserFromList={removeUserFromList}
            emailToSearch={emailToSearch}
          />
        )}
        {/* {searchingUsers && <LinearProgress className={styles.loader} />} */}
      </DialogTitle>

      {!hideContent && (
        <DialogContent className={styles.shareScreenContent}>
          <br />
        </DialogContent>
      )}
      <DialogActions className={styles.shareScreenActions} style={{ borderTopLeftRadius: '0', borderTopRightRadius: '0' }}>
      <Snackbar
        isVisible={toastForGroup?.message && snackbar?.isVisible}
        message={toastForGroup?.message || '...'}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
        <Button
          className={styles.shareScreenDoneButton}
          isBlue
          isDisabled={actionButtonText === 'Create Group' ? ((requireTitle) || !titleName
          || (!hideContent && selectedUsers?.length <= 0)
          || handlingAction) : ((requireTitle && !titleName)
          || (!hideContent && selectedUsers?.length <= 0)
          || handlingAction)}
          onClick={() => {
            setSnackbar({ isVisible: true });
            onContinue([
              onHide,
              setActionHandlingStatus,
              setOperationStatus,
              updateSelectedUsers,
              setUserList,
              selectedUsers,
              titleName,
              imageBase64
            ]);
          }}
        >
          {handlingAction ? actionButtonLoadingText : actionButtonText}
        </Button>
      </DialogActions>

      {hasLink && (
        <DialogActions className={cn({ [styles.shareScreenActions]: true, [styles.linkShare]: true })}>
          <div className={styles.shareScreenHeadingContent}>
            <LinkIcon className={styles.linkIcon} />
            <>{linkTitle}</>
          </div>
          <div className={styles.shareScreenLinkText}>{linkDescription}</div>
          <div className={styles.shareScreenLinkButton}>Copy Link</div>
        </DialogActions>
      )}
    </Dialog>
  );
};
