import { Box } from '@material-ui/core';
import styles from './index.module.css';

export function TitleLabel({ title, category }) {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" m="2px">

            <h4 style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '17ch',
              zIndex: 1,
              margin: '0px'
            }}
            >
              {title}
            </h4>
            {
                category
            && <h6 style={{ margin: '0px', color: 'white' }}>{category}</h6>
            }

        </Box>
    );
}
