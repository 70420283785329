import { ButtonBase, Button as MuiButton } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ link, btnClass, text, redirect }) => (
  !redirect ? (
    <Link to={link} className={`${btnClass} btn`}>
      {text}
    </Link>
  )
    : (
      <MuiButton
        href={link}
        className={`${btnClass} btn`}
      >
        {text}
      </MuiButton>
    )
);

export default Button;
