/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Dialog, Slide,
  DialogTitle,
  Fade,
} from '@material-ui/core';
import { Text } from '../../../../Components/text';
import styles from '../index.module.css';
import PhotoPreview from '../../../../Components/upload-modal/components/PhotoPreview';

const fromContentLayout = true;

const ModalContent = ({
  isVisible,
  onHide,
  photoFile,
  removePhoto,
  selectPhoto,
  uploadPhoto,
  addingPhotos
}) => ((
  <Fade in={isVisible} timeout={isVisible ? 400 : 350}>
    <div className={styles.body}>
      <DialogTitle>
        <Text.PageTitle className={styles.headerText}>
          Upload Photos
        </Text.PageTitle>
      </DialogTitle>
      <PhotoPreview
        photoFile={photoFile}
        removePhoto={removePhoto}
        photoUpload={selectPhoto}
        handleClose={onHide}
        fromContentLayout={fromContentLayout}
        uploadPhoto={uploadPhoto}
        addingPhotos={addingPhotos}
      />
    </div>
  </Fade>
));

export const AddPhotoModal = ({
  onHide,
  isVisible,
  photoFile,
  removePhoto,
  photoUpload,
  fileInputRef,
  handleClose,
  setChooseAlbumStep,
  selectPhoto,
  uploadPhoto,
  addingPhotos
}) => (
  <Dialog
    disableBackdropClick
    onClose={onHide}
    open={isVisible}
    PaperComponent={ModalContent}
    PaperProps={{
      onHide,
      isVisible,
      photoFile,
      removePhoto,
      photoUpload,
      fileInputRef,
      handleClose,
      selectPhoto,
      uploadPhoto,
      addingPhotos
    }}
    TransitionComponent={Slide}
    transitionDuration={isVisible ? 300 : 250}
    TransitionProps={{ direction: 'up' }}
  />
);
