/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import noPhotoAvailable from 'Assets/no-image-available.png';
import {
  DialogContent,
  FormControlLabel,
  RadioGroup,
  LinearProgress,
  withStyles,
  CircularProgress
} from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import cn from 'classnames';
import { Radio, DialogFooter } from './Material-reDesign';
import VideoPreview from './VideoPreview';
import styles from '../index.module.css';
import '../loader.css';
import { ContentBox, ContentBoxSection } from './ContentBox';
import { Button } from '../../button';
import ImagePreviewDetails from './ImagePreviewDetails';
import { Text } from '../../text';
import { GroupSelector } from '../../group-selector';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);
const VideoSummary = ({ videoVisibilityOptions, handleSaveToDraft, uploadedData, videoTitle, updateVisibilityContent, percentage, setVideoVisibility, videoVisibility, selectGroup, defaultVideoThumbnail,
  videoGroup, videoDetails, uploadingVisibility, moveToPreviousStep, isThumbnailCreationFailed, updateVisibility, uploadText, commonText, visibilityText, editFlow, modalType, conversionStart, draftApiStatus }) => (
  <>
    <DialogContent className={styles.content}>
      <ContentBox flex>
      {draftApiStatus === 'loading'
          && (
            <div className={styles.absol}>
              <p className={styles.marginX}>
                <CircularProgress />
              </p>
              <p className={styles.textx}>
                {videoTitle && uploadedData?.category_id && defaultVideoThumbnail && defaultVideoThumbnail !== noPhotoAvailable && videoVisibility && videoVisibility !== 0 && videoVisibility !== '0'
                ? 'Saving'
                : 'Updating Video details'}
              </p>
            </div>
              )}
        <ContentBoxSection
          // title={uploadText?.previousDetails || 'Previous Details'}
          // description={uploadText?.previousDetailVideoDesc || 'Video preview how the audience will see this'}
          className={styles.summaryBoxSection}
        >
          <div className={styles.visibilityContainer}>
            <div className={styles.req}>
            <Text.SectionTitle className={styles.visibilityTitle}>
               {visibilityText?.title || 'Visibility'}
            </Text.SectionTitle>
            <span className={styles.optionalInput}> (Required)</span>
            </div>
            <p className={styles.visibilityDescription}>{visibilityText?.description || 'Choose how your viewers see your content'}</p>
            <RadioGroup
              className={styles.visibilityRadio}
              defaultValue={videoVisibilityOptions[0].value}
              name="visibility"
              value={videoVisibility}
              onChange={(e) => { setVideoVisibility(e?.target?.value); }}
            >
              {videoVisibilityOptions.map(({ name, value }, idx) => (
                <FormControlLabel
                  value={value}
                  key={idx}
                  control={<Radio color="primary" />}
                  label={name}
                />
              ))}
            </RadioGroup>
            {videoVisibility === videoVisibilityOptions[2].value && (
              <GroupSelector
                id="group-selector"
                onSelect={(val) => { selectGroup(val); }}
                selectedOption={videoGroup}
              />
            )}
          </div>
          <VideoPreview
            defaultVideoThumbnail={defaultVideoThumbnail}
            isThumbnailCreationFailed={isThumbnailCreationFailed}
            details={videoDetails}
            modalType={modalType}
            type="details"
            editFlow={editFlow}
            percentage={percentage}
          />
        </ContentBoxSection>
      </ContentBox>
    </DialogContent>
    <DialogFooter twoEnd={conversionStart}>
      <div className={styles.buttonOnEnd}>
        {/* {
          conversionStart ? (
            <div className={styles.leftContainer}>
              <div className={styles.iconContainer}>
                <CloudUploadOutlined
                  className={styles.videoUploadIcon}
                  fontSize="large"
                />
              </div>
              <div className={cn({ [styles.progressbar]: true })}>
                <BorderLinearProgress />
                <div className={styles.processingText}>
                  Processing in background...
                </div>
              </div>
            </div>
          ) : null
        } */}
       {draftApiStatus !== 'loading'
        && (
        <Button
          className={styles.navButton}
          isBlue
          isDisabled={uploadingVisibility}
          isOutlined
          onClick={moveToPreviousStep}
        >
          Previous
        </Button>
        )}
     <Button
  className={cn({ [styles.navButton]: true, [styles.backGroundProcessingText]: true })}
  isBlue
  isDisabled={
    draftApiStatus === 'loading'
    || (videoVisibility && videoVisibility === videoVisibilityOptions[2]?.value && videoGroup?.length === 0)
  }
  isOutlined
  onClick={() => {
    if (modalType === 'edit') {
      updateVisibility();
    } else {
      handleSaveToDraft();
    }
  }}
     >
{draftApiStatus === 'loading' ? (
    <CircularProgress />
  ) : videoTitle && (uploadedData?.category_id && uploadedData?.category_id !== 'delete') && defaultVideoThumbnail && defaultVideoThumbnail !== noPhotoAvailable && videoVisibility && videoVisibility !== 0 && videoVisibility !== '0' ? (
    'Publish'
  ) : (
    'Save to Draft'
  )}
     </Button>

      </div>
    </DialogFooter>
  </>
);
export default VideoSummary;
