import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { debounce } from 'lodash';
import { Collapse, Fade, Checkbox } from '@material-ui/core';
import { validate as isValidEmail } from 'email-validator';
import { Pathname } from 'Routes';
import { Button } from 'Components';
import { encryptedKeyForCredentials, GOOGLE_RECAPTCHA_KEY } from 'Commons';
import { AppContext, AuthContext } from 'Context';
import { useSelector, useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { isMobile } from 'react-device-detect';
import { clearAllDummys } from 'store/store';
import { Input } from '../components/input-field';
import { AuthPage } from '../components/page';
import { StepStatus } from '../components/step-status';
import styles from './index.module.css';
import authStyles from '../auth.module.css';

const transitionTimeout = 300;

export const NewSignIn = () => {
  const navigate = useNavigate();
  const { setAppSnackbar, setinvalidErr, invalidErr, setIsInvalid, userDetails, setUserDetails, plansPage, setPlansPage, langText, setReferralStatus, fetchData, isInvalid, referralCode, setDummyObject } = useContext(AppContext);
  const { forceAutoSignin, setToken, preferredEmail, setPreferredEmail, preferredUserDetails, setPreferredUserDetails, setEmailVerified } = useContext(AuthContext);
  const signInText = langText?.signInUp;
  const [email, setEmail] = useState(isValidEmail(preferredEmail) ? preferredEmail : '');
  const [password, setPassword] = useState('');
  const [emailIsFixed, fixEmail] = useState(false);
  const [emailIsValid, setEmailValidity] = useState(false);
  const [accountExists, setAccountExistence] = useState(undefined);
  const [checkingAccountExistence, setAccountExistenceCheckingStatus] = useState(false);
  const [cancelToken, setCancelToken] = useState(undefined);
  const [termsAreAgreed, setTermsAgreement] = useState(false);
  const [selectedReCAPTCHA, selectReCAPTCHA] = useState(false);
  const dispatch = useDispatch();
  const clearAllDummy = () => {
    dispatch(clearAllDummys());
};
  const { isValidating: signingInUser, mutate: signInUser } = useSWR([endpoints.signin, email, password, referralCode], {
    fetcher: (url, inputEmail, inputPassword, referralCode) => dataFetcher(url, { email: inputEmail, password: inputPassword, affliateCode: referralCode }),
    onSuccess: ({ isNewUser, success, data, has_logged_in, referral_status, is_skip, message, verify_mobile, mobile }) => {
      if (success) {
        // if (parseInt(data?.is_email_verified, 10) === 0) {
        //   setAppSnackbar({ type: 'error', message: 'Email verification is pending', isVisible: true });
        // } else {
        setUserDetails({ ...userDetails, isNewUser });
        setToken(data?.token);
        setEmailVerified(data?.is_email_verified);
        setAppSnackbar({ message: 'Logged in successfully', isVisible: true });
        fetchData();
        setTimeout(() => {
          if ((parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1 && !data?.billing_date)) {
            setPlansPage(false);
            parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1 ? setReferralStatus(1) : setReferralStatus(0);
            //  navigate(Pathname.plans);
          } else {
            // has_logged_in === '0' ? navigate(Pathname.gettingStarted) : navigate(Pathname.access);
            const storedPath = localStorage.getItem('storedPath');
            if (storedPath) {
              navigate(storedPath);
              localStorage.removeItem('storedPath');
          } else {
            navigate(`${Pathname.access}${data?.encVal ? `?email=${data?.encVal}` : ''}`);
          }
          }
        }, 500);
        forceAutoSignin();
        // }
      } else if (!verify_mobile) {
        if (mobile) {
          navigate(Pathname.authentication.newmobileVerification);
          sessionStorage.setItem(encryptedKeyForCredentials.mobile, mobile);
        } else {
          navigate(Pathname.authentication.newmobileVerification);
        }
      } else {
        setAppSnackbar({ type: 'error', message: message || 'Incorrect password', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      // if ('email_verified' in responseData && !responseData?.email_verified) {
      //   sessionStorage.setItem('emailForValidation_projectoruser', email);
      //   navigate(Pathname.authentication.verifyEmail);
      // } else {
      // setAppSnackbar({ type: 'error', message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
      setIsInvalid(true);
      setinvalidErr({ message: responseData?.message || 'Oops! Something went wrong' });
      // }
    },
  });

  const { isValidating: gettingMFAStatus, mutate: getMFAStatus } = useSWR([endpoints.getMFAStatus, email, password], {
    fetcher: (url, inputEmail) => dataFetcher(url, { email: inputEmail }),
    onSuccess: ({ verify_mobile, success, phone_no, mfa, message }) => {
      if (success) {
        if (verify_mobile && mfa) {
          navigate(Pathname.authentication.newmobileVerification);
        } else {
          signInUser();
        }
      } else {
        setAppSnackbar({ type: 'error', message: message || 'Incorrect password', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ type: 'error', message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
      if (isScriptExist && callback) callback();
    };
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_KEY}`);
  }, []);

  const reCaptchaHandler = () => {
    window.grecaptcha.execute(GOOGLE_RECAPTCHA_KEY, { action: 'submit' }).then((value) => {
      if (value) {
        // setRecaptcha(value);
        selectReCAPTCHA(true);
      } else {
        selectReCAPTCHA(false);
      }
    });
  };

  const { mutate: checkAccountExistence } = useSWR([endpoints.signinEmail, email, cancelToken?.token], {
    fetcher: (url, inputEmail, uniqueCancelToken) => dataFetcher(url, { email: inputEmail }, uniqueCancelToken),
    onSuccess: ({ success }) => {
      setAccountExistenceCheckingStatus(false);
      if (success) {
        setAccountExistence(false);

        if (isValidEmail(preferredEmail)) { fixEmail(true); }
      } else {
        setAccountExistence(true);
        reCaptchaHandler();
      }

      setPreferredEmail(undefined);
    },
    onError: () => {
      setAccountExistenceCheckingStatus(false);
    },
  });

  const emailInputHandler = debounce(({ target: { value: inputEmail } }) => {
    if (isValidEmail(inputEmail)) {
      setEmail(inputEmail);
      setEmailValidity(true);
    } else {
      setEmail('');
      setAccountExistence(undefined);
      setEmailValidity(false);
    }
  }, 750, { trailing: true });

  const inputHandlerForUserDetails = (key, value) => {
    const userDetailsCopy = { ...preferredUserDetails };
    userDetailsCopy[key] = value;
    setPreferredUserDetails(userDetailsCopy);
  };

  useEffect(() => {
    if (email) {
      setAccountExistenceCheckingStatus(true);
      checkAccountExistence();
    }
  }, [email, setAccountExistenceCheckingStatus, checkAccountExistence]);

  useEffect(() => {
    if (preferredEmail && isValidEmail(preferredEmail)) {
      setAccountExistenceCheckingStatus(true);
      setEmailValidity(true);
      checkAccountExistence();
    }
  }, [preferredEmail, checkAccountExistence]);

  const saveEmailAndPasswordToSession = () => {
    sessionStorage.setItem(encryptedKeyForCredentials.password, password);
    sessionStorage.setItem(encryptedKeyForCredentials.email, email);
  };
  const isNameFilled = preferredUserDetails.firstName.trim() !== '' && preferredUserDetails.lastName.trim() !== '';
  const handlePasswordInput = (event) => {
    const inputPassword = event.target.value;
    if (inputPassword.length > 30) {
      // alert('Password must be 30 characters or less.');
    } else {
      setPassword(inputPassword);
    }
  };
  return (
    <AuthPage disableRedirect={!isMobile}>
      {
        !isMobile
      && <div aria-hidden="true" onClick={() => { navigate(-1); }} className={styles.backLink}>{signInText?.back || 'BACK'}</div>

      }
      {!emailIsFixed
        ? (
          <form onSubmit={(e) => { e.preventDefault(); }}>
            {/* <StepStatus currentStep="1" totalSteps="2" langText={signInText} /> */}
            <Input
              className={styles.customuserInput}
              defaultValue={email}
              isDisabled={(email === preferredEmail) && checkingAccountExistence}
              isLoading={checkingAccountExistence}
              label={signInText?.signInTitle || 'Login with your email'}
              onInput={(e) => {
                e.persist();
                emailInputHandler(e);
                setAccountExistenceCheckingStatus(false);

                if (cancelToken) { cancelToken.cancel(); }

                if (!e?.target?.value) {
                  setEmail('');
                  setAccountExistence(undefined);
                  setEmailValidity(false);
                } else {
                  setCancelToken(AxiosCancelToken.source());
                }
              }}
              placeholder={signInText?.email || 'Email'}
              type="email"
            />
            {
              accountExists ? (
                <div className={styles.nameFields}>
                  <Input
              className={styles.customuserInput}
              onInput={(e) => {
                      e.persist();
                      inputHandlerForUserDetails('firstName', e?.target?.value);
                    }}
                    placeholder={signInText?.firstName || 'First Name'}
                    type="code"
                    noLabel
                  />
                  <Input
              className={styles.customuserInput}
              onInput={(e) => {
                      e.persist();
                      inputHandlerForUserDetails('lastName', e?.target?.value);
                    }}
                    placeholder={signInText?.lastName || 'Last Name'}
                    type="code"
                    noLabel
                  />
                </div>
              ) : null
            }
            <input
              className={`${authStyles.hiddenFormField} ${styles.customuserInput}`}
              name="password"
              onChange={() => { }}
              type="password"
              value={password}
            />
            <br />
            {/* <Collapse
              in={(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
              timeout={transitionTimeout}
            >
              <Fade
                in={(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
                timeout={transitionTimeout}
              >
                <>
                  <div className={authStyles.recommendationText}>New to Projector?</div>
                  <br />
                </>
              </Fade>
            </Collapse> */}
            <Collapse in={accountExists} timeout={transitionTimeout}>
              <Fade in={accountExists} timeout={transitionTimeout}>
                <div className={styles.newsletter}>
                  <Checkbox
                    className={styles.newsletterCheckbox}
                    color="primary"
                    id="newsletter-sub"
                    onChange={({ target: { checked: termsAgreed } }) => { setTermsAgreement(termsAgreed); }}
                  />
                  <label className={styles.newsletterText} htmlFor="newsletter-sub">
                    Yes! I would like to receive updates, special offers and other information from Projector App.
                  </label>
                </div>
              </Fade>
            </Collapse>
            {
              accountExists ? (
                <>
                  {/* <Collapse in={accountExists} timeout={transitionTimeout}>
                    <Fade in={accountExists} timeout={transitionTimeout}>
                      <div className={styles.reCaptchaContainer}>
                        <ReCAPTCHA
                          sitekey={GOOGLE_RECAPTCHA_KEY}
                          onChange={reCaptchaHandler}
                          theme="dark"
                        />
                      </div>
                    </Fade>
                  </Collapse> */}
                  <div className={styles.termsContainer}>
                    <div className={styles.terms}>
                      <Collapse in={accountExists} timeout={transitionTimeout}>
                        <Fade in={accountExists} timeout={transitionTimeout}>
                          <span>
                            {
                              signInText?.signUpContent ? (
                                <span className={styles.linkClass} dangerouslySetInnerHTML={{ __html: signInText?.signUpContent }} />
                              ) : (
                                <span>
                                  Projector will use your data to personalize and improve your Projector experience and to send you information about Projector. You can change your communication preferences anytime. We may use your data as described in our
                                  <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/terms-and-conditions"> Privacy Policy </a>
                                  , including sharing it with the family of companies.  By clicking “Agree &amp; Continue”, you agree to our &nbsp;
                                  <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/terms-and-conditions">Terms of Use </a>
                                  ,&nbsp;
                                  <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/subscriber-agreement">Subscriber Agreement </a>
                                  and acknowledge that you have read our Privacy Policy.
                                </span>
                              )
                            }
                          </span>
                        </Fade>
                      </Collapse>
                    </div>
                    <Button
                      blockText
                      isBlue
                      isDisabled={!isNameFilled || checkingAccountExistence
                        || !(emailIsValid && (accountExists !== undefined) && (accountExists === true) && selectedReCAPTCHA)}
                      isFullWidth
                      isLarge
                      // submit
                      onClick={() => { setPreferredEmail(email); navigate(Pathname.authentication.newSignUp); }}
                    >
                      {signInText?.agreeAndContinue || 'Agree and Continue'}
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  blockText
                  isBlue
                  isDisabled={checkingAccountExistence
                    || !(emailIsValid && (accountExists !== undefined) && (accountExists === false))}
                  isFullWidth
                  submit
                  isLarge
                  onClick={() => { fixEmail(true); clearAllDummy(); }}
                >
                  {signInText?.continue || 'Continue'}
                </Button>
              )
            }
            <Collapse
              in={!(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
              timeout={transitionTimeout}
            >
              <Fade
                in={!(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
                timeout={transitionTimeout}
              >
                <div className={authStyles.suggestionText}>
                  <>
                    {signInText?.newToProjector || 'New to Projector?'}
                    &nbsp;
                  </>
                  <Link className={authStyles.suggestionLink} to={Pathname.authentication.newSignUp}>{signInText?.signUp || 'Sign Up'}</Link>
                </div>
              </Fade>
            </Collapse>
          </form>
        )
        : (
          <form onSubmit={(e) => { e.preventDefault(); saveEmailAndPasswordToSession(); getMFAStatus(); }}>
            {/* <StepStatus currentStep="2" totalSteps="2" langText={signInText} /> */}
            <input className={authStyles.hiddenFormField} name="email" onChange={() => { }} type="email" value={email} />
            <Input
              className={styles.customuserInput}
              label={signInText?.enterYourPassword || 'Enter your password'}
              message={signInText?.caseSensitive !== undefined ? `(${signInText.caseSensitive})` : '( Case Sensitivexx )'}
              minLength={8}
              maxLength={password && 30}
              isInvalid={isInvalid}
              onInput={handlePasswordInput}
              invalidErr={invalidErr}
              onPaste={(e) => {
                const pastedText = (e.clipboardData || window.clipboardData).getData('text');
                if (pastedText.length > 30) {
                    e.preventDefault();
                }
                const totalLength = e.target.value.length + pastedText.length;
                // Check if the total length (including pasted text) exceeds 30 characters
                if (totalLength > 30) {
                  // alert('Password cannot exceed 30 characters.');
                  e.preventDefault();
                }
            }}
            onChange={(e) => {
              const inputPassword = e.target.value;
              if (inputPassword.length > 30) {
                const trimmedText = inputPassword.slice(0, 30);
                e.target.value = trimmedText;
                setPassword(trimmedText);
              }
            }}
            // onInput={(e) => {
            //   const { target: { value: inputNewPassword } } = e;
            //   if (inputNewPassword.length > 30) {
            //     alert('Password cannot exceed 30 characters.');
            //     setPassword(inputNewPassword.slice(0, 30));
            //   } else {
            //     setPassword(inputNewPassword);
            //   }
            // }}
              placeholder={signInText?.password || 'Password'}
              type="password"
            />
            <br />
            <div>
              <Button id="sasdasddd" blockText isBlue isDisabled={gettingMFAStatus || signingInUser} isFullWidth isLarge submit>
                {signingInUser ? ('Signing In...' || `${signInText?.signingIn}...`) : (signInText?.continue || 'Continue')}
              </Button>
            </div>
            <div className={authStyles.suggestionText}>
              <Link
                className={cn({ [authStyles.suggestionLink]: true, [styles.forgotPasswordLink]: true })}
                to={Pathname.authentication.forgotPassword}
              >
                {signInText?.forgotPassword?.title || 'Forgot Password?'}
              </Link>
            </div>
          </form>
        )}
    </AuthPage>
  );
};
