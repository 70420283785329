import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'Context';
import Aos from 'aos';
import { Box, Dialog, IconButton, makeStyles, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import { isAndroid, isIOS, isMobile, useDeviceSelectors } from 'react-device-detect';
import { PlayCircleFilled } from '@material-ui/icons';
import Header from './components/Header';
import Pricing from './components/Pricing';
import HowToUse from './components/HowToUse';
import Access from './components/Access';
import Footer from './components/Footer';
import styles from './landingPage.module.css';

import './assets/css/App.css';
import 'aos/dist/aos.css';

export const LandingPageExternal = () => {
  // eslint-disable-next-line no-script-url
  // window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const { langText } = useContext(AppContext);
  const landingText = langText?.landing;
  const promotionVideoUrl = 'https://projector-optimized.s3.us-west-2.amazonaws.com/production/background-videos/Web.mp4';
  const promotionVideoUrlMobile = 'https://projector-optimized.s3.us-west-2.amazonaws.com/splashvideo/Mobile_long_splash+(1).mp4';
  const [promoDialogOpen, setPromoDialogOpen] = useState(false);
  const videoPlayerRef = useRef(null);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const promoVideoPlayer = (url) => (
    <div
      className={styles.reactPlayerWrapper}
      dangerouslySetInnerHTML={{
        __html: `<video autoplay loop muted playsinline width="100%" height="100%" >
    <source src=${url} type="video/mp4" />
  </video>` }}
    />
  );
  // Use this function to play video for automatic playing
  function playVideo() {
    if (promoDialogOpen) {
      setVideoPlaying(true);
      // videoPlayerRef.current.volume = 0;
      // videoPlayerRef?.current?.play();
    }
  }

  const promoPlayerReact = (url) => (
    <Box
      className={styles.reactPlayerWrapper}
      onClick={() => playVideo()}
    >
      {/** On ios devices some browser causes isssue to autoplay and hence show a play button to make a action */}
      {!videoPlaying && isIOS && (
        <PlayCircleFilled
          className={styles.playButton}
        />
      )}
      <ReactPlayer
        loop
        playsinline
        playing={videoPlaying || !isIOS}
        onPlay={() => setVideoPlaying(true)}
        muted
        volume={0}
        ref={videoPlayerRef}
        url={url}
        width="100%"
        height="100%"
      />

      {/* <video autoPlay loop muted ref={videoPlayerRef} playsInline controls={false} onPlay={() => setVideoPlaying(true)} width="100%" height="100%">
        <source src={url} type="video/mp4" />
      </video> */}
    </Box>
  );

  useEffect(() => {
    Aos.init({ duration: 1500, once: true });

    setTimeout(() => {
      setPromoDialogOpen(true);
    }, 2000);
  }, []);

  function handlePromoClose() {
    setPromoDialogOpen(false);
  }

  return (
    <div className="App">
      <Header />
      <Pricing />
      <HowToUse />
      <Access />
      <Footer />

      {/* <Dialog
        PaperProps={{
          style: {
            width: '70%',
            maxWidth: '70%',
            maxHeight: '70%',
            borderRadius: '10px',
            overflow: 'hidden',
            backgroundColor: '#131313'
          }
        }}
        onClose={handlePromoClose}
        open={promoDialogOpen}
        TransitionComponent={Slide}
      >
        <Box>

          <Box
            position="absolute"
            width="100%"
            zIndex="1"
            padding="5"
            marginTop="5"
            top="10px"
            left="10px"
          >

            <IconButton className="top-btn" onClick={() => handlePromoClose()}>
              <CloseIcon />
            </IconButton>

          </Box>

          <Box>
            {isMobile
              ? promoVideoPlayer(promotionVideoUrlMobile)
              : promoVideoPlayer(promotionVideoUrl)}

          </Box>

        </Box>

      </Dialog> */}
    </div>
  );
};
