/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { Page as PageLoggedIn } from 'Components/page';
import { AppContext, AuthContext } from 'Context';
import { Pathname } from 'Routes';
import useMyAxios from 'hooks/useMyAxios';
import { getUserDetailsFromCode } from 'Api/apiFunctions';
import { Page } from '../components/page';
import styles from './index.module.css';
import { UploadModal } from './updateVideo';
import Plan from './plan';
import AffliatePlan from './AffliatePlan';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important'
  }
})(MuiCircularProgress);

export const PlanForAffliate = ({ userIsSignedIn }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [planName, setPlanName] = useState();
  const { type, id } = useParams();
  const [contentId, setContentId] = useState(id);
  const [contentType, setContentType] = useState(type);
  const { setAppSnackbar, getSubscription, userDetails, currentSelectedPlan,
    setCurrentSelectedPlan, subscriptionDetails,
    setUserDetails, langText, referralStatus, allPlans } = useContext(AppContext);
  const { gettingSubscription, setToken: setUserToken } = useContext(AuthContext);
  useEffect(() => {
    getSubscription();
  }, []);
  const [uploadModalIsVisible, setUploadModalVisibility] = useState(false);
  const hideUploadModal = () => { setUploadModalVisibility(false); };
  const [plans, setPlans] = useState([]);
  const planText = langText?.settings?.billPayment?.planList;
  const [paymentToken, setToken] = useState();
  const [priceId, setPriceId] = useState();
  const [email, setEmail] = useState(userDetails?.email);
  const [trial_period_days, setTrial_period_days] = useState('0');
  const [myPlan, setMyPlan] = useState(subscriptionDetails?.current_plan);
  const [freePlanId, setFreePlanId] = useState(undefined);
  const emailCode = new URLSearchParams(location?.search)?.get('email');

  useEffect(() => {
    setMyPlan(subscriptionDetails?.current_plan);
  }, [subscriptionDetails]);

  const {
    response: userDetFrmCode,
    error: userDetFrmCodeErr,
    loading: userDetFrmCodeLoading,
    refetch: refetchuserDetFrmCode,
  } = useMyAxios(getUserDetailsFromCode, { code: emailCode }, null, emailCode, emailCode ? [emailCode] : []);

  useEffect(() => {
    if (!userDetFrmCodeLoading && userDetFrmCode && userDetFrmCode?.data) {
      setUserDetails({ ...userDetFrmCode?.data });
      setUserToken(userDetFrmCode?.data?.token);
      window.location.replace(Pathname.plans);
    }
  }, [userDetFrmCode, userDetFrmCodeLoading]);

  const { mutate: getPlans } = useSWR([endpoints.getAllActivePlans], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, subscriptions, data }) => {
      if (success) {
        if (referralStatus && parseInt(referralStatus, 10) === 1 && parseInt(subscriptionDetails?.is_skip, 10) !== 1 && !subscriptionDetails?.paymentMethods?.length) {
          setPlans([...data, subscriptionDetails?.subscription]);
          setFreePlanId(subscriptionDetails?.subscription?.id);
        } else {
          setPlans(data);
          setFreePlanId(undefined);
        }
      } else {
        setPlans([]);
      }
    },
    onError: () => { setPlans([]); },
  });
  useEffect(() => {
    if (subscriptionDetails?.subscription) {
      getPlans();
    }
  }, [referralStatus, subscriptionDetails]);
  return (
  <>
    <div className={styles.planContainer}>
      <div className={styles.message}>
      Start Streaming Your Memories!
      </div>
      <div className={styles.plans}>
        {allPlans && allPlans.length > 0 && allPlans.map((plan, index) => (
          <AffliatePlan
            product_name={plan?.product_name}
            sub_title={plan?.sub_title}
            amount={`${plan?.amount}`}
            description={plan?.description}
            price_description={plan?.price_description}
            viewers={plan?.is_unlimited === '1' ? (planText?.unlimited || 'unlimited') : plan?.max_view_request}
            priceDetails={planText?.userMonth || 'user / month'}
            storage={plan?.max_storage_text}
            price_id={plan?.price_id}
            setToken={setToken}
            setPriceId={setPriceId}
            setEmail={setEmail}
            setTrial_period_days={setTrial_period_days}
            setPlanName={setPlanName}
            userIsSignedIn={false}
            currentSelectedPlan={currentSelectedPlan}
            benefit={`Add ${plan?.max_add_on_allowed} GB of storage for 
            $${(plan?.addon_rate / 100).toFixed(2)}/month`}
            setCurrentSelectedPlan={setCurrentSelectedPlan}
            hasTrial={plan?.has_trial}
            totalTrialDays={plan?.total_trial_days}
            planId={plan?.id}
            contentType={contentType}
            contentId={contentId}
            freePlanId={freePlanId}
            color={index % 2 === 0 ? 'white' : 'blue'}
          />
        ))}
      </div>
    </div>
  </>
  );
};
