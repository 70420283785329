import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { axios, endpoints, baseUrl } from 'Api';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import { ProfilePage, ProjectorShimmer } from 'Components';
import { AppContext, AuthContext } from 'Context';
import { Pathname } from 'Routes';
import SearchGroup from 'Components/ImageGroup/SearchGroup';
import FixedProgressBar from 'Components/header/components/FixedProgressBar';
import BottomBar from '../profile/BottomBar';
import styles from './index.module.css';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important',
  },
})(MuiCircularProgress);

export const SearchPage = () => {
    // eslint-disable-next-line no-script-url
    window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const { setAppSnackbar, userDetails, langText } = useContext(AppContext);
  const { getToken } = useContext(AuthContext);
  const { profileID } = useParams();
  const searchText = langText?.viewProjector;
  const [profileName, setProfileName] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [timer, setTimer] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (userDetails?.firstname) {
      setProfileName(userDetails?.firstname?.substring(0, 10));
    } else if (userDetails?.email) {
      setProfileName(userDetails?.email?.split('@')[0]?.substring(0, 10));
    }
  }, [userDetails]);

  const searchVideos = async () => {
    try {
      const response = await axios.post(`${baseUrl}/searchVideo`, {
        search_key: searchTerm,
        token: getToken,
        user_id: userDetails.myId
      });
      setVideos(response.data);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
      const delayTimer = setTimeout(() => {
        searchVideos();
      }, 500);
      setTimer(delayTimer);
  }, [searchTerm]);
  return (
    <ProfilePage
      containerClassName={styles.pageContainer}
      mainClassName={cn({ [styles.mainContent]: true, [styles.loading]: loading })}
      userName={profileName}
      userProfileLink={Pathname.getFriendProfilePath(profileID)}
      noHeader
      noDefaultBg
      isProfileHeader
    >
      {loading
        ? <FixedProgressBar />
        : (
          <>
            <input
              className={styles.searchInput}
              placeholder={searchText?.search || 'Search'}
              onChange={(e) => { setSearchTerm(e.target.value); }}
            />
            <br />
            <h3 style={{ fontSize: '27pt', color: 'white', textAlign: 'left', marginTop: '2px' }}>{searchTerm}</h3>
            {loading
              ? (
                <div className={styles.categoriesLoader}>
                  <FixedProgressBar />
                </div>
              )
              : (
                <SearchGroup
                  isGrid
                  videos={videos?.videos}
                  searchTerm={searchTerm}
                />
              )}
          </>
        )}
        <BottomBar />
    </ProfilePage>
  );
};
