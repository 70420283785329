/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import DeleteIcon from '@material-ui/icons/Close';
import { AppContext } from 'Context';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip, TextField, Popper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styles from '../index.module.css';
import './newSearchInput.css';
import PersonButton from './PersonButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  tag: {
    'border': '1px solid',
    'background': '#fff',
    fontWeight: '500',
    fontSize: '15px',
    '& svg': {
      color: 'black'
    }
  },
  popper: {
    maxWidth: '100px'
  },
  option: {
    height: '10px'
  }
}));

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottom: '4px solid #5AA5EF',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '4px solid #5AA5EF',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '4px solid #5AA5EF',
    },
    '& .MuiInputBase-input': {
      height: '3.1876em'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: '5px 18px',
    },
    '& .MuiInputBase-root': {
      color: '#63676C',
      fontFamily: 'Poppins',
      fontSize: '1.2rem'

    }
  },
})(TextField);

export default function SearchInput({
  handlingAction, searchInputHandler, setUserSearchingStatus, cancelToken, setEmailToSearch, setUserList,
  setCancelToken, AxiosCancelToken, inputPlaceholder, selectedUsers, removeUser, searchUsers, userList, selectUser,
  removeUserFromList, selectUserFromList, emailToSearch
}) {
  const classes = useStyles();
  const { langText } = useContext(AppContext);
  const commonText = langText?.common;
  const [oldLength, newLength] = useState(0);

  const uniqueUserList = userList.filter(
    (user) => !selectedUsers.some((selectedUser) => selectedUser.email === user.email)
  );

  return (
    <div className={styles.shareScreenEmailInput}>
      <Autocomplete
        multiple
        id="tags-standard"
        disableClearable
        forcePopupIcon={false}
        filterSelectedOptions
        classes={{
          tag: classes.tag,
          option: styles.option
        }}
        noOptionsText={commonText?.startTypingMsg || 'Start typing email to search'}
        options={uniqueUserList} // Use the filtered userList
        renderOption={(option) => ((
          <PersonButton
            data={option}
            key={option?.id}
            onClick={() => {
              selectUserFromList(option);
            }}
          />
        ))}
        renderTags={(values, getTagProps) => {
          const uniqueValues = values.filter((value, index, self) => index === self.findIndex((v) => v.id === value.id));
          return uniqueValues.map((option, index) => (
            <Chip
              variant="outlined"
              label={option?.email}
              {...getTagProps({ index })}
              deleteIcon={(
                <div aria-hidden="true">
                  <DeleteIcon
                    onClick={(event) => {
                      removeUserFromList(option);
                    }}
                    className="MuiChip-deleteIcon"
                    style={{ margin: '0px 5px 0px 0px' }}
                  />
                </div>
                )}
            />
          ));
        }}
        getOptionLabel={(option) => option?.email}
        renderInput={(params) => (
          <CssTextField
            disableUnderline
            {...params}
            autoComplete="off"
            variant="standard"
            placeholder={selectedUsers?.length === 0 ? inputPlaceholder : ''}
            onChange={(e) => {
              searchUsers(e);
            }}
          />
        )}
      />
    </div>
  );
}
