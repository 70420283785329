/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from 'Context';
import { Logo } from 'Components';
import { Pathname } from 'Routes';
import backArrowIcon from 'Assets/backArrowIcon.svg';
import AlterImage from 'Assets/alter-image.png';
import styles from '../../auth.module.css';

export const AuthPage = ({ children, smallIcon, disableRedirect }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { langText } = useContext(AppContext);
  const commonText = langText?.common;
  return (
    <div className={styles.authPage}>
      {
        location.pathname.includes('/tv-login') ? (
          <div className={styles.backButtonBlock}>
            <img
              className={styles.videoBackButton}
              onClick={() => { navigate(-1); }}
              src={backArrowIcon || AlterImage}
              onError={(e) => {
                e.target.src = AlterImage;
              }}
            />
            <span
              className={styles.backText}
              onClick={() => { navigate(-1); }}
              src={backArrowIcon}
              aria-hidden="true"
            >
              {commonText?.back || 'BACK'}
            </span>
          </div>
        ) : null
      }
      <div className={styles.authPageContent}>
        <Link to={disableRedirect ? '' : Pathname.home}>
          <Logo className={styles.logo} isLarge={!smallIcon} isLight />
        </Link>
        {children}
      </div>
    </div>
  );
};
