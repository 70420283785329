/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ProgressBar from 'Components/header/components/ProgressBar';
import cn from 'classnames';
import { ReactComponent as RightArrow } from 'Assets/video-right-arrow.svg';
import { ReactComponent as LeftArrow } from 'Assets/video-left-arrow.svg';
import AlterImage from 'Assets/alter-image.png';
import { dataFetcher, endpoints, axios, baseUrl } from 'Api';
import { ProfilePage, Button } from 'Components';
import { AuthContext, AppContext } from 'Context';
import { Pathname } from 'Routes';
import { Image } from 'semantic-ui-react';
import { Box } from '@material-ui/core';
import { CONTENT_TYPES } from 'Pages/private/profile';
import VideoAutoPlayer from './video-autoplayer';
import styles from './index.module.css';
import { TitleLabel } from './video-autoplayer/TitleLabel';

const isTrueValue = true;
const isFalseValue = false;
const ImageGroupTitle = ({ resumeMe, title, setResumeMe, marginTitle, resumeWatching, setLoading, clearResumeWatching }) => (
<>
{title
    && (
    <div className={styles.headingWrapper}>
      <h3
        className={cn({ [styles.imageGroupTitle]: true, [styles.marginTitle]: !marginTitle })}
      >
        {title}
      </h3>
      {
        resumeMe?.content?.length > 0 ? (
          <div onClick={() => { setLoading(true); clearResumeWatching(); setResumeMe(null); }} className={styles.clearAll}>Clear All</div>
        ) : null
      }
    </div>
    )}
</>
);

const CustomRightArrow = ({ onClick, align }) => ((
  <RightArrow
    fontSize="large"
    onClick={onClick}
    aria-label="Go to next slide"
    className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right custom_arrow"
  // className="right-arrow"
  // style={{ marginTop: align && '5px' }}
  />
));

const CustomLeftArrow = ({ onClick }) => ((
  <LeftArrow
    onClick={onClick}
    aria-label="Go to next slide"
    className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left custom_arrow"
  // className="left-arrow"
  // style={{ zIndex: '1000', cursor: 'pointer', height: '40px', marginLeft: '-100%', background: 'none' }}
  />
));
const ViewMyProjector = ({ imageData, isphoto, resumeMe, setResumeMe, responseData, title, isGrid, type, catG, resumeWatching, setLoading, clearResumeWatching, isPreview, handleCarousel }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 2 // optional, default to 1.
    }
  };
  const responsiveImage = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
      slidesToSlide: 2 // optional, default to 1.
    }
  };
  const navigate = useNavigate();
  const { getToken } = useContext(AuthContext);
  const { loader, setLoader, setcategoryLoader } = useContext(AppContext);
  const [isMoving, setMoving] = useState(false);
  const [showArrow, setArrows] = useState(false);
  const [showAutoPlayDiv, setAutoPlayDiv] = useState(undefined);
  const filteredPhotos = imageData?.data?.filter((element) => element?.photos && element?.photos?.length > 0);
  let myTimeout;
  const [dimensions, setDimensions] = useState({
    // top: '0px',
    // left: '0px',
  });
  const [videoDetails, setVideoDetails] = useState({
    videoURL: '',
    thumbNail: '',
    title: '',
    subCategory: ''
  });
  const mouseOverHandler = (event, id, video_file, thumbnails, title, subCategory, video_raw_file) => {
    const rect = event.target.getBoundingClientRect();
    setDimensions({});
    setAutoPlayDiv(id);
    setVideoDetails({ videoURL: video_file || video_raw_file, thumbNail: thumbnails, title, subCategory });
  };

  const mouseLeaveHandler = () => {
    clearInterval(myTimeout);
    setAutoPlayDiv(undefined);
    setVideoDetails({ videoURL: '', thumbNail: '', title: '', subCategory: '' });
  };

  useEffect(() => {
    const autoPlayElement = document?.getElementById('autoplay-div');
    const thumbnailLinkElement = document?.getElementById('thumbnail-link');
    autoPlayElement?.addEventListener('mouseleave', mouseLeaveHandler);
    return () => autoPlayElement?.removeEventListener('mouseleave', mouseLeaveHandler);
  });
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoader(false);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, []);

function beforeChange(index, type) {
    setMoving(true);
    if (handleCarousel) {
      handleCarousel(index, type);
    }
  }

  return (
<div>
  {
    loader === true
    ? <ProgressBar fullProgress="yes" />
    : (
      <div
      className={cn({ [styles.paddingTop0]: true, [styles.imageGroup]: true, [styles.paddingTopForImage]: catG })}
      >
          <div style={{ alignItems: 'baseline' }} className={styles.headingWrapper}>
          {
          responseData?.content_type === 'Resume Watching' && responseData?.content?.length > 0
          && <ImageGroupTitle title="Resume watching" marginTitle={catG} resumeWatching={resumeWatching} setLoading={setLoading} clearResumeWatching={clearResumeWatching} />
          }
          {
            responseData?.content_type === 'Resume Watching' && responseData?.content?.length > 0 ? (
              <div onClick={() => { setcategoryLoader(true); setLoading(true); clearResumeWatching(); setResumeMe(null); }} className={styles.clearAll}>Clear All</div>
            ) : null
          }
          </div>
      {/* {searchTerm && <ImageGroupTitle title={`Search results for "${searchTerm}"`} />} */}
      <div>
        <div className={cn({ [styles.imageGroupImagesContainer]: true, [styles.isGrid]: isGrid })}>
          {/* resume watching */}
            {responseData?.content_type === 'Resume Watching' && responseData?.content?.length > 0 && <ImageGroupTitle setResumeMe={setResumeMe} resumeMe={resumeMe} title={resumeMe?.content_type} marginTitle={catG} resumeWatching={resumeWatching} setLoading={setLoading} clearResumeWatching={clearResumeWatching} />}
                <div
                  onMouseEnter={() => setArrows(true)}
                  onMouseLeave={() => setArrows(false)}
                >
                  <Carousel
                    // swipeable={isTrueValue}
                    // draggable={isTrueValue}
                    // showDots={searchTerm ? false : isTrueValue}
                    arrows
                    slidesToSlide={3}
                    responsive={responsive}
                    ssr={isTrueValue} // means to render carousel on server-side.
                    autoPlaySpeed={1000}
                    keyBoardControl={isTrueValue}
                    // customTransition="all 20"
                    // transitionDuration={10000}
                    // arrows={showArrow}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass={`image-item spclMargin ${isGrid && styles['image-item-isGrid']}`}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    beforeChange={(p) => beforeChange(p, type)}
                    afterChange={() => setMoving(false)}
                    centerMode={isTrueValue}
                    className={styles.carousel}
                  >
                    { responseData?.content_type === 'Resume Watching'
                      && responseData?.content?.length > 0 && responseData?.content?.map((obj, idx) => (
                        <div className={styles.imageGroupImagesWrapper}>
                        <Box className={styles.hoverZoom} key={idx}>
                          <Link
                            onMouseEnter={(event) => mouseOverHandler(event, idx, obj?.video_file, obj?.thumbnails, obj?.videoTitle, obj?.SubCategory, obj?.video_raw_file)}
                            onMouseLeave={mouseLeaveHandler}
                            className={styles.imageGroupImageLink}
                            key={idx} // Add a unique key prop for each item in the map function
                            to={isPreview ? `${Pathname.getPlayerPath(obj?.id, 'play')}` : Pathname.getVideoPath(obj?.id)}
                            id="thumbnail-link"
                          >
                            <VideoAutoPlayer
                              dimensions={dimensions}
                              videoDetails={resumeMe?.content[idx]}
                              thumbNail={obj?.imagekit_icon || obj?.thumbnails || AlterImage}
                              videoID={showAutoPlayDiv}
                              autoPlay={showAutoPlayDiv === idx}
                            />
                            <TitleLabel title={obj?.title} category={obj?.SubCategory} />
                          </Link>
                        </Box>
                        </div>
                      ))}
                    {/* A blank div added to compromise the carousel bug TODO find bug */}
                    <div />
                  </Carousel>
                </div>
                {/* photo album */}
          {
            filteredPhotos?.length > 0 && (
                <div
                  onMouseEnter={() => setArrows(true)}
                  onMouseLeave={() => setArrows(false)}
                >
                  <ImageGroupTitle title={title} marginTitle={catG} resumeWatching={resumeWatching} setLoading={setLoading} clearResumeWatching={clearResumeWatching} />
                  <Carousel
                    // swipeable={isTrueValue}
                    // draggable={isTrueValue}
                    // showDots={searchTerm ? false : isTrueValue}
                    arrows
                    slidesToSlide={4}
                    responsive={responsiveImage}
                    ssr={isTrueValue} // means to render carousel on server-side.
                    autoPlaySpeed={1000}
                    keyBoardControl={isTrueValue}
                    // customTransition="all 20"
                    // transitionDuration={10000}
                    // arrows={showArrow}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-30-px"
                    // itemClass={`image-item_images ${isGrid && styles['image-item-isGrid']}`}
                    customRightArrow={<CustomRightArrow align />}
                    customLeftArrow={<CustomLeftArrow align />}
                    beforeChange={(p) => beforeChange(p, type)}
                    afterChange={() => setMoving(false)}
                    centerMode={isTrueValue}
                    className={styles.carousel}
                  >
                    {
                        isPreview ? (
                          filteredPhotos?.map(({ id, photos, icon, photo_file, title: albumTitle, album_id }, idx) => (
                            <div className={styles.imageGroupImagesWrapper}>
                            <Box className={styles.hoverZoom}>
                              <Link className={styles.imageGroupImageLink} key={idx} to={`${Pathname.getAlbumPlayerPath(album_id, id)}`}>
                                <Image
                                  src={photo_file}
                                  className={`${styles.imageGroupImage} ${styles.imageGroupImageCarousel}`}
                                  onError={(e) => {
                                    e.target.src = `${photo_file}?tr=orig`;
                                  }}
                                />
                              </Link>
                            </Box>
                            </div>
                          ))
                        ) : (
                          filteredPhotos?.map(({ id, photos, icon, photo_file, title: albumTitle }, idx) => (
                            (
                              <div className={styles.imageGroupImagesWrapper}>
                              <Box className={styles.hoverZoom}>
                                <Link
                                  className={cn({ [styles.imageGroupImageLink]: true, [styles.albumTransition]: true })}
                                  key={idx}
                                  to={Pathname.getAlbumPath(id)}
                                // style={{ width: '80%' }}
                                >
                                  <Image
                                    src={icon}
                                    className={`${styles.imageGroupImage}`}
                                    onError={(e) => {
                                      e.target.src = `${icon}?tr=orig`;
                                    }}
                                  />
                                  <TitleLabel title={albumTitle} />
                                </Link>
                              </Box>
                              </div>
                            )
                          ))
                        )
                    }
                    {/* A blank div added to compromise the carousel bug TODO find bug */}
                    <div />
                  </Carousel>
                </div>
              )
          }
          {/* playlist section */}
          {responseData?.content_type === 'playlists' && responseData?.data?.map((obj, i) => (
          <div key={i}>
            {obj?.videos?.length > 0 && <ImageGroupTitle title={obj?.title} marginTitle={catG} resumeWatching={resumeWatching} setLoading={setLoading} clearResumeWatching={clearResumeWatching} />}
        {
          obj?.videos && obj?.videos?.length > 0 && (
                <div
                  onMouseEnter={() => setArrows(true)}
                  onMouseLeave={() => setArrows(false)}
                >
                  <Carousel
                    // swipeable={isTrueValue}
                    // draggable={isTrueValue}
                    // showDots={searchTerm ? false : isTrueValue}
                    arrows
                    slidesToSlide={3}
                    responsive={responsive}
                    ssr={isTrueValue} // means to render carousel on server-side.
                    autoPlaySpeed={1000}
                    keyBoardControl={isTrueValue}
                    // customTransition="all 20"
                    // transitionDuration={10000}
                    // arrows={showArrow}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass={`image-item spclMargin ${isGrid && styles['image-item-isGrid']}`}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    beforeChange={(p) => beforeChange(p, type)}
                    afterChange={() => setMoving(false)}
                    centerMode={isTrueValue}
                    className={styles.carousel}
                  >
                    {
                        obj?.videos?.length > 0 && obj?.videos?.map(({ id, thumbnails, imagekit_icon, draft_thumbnails, title: videoTitle, SubCategory, video_file, video_raw_file }, idx) => (
                          <div className={styles.imageGroupImagesWrapper}>
                          <Box className={styles.hoverZoom}>
                            <Link
                              onMouseEnter={(event) => mouseOverHandler(event, id, video_file, thumbnails, videoTitle, SubCategory, video_raw_file)}
                              onMouseLeave={mouseLeaveHandler}
                              className={styles.imageGroupImageLink}
                              key={idx}
                              to={isPreview ? `${Pathname.getPlayerPath(id, 'play')}` : Pathname.getVideoPath(id)}
                              // to={Pathname.getVideoPath(id)}
                              id="thumbnail-link"
                            >
                              <VideoAutoPlayer
                                dimensions={dimensions}
                                videoDetails={obj?.videos[idx]}
                                thumbNail={imagekit_icon || thumbnails || AlterImage}
                                videoID={showAutoPlayDiv}
                                autoPlay={showAutoPlayDiv === id}
                              />
                              <TitleLabel title={videoTitle} category={SubCategory} />
                            </Link>
                          </Box>
                          </div>
                        ))
                    }
                    {/* A blank div added to compromise the carousel bug TODO find bug */}
                    <div />
                  </Carousel>
                </div>
              )
          }
          </div>
          ))}
          {/* video category section */}
          {responseData?.content_type === 'categories' && responseData?.data?.map((obj, i) => (
          <div key={i}>
            {obj?.videos?.length > 0 && <ImageGroupTitle title={obj?.title} marginTitle={catG} resumeWatching={resumeWatching} setLoading={setLoading} clearResumeWatching={clearResumeWatching} />}
        {
          obj?.videos && obj?.videos?.length > 0 && (
                <div
                  onMouseEnter={() => setArrows(true)}
                  onMouseLeave={() => setArrows(false)}
                >
                  <Carousel
                    // swipeable={isTrueValue}
                    // draggable={isTrueValue}
                    // showDots={searchTerm ? false : isTrueValue}
                    arrows
                    slidesToSlide={3}
                    responsive={responsive}
                    ssr={isTrueValue} // means to render carousel on server-side.
                    autoPlaySpeed={1000}
                    keyBoardControl={isTrueValue}
                    // customTransition="all 20"
                    // transitionDuration={10000}
                    // arrows={showArrow}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    // itemClass={`image-item spclMargin ${isGrid && styles['image-item-isGrid']}`}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    beforeChange={(p) => beforeChange(p, type)}
                    afterChange={() => setMoving(false)}
                    centerMode={isTrueValue}
                    className={styles.carousel}
                  >
                      {
                        obj?.videos?.length > 0 && obj?.videos?.map(({ id, thumbnails, imagekit_icon, draft_thumbnails, title: videoTitle, SubCategory, video_file, video_raw_file }, idx) => (
                          <div className={styles.imageGroupImagesWrapper}>
                          <Box className={styles.hoverZoom}>
                            <Link
                              onMouseEnter={(event) => mouseOverHandler(event, id, video_file, thumbnails, videoTitle, SubCategory, video_raw_file)}
                              onMouseLeave={mouseLeaveHandler}
                              className={styles.imageGroupImageLink}
                              key={idx}
                              to={isPreview ? `${Pathname.getPlayerPath(id, 'play')}` : Pathname.getVideoPath(id)}
                              // to={Pathname.getVideoPath(id)}
                              id="thumbnail-link"
                            >
                              <VideoAutoPlayer
                                dimensions={dimensions}
                                videoDetails={obj?.videos[idx]}
                                thumbNail={imagekit_icon || thumbnails || AlterImage}
                                videoID={showAutoPlayDiv}
                                autoPlay={showAutoPlayDiv === id}
                              />
                              <TitleLabel title={videoTitle} category={SubCategory} />
                            </Link>
                          </Box>
                          </div>
                        ))
                    }
                    {/* A blank div added to compromise the carousel bug TODO find bug */}
                    <div />
                  </Carousel>
                </div>
              )
          }
          </div>
          ))}
          {/* All video section */}
          <div>
            {
              responseData?.content_type === 'all_videos' && responseData?.data?.length > 0
              && (
                <ImageGroupTitle title="All Videos" marginTitle={catG} resumeWatching={resumeWatching} setLoading={setLoading} clearResumeWatching={clearResumeWatching} />
              )
            }
              <div
                onMouseEnter={() => setArrows(true)}
                onMouseLeave={() => setArrows(false)}
              >
                    <Carousel
                    // swipeable={isTrueValue}
                    // draggable={isTrueValue}
                    // showDots={searchTerm ? false : isTrueValue}
                    arrows
                    slidesToSlide={3}
                    responsive={responsive}
                    ssr={isTrueValue} // means to render carousel on server-side.
                    autoPlaySpeed={1000}
                    keyBoardControl={isTrueValue}
                    // customTransition="all 20"
                    // transitionDuration={10000}
                    // arrows={showArrow}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass={`image-item spclMargin ${isGrid && styles['image-item-isGrid']}`}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    beforeChange={(p) => beforeChange(p, type)}
                    afterChange={() => setMoving(false)}
                    centerMode={isTrueValue}
                    className={styles.carousel}
                    >
                    {responseData?.content_type === 'all_videos' && responseData?.data?.map(({ id, imagekit_icon, thumbnails, draft_thumbnails, title: videoTitle, SubCategory, video_file, video_raw_file }, idx) => (
                  <div className={styles.imageGroupImagesAllVideo}>
                  <Box className={styles.hoverZoom}>
                    <Link
                      onMouseEnter={(event) => mouseOverHandler(event, id, video_file, thumbnails, videoTitle, SubCategory, video_raw_file)}
                      onMouseLeave={mouseLeaveHandler}
                      className={styles.imageGroupImageLink}
                      key={idx}
                      to={isPreview ? `${Pathname.getPlayerPath(id, 'play')}` : Pathname.getVideoPath(id)}
                      id="thumbnail-link"
                    >
                      <VideoAutoPlayer
                        dimensions={dimensions}
                        videoDetails={responseData?.data[idx]}
                        thumbNail={imagekit_icon || thumbnails || AlterImage}
                        videoID={showAutoPlayDiv}
                        autoPlay={showAutoPlayDiv === id}
                      />
                      <TitleLabel title={videoTitle} category={SubCategory} />
                    </Link>
                  </Box>
                  </div>
                  ))}
                  {/* A blank div added to compromise the carousel bug TODO find bug */}
                  <div />
                    </Carousel>
              </div>

          </div>
        </div>
      </div>
      </div>
    )
  }
</div>
  );
};
export default ViewMyProjector;
