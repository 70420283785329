import React from 'react';
import './mobileViewContent.css';
import { ReactComponent as CloseIcon } from 'Assets/close-round-line-icon.svg';
import { Box } from '@material-ui/core';

function MobileViewContent({ isVisible, onHide }) {
  const doCancelAction = () => {
    onHide();
  };
  return (
    <Box display="flex" justifyContent="center" data-aos="fade-down">
      <div className="modaltext">
        Download and install Projector Mobile App from Appstore Or
        Playstore to better view and manage your Projector account
      </div>
      <CloseIcon style={{ height: '25px', fill: 'white', marginTop: '8px' }} onClick={doCancelAction} />
    </Box>
  );
}

export default MobileViewContent;
