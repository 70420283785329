import React from 'react';
import {
  DialogContent,
  Typography,
} from '@material-ui/core';
import cn from 'classnames';
import Lottie from 'react-lottie';
import VideoUploaded from 'Assets/video-uploaded.json';
import { ContentBox } from '../components/ContentBox';
import styles from '../index.module.css';

export default function LoaderContent({ videoUploadingCompleted, videoUploadPercentage, currentStep, contentType, albumStatus, uploadText }) {
  return (
    <DialogContent className={cn({ [styles.content]: true, [styles.publishedContainer]: true })}>
      <ContentBox className={styles.publishedContent}>
        <div className="card-box">
          <div>
            <div className="percent">
              <svg className="svg-circle">
                <circle cx="70" cy="70" r="70" />
                <circle
                  style={{ strokeDashoffset: `calc(440 - ((440 * ${videoUploadPercentage}) / 100))` }}
                  cx="70"
                  cy="70"
                  r="70"
                />
              </svg>
              <div className="number">
                {videoUploadingCompleted
                  ? (
                    <Lottie
                      options={{
                        loop: false,
                        // autoplay: true,
                        animationData: VideoUploaded,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice'
                        },
                      }}
                      height="100%"
                      style={{ flexShrink: 0 }}
                      width="100%"
                    />
                  )
                  : (
                    <h2>
                      {videoUploadPercentage}
                      <span>%</span>
                    </h2>
                  )}
              </div>
            </div>
          </div>
        </div>
        <Typography className={styles.videoPublishedText}>
          {contentType === 'album'
            ? (videoUploadPercentage < 100 ? 'Photo Uploading' : albumStatus) : (
              currentStep < 2 ? videoUploadPercentage < 100 ? 'Video Uploading' : ('Please wait while we generate thumbnails for your content, it could take up to a minute.') : (uploadText?.videoPublished || 'Video Published')
            )}
        </Typography>
      </ContentBox>
    </DialogContent>
  );
}
