import React from 'react';
import {
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Radio as MuiRadio,
  RadioGroup,
  Slide,
  Typography,
  withStyles,
} from '@material-ui/core';
import styles from '../index.module.css';

export const MenuItem = withStyles({
  root: {
    backgroundColor: 'transparent !important',
    fontFamily: 'Poppins, sans-serif',
    padding: '0 !important',
  },
})(MuiMenuItem);
export const DialogActions = withStyles({ root: { padding: 'unset' } })(MuiDialogActions);
export const Radio = withStyles({ root: { color: 'black' }, checked: { color: 'black !important' } })(MuiRadio);

export const Menu = withStyles({
  paper: {
    backgroundColor: '#fff',
    borderRadius: 0,
    boxShadow: '0px 0px 12px 0px #00000029',
    width: 'var(--selectbox-menu-width)',
  },
})(MuiMenu);

export const DialogFooter = ({ children, twoEnd, showThumbnail }) => (
  <DialogActions className={styles.footer}>
    <div style={showThumbnail ? { position: 'absolute', bottom: '70px', borderTopWidth: '0' } : {}} className={twoEnd ? styles.footerContentInTwoEnd : styles.footerContent}>
      {children}
    </div>
  </DialogActions>
);
