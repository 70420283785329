import { useEffect, useRef, useState } from 'react';

/**
 * A custom hook to fetch data using Axios.
 * @param {function} axiosFunction - The Axios function to call.
 * @param {object} body - The request body to send with the request.
 * @param {string} cacheID - The cache ID to use for caching the response.
 * @param {boolean} conditionToCheck - The condition to check before making the API call.
 * @param {Array} checkState - An array of state values to watch for changes.
 * @returns {object} - An object containing the response, error, loading, and refetch function.
 */
const useMyAxios = (axiosFunction, body, cacheID, conditionToCheck = true, checkState = []) => {
    const cache = useRef({});
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const refetch = (body, cacheId) => {
        setLoading(true);
        if (cacheId && cache.current[cacheId]) {
            setResponse(cache.current[cacheId]);
            setLoading(false);
            setError(null);
        } else {
            setLoading(true);
            axiosFunction(body || {})
                .then((result) => {
                    setResponse(result.data);
                    setError(null);
                    if (cacheId) {
                        cache.current[cacheId] = result.data;
                    }
                })
                .catch((err) => {
                    setError(err);
                    setResponse(null);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        // Call the API if the condition is true.
        if (conditionToCheck) {
            refetch(body, cacheID);
        }
    }, [conditionToCheck, ...checkState]);

    return { response, error, loading, refetch };
};

export default useMyAxios;
