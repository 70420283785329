import React, { useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Pathname } from 'Routes';
import useSWR from 'swr';

import { AppContext, AuthContext } from 'Context';
import { dataFetcher, endpoints } from 'Api';
import {
  Dialog, Slide,
  DialogTitle,
  Fade,
} from '@material-ui/core';
import 'Components/upload-modal/loader.css';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import { Text } from 'Components/text';
import LoaderContent from 'Components/upload-modal/model-dialog-content/LoaderContent';
import styles from 'Components/upload-modal/index.module.css';

const Modal2 = ({ onHide, isVisible, contentType, videoUploadingCompleted, videoUploadPercentage, albumStatus }) => {
  setTimeout(() => {
    onHide();
  }, 8000);
  return (
    <Fade in={isVisible} timeout={isVisible ? 300 : 250}>
      <div className={styles.body}>
        <DialogTitle className={styles.header}>
          <div className={styles.headerContent}>
            <Text.PageTitle className={styles.headerText}>
              Album Updated
            </Text.PageTitle>
            <CloseIcon className={styles.closeButton} onClick={onHide} />
          </div>
        </DialogTitle>
        <LoaderContent
          videoUploadingCompleted={videoUploadingCompleted}
          videoUploadPercentage={videoUploadPercentage}
          contentType={contentType}
          albumStatus={albumStatus}
        />
      </div>
    </Fade>
  );
};
export const UploadModal = ({ onHide, isVisible, contentType, videoUploadingCompleted, videoUploadPercentage,
  albumStatus }) => (
    <Dialog
      disableBackdropClick
      onClose={onHide}
      open={isVisible}
      PaperComponent={Modal2}
      PaperProps={{ onHide, isVisible, contentType, videoUploadingCompleted, videoUploadPercentage, albumStatus }}
      TransitionComponent={Slide}
      transitionDuration={isVisible ? 300 : 250}
      TransitionProps={{ direction: 'up' }}
    />
);
