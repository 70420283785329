import React, { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AppContext } from 'Context';
import copyLinkImage from 'Assets/copy-link@2x.png';
import filesize from 'filesize';
import AlterImage from 'Assets/alter-image.png';
import styles from '../index.module.css';

const ImagePreviewDetails = ({ defaultImageThumbnail, count, size, uploadText, isUpdating }) => {
  const albumSizeInMb = (parseFloat(size) / (1024 * 1024)).toFixed(2);
  return (
    <div className={styles.videoPreview}>
      <img
        className={styles.videoPreviewThumbnail}
        alt=" "
        src={defaultImageThumbnail || AlterImage}
        onError={(e) => {
          e.target.src = AlterImage;
        }}
      />
      <div className={styles.videoPreviewDetails}>
        <h3 className={styles.videoPreviewDetailsTitle}>{uploadText?.albumDetail || 'Album details'}</h3>
        <table>
          <tbody>
            <tr>
              <td>{uploadText?.numberOfPhotos || 'Number of Photos'}</td>
              <td>
                {count || '-'}
              </td>
            </tr>
            <tr>
              <td>{uploadText?.albumSize || 'Album size'}</td>
              <td>{isUpdating ? `${size} MB` : albumSizeInMb ? `${albumSizeInMb} MB` : '0 MB'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ImagePreviewDetails;
