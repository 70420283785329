import React, { useState, useEffect } from 'react';

const AddDotLoader = ({ text }) => {
  const loadingTexts = ['.  ', '.. ', '...'];
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
        text + loadingTexts[loadingTextIndex]
  );
};

export default React.memo(AddDotLoader);
