/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import cn from 'classnames';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { Grid, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ReactComponent as CreatePlaylist } from 'Assets/create-playlist.svg';
import styles from './index.module.css';

const CreateCategoryPage = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { setAppSnackbar, langText } = useContext(AppContext);
  const [val, setVal] = useState(undefined);
  const [playListID, setPlayListID] = useState(undefined);
  const [playLists, setPlayLists] = useState(undefined);
  const [editing, setEditing] = useState(false);
  const layoutText = langText?.layout;
  const commonText = langText?.common;
  const { isValidating: addingPlaylist, mutate: addPlayList } = useSWR([endpoints.addEditPlaylist, val], {
    fetcher: (url, playlist) => dataFetcher(url, {
      title: playlist
    }),
    onSuccess: ({ success }) => {
      if (success) {
        setMenuOpen(false);
        setAppSnackbar({
          isVisible: true,
          message: `Category ${val} was ${playListID ? 'updated' : 'created'} `,
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your category' });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data?.message;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData || 'Oops! Something went wrong while creating your category' });
    },
  });

  const { isValidating: gettingPlayList, mutate: getPlaylist } = useSWR([endpoints.getMyPlaylist], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, data }) => {
      if (success) {
        setPlayLists(data?.map(({ id, title, icon_id }) => ({ name: title, value: id, icon: icon_id })));
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your categories',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching your categories' });
    },
  });

  useEffect(() => {
    getPlaylist();
  }, []);

  useEffect(() => {
    if (!isMenuOpen) {
      setVal(undefined);
    }
    getPlaylist();
  }, [isMenuOpen]);

  return (
    <>
      <div className={cn({ [styles.buttonWithText]: true, [styles.placeEnd]: window.screen.width <= 1350 })}>
        {/* {
          window.screen.width < 800 ? (<span className={styles.createPlayListTitle}>{'Playlist' || layoutText?.createPlaylist}</span>) : <span />
        } */}
        <span className={styles.createPlayListTitle}>{'Playlist' || layoutText?.createPlaylist}</span>
        <Tooltip title={layoutText?.createPlaylist || 'Create Playlist'} aria-label="Create Playlist" disableHoverListener={!(window.screen.width <= 1350)}>
          <CreatePlaylist onClick={() => { setMenuOpen(true); }} className={styles.playlistMe} />
        </Tooltip>
      </div>
      {isMenuOpen && (
        <div className={styles.createMenuContainer}>
          <div
            className={styles.createButtonScrim}
            onClick={() => { setMenuOpen(false); }}
            onKeyPress={undefined}
            role="button"
            tabIndex={0}
          />
          <div className={styles.createMenu}>
            <div className={styles.headerDiv}>
              <div className={styles.addTitle}>
                {' '}
                {layoutText?.addNewPlaylist || 'Add New Playlist'}
              </div>
              <div className={styles.backButton} onClick={() => { setMenuOpen(false); }}>
                {' '}
                {commonText?.back || 'Back'}
              </div>
            </div>
            <input
              className={styles.createMenuInput}
              value={val}
              onChange={(e) => { setVal(e.target.value); }}
              maxLength="16"
            />
            <div className={styles.buttonOnEnd}>
              <div
                className={styles.addButton}
                onClick={() => { addPlayList(); }}
              >
                {' '}
                {editing ? (commonText?.save || 'Save') : (commonText?.add || 'Add')}
              </div>
            </div>
            <div className={styles.existingSubCatWrap}>
              {
                playLists?.map(({ name, value, icon }) => (
                  <Grid container className={styles.subCatDiv}>
                    <Grid item xs={12}>
                      {name}
                    </Grid>
                  </Grid>
                ))
              }
            </div>
          </div>
        </div>
      )}
    </>

  );
};

export default CreateCategoryPage;
