/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, forwardRef, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Button,
} from '@material-ui/core';
import { Slider, Direction } from 'react-player-controls';
import styles from './index.module.css';

const useStyles = makeStyles({
  playerWrapper: {
    width: '100%',
    position: 'relative',
  },
  controlesWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0,0,0,.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  controlesWrapperForView: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // background: 'rgba(0,0,0,.6)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
    height: 200,
  },
  controllIcons: {
    color: '#777',
    fontSize: 92,
    transform: 'scale(0.9)',
    '&:hover': {
      color: '#fff',
      transform: 'scale(1)',
    },
  },
  bottomIcons: {
    color: '#999',
    '&:hover': {
      color: '#fff',
    },
  },
  volumeSlider: {
    width: 100,
  },
  titleTrim: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '50ch',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 25
  },
});
const WHITE_SMOKE = '#eee';
const GRAY = '#323232';
const GREEN = '#72d687';
const WHITE = '#fff';

export default forwardRef(
  ({
    onVolumeSeekUp,
    onVolumeChange,
    played,
    onSeek,
    onSeekMouseUp,
    totalDuration,
    buffering,
    duration,
    elapsedTime,
    setState,
    state,
    videoDetails,
    thumbNail,
    autoPlay
  }, ref) => {
    const [isDragStart, setDragStart] = useState(false);
    const [dragDirection, setDragDirection] = useState(undefined);
    const [progressValue, setProgressValue] = useState(undefined);
    let newValueFromSlideBar;
    const handleIntent = (value) => {
      if (isDragStart) {
        // onSeekMouseUp(value);
        setProgressValue(value);
      }
      newValueFromSlideBar = value;
    };

    const handleDragEnd = (event) => {
      setDragStart(false);
      setDragDirection(undefined);
    };
    const handleDragStart = (direction, value) => {
      setDragDirection(direction);
      setDragStart(true);
    };

    const SliderBar = ({ direction, value, style }) => (
      <div
        style={{
          position: 'absolute',
          background: WHITE_SMOKE,
          borderRadius: direction === Direction.HORIZONTAL ? 4 : 2,
          top: direction === Direction.HORIZONTAL ? 0 : `${100 - (value * 100)}%`,
          bottom: 0,
          left: 0,
          width: direction === Direction.HORIZONTAL ? `${value}%` : 4,
          height: direction === Direction.HORIZONTAL ? '' : `${value * 100}%`,
        }}
      // aria-hidden="true"
      />
    );

    useEffect(() => {
      if (isDragStart) {
        if (dragDirection === Direction.HORIZONTAL) {
          if (state.playing) {
            setState({ ...state, playing: false });
          }
        }
      } else if (dragDirection === Direction.HORIZONTAL) {
        if (!state.playing) {
          setState({ ...state, playing: true });
        }
      }
    }, [isDragStart]);

    useEffect(() => {
      if (isDragStart) {
        document.addEventListener('mouseup', handleDragEnd);
        return () => document.removeEventListener('mouseup', handleDragEnd);
      }
    }, [isDragStart]);

    const moveListener = (event) => {
      const percentageMoved = parseInt(event.screenX, 10) / parseInt(window.screen.width, 10);
      // onSeekMouseUp(percentageMoved);
      setProgressValue(percentageMoved);
    };

    useEffect(() => {
      if (progressValue) {
        onSeekMouseUp(progressValue);
      }
    }, [progressValue]);

    useEffect(() => {
      if (isDragStart && dragDirection === Direction.HORIZONTAL) {
        const element = document.getElementById('video-auto-player');
        element.addEventListener('mousemove', moveListener);
        return () => element.removeEventListener('mousemove', moveListener);
      }
    }, [isDragStart]);

    // A handle to indicate the current value
    const SliderHandle = ({ direction, value, style }) => (
      <div
        style={{
          position: 'absolute',
          width: 15,
          height: 15,
          background: WHITE,
          borderRadius: '100%',
          transform: 'scale(1)',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.3)',
          },
          bottom: direction === Direction.HORIZONTAL ? 0 : `${value * 100}%`,
          left: direction === Direction.HORIZONTAL ? `${value}%` : 1,
          marginTop: -4,
          marginLeft: -8,
          top: direction === Direction.HORIZONTAL ? -2 : '',
          zIndex: 1000
        }}
        // aria-hidden="true"
        onMouseDown={() => { handleDragStart(direction, value); }}
        id="handler"
      />
    );

    const ProgressBar = ({ isEnabled, direction, value, onMouseOver, ...props }) => (
      <div
        id="slideBar"
        style={{
          width: direction === Direction.HORIZONTAL ? '100%' : 4,
          height: direction === Direction.HORIZONTAL ? 6 : '100%',
          borderRadius: 0,
          transition: direction === Direction.HORIZONTAL ? 'width 0.1s' : 'height 0.1s',
          cursor: isEnabled === true ? 'pointer' : 'default',
          left: direction === Direction.HORIZONTAL ? 0 : 9,
          bottom: direction === Direction.HORIZONTAL ? 0 : 10,
          zIndex: 1000
        }}
        onMouseDown={() => { handleDragStart(direction); }}
      >
        <Slider
          direction={direction}
          onChangeStart={(newValue) => { direction === Direction.HORIZONTAL ? onSeekMouseUp(newValue) : onVolumeSeekUp(newValue); }}
          onChange={(newValue) => { direction === Direction.HORIZONTAL ? onSeek(newValue) : onVolumeChange(newValue); }}
          style={{
            width: direction === Direction.HORIZONTAL ? '100%' : 4,
            height: direction === Direction.HORIZONTAL ? 4 : '100%',
            borderRadius: 0,
            background: GRAY,
            transition: direction === Direction.HORIZONTAL ? 'width 0.1s' : 'height 0.1s',
            cursor: isEnabled === true ? 'pointer' : 'default',
            left: direction === Direction.HORIZONTAL ? 0 : 9,
            bottom: direction === Direction.HORIZONTAL ? 0 : 10,
            zIndex: 1000
          }}
          {...props}
          onIntent={handleIntent}
        >
          <SliderBar direction={direction} value={value} style={{ background: isEnabled ? GREEN : GRAY }} />
          <SliderHandle direction={direction} value={value} style={{ background: isEnabled ? GREEN : GRAY }} />
        </Slider>
      </div>

    );

    const classes = useStyles();
    return (
      <>
        <div className={isDragStart ? classes.controlesWrapper : classes.controlesWrapperForView} ref={ref} id="video-auto-player">
          {!autoPlay
            && <img className={styles.thumbNail} src={thumbNail} />}
        </div>
        <div className={styles.detailsWrapper}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4 style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '17ch',
              zIndex: 1,
              fontSize: '14px',
              marginLeft: '5px'
            }}
            >
              {videoDetails.title}
            </h4>
            <h6 style={{ marginTop: '22px', marginRight: '5px', color: '#485d65' }}>{videoDetails.subCategory}</h6>
          </div>
        </div>
      </>
    );
  }
);
