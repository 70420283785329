import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import styles from '../index.module.css';
import CategoriesMenu from './CategoriesMenu';
import SubCategoriesMenu from './SubCategoriesMenu';

const CategoriesMenuContainer = ({
  defaultCategory,
  defaultSubCategory,
  id,
  onChange,
  onClose,
  setActionStatus,
  visibility,
}) => {
  const { setAppSnackbar, langText } = useContext(AppContext);
  const contentText = langText?.content;
  const commonText = langText?.common;
  const [currentCategory, setCurrentCategory] = useState(defaultCategory);
  const [currentSubCategory, setSubCurrentCategory] = useState(defaultSubCategory);
  const [inputCategory, setInputCategory] = useState(defaultCategory);
  const [inputSubCategory, setInputSubCategory] = useState(defaultSubCategory);
  const [updateData, setUpdateData] = useState(undefined);

  const { isValidating: updating, mutate: updateContent } = useSWR([
    endpoints.addVideoContent, updateData,
  ], {
    fetcher: (url, formdata) => dataFetcher(url, formdata),
    onSuccess: ({ success }) => {
      setActionStatus(false);

      if (success) {
        setCurrentCategory(inputCategory);
        setSubCurrentCategory(inputSubCategory);
        setAppSnackbar({ isVisible: true, message: 'category was updated successfully' });
        onChange(id, [
          ['category_id', currentCategory],
          ['subcategory_id', currentSubCategory],
          ...((visibility !== '0') ? ['status', '1'] : [])
        ]);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setActionStatus(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const update = () => {
    const formData = new FormData();

    formData.append('video_id', id);
    formData.append('category_id', inputCategory);
    formData.append('subcategory_id', inputSubCategory);
    if (visibility !== '0') {
      formData.append('status', 1);
    }
    setActionStatus(true);
    setUpdateData(formData);
  };

  useEffect(() => { if (updateData) { updateContent(); } }, [updateData]);

  // useEffect(() => {
  //   if (currentCategory && currentSubCategory) {
  //     onChange(id, [
  //       ['category_id', currentCategory],
  //       ['subcategory_id', currentSubCategory],
  //       ...((visibility !== '0') ? ['status', '1'] : [])
  //     ]);
  //   }
  // }, [updating]);

  return (
    <div className={styles.editMenu}>
      <div className={styles.categoriesMenuContainer}>
        <CategoriesMenu
          defaultValue={inputCategory || defaultCategory}
          onSelect={setInputCategory}
          placeholder={contentText?.categoryPlaceholder || 'Select Category'}
          title={contentText?.categoryTitle || 'Category'}
          video_id={id}
        />
        <SubCategoriesMenu
          defaultValue={inputSubCategory || defaultSubCategory}
          onSelect={setInputSubCategory}
          isSubcategory
          placeholder={contentText?.subCategoryPlaceholder || 'Select Sub Category'}
          title={contentText?.subCategoryTitle || 'Sub Category'}
          video_id={id}
        />
      </div>
      <br />
      <button
        className={styles.editMenuUpdateButton}
        disabled={updating}
        // || !inputSubCategory
          // || ((currentCategory === inputCategory) || (currentSubCategory === inputSubCategory))}
        onClick={update}
      >
        {updating ? `${commonText?.updating || 'Updating ...'}` : (commonText?.update || 'Update')}
      </button>
      <input id="close-button-input" onClick={onClose} style={{ display: 'none' }} />
    </div>
  );
};
export default CategoriesMenuContainer;
