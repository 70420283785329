import React, { useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Pathname } from 'Routes';
import useSWR from 'swr';

import { AppContext, AuthContext } from 'Context';
import { dataFetcher, endpoints } from 'Api';
import {
  Dialog, Slide,
  DialogTitle,
  Fade,
} from '@material-ui/core';
import 'Components/upload-modal/loader.css';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import { Text } from 'Components/text';
import LoaderContent from 'Components/upload-modal/model-dialog-content/LoaderContent';
import styles from 'Components/upload-modal/index.module.css';

const Modal2 = ({ onHide, isVisible, contentType }) => {
  setTimeout(() => {
    onHide();
  }, 5500);
  return (
    <Fade in={isVisible} timeout={isVisible ? 300 : 250}>
      <div className={styles.body}>
        <DialogTitle className={styles.header}>
          <div className={styles.headerContent}>
            <Text.PageTitle className={styles.headerText}>
              {contentType === 'album' ? 'Album Published' : 'Video Published'}
              {/* {`${contentType === 'album' ? 'Album' : 'Video'} Published`} */}
            </Text.PageTitle>
            <CloseIcon className={styles.closeButton} onClick={onHide} />
          </div>
        </DialogTitle>
        <LoaderContent
          videoUploadingCompleted
          videoUploadPercentage={100}
          currentStep={3}
          contentType={contentType}
        />
      </div>
    </Fade>
  );
};
export const UploadModal = ({ onHide, isVisible, contentType }) => (
  <Dialog
    disableBackdropClick
    onClose={onHide}
    open={isVisible}
    PaperComponent={Modal2}
    PaperProps={{ onHide, isVisible, contentType }}
    TransitionComponent={Slide}
    transitionDuration={isVisible ? 300 : 250}
    TransitionProps={{ direction: 'up' }}
  />
);
