/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, forwardRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  makeStyles,
  Grid,
  Button,
  IconButton,
  withStyles,
  Tooltip,
  Popover,
  Menu,
  Box,
  CircularProgress
} from '@material-ui/core';
import {
  Bookmark,
  FastForward,
  FastRewind,
  Fullscreen,
  Pause,
  PlayArrow,
  VolumeOff,
  VolumeUp,
  Forward10,
  Replay10,
  ArrowBackIos,
} from '@material-ui/icons';
import cn from 'classnames';
import { endpoints, axios } from 'Api';
import { Slider, Direction, FormattedTime } from 'react-player-controls';
import { ReactComponent as VolumeControlIcon } from 'Assets/volumeControl.svg';
import { ReactComponent as FullScreen } from 'Assets/full-screen.svg';
import { ReactComponent as NormalScreen } from 'Assets/normal-screen.svg';
import { ReactComponent as PauseIcon } from 'Assets/pause.svg';
import { ReactComponent as PlayIcon } from 'Assets/play-video.svg';
import { ReactComponent as Replay15 } from 'Assets/replay-15.svg';
import { ReactComponent as Forward15 } from 'Assets/forward-15.svg';
import { ReactComponent as PlayAgain } from 'Assets/play-again.svg';
import { Pathname } from 'Routes';
import { ShowNextVideo } from './components/show-next-video';
import styles from './index.module.css';

const useStyles = makeStyles({
  playerWrapper: {
    width: '100%',
    position: 'relative',
  },
  controlesWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0,0,0,.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  controlesWrapperForView: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // background: 'rgba(0,0,0,.6)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  controllIcons: {
    color: '#777',
    fontSize: 92,
    transform: 'scale(0.9)',
    '&:hover': {
      color: '#fff',
      transform: 'scale(1)',
    },
    // width: '120%'
    // marginRight: 50
  },
  bottomIcons: {
    color: '#999',
    '&:hover': {
      color: '#fff',
    },
  },
  volumeSlider: {
    width: 100,
  },
  titleTrim: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '50ch',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 25
  },
});
const WHITE_SMOKE = '#eee';
const GRAY = '#323232';
const GREEN = '#72d687';
const WHITE = '#fff';
let newValueFromSlideBar = 0;

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const PrettoSlider = withStyles({
  root: {
    // color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default forwardRef(
  ({
    onPlayPause,
    playing,
    onFastForward,
    onRewind,
    muted,
    onMute,
    onVolumeSeekUp,
    onVolumeChange,
    volume,
    onPlaybackRateChange,
    playbackRate,
    onToggleFullScreen,
    played,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    elapsedTime,
    totalDuration,
    title,
    videoID,
    buffering,
    duration,
    vttContent,
    rows,
    columns,
    spriteImage,
    screenSize,
    format,
    showPlayAgainButton,
    setPlayAgainButton,
    showControllers,
    setControllers,
    hoverValue,
    setHoverValue,
    setState,
    state,
    nextVideoDetails,
    countDown
  }, ref) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    // const [hoverValue, setHoverValue] = useState(null);
    const [imgWidthPosition, setImgWidthPosition] = useState(0);
    const [imgHeightPosition, setImgHeightPosition] = useState(0);
    const [showVolumeCotroller, setVolumeController] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [isDragStart, setDragStart] = useState(false);
    const [dragDirection, setDragDirection] = useState(undefined);
    const [progressValue, setProgressValue] = useState(undefined);
    const handleSpeedRate = (event) => {
      switch (playbackRate) {
        case 0.5:
          onPlaybackRateChange(1);
          break;
        case 1:
          onPlaybackRateChange(1.5);
          break;
        case 1.5:
          onPlaybackRateChange(2);
          break;
        case 2:
          onPlaybackRateChange(0.5);
          break;
        default:
          onPlaybackRateChange(1);
          break;
      }
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const mouseOutSlider = () => {
      if (hoverValue !== null) {
        setHoverValue(null);
      }
    };

    // Create a tooltip that will show the thumbnail and time
    const TimeTooltip = ({ numSeconds, style = {} }) => (
      spriteImage ? (
        <div
          style={{
            display: 'inline-block',
            position: 'absolute',
            bottom: '25px',
            transform: 'translateX(-50%)',
            padding: '0px 0px 5px 0px',
            borderRadius: '.5rem',
            background: '#707070',
            color: 'white',
            fontSize: 12,
            fontWeight: 'bold',
            lineHeight: 16,
            textAlign: 'center',
            height: '105px',
            zIndex: 1,
            ...style,
          }}
          className={styles.toolTip}
          onMouseEnter={() => { mouseOutSlider(); }}
          // aria-hidden="true"
        >
          <div
            style={{
              background: `url(${spriteImage})`,
              color: 'white',
              borderRadius: '.5rem',
              // padding: '10px 5px',
              backgroundSize: `${columns * 100}% ${rows * 100}%`,
              backgroundPosition: `-${imgWidthPosition}% -${imgHeightPosition}%`,
              width: '140px',
              height: '85px',
            }}
          />
          <div className={styles.timeStampInThumb}>
            {
              format(numSeconds * duration)
            }
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'inline-block',
            position: 'absolute',
            bottom: '25px',
            transform: 'translateX(-50%)',
            padding: '0px 0px 5px 0px',
            borderRadius: '.2rem',
            background: '#707070',
            color: 'white',
            fontSize: 12,
            fontWeight: 'bold',
            lineHeight: 16,
            textAlign: 'center',
            height: '25px',
            width: '50px',
            ...style,
          }}
          className={styles.toolTipWithOutImage}
        >
          <div className={styles.timeStampInThumbWithOutImage}>
            {
              format(numSeconds * duration)
            }
          </div>
        </div>
      )
    );

    const setDateTime = (str) => {
      const dateTime = new Date(new Date().getTime());
      const sp = str.split(':');
      dateTime.setHours(parseInt(sp[0], 10));
      dateTime.setMinutes(parseInt(sp[1], 10));
      dateTime.setSeconds(parseInt(sp[2], 10));
      return dateTime;
    };

    const getVttDataAsPerTime = (time) => {
      const index = vttContent?.findIndex((content) => setDateTime(content.start) <= setDateTime(time) && setDateTime(content.end) >= setDateTime(time));
      if (index && index !== -1) {
        return vttContent[index];
      }
    };

    const handleIntent = (value) => {
      if (value !== hoverValue) {
        setHoverValue(value);
      }
      if (isDragStart) {
        // onSeekMouseUp(value);
        setProgressValue(value);
      }
      newValueFromSlideBar = value;
      const hOverInSec = duration && value ? duration * value : 0;
      const timeformat = new Date(hOverInSec * 1000).toISOString().substr(11, 8);
      const data = getVttDataAsPerTime(timeformat);
      if (data?.x && data?.x !== '0') {
        setImgWidthPosition((parseInt(data.x, 10) / parseInt(data.w, 10)) * 100);
      } else {
        setImgWidthPosition(0);
      }
      if (data?.y && data?.y !== '0') {
        setImgHeightPosition((parseInt(data.y, 10) / parseInt(data.h, 10)) * 100);
      } else {
        setImgHeightPosition(0);
      }
    };

    const handleIntendForVolume = (value) => {
      if (isDragStart) {
        onVolumeSeekUp(value);
      }
    };

    const handleDragEnd = (event) => {
      setDragStart(false);
      setDragDirection(undefined);
    };
    const handleDragStart = (direction, value) => {
      setDragDirection(direction);
      setDragStart(true);
    };

    const SliderBar = ({ direction, value, style }) => (
      <div
        style={{
          position: 'absolute',
          background: WHITE_SMOKE,
          borderRadius: direction === Direction.HORIZONTAL ? 4 : 2,
          top: direction === Direction.HORIZONTAL ? 0 : `${100 - (value * 100)}%`,
          bottom: 0,
          left: 0,
          width: direction === Direction.HORIZONTAL ? `${value}%` : 4,
          height: direction === Direction.HORIZONTAL ? '' : `${value * 100}%`,
        }}
        // aria-hidden="true"
      />
    );

    useEffect(() => {
      if (isDragStart) {
        if (dragDirection === Direction.HORIZONTAL) {
          if (state.playing) {
            setState({ ...state, playing: false });
          }
        }
      } else if (dragDirection === Direction.HORIZONTAL) {
        if (!state.playing) {
          setState({ ...state, playing: true });
        }
      }
    }, [isDragStart]);

    useEffect(() => {
      if (isDragStart) {
        document.addEventListener('mouseup', handleDragEnd);
        return () => document.removeEventListener('mouseup', handleDragEnd);
      }
    }, [isDragStart]);

    const moveListener = (event) => {
      const percentageMoved = parseInt(event.screenX, 10) / parseInt(window.screen.width, 10);
      // onSeekMouseUp(percentageMoved);
      setProgressValue(percentageMoved);
    };

    useEffect(() => {
      if (progressValue) {
        onSeekMouseUp(progressValue);
      }
    }, [progressValue]);

    useEffect(() => {
      if (isDragStart && dragDirection === Direction.HORIZONTAL) {
        const element = document.getElementById('video-player-id');
        element.addEventListener('mousemove', moveListener);
        return () => element.removeEventListener('mousemove', moveListener);
      }
    }, [isDragStart]);

    // A handle to indicate the current value
    const SliderHandle = ({ direction, value, style }) => (
      <div
        style={{
          position: 'absolute',
          width: 20,
          height: 20,
          background: WHITE,
          borderRadius: '100%',
          transform: 'scale(1)',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.3)',
          },
          bottom: direction === Direction.HORIZONTAL ? 0 : `${value * 100}%`,
          left: direction === Direction.HORIZONTAL ? `${value}%` : 1,
          marginTop: -4,
          marginLeft: -8,
          top: direction === Direction.HORIZONTAL ? -2 : '',
          zIndex: 1000
        }}
        // aria-hidden="true"
        onMouseDown={() => { handleDragStart(direction, value); }}
        id="handler"
      />
    );

    const handleIntentEnd = () => {
      setHoverValue(null);
    };

    const ffff = () => {
    };

    const ProgressBar = ({ isEnabled, direction, value, onMouseOver, ...props }) => (
      <div
        id="slideBar"
        style={{
          width: direction === Direction.HORIZONTAL ? '100%' : 4,
          height: direction === Direction.HORIZONTAL ? 6 : '100%',
          borderRadius: 0,
          // background: GRAY,
          transition: direction === Direction.HORIZONTAL ? 'width 0.1s' : 'height 0.1s',
          cursor: isEnabled === true ? 'pointer' : 'default',
          left: direction === Direction.HORIZONTAL ? 0 : 9,
          bottom: direction === Direction.HORIZONTAL ? 0 : 10,
          zIndex: 1000
        }}
        onMouseLeave={() => { mouseOutSlider(); }}
        onBlur={() => { mouseOutSlider(); }}
        onMouseDown={() => { handleDragStart(direction); }}
      >
        <Slider
          direction={direction}
          onChangeStart={(newValue) => { direction === Direction.HORIZONTAL ? onSeekMouseUp(newValue) : onVolumeSeekUp(newValue); }}
          onChange={(newValue) => { direction === Direction.HORIZONTAL ? onSeek(newValue) : onVolumeChange(newValue); }}
          style={{
            width: direction === Direction.HORIZONTAL ? '100%' : 4,
            height: direction === Direction.HORIZONTAL ? 6 : '100%',
            borderRadius: 0,
            background: GRAY,
            transition: direction === Direction.HORIZONTAL ? 'width 0.1s' : 'height 0.1s',
            cursor: isEnabled === true ? 'pointer' : 'default',
            left: direction === Direction.HORIZONTAL ? 0 : 9,
            bottom: direction === Direction.HORIZONTAL ? 0 : 10,
            zIndex: 1000
          }}
          {...props}
          onIntent={direction === Direction.HORIZONTAL ? handleIntent : handleIntendForVolume}
          onIntentEnd={direction === Direction.HORIZONTAL ? handleIntentEnd : ffff}
          onIntentStart={handleIntentEnd}
        >
          {hoverValue !== null && direction === Direction.HORIZONTAL ? (
            <TimeTooltip
              numSeconds={hoverValue}
              style={{
                left: `${hoverValue * 100}%`,
              }}
            />
          ) : null}
          <SliderBar direction={direction} value={value} style={{ background: isEnabled ? GREEN : GRAY }} />
          <SliderHandle direction={direction} value={value} style={{ background: isEnabled ? GREEN : GRAY }} />
        </Slider>
      </div>

    );

    const backToPreviewScreen = async () => {
      let count = 0;
      if (localStorage?.getItem('video_id') && localStorage?.getItem('video_duration')) {
        if (!count) {
          await axios
            .post(endpoints.updateVideoPaused, { video_id: localStorage.getItem('video_id'), paused_at: localStorage.getItem('video_duration') })
            .then(({ data }) => {
              localStorage.setItem('video_id', null);
              localStorage.setItem('video_duration', null);
            })
            .catch((error) => {
              localStorage.setItem('video_id', null);
              localStorage.setItem('video_duration', null);
            });
          count += 1;
        }
      }
      navigate(Pathname.getVideoPath(videoID));
    };

    const open = Boolean(anchorEl);
    const id = open ? 'playBackRate-popover' : undefined;
    const classes = useStyles();
    return (
      <div className={(showPlayAgainButton || isDragStart) ? classes.controlesWrapper : classes.controlesWrapperForView} ref={ref} id="video-player-id">
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          style={{ padding: 16 }}
          onMouseEnter={() => { mouseOutSlider(); }}
        >
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
            <div
              // aria-hidden="true"
              className={styles.backButton}
              onClick={() => { backToPreviewScreen(); }}
              style={{ cursor: 'pointer', zIndex: 1000 }}
            >
              Back
            </div>
          </Grid>
          <Grid item xs={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* <div style={{ color: '#fff' }} className={classes.titleTrim}>
              {title}
            </div> */}
          </Grid>
          <Grid item xs={1}>
            {
              !buffering ? (
                <IconButton
                  onClick={() => { setVolumeController(!showVolumeCotroller); }}
                  className={classes.controllIcons}
                  style={{ width: '120%' }}
                >
                  <VolumeControlIcon className={styles.colorChangeControl} />
                </IconButton>
              ) : null
            }
            {
              showVolumeCotroller ? (
                <div className={cn({ [styles.box]: true, [styles.arrowTop]: true })}>
                  <ProgressBar
                    isEnabled
                    direction={Direction.VERTICAL}
                    value={volume}
                  />
                </div>
              ) : null
            }
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={onToggleFullScreen}
              className={classes.controllIcons}
            >
              {
                screenSize ? <NormalScreen style={{ width: '80%' }} className={styles.colorChangePlay} /> : <FullScreen style={{ width: '80%' }} className={styles.colorChangePlay} />
              }
            </IconButton>
          </Grid>
        </Grid>
        {/* mid */}
        <Grid container direction="row" alignItems="center" justify="center" onMouseEnter={() => { mouseOutSlider(); }}>
          {
            buffering ? (
              <CircularProgress size={100} />
            ) : (
              showPlayAgainButton && played === 1 ? (
                <div>
                  <PlayAgain onClick={() => { setPlayAgainButton(false); }} style={{ width: '75%', marginLeft: '12px', cursor: 'pointer' }} />
                  <div className={styles.playAgain}>Play Again</div>
                </div>
              ) : (
                <>
                  <IconButton style={{ marginRight: 50, width: '5.5%' }} onClick={onRewind} className={classes.controllIcons} aria-label="reqind">
                    <Replay15 className={styles.colorChangeControl} />
                  </IconButton>
                  <IconButton
                    onClick={onPlayPause}
                    className={classes.controllIcons}
                    aria-label="reqind"
                    style={{ width: '5%' }}
                  >
                    {playing ? <PauseIcon className={styles.colorChangePause} /> : <PlayIcon className={styles.colorChangePlay} />}
                  </IconButton>
                  <IconButton style={{ marginLeft: 50, width: '5.5%' }} onClick={onFastForward} className={classes.controllIcons} aria-label="reqind">
                    <Forward15 className={styles.colorChangeControl} />
                  </IconButton>
                </>
              )
            )
          }
        </Grid>

        {/* bottom */}
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          style={{ padding: '34px 37px' }}
        >
          <Grid style={{ padding: '20px 0px' }} item xs={12} onMouseLeave={() => { mouseOutSlider(); }}>
            <Grid container direction="row" alignItems="left">
              <Grid item xs={6} onMouseLeave={() => { mouseOutSlider(); }}>
                {
                  !buffering ? (
                    <div style={{ color: '#fff' }} className={classes.titleTrim}>
                      {title}
                    </div>
                  ) : null
                }
              </Grid>
              <Grid item xs={6} justifyContent="flex-end" onMouseLeave={() => { mouseOutSlider(); }}>
                <Grid container style={{ justifyContent: 'end' }} direction="row">
                  {
                    (showPlayAgainButton && nextVideoDetails && Object.keys(nextVideoDetails).length) ? (
                      <ShowNextVideo nextVideoDetails={nextVideoDetails} countDown={countDown} />
                    ) : null
                  }
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} onMouseLeave={() => { mouseOutSlider(); }}>
            {
              !buffering ? (
                <ProgressBar
                  isEnabled
                  direction={Direction.HORIZONTAL}
                  value={played * 100}
                />
              ) : null
            }
          </Grid>
          <Grid item onMouseEnter={() => { mouseOutSlider(); }}>
            <Grid container direction="row" alignItems="left">
              {
                !buffering ? (
                  <Button variant="text" style={{ color: '#fff', marginLeft: -11, position: 'relative', top: '17px' }}>
                    <span className={styles.timeStamp}>
                      {elapsedTime}
                      <span style={{ color: GRAY }}>
                        {` / ${totalDuration}`}
                        {/* /
                      {totalDuration} */}
                      </span>
                    </span>
                  </Button>
                ) : null
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
);
