/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { Snackbar, ProjectorShimmer } from 'Components';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import UserRequest from './UserRequest';
import { ViewerPage } from '../components/page';
import styles from './index.module.css';
import AddSuccessorModal from '../../settings/connected-accounts/Modals/AddSuccessorModal';

const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };

export const Successors = () => {
    // eslint-disable-next-line no-script-url
    window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const [snackbar, setSnackbar] = useState(defaultSnackbar);
  const [addSuccessorModal, openAddSuccessorModal] = useState(false);
  const [successors, setSuccessor] = useState(undefined);
  const { langText, subscriptionDetails } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const successorText = langText?.viewersSideBar?.successor;
  const commonText = langText?.common;
  const noPlan = !subscriptionDetails?.has_activePlan;
  const freePlan = parseInt(subscriptionDetails?.subscription?.plan_type, 10) === 3;
  const freePlanMsg = 'Successors can only be added with the purchase of a plan';
  const newPlanMsg = 'Successors can only be added with purchase plans';
  const freePlanAction = 'Upgrade';
  const newPlanAction = 'Purchase';

  const { isValidating: loadingAccessRequests, mutate: fetchMySuccessor } = useSWR([endpoints.getMySucessors], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setSuccessor(data);
      } else {
        setSuccessor(undefined);
      }
    },
    onError: () => {
      setSuccessor(undefined);
    },
  });

  useEffect(() => {
    fetchMySuccessor();
  }, []);

  useEffect(() => {
    if (location.search?.includes(Pathname.addSuccessor)) {
      openAddSuccessorModal(true);
    } else {
      openAddSuccessorModal(false);
      navigate(location.pathname);
    }
  }, [location.search]);

  const manageSuccessorModal = () => {
    openAddSuccessorModal(false);
    navigate(location.pathname);
  };

  const actionAfterAddSuccessor = () => {
    fetchMySuccessor();
  };

  return (
    <>
      <ViewerPage
        title={successorText?.title || 'Successors'}
        addActionName={successorText?.newSuccessor || 'New Successor'}
        link={noPlan || freePlan ? '' : Pathname.addSuccessor}
        subTitle={successorText?.description || 'a successor has to be a current viewer'}
        restricted={noPlan || freePlan}
      >
        {
          noPlan || freePlan ? (
            <div className={styles.successorRestrictContainer}>
              <div className={styles.restrictBox}>
                <div className={styles.message}>
                  {freePlanMsg}
                </div>
                <div className={styles.upgradeButton} onClick={() => navigate(Pathname.plans)}>
                  {noPlan ? newPlanAction : freePlanAction}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.allViewersContainer}>
              {
                loadingAccessRequests ? (
                  <ProjectorShimmer isLight />
                ) : (
                  <div>
                    {successors?.map(({ id, firstname, lastname, email }, idx) => (
                      <UserRequest
                        email={email}
                        id={id}
                        key={idx}
                        firstname={firstname}
                        lastname={lastname}
                        setSnackbar={setSnackbar}
                        setSuccessor={setSuccessor}
                        successors={successors}
                      />
                    ))}
                  </div>
                )
              }
            </div>
          )
        }
      </ViewerPage>
      <AddSuccessorModal
        secessionAccount={addSuccessorModal}
        actionAfterAddSuccessor={() => actionAfterAddSuccessor()}
        setSecessionAccount={() => manageSuccessorModal()}
      />
      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
    </>
  );
};
