import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Pathname } from 'Routes';
import { Page } from 'Components';
import { AppContext } from 'Context';
import galleryImage from 'Assets/gallery@2x.png';
import AccessImage from 'Assets/access@2x.png';
import styles from './index.module.css';

const Action = ({ altText, imageURL, link, text }) => (
  <Link className={styles.action} to={link}>
    <img className={styles.actionImage} src={imageURL} alt={altText} />
    <div className={styles.actionText}>{text}</div>
  </Link>
);

export const GettingStarted = () => {
  const { userConnections, langText } = useContext(AppContext);
  const gettingStartText = langText?.dashboard;
  return (
    <Page
      className={styles.page}
      containerClassName={styles.pageContainer}
      contentClassName={styles.pageContent}
      noBottomBar
      noDefaultBg
      noFixedSidebar
      transparentCompactHeader
    >
      <div className={styles.gettingStarted}>
        <div className={styles.message}>{gettingStartText?.letGetStarted || 'LET’S GET YOU STARTED'}</div>
        <div className={styles.actionsContainer}>
          <Action
            altText="Upload"
            imageURL={galleryImage}
            link={`${Pathname.dashboard}${Pathname.uploadVideo}`}
            text={gettingStartText?.startUploading || 'Start creating and uploading your content!'}
          />
          <Action
            altText="Access"
            imageURL={AccessImage}
            link={`${Pathname.access}${userConnections?.length ? '' : Pathname.requestAccess}`}
            text={gettingStartText?.startRequestAccess || 'Join or request access to watch content!'}
          />
        </div>
      </div>
    </Page>
  );
};
