import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { debounce } from 'lodash';
import { Collapse, Fade } from '@material-ui/core';
import { validate as isValidEmail } from 'email-validator';
import { Pathname } from 'Routes';
import { Button, Snackbar } from 'Components';
import { AuthContext, AppContext } from 'Context';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { Input } from '../../public/authentication/components/input-field';
import { AuthPage } from '../../public/authentication/components/page';
import styles from './index.module.css';
import authStyles from '../../public/authentication/auth.module.css';

const transitionTimeout = 300;
const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };

export const ManageDevice = () => {
  const navigate = useNavigate();
  const { preferredEmail, setPreferredEmail } = useContext(AuthContext);
  const { langText } = useContext(AppContext);
  const manageDeviceText = langText?.manageDevice;
  const [email, setEmail] = useState(isValidEmail(preferredEmail) ? preferredEmail : '');
  const [qrCode, setQrCode] = useState('');
  const [qrCodeIsValid, setQrCodeValidity] = useState(false);
  const [emailIsFixed, fixEmail] = useState(false);
  const [emailIsValid, setEmailValidity] = useState(false);
  const [accountExists, setAccountExistence] = useState(undefined);
  const [checkingAccountExistence, setAccountExistenceCheckingStatus] = useState(false);
  const [cancelToken, setCancelToken] = useState(undefined);

  const [snackbar, setSnackbar] = useState(defaultSnackbar);

  const { isValidating: addingDevices, mutate: addDevice } = useSWR([endpoints.registerTv, qrCode], {
    fetcher: (url, inputQRCode) => dataFetcher(url, { code: inputQRCode }),
    onSuccess: ({ success, message }) => {
      if (success) {
        setSnackbar({
          isVisible: true,
          message: 'Device Added',
        });
        setTimeout(() => { navigate(Pathname.dashboard); }, 500);
      } else {
        setSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while sending Reset Code' });
    },
  });

  const qrCodeHandler = debounce(({ target: { value: inputQrCode } }) => {
    if (inputQrCode.length >= 6) {
      setQrCode(inputQrCode);
      setQrCodeValidity(true);
    } else {
      setQrCode('');
      setQrCodeValidity(false);
    }
  }, 750, { trailing: true });

  return (
    <AuthPage>
      <form onSubmit={(e) => { e.preventDefault(); }}>
        <Input
          defaultValue={email}
          type="code"
          // isDisabled={(email === preferredEmail) && checkingAccountExistence}
          isLoading={checkingAccountExistence}
          label="Enter TV Code"
          onChange={(e) => {
            e.persist();
            qrCodeHandler(e);
            setAccountExistenceCheckingStatus(false);
            if (cancelToken) { cancelToken.cancel(); }
            if (!e?.target?.value) {
              setEmail('');
              setAccountExistence(undefined);
              setEmailValidity(false);
            } else {
              setCancelToken(AxiosCancelToken.source());
            }
          }}
          placeholder="TV Code"
        />
        <br />
        <Button
          blockText
          isBlue
          isDisabled={!qrCodeIsValid || addingDevices}
          isFullWidth
          isLarge
          onClick={addDevice}
        >
          {addingDevices ? `${(manageDeviceText?.addingDevice || 'Adding Device')}...` : (manageDeviceText?.addDevice || 'Add Device')}
        </Button>
      </form>

      <br />
      <br />
      <br />
      <br />

      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
    </AuthPage>
  );
};
