/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import { AppContext } from 'Context';
import cn from 'classnames';
import TransformIcon from '@material-ui/icons/Transform';
import styles from './index.module.css';

const PreviewSettings = ({ previewType, previewChange, selectedPreviewSettings }) => {
  const { langText } = useContext(AppContext);
  const layoutText = langText?.layout;
  const commonText = langText?.common;

  return (
    // <div className={styles.slideShowContainer}>
    //   {/* <div className={styles.chooseOrder}>
    //     {layoutText?.previewScreen || 'Preview Screen'}
    //   </div>
    //   <div className={styles.albumSettingsBundle}>
    //     <div
    //       className={cn({
    //         [styles.albumSettingsDiv]: true,
    //       })}
    //     >
    //       <div>
    //         <span style={{ cursor: 'pointer' }}>
    //           <TransformIcon className={styles.addMorePhotoIcon} />
    //         </span>
    //         <span className={styles.addMorePhoto}>{layoutText?.thumbnail || 'Thumbnail'}</span>
    //       </div>
    //       <select name="transition-type" className={styles.slideShowTransitions} onChange={previewChange} value={selectedPreviewSettings}>
    //         {
    //           previewType?.map((type) => (
    //             <option value={type.id}>
    //               {type.name}
    //             </option>
    //           ))
    //         }
    //       </select>
    //     </div>
    //   </div> */}
    // </div>
    null
  );
};

export default PreviewSettings;
