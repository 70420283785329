import React, { useContext, useEffect } from 'react';
import { AppContext, AuthContext } from 'Context';
import { NavLink } from 'react-router-dom';
import { Page } from 'Components';
import { Pathname } from 'Routes';
import cn from 'classnames';
import styles from './index.module.css';

export const SettingsPage = ({ children, title, description }) => {
    // eslint-disable-next-line no-script-url
    window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const { langText, getAccessChecker, lineReq, setTopOrBottom } = useContext(AppContext);
  const { isInActiveUser } = useContext(AuthContext);
  const sideBarText = langText?.settings;
  useEffect(() => {
    setTopOrBottom(true);
    return () => {
      setTopOrBottom(false); // Set topOrBottom to false when leaving the component
    };
  }, []);
  const commonSidebarLinks = [
    { name: (sideBarText?.accountSideBarTitle || 'Account'), link: Pathname.getSettingsPath(Pathname.settings.accounts) },
    { name: (sideBarText?.notificationSidebarTitle || 'Notifications'), link: Pathname.getSettingsPath(Pathname.settings.notifications) },
    { name: ('Billing & Payments' || sideBarText?.billPaymentSidebarTitle), link: Pathname.getSettingsPath(Pathname.settings.billings) },
    { name: (sideBarText?.privacySidebarTitle || 'Privacy'), link: Pathname.getSettingsPath(Pathname.settings.privacy) },
    { name: 'User Management', link: Pathname.getSettingsPath(Pathname.settings.manageUsers) },
    { name: (sideBarText?.termsSidebarTitle || 'Terms of Use'), link: Pathname.getSettingsPath(Pathname.settings.termsAndConditions) },
    { name: (sideBarText?.subscriberSidebarTitle || 'Subscriber Agreement'), link: Pathname.getSettingsPath(Pathname.settings.subscriberAgreement) }
  ];

  const sidebarLinks = isInActiveUser ? [
    { name: ('Billings and Payments' || sideBarText?.billPaymentSidebarTitle), link: Pathname.getSettingsPath(Pathname.settings.billings) },
    { name: (sideBarText?.privacySidebarTitle || 'Privacy'), link: Pathname.getSettingsPath(Pathname.settings.privacy) },
    { name: (sideBarText?.termsSidebarTitle || 'Terms of Use'), link: Pathname.getSettingsPath(Pathname.settings.termsAndConditions) },
    { name: (sideBarText?.subscriberSidebarTitle || 'Subscriber Agreement'), link: Pathname.getSettingsPath(Pathname.settings.subscriberAgreement) }
  ] : getAccessChecker?.success === true
  ? [
    { name: (sideBarText?.accountSideBarTitle || 'Account'), link: Pathname.getSettingsPath(Pathname.settings.accounts) },
    { name: (sideBarText?.notificationSidebarTitle || 'Notifications'), link: Pathname.getSettingsPath(Pathname.settings.notifications) },
    { name: ('Billing & Payments' || sideBarText?.billPaymentSidebarTitle), link: Pathname.getSettingsPath(Pathname.settings.billings) },
    { name: (sideBarText?.privacySidebarTitle || 'Privacy'), link: Pathname.getSettingsPath(Pathname.settings.privacy) },
    { name: (sideBarText?.termsSidebarTitle || 'Terms of Use'), link: Pathname.getSettingsPath(Pathname.settings.termsAndConditions) },
    { name: (sideBarText?.subscriberSidebarTitle || 'Subscriber Agreement'), link: Pathname.getSettingsPath(Pathname.settings.subscriberAgreement) }
  ] : commonSidebarLinks;
  return (
    <Page noBottomBar contentClassName={styles.pageContent} headerTheme={styles.headerTheme}>
      <div className={styles.settingsPage}>
        <div className={styles.sidebar}>
          <div className={styles.sidebarContent}>
          {sidebarLinks.map(({ name, link }, idx) => {
          const isActive = window.location.pathname === link;
          const navLinkClassName = cn(styles.sidebarLink, { [styles.currentSidebarLink]: isActive });
          return (
            <NavLink
              key={idx}
              to={link}
              className={navLinkClassName}
            >
             {name}
            </NavLink>
          );
        })}
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.pageTitle}>{title}</div>
          <div className={styles.pageDescription}>{description}</div>
          {lineReq === false && <hr className={styles.pageDivider} />}
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </Page>
  );
};
