import heic2any from 'heic2any';
import { useState, useEffect } from 'react';

const useConvertHeicImages = (photoFile) => {
  const [images, setImages] = useState([]);
  const [convertingHeic, setConvertingHeic] = useState([]);

  useEffect(() => {
    (async () => {
      const files = Array.isArray(photoFile) ? photoFile : [photoFile];
      if (files.length === 0) {
        setImages([]);
        return;
      }

      const newImages = [];
      const promises = [];
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i]?.file;
        const alreadyConverted = images.find((image) => image.file?.name === file?.name && image.converted);
        if (file.type === 'image/heic' || file?.name?.includes('.HEIC')) {
          if (!alreadyConverted) {
            setConvertingHeic((c) => c.concat({ index: i }));
            setImages((images) => images.concat({ file, converted: false }));
            promises.push(
              heic2any({ blob: file, toType: 'image/jpeg' })
                .then((blob) => URL.createObjectURL(blob))
                .then((url) => newImages.push({ url, file, converted: true }))
            );
          } else {
            newImages.push(alreadyConverted);
          }
        } else {
          newImages.push({ url: files[i]?.url || URL.createObjectURL(file), file, converted: true });
        }
      }
      await Promise.all(promises).then(() => { });
      setConvertingHeic([]);
      setImages(newImages);
    })();
  }, [photoFile]);

  return [images, convertingHeic];
};

export default useConvertHeicImages;
