import React, { useState } from 'react';
import { Box, Grid, Typography, Button as MuiButton } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import Navbar from './Navbar';
import Button from '../common/Button';
import logo from '../assets/img/logo-p.svg';
import appStore from '../assets/img/appstore2.svg';
import appleTv from '../assets/img/apple-tv.svg';
import roku from '../assets/img/roku.svg';
import fireTv from '../assets/img/fire-tv.svg';
import googleCast from '../assets/img/google-cast.svg';
import googlePlay from '../assets/img/playstore.svg';
import MobileViewContent from '../../../../Components/mobileViewModal/mobileViewContent';

function Header() {
  const [downloadAppTestVisible, SetDownloadAppVisiblilty] = useState(true);

  return (
    <>
      <header className="external-header">
        <Navbar />

        {/* <Box display="flex" justifyContent="space-between">
      <div className="modaltext">
        Download and install Projector Mobile App from Appstore Or
        Playstore to better view and manage your Projector account
      </div>
      <Closeic style={{ height: '25px', fill: 'white', marginTop: '8px' }} onClick={doCancelAction} />
      </Box> */}

        <div className="header-contents">
          {/* {downloadAppTestVisible && isMobile && <MobileViewContent onHide={() => SetDownloadAppVisiblilty(false)} />} */}

          <h1 data-aos="fade-down" data-aos-delay="100" className="start-streaming">
            Start streaming
            <br />
            Your home movies
          </h1>

          {/* <div data-aos="fade-down" data-aos-delay="100" className="view-share-preserve">
            <span>View</span>
            <img src={logo} alt="" />
            <span>Share</span>
            <img src={logo} alt="" />
            <span>Preserve</span>
          </div> */}

          <div data-aos="fade-zoom-in" data-aos-delay="100">
            <Button
              text="Start Today!"
              link="/newSign-up"
              btnClass="blue header-btn"
            />
          </div>
        </div>
      </header>

      <Grid className="platform-sec" container spacing={1} style={{ justifyContent: 'center', padding: '15px', marginTop: '20px' }}>
        {/* App Store and Play Store */}
        <Grid item lg={4} md={4} style={{ justifyContent: 'space-around' }} className="platforms store-icons">
          <Grid>
            <a href="https://apps.apple.com/in/app/projector-app/id1585850104" target="_blank" rel="noreferrer">
              <img src={appStore} alt="App Store" />
            </a>
          </Grid>
          <Grid item>
            <a href="https://play.google.com/store/apps/details?id=com.projectorllc.projectorapp" target="_blank" rel="noreferrer">
              <img className="play-icon" src={googlePlay} alt="Play Store" />
            </a>
          </Grid>
        </Grid>

        {/* Other platforms */}
        <Grid item xs={12} md={8} className="platforms" style={{ padding: '2px' }}>
          <Grid container spacing={1} style={{ justifyContent: 'space-around', alignItems: 'center' }}>
            <Grid item>
              <a href="https://channelstore.roku.com/details/0b5b16be5182204ba916edba34f69b20/projector" target="_blank" rel="noreferrer">
                <img src={roku} alt="Roku" />
              </a>
            </Grid>
            <Grid item>
              <a href="https://www.amazon.com/s?i=mobile-apps&rh=p_4%3AProjector%2C+LLC&search-type=ss" target="_blank" rel="noreferrer">
                <img src={fireTv} alt="Fire TV" />
              </a>
            </Grid>
            <Grid item>
              <a href="https://apps.apple.com/in/app/projector-app/id1585850104" target="_blank" rel="noreferrer">
                <img src={appleTv} alt="Apple TV" />
              </a>
            </Grid>
            <Grid item className="google-cast-container">
              <a href="https://play.google.com/store/apps/details?id=com.projectorllc.projectorapptv" target="_blank" rel="noreferrer">
                <img className="google-cast" src={googleCast} alt="Google Cast" />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Header;
