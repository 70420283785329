import React, { useState, useEffect, useContext, useParams } from 'react';
import {
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { Box, Button } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AppContext, AuthContext } from 'Context';
import { Pathname } from 'Routes';
import { dataFetcher, endpoints, baseUrl } from 'Api';
import useSWR from 'swr';
import SubscriptionPage from './subscribe';
import TrialPage from './trial';
import TrialEndPage from './trial-end';
import './index.css';
import InputGrid from './components/input-grid';
import PaymentField from '../../../../private/settings/manage-payment-methods/AddCard/components/payment-field';

export default function CheckoutForm({ setIsToken, selected, purchasingProduct, setOpen, selectedPlan, totalTrialDays,
  updateDetails, contentId, setUploadModalVisibility }) {
  const { userDetails, setUserDetails, setAppSnackbar, getSubscription, setCurrentSelectedPlan, affiliateCode, apiResponse, isTrail } = useContext(AppContext);
  const { forceAutoSignin, getToken } = useContext(AuthContext);
  const [email, setEmail] = useState(userDetails.email);
  const [firstName, setFirstName] = useState(userDetails.firstname);
  const [lastName, setLastName] = useState(userDetails.lastname);
  const [processing, setProcessing] = useState(false);
  const [cardNumber, setCardNumber] = useState(undefined);
  const [expirationDate, setExpirationDate] = useState(undefined);
  const [cvvNumber, setCvvNumber] = useState(undefined);
  const [paymentIsValid, setPaymentValidity] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [stripeToken, setStripeToken] = useState([]);
  const [cardNumberStripe, setCardNumberStripe] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [checked, setChecked] = useState(false);
  const userId = '11';
  const [error, setError] = useState(null);
  const [cvc, setCvc] = useState('');
  const [promoCodeNew, setPromoCodeNew] = useState('');
  const digits = cardNumberStripe.replace(/\D/g, '');
  const isAmex = digits.startsWith('34') || digits.startsWith('37');
  const { isValidating: updatingName, mutate: updateName } = useSWR([endpoints.updateMyProfile, firstName, lastName, affiliateCode], {
    fetcher: (url, inputFirstname, inputLastname, affiliateCode) => dataFetcher(url, { firstname: inputFirstname, lastname: inputLastname, affiliateCode }),
    onSuccess: ({ success }) => {
      if (success) {
        setUserDetails({ ...userDetails, firstname: firstName, lastname: lastName });
      }
    },
    onError: () => {
    },
  });
  const subscribePlan = async () => {
    try {
      const myToken = await getToken();
      const apiUrl = `${baseUrl}/subscribePlan`;
      const requestBody = {
        token: myToken,
        plan_id: 11,
        is_free: 0,
        trial: isTrail === '1',
        promo: promoCodeNew,
        // affiliateCode
      };
      const response = await axios.post(apiUrl, requestBody);
      getSubscription();
      forceAutoSignin(); // Calls getmy profile api
      setCurrentSelectedPlan(undefined);
      if (contentId) {
        // navigate(location.pathname);
        updateDetails();
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'success',
          message: 'Plan purchased successfully',
        });
        navigate(`${Pathname.dashboard}?purchased=completed`);
        setProcessing(false);
      }
    } catch (error) {
      forceAutoSignin();
      const responseData = error?.response?.data?.message;
      console.error('error', error);
      setAppSnackbar({
        isVisible: true,
        type: 'error',
        message: responseData || 'Oops! Something went wrong while purchasing plan',
      });
    }
  };
  const callStripeAPI = async () => {
    const datax = new URLSearchParams();
    datax.append('card[number]', cardNumberStripe);
    datax.append('card[exp_month]', expiryMonth);
    datax.append('card[exp_year]', expiryYear);
    datax.append('card[cvc]', cvc);
    try {
      const response = await axios.post('https://api.stripe.com/v1/tokens', datax, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer pk_live_51OyTOEKaFrbWdXakXgNskBU4TmXmqTIP6thT1vf8LjiR1FADapz2jX6Fi5a1h8KpG7hCAiEK9EnlrKbH7wDVGjOs00ReGilVdx',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      // setAppSnackbar({ isVisible: true, type: 'error', message: error?.response?.data?.error?.message });
    }
  };

  const fetcher = async (url, cardNumberStripe, expiryMonth, expiryYear, cvc) => {
    try {
      const stripeToken1 = await callStripeAPI();
      const stripeToken2 = await callStripeAPI();

      const stripeTokenId1 = stripeToken1?.id;
      const stripeTokenId2 = stripeToken2?.id;

      if (!stripeTokenId1 || !stripeTokenId2) {
        console.error('Error: Failed to get both Stripe tokens');
        return;
      }

      const payload = {
        card_token_alternative: stripeTokenId1,
        card_token: stripeTokenId2,
        userId,
        managerCard: '0',
      };

      const response = await dataFetcher(url, payload);

      return response;
    } catch (error) {
      console.error('Error in fetcher:', error);
      throw error;
    }
  };

  const payload = {
    card_token_alternative: stripeToken[0]?.id,
    card_token: stripeToken[1]?.id,
    userId,
    managerCard: '0',
  };
  const { isValidating: addingCard, mutate: addCard } = useSWR([
    endpoints.addPaymentMethods, payload], {
    fetcher: (url, payload) => dataFetcher(url, payload), // Directly pass the payload object
    onSuccess: ({ success, message }) => {
      if (success) {
        subscribePlan();
        // closeAddCard();
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message,
        });
        // setProcessing(false);
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data?.message;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData || 'Oops! Something went wrong while adding new card' });
      setProcessing(false);
    },
  });

  const handleSubmit = async () => {
    try {
      setProcessing(true);
      updateName();
      if (checked === true) {
        await subscribePlan();
      } else {
        const stripeToken1 = await callStripeAPI();
        const stripeToken2 = await callStripeAPI();
        setStripeToken([stripeToken1, stripeToken2]);
        await addCard();
      }
    } catch (error) {
      const responseData = error?.response?.data?.message;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData || 'Oops! Something went wrong' });
    } finally {
      // setProcessing(false);
    }
  };
  useEffect(() => {
    if (!purchasingProduct) {
      setProcessing(false);
    }
  }, [purchasingProduct]);
  return (
    // <form id="payment-form" onSubmit={handleSubmiSub}>
    <Box bgcolor="#FAFBFC">
      {selected?.isTrial !== 'No' ? (
        <SubscriptionPage
          afflitedDetails={apiResponse?.affDetails}
          setOpen={setOpen}
          setCardNumberStripe={setCardNumberStripe}
          userEmail={email}
          setExpiryYear={setExpiryYear}
          setExpiryMonth={setExpiryMonth}
          setInputValue={setInputValue}
          userFirstName={firstName}
          userLastName={lastName}
          setFirstName={setFirstName}
          setLastName={setLastName}
          purchasingProduct={processing}
          isDisabled={userDetails.firstname !== '' && userDetails.lastName !== ''}
          onContinue={handleSubmit}
          totalTrialDays={totalTrialDays}
          planId={selectedPlan}
          promoCodeNew={promoCodeNew}
          setPromoCodeNew={setPromoCodeNew}
          checked={checked}
          setChecked={setChecked}
          setCvc={setCvc}
          expiryMonth={expiryMonth}
          cardNumberStripe={cardNumberStripe}
          cvc={cvc}
        >
          <div className="card">
            <PaymentField
              isAmex={isAmex}
              error={error}
              setError={setError}
              stripe
              cvc={cvc}
              setCvc={setCvc}
              expiryYear={expiryYear}
              setExpiryYear={setExpiryYear}
              inputValue={inputValue}
              setInputValue={setInputValue}
              expiryMonth={expiryMonth}
              setExpiryMonth={setExpiryMonth}
              cardNumberStripe={cardNumberStripe}
              setCardNumberStripe={setCardNumberStripe}
              onCardNumberChange={setCardNumber}
              onCvvNumberChange={setCvvNumber}
              onExpirationDateChange={setExpirationDate}
              setValidity={setPaymentValidity}
            />
          </div>
        </SubscriptionPage>
      )
        : (
          <SubscriptionPage
            afflitedDetails={apiResponse?.affDetails}
            setOpen={setOpen}
            userEmail={email}
            userFirstName={firstName}
            userLastName={lastName}
            setFirstName={setFirstName}
            setLastName={setLastName}
            purchasingProduct={processing}
            isDisabled={userDetails.firstname !== '' && userDetails.lastName !== ''}
            onContinue={handleSubmit}
            planId={selectedPlan}
            setExpiryYear={setExpiryYear}
            setExpiryMonth={setExpiryMonth}
            setCardNumberStripe={setCardNumberStripe}
            checked={checked}
            setChecked={setChecked}
          >
            <div className="card">
              <PaymentField
                isAmex={isAmex}
                onCardNumberChange={setCardNumber}
                onCvvNumberChange={setCvvNumber}
                onExpirationDateChange={setExpirationDate}
                setValidity={setPaymentValidity}
              />
            </div>
          </SubscriptionPage>
        )}

      {/* <TrialEndPage>
        <CardElement id="card-element" />
        {error && (
          <div className="card-error" style={{ color: 'red' }} role="alert">
            {error}
          </div>
        )}
      </TrialEndPage> */}
    </Box>
  );
}
