/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState, useEffect } from 'react';
import {
  DialogContent,
  Grid,
  FormControlLabel,
  Divider,
  Checkbox,
  CircularProgress as MuiCircularProgress, withStyles
} from '@material-ui/core';
import cn from 'classnames';
import { ReactComponent as SelectPhoto } from 'Assets/select-photo.svg';
import { ReactComponent as SelectVideo } from 'Assets/select-video.svg';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import StopIcon from '@material-ui/icons/Stop';
import { DialogFooter } from './Material-reDesign';
import styles from '../index.module.css';
import '../loader.css';

const CircularProgress = withStyles({
  circle: {
    color: '#000 !important'
  }
})(MuiCircularProgress);

const ChooseWhatUpload = ({ setUpdateType, setChooseAlbumStep, albumList, gettingAlbums,
  setExistingAlbum, chooseExistingAlbum, updateAnExistingAlbum, uploadText, commonText }) => {
  const [albumId, setAlbumId] = useState(undefined);

  return (
    <>
      <DialogContent
        dropEffect="copy"
        className={styles.content}
        style={{ position: 'relative', overflow: 'hidden' }}
      >
        <div className={styles.actionContainer}>
          <Grid container>
            <Grid item xs={5} sm={5} className={cn({ [styles.iconColumn]: true, [styles.videoUploadColumn]: true })}>
              <div className={styles.alignCenterContainer}>
                <div onClick={() => { setUpdateType('video'); }} className={styles.addFilesSectionImageContainer}>
                  <SelectVideo className={styles.addFilesSectionImage} />
                </div>
                <div onClick={() => { setUpdateType('video'); }} className={styles.addFilesSectionButton}>{uploadText?.addVideo || 'Add Video'}</div>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} className={styles.separator}>
              <div className={styles.resizeSeparator} />
            </Grid>
            <Grid
              item
              xs={5}
              sm={5}
              className={cn({
                [styles.iconColumn]: true,
                [styles.photoUploadColumn]: true
              })}
            >
              <div className={styles.alignCenterContainer}>
                <div
                  onClick={() => { setUpdateType('photo'); setChooseAlbumStep(1); }}
                  className={styles.addFilesSectionImageContainer}
                >
                  <SelectPhoto className={styles.addFilesSectionImage} />
                </div>
                {
                  gettingAlbums ? (
                    <div className={styles.loadingAlbum}>
                      {`${commonText?.loading || 'Loading'}...`}
                    </div>
                  ) : (
                    albumList?.length === 0 ? (
                      <div
                        onClick={() => { setUpdateType('photo'); setChooseAlbumStep(1); }}
                        className={styles.addFilesSectionButton}
                      >
                        {uploadText?.addPhotos || 'Add Photos'}
                      </div>
                    ) : (
                      <>
                        <div className={styles.addToExisting}>
                          {uploadText?.existingAlbum || 'Add to Existing Albums'}
                        </div>
                        <div style={{ border: 'none' }} className={styles.albumListContainer}>
                          {
                            gettingAlbums ? (
                              <div className={styles.noAlbumFound}>{`${commonText?.loading || 'Loading'}...`}</div>
                            ) : (
                              <>
                              <div className={styles.albumListContainer}>
                                <div className={styles.albumList}>
                                  {
                                    albumList?.length > 0 ? (albumList?.map((album) => (
                                      <>
                                        <div className={styles.iconTextContainer}>
                                          <span className={styles.checkBox}>
                                            {
                                              chooseExistingAlbum === album.id ? (
                                                <StopIcon
                                                  className={styles.stopIcon}
                                                  fontSize="large"
                                                  onClick={() => { setExistingAlbum(undefined); }}
                                                />
                                              ) : (
                                                <CheckBoxOutlineBlankIcon
                                                  className={styles.iconColor}
                                                  onClick={() => { setExistingAlbum(album.id); }}
                                                />
                                              )
                                            }
                                          </span>
                                          <span className={styles.albumTitleText}>{album.title}</span>
                                        </div>
                                      </>
                                    ))) : (
                                      <div className={styles.noAlbumFound}>No Album found</div>
                                    )
                                  }
                                </div>
                              </div>
                            <div className={styles.buttonGroup}>
                                  <div
                                    className={styles.addAsNewButton}
                                    onClick={() => { setUpdateType('photo'); setChooseAlbumStep(1); }}
                                  >
                                    {uploadText?.newAlbum || 'New Album'}
                                  </div>
                                <div
                                  style={!chooseExistingAlbum ? { pointerEvents: 'none', opacity: '0.4' } : {}}
                                  className={styles.chooseAlbum}
                                  onClick={() => {
                                    setUpdateType('photo');
                                    setChooseAlbumStep(1);
                                    updateAnExistingAlbum(true);
                                  }}
                                >
                                  {uploadText?.choose || 'Choose'}
                                </div>
                            </div>
                              </>
                            )
                          }
                        </div>
                      </>
                    )
                  )
                }
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogFooter>
        <div className={styles.uploadTerms}>
          {uploadText?.footer || 'By submitting your videos to Projector, you acknowledge that you agree to Projector’s Terms of Service and Community Guidelines. Please make sure that you do not violate others’ copyright or privacy rights. Learn more'}
        </div>
      </DialogFooter>
    </>
  );
};
export default ChooseWhatUpload;
