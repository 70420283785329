/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Pathname } from 'Routes';
import { Button } from 'Components';
import { encryptedKeyForCredentials } from 'Commons';
import { AppContext, AuthContext } from 'Context';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { AuthPage } from '../components/page';
import styles from './index.module.css';

/**
 * Page /mobile-verification
 * To update user mobile number
 */
export const MobileVerification = () => {
  const navigate = useNavigate();
  const { setAppSnackbar, userDetails, setUserDetails, plansPage, setPlansPage, langText, setReferralStatus } = useContext(AppContext);
  const mobileVerificationText = langText?.mobileVerification;
  const commonText = langText?.common;
  const signUpText = langText?.signInUp;
  const email = sessionStorage.getItem(encryptedKeyForCredentials.email);
  const [phoneNumber, setPhoneNumber] = useState(undefined);

  const { isValidating: requestingCode, mutate: requestVerificationCode } = useSWR([endpoints.sendOtpToMobile, email], {
    fetcher: (url, email) => dataFetcher(url, { email }),
    onSuccess: ({ success, message }) => {
      if (success) {
        sessionStorage.setItem('#fs656gfhllh@#$', true);
        navigate(Pathname.authentication.verifyCode);
        setAppSnackbar({ message: message || 'Otp Resend', isVisible: true });
      } else {
        setAppSnackbar({ message: message || 'Oops! Something went wrong', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: updatingMobile, mutate: updateMobile } = useSWR([endpoints.updateMyProfile, phoneNumber, email], {
    fetcher: (url, inputMobile, email) => dataFetcher(url, { mobile: inputMobile, email }),
    onSuccess: ({ success, message }) => {
      if (success) {
        setUserDetails({ ...userDetails, mobile: phoneNumber });
        requestVerificationCode();
      } else {
        setAppSnackbar({ isVisible: true, message: message || 'Oops! Something went wrong while updating phone number' });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, message: responseData?.message || 'Oops! Something went wrong while updating phone number' });
    },
  });

  return (
    <>
      <AuthPage smallIcon>
        {/* <Link to={Pathname.authentication.signIn} className={styles.signInLink}>{signUpText?.logIn || 'Log In'}</Link> */}
        <div aria-hidden="true" onClick={() => { navigate(-1); }} className={styles.backLink}>{commonText?.back || 'BACK'}</div>
        <form className={styles.alignForm} onSubmit={(e) => { e.preventDefault(); updateMobile(); }}>
          <div className={styles.inputLabel}>{mobileVerificationText?.enterYourPhoneNumber || 'Enter your phone number'}</div>
          <div className={styles.description}>
            {mobileVerificationText?.enterPhoneDescription || 'Enter your phone number to activate and verify your account'}
          </div>
          <br />
          <div className={styles.buttonBlock}>
            <PhoneInput
              placeholder={mobileVerificationText?.enterYourPhoneNumber || 'Enter your phone number'}
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry="US"
            />
          </div>
          <br />
          <div className={styles.buttonBlock}>
            <Button blockText isDisabled={!phoneNumber || !phoneNumber?.length || updatingMobile || requestingCode} isBlue isLarge submit isFullWidth>
              {mobileVerificationText?.continue || 'Continue'}
            </Button>
          </div>
        </form>
      </AuthPage>
    </>
  );
};
