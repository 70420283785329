/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';
import {
  DialogContent,
} from '@material-ui/core';
import cn from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as PlusButton } from 'Assets/plus-button.svg';
import AlterImage from 'Assets/alter-image.png';
import Spinner from 'Components/Spinner';
import { DialogFooter } from './Material-reDesign';
import styles from '../index.module.css';
import '../loader.css';
import { ContentBox, ContentBoxSection } from './ContentBox';
import InputField from './InputField';
import { Button } from '../../button';
import ImagePreviewDetails from './ImagePreviewDetails';

const AlbumDetails = ({ didItCalled, pubLoader, addingPhotos, publishAlbum, spinerSmall, loader, albumTitle, albumDescription, uploadingPhoto, setAlbumTitle, setAlbumDescription, defaultAlbumThumbnail,
  albumDetails, generateCustomThumbnail, setDefaultAlbumThumbnail, customAlbumThumbnails,
  albumThumbnails, addOrEditAlbum, albumSize, albumPhotoCount, uploadText, commonText, isUpdating }) => {
    const componentRef = useRef(null);
    // Function to handle retrieving the scroll position
    const handleScrollPosition = () => {
      if (componentRef.current) {
        const scrollYPosition = componentRef.current.scrollTop;
      }
    };
    // useEffect(() => {
    //   setTimeout(() => {
    //     if (componentRef.current) {
    //       componentRef.current.scrollTo({
    //         top: 350,
    //         behavior: 'smooth'
    //       });
    //     }
    //   }, 200);
    // }, []);
    return (
    <>
      <DialogContent ref={componentRef} onScroll={handleScrollPosition} className={styles.content}>
        <ContentBox flex>
          <div className={styles.contentBox}>
            <ContentBoxSection title={uploadText?.detail || 'Details'} className={styles.detailsBoxSection}>
              <InputField
                defaultValue={albumTitle || ''}
                label={uploadText?.title || 'Title'}
                isDisabled={uploadingPhoto}
                isRequired
                onChange={setAlbumTitle}
                placeholder={uploadText?.titlePhotoPlaceholder || 'Add a title to your photo album'}
                type="text"
              />
              <InputField
                defaultValue={albumDescription || ''}
                label="Description"
                isOptional="(optional)"
                isDisabled={uploadingPhoto}
                onChange={setAlbumDescription}
                placeholder={uploadText?.descriptionPhotoPlaceholder || 'Short lens view of your album, tell your viewers what your album is about'}
                type="textarea"
              />
            </ContentBoxSection>
            <ContentBoxSection
              title={uploadText?.thumbnail || 'Thumbnail'}
              description={uploadText?.thumbnailPhotoDescription || 'Select or upload a picture that shows what’s in your album. A great thumbnail stands out and draws viewers’ attention.'}
              className={styles.thumbnailBoxSection}
            >
              <div className={styles.videoThumbnailsContainer}>
                <input
                  accept="image/*"
                  id="photo-thumbnails"
                  multiple
                  onChange={(e) => { generateCustomThumbnail(e); }}
                  style={{ display: 'none' }}
                  type="file"
                />
                <label
                  className={cn({ [styles.videoThumbnail]: true, [styles.thumbnailUploadButton]: true })}
                  htmlFor="photo-thumbnails"
                >
                  <AddIcon className={styles.videoThumbnailAddIcon} />
                </label>
                {customAlbumThumbnails?.map((albumThumbnail, idx) => (
                  <div
                    aria-hidden="true"
                    className={styles.videoThumbnail}
                    key={idx}
                    onClick={() => {
                      setDefaultAlbumThumbnail(albumThumbnail);
                    }}
                    style={{
                      cursor: 'pointer',
                      border: `${defaultAlbumThumbnail === albumThumbnail ? '4px solid #5aa5ef' : ''}`
                    }}
                  >
                    <img
                      alt={`album thumbnail ${idx}`}
                      className={styles.videoThumbnailImage}
                      src={albumThumbnail.url || AlterImage}
                      onError={(e) => {
                        e.target.src = AlterImage;
                      }}
                    />
                  </div>
                ))}
                {albumThumbnails?.map((albumThumbnail, idx) => (
                  <div
                    aria-hidden="true"
                    className={styles.videoThumbnail}
                    key={idx}
                    onClick={() => { setDefaultAlbumThumbnail(albumThumbnail); }}
                    style={{
                      cursor: 'pointer',
                      border: `${defaultAlbumThumbnail === albumThumbnail ? '4px solid #5aa5ef' : ''}`
                    }}
                  >
                    <img
                      alt={`album thumbnail ${idx}`}
                      className={styles.videoThumbnailImage}
                      src={albumThumbnail.url || AlterImage}
                      onError={(e) => {
                        e.target.src = AlterImage;
                      }}
                    />
                  </div>
                ))}
              </div>
            </ContentBoxSection>
          </div>
          <ImagePreviewDetails
            defaultImageThumbnail={defaultAlbumThumbnail.url}
            count={albumPhotoCount}
            size={albumSize}
            uploadText={uploadText}
            isUpdating={isUpdating}
          />
        </ContentBox>
      </DialogContent>
      <DialogFooter showThumbnail="yes">
        {isUpdating
        && (
          <Button
          className={styles.navButton}
          isBlue
          isOutlined
          isDisabled={!albumTitle || addingPhotos || loader}
          onClick={() => {
            if (!loader) {
              addOrEditAlbum('publish');
              // if (didItCalled === 'yes') {
              //   publishAlbum();
              // }
            }
          }}
          >
          {pubLoader ? (
          <svg className="loading-circle" viewBox="0 0 50 50">
            <circle className="circle" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
          </svg>
        ) : (uploadText?.publish || 'Publish')}
          </Button>
        )}

        <Button
          // isDisabled={(!albumTitle || !albumDescription)}
          isDisabled={(!albumTitle)}
          className={styles.navButton}
          isBlue
          bgWhite
          isOutlined
          onClick={() => { loader === false && addOrEditAlbum(); }}
        >
          {commonText?.next || 'Next'}
          {loader === true && <Spinner spinex={spinerSmall} />}
        </Button>
      </DialogFooter>
    </>
);
                    };
export default AlbumDetails;
