/* eslint-disable max-len */
import React from 'react';
import SubscriberAgreement from 'Components/Doc/SubscriberAgreement/index';
import blackLogo from 'Assets/logo-black-bg.png';
import whiteLogo from 'Assets/logo-white-bg.png';
import logo from 'Assets/Login Logo.png';
import AlterImage from 'Assets/alter-image.png';
import styles from './index.module.css';

export function TAC() {
  return (
    <>
      <header className={styles.header}>
        <img
          className={styles.logo}
          src={logo || AlterImage}
          onError={(e) => {
            e.target.src = AlterImage;
          }}
        />
        <div className={styles.helpCenterButton}>Help Center</div>
      </header>
      <SubscriberAgreement />
    </>
  );
}

// export default TAC;
