/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import { AppContext, AuthContext } from 'Context';
import { SettingsPage } from '../private/settings/components/page';
import UserManger from './UserManger';

export const UserMangementSettings = () => {
  const { getToken } = useContext(AuthContext);
  const { langText, setLineReq, isTokenChange } = useContext(AppContext);
  const privacyText = langText?.settings?.privacy;
  return (
    <SettingsPage>
      <UserManger isTokenChange={isTokenChange} getToken={getToken} setLineReq={setLineReq} />
    </SettingsPage>
  );
};
