/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import cn from 'classnames';
import { Slider, withStyles, Tooltip } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import TransformIcon from '@material-ui/icons/Transform';
import AddContentModal from './AddContentModal';
import styles from './index.module.css';

const BannerSettings = ({ selectedContent, selectContent, bannerType, bannerChange, selectedBannerType, updateBannerType, categoryBoxes, selectedCategoryBox, categoryChange }) => {
  const { setAppSnackbar, langText } = useContext(AppContext);
  const layoutText = langText?.layout;
  const commonText = langText?.common;
  const [isVisibleContentModal, setContentModal] = useState(false);
  const hideContentModal = () => { setContentModal(false); };
  const removeSelectedItem = (id) => {
    if (selectedContent.some((content) => content.id === id)) {
      // remove item
      const arr = selectedContent.filter((item) => item.id !== id);
      selectContent(arr);
    }
  };
  return (
    // <div className={styles.slideShowContainer}>
    //   <div className={styles.chooseOrder}>
    //     {layoutText?.bannerAndCategoryHeading || 'Banner and Categories'}
    //   </div>
    //   <div className={styles.albumSettingsBundle}>
    //     <div
    //       className={cn({
    //         [styles.albumSettingsDiv]: true,
    //         [styles.bannerSettingForMedia]: selectedBannerType === '3' || selectedBannerType === '4' || selectedBannerType === '5'
    //       })}
    //     >
    //       <div>
    //         <span style={{ cursor: 'pointer' }}>
    //           <TransformIcon className={styles.addMorePhotoIcon} />
    //         </span>
    //         <span className={styles.addMorePhoto}>{layoutText?.bannerType || 'Banner Type'}</span>
    //       </div>
    //       <select name="transition-type" className={styles.slideShowTransitions} onChange={bannerChange} value={selectedBannerType}>
    //         {
    //           bannerType?.map((type) => (
    //             <option value={type.id}>
    //               {type.name}
    //             </option>
    //           ))
    //         }
    //       </select>
    //       {
    //         selectedBannerType === '3' || selectedBannerType === '4' || selectedBannerType === '5' ? (
    //           <div
    //             onClick={() => { setContentModal(!isVisibleContentModal); }}
    //             className={styles.addContent}
    //           >
    //             Add content
    //           </div>
    //         ) : null
    //       }
    //     </div>
    //     <div className={styles.selectedItemWrapper}>
    //       {
    //         selectedContent?.map(({ id, name }) => (
    //           <div className={styles.selectedIemContainer}>
    //             <div className={styles.selectedItemTitle}>
    //               {name}
    //             </div>
    //             <CloseIcon className={styles.closeButton} onClick={() => { removeSelectedItem(id); }} />
    //           </div>
    //         ))
    //       }
    //     </div>
    //     {/* <div
    //       className={cn({
    //         [styles.updateButtonContainer]: true,
    //         [styles.bannerUpdateButtonContainer]: selectedContent?.length > 0
    //       })}
    //     >
    //       <div
    //         onClick={() => { updateBannerType(); }}
    //         className={cn({
    //           [styles.bannerUpdateButton]: true,
    //           [styles.saveButtonForSettingDisabled]: (selectedBannerType === '3' || selectedBannerType === '4' || selectedBannerType === '5') && selectedContent?.length === 0
    //         })}
    //       >
    //         {commonText?.update || 'Update'}
    //       </div>
    //     </div> */}
    //     <div
    //       className={cn({
    //         [styles.albumSettingsDiv]: true,
    //       })}
    //     >
    //       <div>
    //         <span style={{ cursor: 'pointer' }}>
    //           <TransformIcon className={styles.addMorePhotoIcon} />
    //         </span>
    //         <span className={styles.addMorePhoto}>{layoutText?.categoryBoxes || 'Category Boxes'}</span>
    //       </div>
    //       <select name="transition-type" className={styles.slideShowTransitions} onChange={categoryChange} value={selectedCategoryBox}>
    //         <>
    //           <option disabled value="">Select category type</option>
    //           {
    //             categoryBoxes?.map((type) => (
    //               <option value={type.id}>
    //                 {type.name}
    //               </option>
    //             ))
    //           }
    //         </>
    //       </select>
    //     </div>
    //   </div>
    //   <AddContentModal isVisible={isVisibleContentModal} updateBannerType={updateBannerType} onHide={hideContentModal} title={`Choose up to 5 ${selectedBannerType === '3' ? 'images' : selectedBannerType === '4' ? 'videos' : 'albums'} from your content`} selectedBannerType={selectedBannerType} selectedContent={selectedContent} selectContent={selectContent} />
    // </div>
    null
  );
};

export default BannerSettings;
