import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Logo } from 'Components';
import { Pathname } from 'Routes';
import styles from './index.module.css';

export const Header = () => (
  <header className={styles.header}>
    <div className={styles.headerContent}>
      <Link className={styles.homeLink} to={Pathname.home}>
        <Logo isLight isLarge />
      </Link>
      <div className={styles.headerLinksContainer}>
        <Button
          className={styles.headerLink}
          isDarkBlue
          link={Pathname.authentication.newSignUp}
        >
          Sign up now
        </Button>
        <Button className={styles.headerLink} isWhite isOutlined link={Pathname.authentication.newsignIn}>log in</Button>
      </div>
    </div>
  </header>
);
