import React, { useState, useContext } from 'react';
import cn from 'classnames';
import { AppContext, AuthContext } from 'Context';
import pt from 'prop-types';
import { ReactComponent as VisibleIcon } from 'Assets/eye.svg';
import { ReactComponent as HiddenIcon } from 'Assets/eye.slash.svg';
import styles from './index.module.css';

const PasswordInput = ({ className, isDisabled, ...props }) => {
  const [isPswdVisible, setPswdVisibility] = useState(false);
  const handlePasswordInput = (event) => {
    const inputPassword = event.target.value;
    if (inputPassword.length > 30) {
      // alert('Password must be 30 characters or less.');
    }
  };
  return (
    <>
      <input
        autoFocus
        className={cn({ [styles.inputField]: true, [className]: className })}
        disabled={isDisabled}
        id="password-input"
        type={isPswdVisible ? 'text' : 'password'}
        {...props}
      />
      <label className={styles.passwordVisibilityToggle} htmlFor="password-input">
        {isPswdVisible
          ? (
            <HiddenIcon
              className={styles.passwordVisibilityToggleIcon}
              onClick={() => { setPswdVisibility(!isPswdVisible); }}
            />
          )
          : (
            <VisibleIcon
              className={styles.passwordVisibilityToggleIcon}
              onClick={() => { setPswdVisibility(!isPswdVisible); }}
            />
          )}
      </label>
    </>
  );
};

const PasswordInputSignUp = ({ className, isDisabled, ...props }) => {
  const [isPswdVisible, setPswdVisibility] = useState(false);

  return (
    <>
      <input
        autoFocus
        className={cn({ [styles.inputField]: true, [className]: className })}
        disabled={isDisabled}
        id="password-input"
        type={isPswdVisible ? 'text' : 'password'}
        {...props}
      />
      <label className={styles.passwordVisibilityToggle} htmlFor="password-input">
        {isPswdVisible
          ? (
            <HiddenIcon
              className={styles.signupPasswordVisibilityToggleIcon}
              onClick={() => { setPswdVisibility(!isPswdVisible); }}
            />
          )
          : (
            <VisibleIcon
              className={styles.signupPasswordVisibilityToggleIcon}
              onClick={() => { setPswdVisibility(!isPswdVisible); }}
            />
          )}
      </label>
    </>
  );
};

const EmailInput = ({ className, isDisabled, isLoading, ...props }) => (
  <>
    <input
      autoFocus
      className={cn({ [styles.inputField]: true, [className]: className })}
      disabled={isDisabled}
      type="email"
      {...props}
    />
    <div className={cn({ [styles.spinnerContainer]: true, [styles.loading]: isLoading })}>
      <div style={{ borderColor: '#ffffff44' }} className={styles.spinner} />
      <div style={{ borderColor: '#5aa5ef' }} className={cn({ [styles.spinner]: true, [styles.animatedSpinner]: true })} />
    </div>
  </>
);

const CodeInput = ({ maxLength, className, isDisabled, isLoading, onlyNumber, ...props }) => (
  <input className={cn({ [styles.inputField]: true, [className]: className })} maxLength={maxLength} disabled={isDisabled} type={onlyNumber ? 'number' : 'text'} {...props} />
);

export const Input = ({ maxLength, onlyNumber, containerClassName, invalidErr, isLoading, label, labelClassName, isInvalid, message, type, noLabel, ...props }) => (
  <>
    {!noLabel ? (
      <label className={cn({ [styles.inputLabel]: true, [labelClassName]: labelClassName })}>{label}</label>
    ) : null}
    <div
      className={cn({
        [styles.inputFieldContainer]: true,
        [styles.loading]: isLoading,
        [styles.passwordInput]: type === 'password',
        [containerClassName]: containerClassName,
      })}
    >
      {type === 'email'
        ? <EmailInput isLoading={isLoading} {...props} />
        : type === 'code'
          ? <CodeInput maxLength={maxLength} onlyNumber={onlyNumber} {...props} />
          : type === 'SignUpPass'
            ? <PasswordInputSignUp {...props} />
            : <PasswordInput {...props} />}
    </div>
<div className={styles.errrMessage}>
    {message && <span className={styles.inputMessage}>{message}</span>}
    {isInvalid && <span style={{ color: 'red' }} className={styles.inputMessage}>{'Incorrect password' || invalidErr?.message}</span>}
</div>
  </>
);

Input.defaultProps = {
  className: undefined,
  isLoading: false,
  message: undefined,
  placeholder: undefined,
};

Input.propTypes = {
  className: pt.string,
  isLoading: pt.bool,
  label: pt.string.isRequired,
  message: pt.string,
  placeholder: pt.string,
  type: pt.string.isRequired,
};
