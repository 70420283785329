import React from 'react';

const ThreeDotLoader = ({ colour }) => (
  <div className="loader">
    <div style={{ backgroundColor: colour && colour }} className="dot" />
    <div style={{ backgroundColor: colour && colour }} className="dot" />
    <div style={{ backgroundColor: colour && colour }} className="dot" />
  </div>
);

export default ThreeDotLoader;
