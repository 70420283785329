import React, { useState, useContext, useEffect } from 'react';
import './UserM.css';
import { baseUrl } from 'Api';
import axios from 'axios';
import { AppContext, AuthContext } from 'Context';
import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InviteUser from './InviteUser';
import UserList from './UserList';

function RegisterPage({ getToken, fetchData }) {
  const { setAppSnackbar, apiResponse } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [userData, setUserData] = useState([]);
  const [checkAcceptanceStatus, setCheckAcceptanceStatus] = useState(null);
  const startFetchingData = () => {
    fetchData();
    const interval = setInterval(fetchData, 30000);
    setCheckAcceptanceStatus(interval);
  };

  const stopFetchingData = () => {
    clearInterval(checkAcceptanceStatus);
    setCheckAcceptanceStatus(null);
  };
  const [loader, setLoader] = useState(false);
  const registermE = async () => {
    setLoader(true);
    try {
      const myToken = await getToken();
      const apiUrl = `${baseUrl}/requestToManagerRole`;
      const requestBody = {
        token: myToken,
      };
      const response = await axios.post(apiUrl, requestBody);
      setdata(response.data);
      if (response?.data?.success === false) {
        setLoader(false);
      } else {
        setLoader(true);
      }

      setAppSnackbar({ isVisible: true, message: response?.data?.message || 'Oops! Something went wrong while updating phone number' });
    } catch (error) {
      setAppSnackbar({ isVisible: true, message: error.response?.data?.message || 'Oops! Something went wrong while updating phone number' });
      setLoader(true);
    }
  };
  useEffect(() => {
    if (loader) {
      startFetchingData();
    } else {
      stopFetchingData();
    }
  }, [loader]);
  const userFetcher = async () => {
    try {
      const myToken = await getToken();
      const apiUrl = `${baseUrl}/inviteUsersList`;
      const requestBody = {
        token: myToken,
      };
      const response = await axios.post(apiUrl, requestBody);
      setUserData(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="relativez">
      {isOpen
      && <InviteUser fetchData={userFetcher} getToken={getToken} setIsOpen={setIsOpen} /> }
      <div className="flexTree">
        <div className="gridssz relativez">
          <div>
            <p className="boldtext">User Permissions</p>
          </div>
          <div className="flexStarter">
           <div>
           <p className="nonBoldxtext" style={{ userSelect: 'none' }}>Register as an Affiliate</p>
            <p className="nonColurxtext">Create and manage users</p>
           </div>
        {/* <button className="buttonrEG" style={{ opacity: apiResponse?.data?.manager_status !== undefined && '0.5' }} onClick={() => apiResponse?.data?.manager_status === undefined && registermE()}>
          {apiResponse?.data?.manager_status === undefined ? 'Register Today' : apiResponse?.data?.manager_status}
          { loader === true
          && (
            <svg className="loading-circle" viewBox="0 0 50 50">
              <circle className="circle" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
            </svg>
          )}
        </button> */}
        <Tooltip title={apiResponse?.data?.manager_status !== undefined ? 'Your affiliate request is being reviewed by the system admin. Please contact helpdesk https://support.projector.app/hc/en-us' : ''}>
          <button
            className="buttonrEG"
            style={{ opacity: apiResponse?.data?.manager_status !== undefined ? '0.5' : '1' }}
            onClick={() => apiResponse?.data?.manager_status === undefined && registermE()}
          >
            {apiResponse?.data?.manager_status === undefined ? 'Register Today' : apiResponse?.data?.manager_status}
            {loader === true && (
              <svg className="loading-circle" viewBox="0 0 50 50">
                <circle className="circle" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
              </svg>
            )}
          </button>
        </Tooltip>
          </div>
          <div className="mt-[37px]">
            <p className="nonBoldxtext">Connected Accounts:</p>
            <p className="nonColurxtext">
              Give permission to access your account
            </p>
            <button onClick={() => setIsOpen(true)} className="inviteer">Invite User+</button>
          </div>
          {/* <p className="invite absolute">Create a new user +</p> */}
          <div>
            <p className="nonBoldxtext">Access to:</p>
            <p className="nonColurxtext">Accounts that you have access to</p>
            <p style={{ marginTop: '36px' }} className="nonColurxtext">You have no access yet</p>
          </div>
        </div>
      </div>
      {/* <div className="flexico">
        <button onClick={() => setIsOpen(true)} className="inviteer">Invite User+</button>
        <p className="nonColurxtext">You have no access yet</p>
      </div> */}
      <div className="marginTopic flexfifty">
            <UserList fetchData={userFetcher} data={userData} getToken={getToken} />
      </div>
    </div>
  );
}

export default RegisterPage;
