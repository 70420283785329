import React from 'react';
import cn from 'classnames';
import { ReactComponent as AddIcon } from 'Assets/add-circle.svg';
import styles from '../index.module.css';

const AddBox = ({ alignRight, className, description, onAdd, title }) => (
  <div className={cn({ [className]: className, [styles.addBox]: true, [styles.alignRight]: alignRight })}>
    <div className={styles.addBoxTitle}>
      <div className={styles.addBoxTitleText}>{title}</div>
      <AddIcon className={styles.addBoxTitleIcon} onClick={onAdd} />
    </div>
    <div className={styles.addBoxDescription}>{description}</div>
  </div>
);
export default AddBox;
