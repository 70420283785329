// src/StripeProvider.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51OyTOEKaFrbWdXakQInnJTuk5FH8axcBeA25fwF2NjVLtugqr2UZ6R25nTsRFrHtled3FIuV6kgT0XkrQYJ7k32200B2iBulzH');

const StripeProvider = ({ children }) => (
  <Elements stripe={stripePromise}>
    {children}
  </Elements>
);

export default StripeProvider;
