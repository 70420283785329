import React, { useEffect, useState, useContext } from 'react';
import { CircularProgress as MuiCircularProgress, withStyles, makeStyles, Grid, IconButton } from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import { AppContext } from 'Context';
import styles from './album-player/index.module.css';

function Referral() {
    const CircularProgress = withStyles({
        circle: {
          color: '#fff !important'
        }
      })(MuiCircularProgress);
    const { removeToken } = useContext(AppContext);
    const { code } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
      localStorage.setItem('code', code);
    //   removeToken();
    }, [code]);
    navigate('/newSign-in');
  return (
    <div>
 <CircularProgress className={styles.circularProgress} />
    </div>
  );
}

export default Referral;
