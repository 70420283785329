import { Checkbox, Collapse, Fade } from '@material-ui/core';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import cn from 'classnames';
import { encryptedKeyForCredentials, GOOGLE_RECAPTCHA_KEY } from 'Commons';
import { Button } from 'Components';
import { AppContext, AuthContext } from 'Context';
import { validate as isValidEmail } from 'email-validator';
import { debounce } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import { Pathname } from 'Routes';
import useSWR from 'swr';
import authStyles from '../auth.module.css';
import { Input } from '../components/input-field';
import { AuthPage } from '../components/page';
import styles from './index.module.css';

const transitionTimeout = 300;

export const SignIn = () => {
  const navigate = useNavigate();
  const { setAppSnackbar, userDetails, setUserDetails, plansPage, setPlansPage, langText, setReferralStatus } = useContext(AppContext);
  const { forceAutoSignin, setToken, preferredEmail, setPreferredEmail, preferredUserDetails, setPreferredUserDetails, setEmailVerified } = useContext(AuthContext);
  const signInText = langText?.signInUp;
  const [email, setEmail] = useState(isValidEmail(preferredEmail) ? preferredEmail : '');
  const [password, setPassword] = useState('');
  // fixEmail to true to continue to password page
  const [emailIsFixed, fixEmail] = useState(false);
  const [emailIsValid, setEmailValidity] = useState(false);
  const [accountExists, setAccountExistence] = useState(true);
  const [checkingAccountExistence, setAccountExistenceCheckingStatus] = useState(false);
  const [cancelToken, setCancelToken] = useState(undefined);
  const [termsAreAgreed, setTermsAgreement] = useState(false);
  const [selectedReCAPTCHA, selectReCAPTCHA] = useState(false);
  const [uploadModalIsVisible, setUploadModalVisibility] = useState(false);

  const { data: loginResponse, isValidating: signingInUser, mutate: signInUser } = useSWR([endpoints.signin, email, password], {
    fetcher: (url, inputEmail, inputPassword) => dataFetcher(url, { email: inputEmail, password: inputPassword }),
    onSuccess: ({ isNewUser, success, data, has_logged_in, referral_status, is_skip, message, verify_mobile, mobile, promo_code }) => {
      if (success) {
        setUserDetails({ ...userDetails, isNewUser });
        setToken(data?.token);
        setEmailVerified(data?.is_email_verified);
        setAppSnackbar({ message: 'Logged in successfully', isVisible: true });
        setTimeout(() => {
          if (plansPage || (parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1 && !data?.billing_date)) {
            setPlansPage(false);
            parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1 ? setUserDetails(1) : setUserDetails(0);
            navigate(Pathname.plans);
          } else if (data?.other_promo_codes !== undefined && data?.other_promo_codes !== null) {
            const loginPromoCodes = data?.other_promo_codes?.filter((promocode) => promocode.promo_type === '1');
            if (loginPromoCodes !== undefined && loginPromoCodes !== null && loginPromoCodes?.length > 0) {
              const loginPromo = loginPromoCodes[0];
              navigate(`${Pathname.access}${data?.encVal ? `?email=${data?.encVal}` : ''}${loginPromo?.promo_code ? `&promo_code=${loginPromo.promo_code}` : ''}${loginPromo?.promo_value ? `&promo_value=${loginPromo.promo_value}` : ''}`, { state: { promocode: loginPromo, mobile_token: data?.encVal ? data?.encVal : '' } });
            } else {
              navigate(`${Pathname.access}${data?.encVal ? `?email=${data?.encVal}` : ''}`);
            }
          } else {
            navigate(`${Pathname.access}${data?.encVal ? `?email=${data?.encVal}` : ''}`);
          }
        }, 500);
        forceAutoSignin();
      } else if (!verify_mobile) {
        if (mobile) {
          navigate(Pathname.authentication.verifyCode);
        } else {
          navigate(Pathname.authentication.mobileVerification);
        }
      } else {
        setAppSnackbar({ message: message || 'Incorrect password', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: gettingMFAStatus, mutate: getMFAStatus } = useSWR([endpoints.getMFAStatus, email, password], {
    fetcher: (url, inputEmail) => dataFetcher(url, { email: inputEmail }),
    onSuccess: ({ verify_mobile, success, phone_no, mfa, message }) => {
      if (success) {
        if (verify_mobile && mfa) {
          sessionStorage.setItem(encryptedKeyForCredentials.mobile, phone_no);
          navigate(Pathname.authentication.verifyCode);
        } else {
          signInUser();
        }
      } else {
        setAppSnackbar({ type: 'error', message: message || 'Incorrect password', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ type: 'error', message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { mutate: checkAccountExistence } = useSWR([endpoints.signinEmail, email, cancelToken?.token], {
    fetcher: (url, inputEmail, uniqueCancelToken) => dataFetcher(url, { email: inputEmail }, uniqueCancelToken),
    onSuccess: ({ success }) => {
      setAccountExistenceCheckingStatus(false);

      if (success) {
        setAccountExistence(true);

        if (isValidEmail(preferredEmail)) { fixEmail(true); }
      } else {
        setAccountExistence(false);
      }

      setPreferredEmail(undefined);
    },
    onError: () => {
      setAccountExistenceCheckingStatus(false);
    },
  });

  const emailInputHandler = debounce(({ target: { value: inputEmail } }) => {
    if (isValidEmail(inputEmail)) {
      setEmail(inputEmail);
      setEmailValidity(true);
    } else {
      setEmail('');
      setAccountExistence(undefined);
      setEmailValidity(false);
    }
  }, 750, { trailing: true });

  const inputHandlerForUserDetails = (key, value) => {
    const userDetailsCopy = { ...preferredUserDetails };
    userDetailsCopy[key] = value;
    setPreferredUserDetails(userDetailsCopy);
  };

  useEffect(() => {
    if (email) {
      setAccountExistenceCheckingStatus(true);
      checkAccountExistence();
    }
  }, [email, setAccountExistenceCheckingStatus, checkAccountExistence]);

  useEffect(() => {
    if (preferredEmail && isValidEmail(preferredEmail)) {
      setAccountExistenceCheckingStatus(true);
      setEmailValidity(true);
      checkAccountExistence();
    }
  }, [preferredEmail, checkAccountExistence]);

  const reCaptchaHandler = (value) => {
    if (value) {
      selectReCAPTCHA(true);
    } else {
      selectReCAPTCHA(false);
    }
  };

  const saveEmailAndPasswordToSession = () => {
    window.fbq('trackCustom', 'ShareDiscount', { promotion: 'share_discount_10%' });
    sessionStorage.setItem(encryptedKeyForCredentials.password, password);
    sessionStorage.setItem(encryptedKeyForCredentials.email, email);
  };
  const revealUploadModal = () => { setUploadModalVisibility(true); };

  return (
    <AuthPage>
      <div aria-hidden="true" onClick={() => { navigate(-1); }} className={styles.backLink}>{signInText?.back || 'BACK'}</div>
      {!emailIsFixed
        ? (
          <form onSubmit={(e) => { e.preventDefault(); }}>
            {/* <StepStatus currentStep="1" totalSteps="2" langText={signInText} /> */}
            <Input
              defaultValue={email}
              isDisabled={(email === preferredEmail) && checkingAccountExistence}
              isLoading={checkingAccountExistence}
              label={signInText?.signInTitle || 'Login with your email'}
              onInput={(e) => {
                e.persist();
                emailInputHandler(e);
                setAccountExistenceCheckingStatus(false);

                if (cancelToken) { cancelToken.cancel(); }

                if (!e?.target?.value) {
                  setEmail('');
                  setAccountExistence(undefined);
                  setEmailValidity(false);
                } else {
                  setCancelToken(AxiosCancelToken.source());
                }
              }}
              placeholder={signInText?.email || 'Email'}
              type="email"
            />
            {
              !accountExists ? (
                <div className={styles.nameFields}>
                  <Input
                    onInput={(e) => {
                      e.persist();
                      inputHandlerForUserDetails('firstName', e?.target?.value);
                    }}
                    placeholder={signInText?.firstName || 'First Name'}
                    type="code"
                    noLabel
                  />
                  <Input
                    onInput={(e) => {
                      e.persist();
                      inputHandlerForUserDetails('lastName', e?.target?.value);
                    }}
                    placeholder={signInText?.lastName || 'Last Name'}
                    type="code"
                    noLabel
                  />
                </div>
              ) : null
            }
            <input
              className={authStyles.hiddenFormField}
              name="password"
              onChange={() => { }}
              type="password"
              value={password}
            />
            <br />
            <Collapse in={!accountExists} timeout={transitionTimeout}>
              <Fade in={!accountExists} timeout={transitionTimeout}>
                <div className={styles.newsletter}>
                  <Checkbox
                    className={styles.newsletterCheckbox}
                    color="primary"
                    id="newsletter-sub"
                    onChange={({ target: { checked: termsAgreed } }) => { setTermsAgreement(termsAgreed); }}
                  />
                  <label className={styles.newsletterText} htmlFor="newsletter-sub">
                    {signInText?.signUpCheckBox || 'Yes! I would like to receive updates, special offers and other information from Projector App.'}
                  </label>
                </div>
              </Fade>
            </Collapse>
            {
              !accountExists ? (
                <>
                  <Collapse in={!accountExists} timeout={transitionTimeout}>
                    <Fade in={!accountExists} timeout={transitionTimeout}>
                      <div className={styles.reCaptchaContainer}>
                        <ReCAPTCHA
                          sitekey={GOOGLE_RECAPTCHA_KEY}
                          onChange={reCaptchaHandler}
                          theme="dark"
                        />
                      </div>
                    </Fade>
                  </Collapse>
                  <div className={styles.termsContainer}>
                    <div className={styles.terms}>
                      <Collapse in={!accountExists} timeout={transitionTimeout}>
                        <Fade in={!accountExists} timeout={transitionTimeout}>
                          <span>
                            {
                              signInText?.signUpContent ? (
                                <span className={styles.linkClass} dangerouslySetInnerHTML={{ __html: signInText?.signUpContent }} />
                              ) : (
                                <span>
                                  Projector will use your data to personalize and improve your Projector experience and to send you information about Projector. You can change your communication preferences anytime. We may use your data as described in our
                                  <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/terms-and-conditions"> Privacy Policy </a>
                                  , including sharing it with the family of companies.  By clicking “Agree &amp; Continue”, you agree to our &nbsp;
                                  <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/terms-and-conditions">Terms of Use </a>
                                  ,&nbsp;
                                  <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/subscriber-agreement">Subscriber Agreement </a>
                                  and acknowledge that you have read our Privacy Policy.
                                </span>
                              )
                            }
                          </span>
                        </Fade>
                      </Collapse>
                    </div>
                    <Button
                      blockText
                      isBlue
                      isDisabled={checkingAccountExistence
                        || !(termsAreAgreed && emailIsValid && (accountExists !== undefined) && (accountExists === false) && selectedReCAPTCHA)}
                      isFullWidth
                      isLarge
                      // submit
                      onClick={() => { setPreferredEmail(email); navigate(Pathname.authentication.signUp); }}
                    >
                      {signInText?.agreeAndContinue || 'Agree and Continue'}
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  blockText
                  isBlue
                  isDisabled={checkingAccountExistence
                    || !(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
                  isFullWidth
                  submit
                  isLarge
                  onClick={() => { fixEmail(true); }}
                >
                  {signInText?.continue || 'Continue'}
                </Button>
              )
            }
            <Collapse
              in={!(emailIsValid && (accountExists !== undefined) && (accountExists === false))}
              timeout={transitionTimeout}
            >
              <Fade
                in={!(emailIsValid && (accountExists !== undefined) && (accountExists === false))}
                timeout={transitionTimeout}
              >
                <div className={authStyles.suggestionText}>
                  <>
                    {signInText?.newToProjector || 'New to Projector?'}
                    &nbsp;
                  </>
                  <Link className={authStyles.suggestionLink} to={Pathname.authentication.newSignUp}>{signInText?.signUp || 'Sign Up'}</Link>
                </div>
              </Fade>
            </Collapse>
          </form>
        )
        : (
          <form onSubmit={(e) => { e.preventDefault(); saveEmailAndPasswordToSession(); getMFAStatus(); }}>
            <input className={authStyles.hiddenFormField} name="email" onChange={() => { }} type="email" value={email} />
            <Input
              label={signInText?.enterYourPassword || 'Enter your password'}
              message={signInText?.caseSensitive ? `( ${signInText?.caseSensitive} )` : '( Case Sensitive )'}
              minLength={6}
              onInput={({ target: { value: inputPassword } }) => { setPassword(inputPassword); }}
              placeholder={signInText?.password || 'Password'}
              type="password"
            />
            <br />
            <div>
              <Button id="signin-btn" blockText isBlue isDisabled={signingInUser} isFullWidth isLarge submit>
                {signingInUser ? ('Signing In...' || `${signInText?.signingIn}...`) : (signInText?.continue || 'Continue')}
              </Button>
            </div>
            <div className={authStyles.suggestionText}>
              <Link
                className={cn({ [authStyles.suggestionLink]: true, [styles.forgotPasswordLink]: true })}
                to={Pathname.authentication.forgotPassword}
              >
                {signInText?.forgotPassword?.title || 'Forgot Password?'}
              </Link>
            </div>
          </form>
        )}
    </AuthPage>
  );
};
