/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from 'Context';
import SubscriberAgreement from 'Components/Doc/SubscriberAgreement/index';
import { SettingsPage } from '../components/page';

export const SubscriberAgreementSettings = () => {
  const { langText } = useContext(AppContext);
  const subscriberText = langText?.settings?.subscriber;
  return (
    <SettingsPage
      title={subscriberText?.title || 'Subscriber Agreement'}
      // description={subscriberText?.description || 'Subscriber Agreement settings'}
    >
      <SubscriberAgreement />
    </SettingsPage>
  );
};
