import React, { useState } from 'react';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import { endpoints, dataFetcher } from 'Api';

import {
  IconButton,
  TableBody,
  TableRow,
} from '@material-ui/core';
import { Pathname } from 'Routes';

import { DeleteOutline, Cancel, Edit } from '@material-ui/icons';

import styles from '../index.module.css';

import { TableBodyCell } from './CustomDesigns';

export default function GroupRow({ title, members_count, id, setSnackbar, onChange }) {
  const navigate = useNavigate();

  const { isValidating: deletingMyGroups, mutate: deleteMyGroups } = useSWR([endpoints.deleteMyGroups, id], {
    fetcher: (url, userId) => dataFetcher(url, { id: userId }),
    onSuccess: ({ success }) => {
      if (success) {
        setSnackbar({
          isVisible: true,
          message: `${title} removed`
        });
        onChange();
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });
  const [removeStatus, setRemoveStatus] = useState(false);

  const editMyGroup = () => {
    navigate(Pathname.updatingGroup(id, title));
  };
  return (
    <>

      <TableRow
        onMouseEnter={() => setRemoveStatus(true)}
        onMouseLeave={() => setRemoveStatus(false)}
        key={id}
      >
        <TableBodyCell
          className={styles.tableBodyCell}
        >
          {title}

        </TableBodyCell>
        <TableBodyCell className={styles.tableBodyCell} align="right">
          {removeStatus ? (
            <>
              <IconButton onClick={editMyGroup}>
                <Edit Outline color="primary" />
              </IconButton>
              <IconButton onClick={deleteMyGroups}>
                <DeleteOutline color="error" />
              </IconButton>
            </>
          )
            : members_count}
        </TableBodyCell>
      </TableRow>

    </>
  );
}
