import React from 'react';

function FixedProgressBar() {
  return (
    <div className="fixedProgressBar">
    <div className="moving-progress-bar-container">
        <div className="moving-progress-bar">
            <div className="progress" />
        </div>
    </div>
    </div>
  );
}

export default FixedProgressBar;
