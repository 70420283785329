import { Grid, Menu } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import useSWR from 'swr';
import { AppContext } from 'Context';

import { dataFetcher, endpoints } from 'Api';
import styles from './index.module.css';

export default function CardDetails({ paymentMethods, brand, last4, status, isPreferred, cardId, removedAllowed }) {
  const { setAppSnackbar, subscriptionDetails, getSubscription, langText } = useContext(AppContext);
  const paymentMethodText = langText?.settings?.billPayment?.paymentMethod;
  const commonText = langText?.common;
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { isValidating: removingCard, mutate: removeCard } = useSWR([endpoints.removePaymentMethods, cardId], {
    fetcher: (url, cardId) => dataFetcher(url, { payment_method_id: cardId }),
    onSuccess: ({ success }) => {
      if (success) {
        getSubscription();
        setMobileMoreAnchorEl(null);
        setAppSnackbar({
          isVisible: true, type: 'success', message: 'Removed Successfully' });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while removing',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while removing' });
    },
  });
  const { isValidating: makingDefault, mutate: makeDefault } = useSWR([endpoints.setDefaultPaymentMethods, cardId], {
    fetcher: (url, cardId) => dataFetcher(url, { payment_method_id: cardId }),
    onSuccess: ({ success }) => {
      if (success) {
        getSubscription();
        setAppSnackbar({
          isVisible: true, type: 'success', message: 'Set Default payment method Successfully' });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while setting default payment method',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true,
        type: 'error',
        message: 'Oops! Something went wrong while setting default payment method' });
    },
  });

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event, id) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleRemoveCard = () => {
    if (removedAllowed) {
      removeCard();
    } else {
      setAppSnackbar({ isVisible: true,
        type: 'error',
        message: 'To remove this card you have to add one more payment method' });
    }
  };
  const handlePreferredCard = () => {
    makeDefault();
  };
  const startDate = new Date(subscriptionDetails?.subscription?.start_date);
  const endDate = new Date(subscriptionDetails?.subscription?.end_date);
  const trialDuration = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)); // Calculate duration in days
  // Ensure totalTrialDays is correctly converted to a number
  const totalTrialDays = parseInt(subscriptionDetails?.subscription?.total_trial_days, 10);
  const shouldShowRemoveButton = !(paymentMethods.length === 1 && trialDuration === totalTrialDays);
  return (
    <div className={styles.cardDetails}>

      <div className={`${styles.labelLinks} ${styles.secondLabelLink} `}>
        <div className={`${styles.label} ${styles.secondLabel} `}>
          {brand}
          {last4
               && (
                <div>
               <span className={`${styles.colorBlack} `}>
                 {' '}
                 ***** ****
                 {' '}
                 {last4}
               </span>
               {status?.payment_status === '0' && (
                <div className={styles.redColorExpired}>
                {`(${status?.failure_reason || 'Expired'})`}
                </div>
                )}
                </div>
               )}
        </div>
        {isPreferred && <div className={styles.preferred}>{paymentMethodText?.preferred || 'PREFERRED'}</div>}
        <Grid
          onClick={(event) => handleMobileMenuOpen(event)}
          className={`${styles.link} ${styles.secondLink} `}
        >
          {commonText?.edit || 'Edit'}

        </Grid>
        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          // keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
          PopoverClasses={{ paper: styles.dropDownAnimation }}
        >
          <div
            className={`${styles.editButtonsMenu}`}
          >
            <button
              // style={{ display: `${isPreferred ? 'none' : undefined}` }}
              disabled={isPreferred}
              onClick={handlePreferredCard}
              className={`${styles.editButton}`}
            >
              {!isPreferred && makingDefault ? (paymentMethodText?.markingPreffered || 'Making preferred') : (paymentMethodText?.markPreffered || 'Mark Preferred')}
            </button>
            {subscriptionDetails?.subscription?.is_trial !== '1' && (
            <button
            onClick={handleRemoveCard}
        // onClick={() => deleteThis(groupType, currentId)}
            className={`${styles.editButton}`}
            >
            {removingCard ? (paymentMethodText?.removingCard || 'Removing Card') : (paymentMethodText?.removeCard || 'Remove Card')}
            </button>
            )}
          </div>

        </Menu>
      </div>

    </div>
  );
}
