/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Zoom,
  DialogContentText,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import useSWR from 'swr';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import { dataFetcher, endpoints, axios } from '../../../../../../Api';
import { Button } from '../../../../../../Components/button';
import styles from './index.module.css';

const useStyles = makeStyles({
  leftAlignDialogActions: {
    justifyContent: 'center'
  }
});
export const ConfirmationModal = ({
  cancelButton,
  continueButton,
  isVisible,
  onHide,
  title,
  content,
}) => {
  const classes = useStyles();
  const { setAppSnackbar, getSubscription } = useContext(AppContext);
  const navigate = useNavigate();

  const { isValidating: planSkipping, mutate: skipReward } = useSWR([endpoints.skipPlan], {
    fetcher: (url) => dataFetcher(url, { is_skip: 1 }),
    onSuccess: ({ success, message }) => {
      if (success) {
        setAppSnackbar({ isVisible: true, message });
        getSubscription();
        navigate(Pathname.dashboard);
      } else {
        setAppSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
      onHide();
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  return (
    <>
      <Dialog
        open={isVisible}
        TransitionComponent={Zoom}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <div className={styles.dialogContainer}>
          <DialogTitle>
            <div className={styles.title}>
              {title}
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {
                content ? (
                  <div className={styles.content}>
                    {content}
                  </div>
                ) : null
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions
            classes={{ root: classes.leftAlignDialogActions }}
          >
            <div className={styles.actionContainer}>
              <Button size="small" className={styles.cancelButton} color="primary" onClick={onHide}>
                {cancelButton}
              </Button>
              <div
                className={styles.continueButton}
                onClick={skipReward}
              >
                {continueButton}
              </div>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};
