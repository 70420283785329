/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import cn from 'classnames';
import styles from '../index.module.css';

const Button = ({ link, btnClass, text, onClick, isDisabled }) => (
  <div
    onClick={onClick}
    className={cn({ [`${btnClass} ${styles.btn}`]: true, [styles.disabled]: isDisabled })}
  >
    {text}
  </div>
);

export default Button;
