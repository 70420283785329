import React, { useState, useContext, useEffect, useRef } from 'react';
import './UserM.css';
import { baseUrl } from 'Api';
import { Chip, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Close';
import Req from '../../Assets/req.png';
import styles from '../../Components/share-screen/index.module.css';
import PersonButton from '../../Components/share-screen/components/PersonButton';

function InviteUser({ getToken, setIsOpen, fetchData }) {
  const getRandomColor = () => {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgb(${red}, ${green}, ${blue})`;
  };

  // State to store the random background color
  const [backgroundColor, setBackgroundColor] = useState(getRandomColor());

  // Function to change the background color
  const changeBackgroundColor = () => {
    const randomColor = getRandomColor();
    setBackgroundColor(randomColor);
  };

  // Use useEffect to set up the interval and clean it up on component unmount
  // useEffect(() => {
  //   const intervalId = setInterval(changeBackgroundColor, 2000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);
    const myToken = getToken();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedID, setSelectedID] = useState('');
    const [emailBox, setEmailBox] = useState([]);
    const handleInputChange = async (event) => {
        const inputValue = event.target.value;
        setSearchQuery(inputValue);
        try {
          const apiUrl = `${baseUrl}/searchManager`;
          const requestBody = {
            token: myToken,
            searchItem: inputValue
          };
          const response = await axios.post(apiUrl, requestBody);
          setSearchResults(response.data);
        } catch (error) {
          console.error('Error:', error);
        }
      };
      // const handleResultClick = (result) => {
      //   const { email, id } = result;
      //   setSearchQuery('');
      //   setSelectedID((prevSelectedIDs) => [...prevSelectedIDs, id]);
      //   setEmailBox((prevSelectedEmails) => [...prevSelectedEmails, email]);
      // };
      const handleResultClick = (result) => {
        const { email, id } = result;
        setSearchQuery('');
        setSearchResults('');
        const isDuplicate = emailBox.includes(email);
        if (!isDuplicate) {
          setSelectedID((prevSelectedIDs) => [...prevSelectedIDs, id]);
          setEmailBox((prevSelectedEmails) => [...prevSelectedEmails, email]);
        }
      };
      const handleAdd = async () => {
        try {
          const apiUrl = `${baseUrl}/inviteManager`;
          const requestBody = {
            token: myToken,
            manager_id: selectedID
          };
          const response = await axios.post(apiUrl, requestBody);
          fetchData();
          setIsOpen(false);
        } catch (error) {
          console.error('Error:', error);
        }
      };
      const removeUserFromList = (user) => {
        const updatedEmailBox = emailBox.filter((email) => email !== user);
        setEmailBox(updatedEmailBox);
      };
      const modalRef = useRef(null);

      const handleClose = () => {
        setIsOpen(false);
      };

      const handleModalClick = (e) => {
        e.stopPropagation();
      };
  return (
  <div
  className="fullFledge"
  role="button"
  tabIndex={0}
  onClick={handleClose}
  onKeyDown={() => {}}
  >
  <div
  className="modal"
  ref={modalRef}
  role="button"
  tabIndex={0}
  onClick={handleModalClick}
  onKeyDown={() => {}}
  >
      <div className="modelFelx">
        <img className="req" src={Req} alt="" />
        <p className="userName noMARGIN">Invite New User</p>
      </div>
      <div className="chipToInputBox">
  {emailBox?.map((obj, index) => (
    <Chip
      key={index}
      variant="outlined"
      label={obj}
      onDelete={() => removeUserFromList(obj)}
      style={{
        fontFamily: 'Poppins, sans-serif',
        fontSize: '15px',
        fontWeight: 500,
        // Add any other CSS properties you want to customize here
      }}
    />
  ))}
  <input
    type="mail"
    className="searcher"
    value={searchQuery}
    onChange={handleInputChange}
    placeholder="Search email"
  />
      </div>
      {searchResults?.data?.length > 0 && (
      searchResults?.data?.map((result, index) => (
        <div
        className="SearchResulter"
        onClick={() => handleResultClick(result)}
        onKeyPress={undefined}
        role="button"
        tabIndex={0}
        >
        <button className="theList" key={index}>
          <div style={{ backgroundColor }} className="rounderChar">
          {result?.email?.charAt(0).toUpperCase()}
          </div>
          <p className="eachName">
          {result.email}
          </p>
        </button>
        </div>
      ))
      )}
     <div style={{ width: '100%', display: 'flex' }}>
     {emailBox.length > 0
     ? <button className="adME" onClick={handleAdd}>Add</button>
     : <button className="adME" style={{ opacity: '0.5' }}>Add</button>}
     </div>
  </div>
  </div>
  );
}

export default InviteUser;
