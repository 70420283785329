import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import styles from '../index.module.css';

const EditMenu = ({ formField, id, name, onChange, onClose, setActionStatus, value, type }) => {
  const { setAppSnackbar, langText } = useContext(AppContext);
  const commonText = langText?.common;
  const [currentValue, setCurrentValue] = useState(value);
  const [inputValue, setInputValue] = useState(value);
  const [updateData, setUpdateData] = useState(undefined);

  const { isValidating: updating, mutate: updateContent } = useSWR([
    type ? endpoints.addPhotoContent : endpoints.addVideoContent, updateData, name,
  ], {
    fetcher: (url, formdata) => dataFetcher(url, formdata),
    onSuccess: ({ success }) => {
      setActionStatus(false);

      if (success) {
        setCurrentValue(inputValue);
        setAppSnackbar({ isVisible: true, message: `${name} was updated successfully` });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setActionStatus(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const update = () => {
    const formData = new FormData();

    formData.append(`${type ? 'album_id' : 'video_id'}`, id);
    formData.append(formField, inputValue);

    setActionStatus(true);
    setUpdateData(formData);
  };

  useEffect(() => { if (updateData) { updateContent(); } }, [updateData]);

  useEffect(() => {
    if (currentValue !== value) {
      onChange(id, [[formField, currentValue]]);
    }
  }, [currentValue]);

  return (
    <div className={styles.editMenu}>
      <div className={styles.editMenuTitle}>{name}</div>
      <textarea
        className={styles.editMenuInput}
        defaultValue={value}
        onChange={(e) => { setInputValue(e?.target?.value); }}
        placeholder={value}
      />
      <br />
      <button
        className={styles.editMenuUpdateButton}
        disabled={updating || inputValue === currentValue}
        onClick={update}
      >
        {updating ? `${commonText?.updating || 'Updating'} ...` : (commonText?.update || 'Update')}
      </button>
      <input id="close-button-input" onClick={onClose} style={{ display: 'none' }} />
    </div>
  );
};
export default EditMenu;
