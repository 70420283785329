import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pathname } from 'Routes';
import useSWR from 'swr';
import cn from 'classnames';
import { blue } from '@material-ui/core/colors';
import { Snackbar } from 'Components';
import { AppContext } from 'Context';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { ReactComponent as AddPersonIcon } from 'Assets/add-person.svg';
import AlterImage from 'Assets/alter-image.png';

import {
  NameEditModal,
  EmailEditModal,
  PhoneNumberEditModal,
  PasswordEditModal
} from 'Components/edits';

import { SettingsPage } from '../components/page';
import { DataView } from '../components/data-view';
import { Input } from '../../../public/authentication/components/input-field';
import styles from './index.module.css';

const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };

const Switch = ({ isDisabled, isOn, onChange }) => (
  <div
    className={cn({ [styles.switch]: true })}
    onClick={() => {
      if (!isDisabled) {
        onChange(!isOn);
      }
    }}
    onKeyPress={() => { }}
    role="button"
    style={{
      backgroundColor: isOn ? blue[200] : 'rgba(0, 0, 0, 0.4)',
      opacity: isDisabled ? '0.7' : '1',
    }}
    tabIndex={0}
  >
    <div
      className={cn({ [styles.button]: true, [styles.isOn]: isOn })}
      style={{ backgroundColor: isOn ? blue[500] : '#ffffff' }}
    />
  </div>
);

const NSSwitch = () => {
  const { setAppSnackbar, setUserDetails, userDetails } = useContext(AppContext);
  const [isOn, setOn] = useState(parseInt(userDetails?.mfa, 10));
  const [setting, updateSettingStatus] = useState(false);

  const { mutate: updateNS } = useSWR([
    endpoints.updateMyProfile, isOn ? 1 : 0], {
    fetcher: (url, mfa) => dataFetcher(url, {
      mfa
    }),
    onSuccess: ({ success }) => {
      if (success) {
        setUserDetails({ ...userDetails, mfa: isOn ? '1' : '0' });
        setAppSnackbar({ isVisible: true, message: 'MFA settings updated' });
      } else {
        setOn(!isOn);
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
      updateSettingStatus(false);
    },
    onError: () => {
      setOn(!isOn);
      updateSettingStatus(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  useEffect(() => {
    if (parseInt(userDetails.mfa, 10) !== (isOn ? 1 : 0)) {
      updateSettingStatus(true);
      updateNS();
    }
  }, [isOn]);

  return (
    <Switch
      isDisabled={setting}
      isOn={isOn}
      onChange={(on) => { setOn(on); }}
    />
  );
};

export const AccountSettings = () => {
  const { userDetails, setUserDetails, langText, autoSignIn } = useContext(AppContext);

  const [avatar, setAvatar] = useState(undefined);
  const [avatarUploadError, setAvatarUploadErrorStatus] = useState(false);
  const [nameEditModalIsVisible, setNameEditModalVisibility] = useState(false);
  const [emailEditModalIsVisible, setEmailEditModalVisibility] = useState(false);
  const [phoneNumberEditModalIsVisible, setPhoneNumberEditModalVisibility] = useState(false);
  const [passwordEditModalIsVisible, setPasswordEditModalVisibility] = useState(false);

  const [snackbar, setSnackbar] = useState(defaultSnackbar);
  const navigate = useNavigate();

  const revealNameEditModal = () => { setNameEditModalVisibility(true); };
  const hideNameEditModal = () => { setNameEditModalVisibility(false); };
  const revealEmailEditModal = () => { setEmailEditModalVisibility(true); };
  const hideEmailEditModal = () => { setEmailEditModalVisibility(false); };
  const revealPhoneNumberEditModal = () => { navigate(Pathname.authentication.mobileVerification); };
  const hidePhoneNumberEditModal = () => { setPhoneNumberEditModalVisibility(false); };
  const revealPasswordEditModal = () => { setPasswordEditModalVisibility(true); };
  const hidePasswordEditModal = () => { setPasswordEditModalVisibility(false); };
  const accountText = langText?.settings?.account;
  const commonText = langText?.common;

  const AccountData = ({ changeElement, data, onChange }) => (
    <div className={styles.accountDataContainer}>
      <div className={styles.accountData}>{data}</div>
      {changeElement || (
        <div
          className={styles.accountDataAction}
          onClick={onChange}
          onKeyPress={undefined}
          role="button"
          tabIndex={0}
        >
          {commonText?.change || 'Change'}
        </div>
      )}
    </div>
  );
  const { isValidating: updatingAvatar, mutate: updateAvatar } = useSWR([endpoints.updateProfileImge, avatar], {
    fetcher: (url, inputAvatar) => dataFetcher(url, { image: inputAvatar }),
    onSuccess: ({ success, image }) => {
      if (success) {
        setAvatarUploadErrorStatus(false);
        setSnackbar({ isVisible: true, type: 'success', message: 'Avatar was updated successfully' });
        setUserDetails({ ...userDetails, dpURL: image });
        autoSignIn();
      } else {
        setAvatarUploadErrorStatus(true);
        setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating your avatar' });
      }
    },
    onError: () => {
      setAvatarUploadErrorStatus(true);
      setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating your avatar' });
    },
  });

  useEffect(() => { if (avatar) { updateAvatar(); } }, [avatar, updateAvatar]);

  return (
    <>
      <SettingsPage
        title="Account details"
        // title={accountText?.title || 'Account'}
        description={accountText?.description || 'Choose how your friends see you on Projector'}
      >
        <DataView
          data={[
            {
              labelTitle: accountText?.profile || 'Profile',
              data: (
                <>
                  {/* <AccountData
                    data={( */}
                  <form className={styles.profileImageUploadForm}>
                    <input
                      accept="image/*"
                      disabled={updatingAvatar}
                      className={styles.hiddenFormField}
                      id="account-profile-pic"
                      onChange={({ target: { files } }) => {
                        const reader = new FileReader();
                        const tempAvatar = files[0];

                        if (tempAvatar) { reader.readAsDataURL(tempAvatar); }

                        reader.addEventListener('load', () => { setAvatar(reader.result); }, false);
                      }}
                      type="file"
                    />
                    {userDetails?.dpURL ? (
                      <img
                        alt="profile"
                        className={styles.profilePicture}
                        src={userDetails?.dpURL || AlterImage}
                        onError={(e) => {
                          e.target.src = AlterImage;
                        }}
                      />
                    ) : (
                      <div className={styles.profilePicture}>
                        <AddPersonIcon className={styles.profilePictureIcon} />
                      </div>
                    )}
                    {
                      avatarUploadError
                        ? <div className={styles.avatarEditButton}>Retry Upload</div>
                        : (
                          <label
                            className={styles.avatarEditButton}
                            htmlFor="account-profile-pic"
                          >
                            {updatingAvatar ? (accountText?.uploadingProfilePicture || 'Uploading Profile picture') : (accountText?.editProfilePicture || 'Edit Profile Picture ')}
                          </label>
                        )
                    }
                  </form>
                  {/* )}
                    changeElement={avatarUploadError
                      ? <div className={styles.avatarEditButton}>Retry Upload</div>
                      : (
                          <label
                            className={styles.avatarEditButton}
                            htmlFor="account-profile-pic"
                          >
                            {updatingAvatar ? (accountText?.uploadingProfilePicture || 'Uploading Profile picture') : (accountText?.editProfilePicture || 'Edit Profile Picture ')}
                          </label>
                      )}
                    onChange={revealNameEditModal}
                  /> */}
                  <br />
                  <AccountData data={`${userDetails?.firstname} ${userDetails?.lastname}`} onChange={revealNameEditModal} />
                  <br />
                </>
              ),
            },
            {
              labelTitle: accountText?.email ? accountText?.email : 'Email',
              data: (
                <AccountData data={userDetails?.email} onChange={revealEmailEditModal} />
              ),
            },
            {
              labelTitle: accountText?.phonenumber || 'Phone number',
              data: (
                <AccountData
                  data={userDetails?.mobile || (
                    <> -&nbsp;&nbsp;&nbsp;-&nbsp;-&nbsp;-&nbsp;&nbsp;&nbsp;-&nbsp;-&nbsp;-&nbsp;&nbsp;&nbsp;-&nbsp;-&nbsp;-</>
                  )}
                  onChange={revealPhoneNumberEditModal}
                />
              ),
            },
            {
              labelTitle: accountText?.password || 'Password',
              data: (
                <AccountData data="***************" onChange={revealPasswordEditModal} />
              ),
            },
            // {
            //   labelTitle: accountText?.twoFactorAuthentification || '2 Factor-Authentication',
            //   labelDescription: accountText?.twoFactorAuthentificationDesc || 'Secure your account by enabling the 2FA. You will need to enter the Code sent to your registered Phone',
            //   data: <NSSwitch />,
            //   isFlex: true,
            //   isToggle: true,
            // },
          ]}
        />
      </SettingsPage>

      <NameEditModal isVisible={nameEditModalIsVisible} onHide={hideNameEditModal} title={accountText?.updateName || 'Update Name'} firstNameLabel={accountText?.enterFirstName || 'Enter first name'} lastNameLabel={accountText?.enterLastName || 'Enter last name'} />
      <EmailEditModal isVisible={emailEditModalIsVisible} onHide={hideEmailEditModal} title={accountText?.updateEmail ? accountText?.updateEmail : 'Update Email'} resetCodeLabel={accountText?.enterResetCode ? accountText?.enterResetCode : 'Enter reset code'} newEmailLabel={accountText?.enterNewEmail ? accountText?.enterNewEmail : 'Enter New Email'} />
      <PhoneNumberEditModal isVisible={phoneNumberEditModalIsVisible} onHide={hidePhoneNumberEditModal} title={accountText?.updatepPhoneNumber ? accountText?.updatepPhoneNumber : 'Update Phone number'} phoneNumerLabel={accountText?.enterPhoneNumber ? accountText?.enterPhoneNumber : 'Enter Phone number'} />
      <PasswordEditModal isVisible={passwordEditModalIsVisible} onHide={hidePasswordEditModal} title={accountText?.updatePassword ? accountText?.updatePassword : 'Update Password'} currentPasswordLabel={accountText?.enterCurrentPassword || 'Enter Current Password'} newPasswordLabel={accountText?.enterNewPassword || 'Enter new password'} passwordRule={accountText?.passwordDescription || 'Use a minimum of 6 characters (case sensitive) with at lease one number and one special character.'} />

      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
    </>
  );
};
