import React from 'react';
import cn from 'classnames';
import { Page } from '../page';
import { Header } from '../header';
import styles from './index.module.css';

export const VideoPreview = ({
  children,
  className,
  noHeader,
  contentClassName,
  containerClassName,
  isProfileHeader,
  mainClassName,
  noRegularHeader,
  userName,
  userProfileLink,
}) => ((
  <Page
    className={cn({ [styles.main]: true, [className]: className })}
    containerClassName={cn({ [styles.page]: true, [containerClassName]: containerClassName })}
    contentClassName={cn({ [styles.pageContent]: true, [contentClassName]: contentClassName })}
    isProfileHeader={isProfileHeader}
    noBottomBar
    noFixedSidebar
    noHeader={noHeader}
    noRegularHeader={noRegularHeader}
    transparentCompactHeader
    userName={userName}
    userProfileLink={userProfileLink}
  >
    <div className={cn({ [styles.mainContent]: false, [mainClassName]: mainClassName })}>
      {children}
    </div>
  </Page>
));
