import React from 'react';
import { render } from 'react-dom';
import { SWRConfig } from 'swr';
import { dataFetcher } from 'Api';
import AOS from 'aos';
// import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from 'store/store';
import { App } from './app';
// import './index.css';
import 'aos/dist/aos.css';

AOS.init();
render(
  <Provider store={store}>
    <SWRConfig
      value={{
        fetcher: dataFetcher,
        revalidateOnFocus: false,
        revalidateOnMount: false,
        shouldRetryOnError: false,
      }}
    >
      <App />
    </SWRConfig>
  </Provider>,
  document.getElementById('root')
  );
// reportWebVitals();
