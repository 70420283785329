import React from 'react';
import { Badge, Drawer as MuiDrawer, IconButton, Menu as MuiMenu, MenuItem, withStyles } from '@material-ui/core';

export const Drawer = withStyles({ paper: { backgroundColor: '#181a24' } })(MuiDrawer);
export const Menu = withStyles({
  paper: {
    border: '1px solid #000',
    boxShadow: 'none',
    borderRadius: 0,
    maxHeight: 'calc(100vh - 44px)',
    width: 'unset',
  }
})(MuiMenu);
