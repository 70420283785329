import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import useSWR from 'swr';
import { dataFetcher, endpoints, axios } from 'Api';
import {
  Collapse,
} from '@material-ui/core';
import { Button, Modal, Snackbar, ShareScreen } from 'Components';
import cn from 'classnames';
import styles from '../index.module.css';

const UserRequest = ({ id, firstname, setSnackbar, email, lastname }) => {
  const [action, setAction] = useState(undefined);
  const [isVisible, setVisibility] = useState(true);

  const { isValidating: handlingAction, mutate: handleAction } = useSWR([endpoints.updateViewRequest, id, action], {
    fetcher: (url, user, status) => dataFetcher(url, { user, status }),
    onSuccess: ({ success }) => {
      if (success) {
        setVisibility(false);
        setSnackbar({
          isVisible: true,
          message: `${firstname ? `${firstname}'s r` : 'R'}equest ${action === 1 ? 'accepted' : 'declined'}`,
        });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  useEffect(() => { if (action) { handleAction(); } }, [handleAction, action]);

  return (
    <Collapse in={isVisible} timeout={250}>
      <div className={styles.userBoxRequest}>
        <div className={styles.userBoxRequestDescription}>
          <div>
            <span className={styles.userBoxRequestUserName}>
              Name :
            </span>
            {` ${firstname !== null && firstname !== '' ? firstname : 'Not available'} ${lastname}`}
          </div>
          <div>
            <span className={styles.userBoxRequestUserName}>
              Email &nbsp;:
            </span>
            {` ${email}`}
          </div>
          <> Wants to view your content</>
        </div>
        <hr className={styles.userBoxDivider} />
        <div className={styles.userBoxRequestActions}>
          <Button
            isDisabled={handlingAction}
            className={cn({ [styles.userBoxRequestActionButton]: true, [styles.userBoxRequestAcceptButton]: true })}
            onClick={() => { setAction(1); }}
            isOutlined
            isBlue
          >
            {(handlingAction && (action === 1)) ? 'Accepting...' : 'Accept'}
          </Button>
          <Button
            isDisabled={handlingAction}
            className={styles.userBoxRequestActionButton}
            onClick={() => { setAction(2); }}
            isOutlined
            isBlack
          >
            {(handlingAction && (action === 2)) ? 'Declining...' : 'Decline'}
          </Button>
        </div>
      </div>
    </Collapse>
  );
};

UserRequest.defaultProps = {
  firstname: undefined,
};

UserRequest.propTypes = {
  id: pt.string.isRequired,
  firstname: pt.string,
  setSnackbar: pt.func.isRequired,
};
export default UserRequest;
