import React from 'react';
import PPLLC from 'Components/Doc/ppLLC/index';
import { Page } from './landing/components/page';

export function Privacy() {
  return (
    <Page>
      <PPLLC isDark />
    </Page>
  );
}
