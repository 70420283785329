import cn from 'classnames';
import styles from './index.module.css';

export const ProjectorShimmer = ({ isLight }) => ((
  <div className={cn({ [styles.shimmerWrapper]: true, [styles.lightShimmerWrap]: isLight })}>
    {
      isLight ? (
        <>
          <div className={styles.shimmerLight} />
          <div className={styles.shimmerLight} />
          <div className={styles.shimmerLight} />
        </>
      ) : (
        <>
          <div className={styles.shimmerBox} />
          <div className={styles.shimmerBox} />
          <div className={styles.shimmerBox} />
          <div className={styles.shimmerBox} />
          <div className={styles.shimmerBox} />
          <div className={styles.shimmerBox} />
        </>
      )
    }
  </div>
));
