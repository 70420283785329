import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { Modal, Snackbar, ProjectorShimmer } from 'Components';
import { AppContext } from 'Context';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { ReactComponent as AddPersonIcon } from 'Assets/add-person.svg';
import { Pathname } from 'Routes';

import {
  NameEditModal,
  EmailEditModal,
  PhoneNumberEditModal,
  PasswordEditModal
} from 'Components/edits';

import { ViewerPage } from '../components/page';
import { DataView } from '../components/data-view';
import { Input } from '../../../public/authentication/components/input-field';
import UserRequest from './UserRequest';
import styles from './index.module.css';

const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };

export const Viewers = () => {
    // eslint-disable-next-line no-script-url
    window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const [snackbar, setSnackbar] = useState(defaultSnackbar);
  const { langText, isViewerListUpdated, setViewerListUpdate } = useContext(AppContext);
  const location = useLocation();
  const [accessRequests, setAccessRequests] = useState([]);
  const [hasAccess, setHasAccess] = useState([]);
  const [viewers, setViewers] = useState([]);
  const [pendingViewersList, setPendingViewersList] = useState([]);
  const [searchKey, setSearchKey] = useState(undefined);
  const [openGrantAccessModal, setGrantAccessModal] = useState(false);
  const viewerText = langText?.viewersSideBar?.viewers;
  const commonText = langText?.common;
  const { isValidating: fetchingViewers, mutate: fetchViewerList } = useSWR([
    endpoints.getViewersList, searchKey], {
    fetcher: (url, search, status) => dataFetcher(url, { search, status }),
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setViewers(data);
      } else {
        setViewers([]);
      }
    },
    onError: () => {
      setViewers([]);
    },
  });

  const { isValidating: fetchingPendingList, mutate: fetchPendingList } = useSWR([
    endpoints.getPendingViewersList], {
    fetcher: (url, search, status) => dataFetcher(url, { search, status }),
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setPendingViewersList(data);
      } else {
        setPendingViewersList([]);
      }
    },
    onError: () => {
      setPendingViewersList([]);
    },
  });

  useEffect(() => {
    fetchViewerList();
  }, [searchKey]);

  useEffect(() => {
    fetchPendingList();
  }, []);

  useEffect(() => {
    if (openGrantAccessModal && !location.search?.includes(Pathname.grantAccess)) {
      fetchPendingList();
    }
  }, [openGrantAccessModal, location.search]);

  useEffect(() => {
    if (location.search?.includes(Pathname.grantAccess)) {
      setGrantAccessModal(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (isViewerListUpdated) {
      fetchViewerList();
      fetchPendingList();
      setViewerListUpdate(false);
    }
  }, [isViewerListUpdated]);
  return (
    <>
      <ViewerPage
        title={'Active Viewers' || viewerText?.title}
        addActionName={viewerText?.addViewer || 'Add viewer'}
        link={Pathname.grantAccess}
      >
            <div className={styles.viewersDiv}>
              <div className={styles.allViewersContainer}>
                <input
                  id="SearchInput"
                  className={styles.viewerSearchinput}
                  onChange={(e) => {
                    e.persist();
                    if (!e?.target?.value) {
                      setSearchKey(undefined);
                    } else {
                      setSearchKey(e?.target?.value);
                    }
                  }}
                  placeholder={viewerText?.searchViewer || 'Search Viewers'}
                  type="text"
                />
                <div className={styles.viewerList}>
                  {viewers?.map(({ id, firstname, lastname, email, image }, idx) => (
                    <UserRequest
                      email={email}
                      id={id}
                      key={idx}
                      firstname={firstname}
                      lastname={lastname}
                      image={image}
                      setSnackbar={setSnackbar}
                      hasAccess
                      fetchAccessRequests={fetchViewerList}
                      setAccessRequests={setViewers}
                      setHasAccess={setViewers}
                    />
                  ))}
                </div>
              </div>
              <div className={styles.pendingViewersContainer}>
                <div className={styles.pendingTitle}>{viewerText?.pendingViewers || 'Pending Viewers'}</div>
                <div className={styles.viewerList}>
                  {pendingViewersList?.map(({ id, firstname, lastname, email, type, image }, idx) => (
                    <UserRequest
                      email={email}
                      id={id}
                      key={idx}
                      firstname={firstname}
                      lastname={lastname}
                      image={image}
                      setSnackbar={setSnackbar}
                      fetchAccessRequests={fetchPendingList}
                      setAccessRequests={setPendingViewersList}
                      setHasAccess={setViewers}
                      type={type}
                      fetchViewerList={fetchViewerList}
                    />
                  ))}
                </div>
              </div>
            </div>
        {/* {
          fetchingViewers || fetchingPendingList ? (
            <ProjectorShimmer isLight />
          ) : (
            <div className={styles.viewersDiv}>
              <div className={styles.allViewersContainer}>
                <input
                  id="SearchInput"
                  className={styles.viewerSearchinput}
                  onChange={(e) => {
                    e.persist();
                    if (!e?.target?.value) {
                      setSearchKey(undefined);
                    } else {
                      setSearchKey(e?.target?.value);
                    }
                  }}
                  placeholder={viewerText?.searchViewer || 'Search Viewers'}
                  type="text"
                />
                <div className={styles.viewerList}>
                  {viewers?.map(({ id, firstname, lastname, email, image }, idx) => (
                    <UserRequest
                      email={email}
                      id={id}
                      key={idx}
                      firstname={firstname}
                      lastname={lastname}
                      image={image}
                      setSnackbar={setSnackbar}
                      hasAccess
                      fetchAccessRequests={fetchViewerList}
                      setAccessRequests={setViewers}
                      setHasAccess={setViewers}
                    />
                  ))}
                </div>
              </div>
              <div className={styles.pendingViewersContainer}>
                <div className={styles.pendingTitle}>{viewerText?.pendingViewers || 'Pending Viewers'}</div>
                <div className={styles.viewerList}>
                  {pendingViewersList?.map(({ id, firstname, lastname, email, type, image }, idx) => (
                    <UserRequest
                      email={email}
                      id={id}
                      key={idx}
                      firstname={firstname}
                      lastname={lastname}
                      image={image}
                      setSnackbar={setSnackbar}
                      fetchAccessRequests={fetchPendingList}
                      setAccessRequests={setPendingViewersList}
                      setHasAccess={setViewers}
                      type={type}
                      fetchViewerList={fetchViewerList}
                    />
                  ))}
                </div>
              </div>
            </div>
          )
        } */}
      </ViewerPage>

      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
    </>
  );
};
