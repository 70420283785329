/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import detectBrowserLanguage from 'detect-browser-language';
import { AppContext } from 'Context';
import { Page, Text, ProjectorShimmer } from 'Components';
import { dataFetcher, endpoints } from 'Api';
import { Pathname } from 'Routes';
import { ReactComponent as AddIcon } from 'Assets/add.svg';
import { ReactComponent as DragIcon } from 'Assets/drag.svg';
import { ReactComponent as EditIcon } from 'Assets/edit.svg';
import { ReactComponent as DropdownIcon } from 'Assets/chevron-down.svg';
import { ReactComponent as CreateCategory } from 'Assets/create-category.svg';
import styles from './index.module.css';
import { transitionType } from '../../../Commons';
import CategoryCreateButton from './CategoryCreateButton';
import CreateMenuDropdown from './CreateMenuDropdown';
import ContentGroupButton from './ContentGroupButton';
import SlideShowSettings from './SlideShowSettings';
import CreateCategoryPage from './CreateCategory';
import CreatePlaylistPage from './CreatePlaylist';
import BannerSettings from './BannerSettings';
import PreviewSettings from './PreviewSettings';

const CreateButton = ({
  bottomLeftComponent: BLC,
  buttonLabel,
  isCompact,
  onCreate,
  title,
}) => {
  const { setAppSnackbar, langText } = useContext(AppContext);
  const [val, setVal] = useState(undefined);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [creating, setCreatingStatus] = useState(false);
  const commonText = langText?.common;

  const { mutate: addNewPlaylist } = useSWR([endpoints.addEditPlaylist, val, onCreate], {
    fetcher: (url, playlistName) => dataFetcher(url, { title: playlistName }),
    onSuccess: ({ success }) => {
      if (success) {
        if (onCreate) {
          onCreate();
        }
        setCreatingStatus(false);
        setVal(undefined);
        setMenuOpen(false);
        setAppSnackbar({
          isVisible: true,
          message: `Playlist '${val}' was created`,
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your playlist' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your playlist' });
    },
  });

  return (
    <div className={styles.createButtonWrapper}>
      <button
        className={cn({ [styles.createButton]: true, [styles.compact]: isCompact })}
        onClick={() => { if (!isMenuOpen) { setMenuOpen(true); } }}
      >
        <span>{buttonLabel}</span>
        {!isCompact && <AddIcon className={styles.addIcon} />}
      </button>
      {isMenuOpen && (
        <div className={styles.createMenuContainer}>
          <div
            className={styles.createButtonScrim}
            onClick={() => { if (!creating) { setVal(undefined); setMenuOpen(false); } }}
            onKeyPress={undefined}
            role="button"
            tabIndex={0}
          />
          <div className={styles.createMenu}>
            <div className={styles.createMenuTitle}>{title}</div>
            <input
              className={styles.createMenuInput}
              defaultValue={val}
              onInput={(e) => { setVal(e.target.value); }}
            />
            <div className={styles.createMenuButtons}>
              {BLC && (
                <div className={styles.createMenuButtonsLeft}>
                  <BLC />
                </div>
              )}
              <div className={styles.createMenuButtonsRight}>
                <button
                  className={styles.createMenuCancelButton}
                  disabled={creating}
                  onClick={() => { setVal(undefined); setMenuOpen(false); }}
                >
                  {commonText?.cancel || 'Cancel'}
                </button>
                <button
                  className={styles.createMenuCreateButton}
                  disabled={creating || (!val?.trim())}
                  onClick={() => {
                    setCreatingStatus(true);
                    addNewPlaylist();
                  }}
                >
                  {creating ? `${(commonText?.creating || 'Creating')}...` : (commonText?.create || 'Create')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const newOrderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const ContentLayout = () => {
    // eslint-disable-next-line no-script-url
    window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const { setAppSnackbar, langText, setTopOrBottom } = useContext(AppContext);
  useEffect(() => {
    setTopOrBottom(true);
    return () => {
      setTopOrBottom(false); // Set topOrBottom to false when leaving the component
    };
  }, []);
  const layoutText = langText?.layout;
  const commonText = langText?.common;
  const [categoriesData, setCategoriesData] = useState([]);
  const [playlistsData, setPlaylistsData] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const browserLang = detectBrowserLanguage();
  const [transitionSpeed, setTransitionSpeed] = useState(7);
  const [settingsType, setSettingsType] = useState(undefined);
  const [updatingSettings, setUpdatingSettings] = useState(false);
  const [selectedTransitionType, selectTransitionType] = useState(transitionType[0].class);
  const [selectedBannerType, selectBannerType] = useState(undefined);
  const [savedSettings, setSavedSettings] = useState({
    type: null,
    speed: null
  });
  const [bannerTypes, setBannerTypes] = useState(undefined);
  const [categoryBoxes, setCategoryBoxes] = useState(undefined);
  const [selectedCategoryBox, selectCategoryBox] = useState(undefined);
  const [selectedContent, selectContent] = useState([]);
  const [savedBannerType, setSavedBannerType] = useState(undefined);
  const [savedContent, setSavedContent] = useState([]);
  const [updateType, setUpdateType] = useState(undefined);
  const [previewType, setPreviewType] = useState(undefined);
  const [selectedPreviewSettings, selectPreviewSettings] = useState(undefined);
  const EditButton = ({ onClick }) => (
    <button className={styles.editButton} onClick={onClick}>
      <div>{commonText?.edit || 'Edit'}</div>
      <div className={styles.editButtonDropdown}>▾</div>
    </button>
  );
  const { isValidating: gettingCategories, mutate: getCategories } = useSWR(endpoints.getMyCategory, {
    onSuccess: ({ success, data }) => {
      if (success) {
        setCategoriesData(data?.sort(({ order_number: order_number_1 }, { order_number: order_number_2 }) => {
          if (order_number_1 > order_number_2) { return 1; }

          if (order_number_2 > order_number_1) { return -1; }

          return 0;
        }));
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your categories',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching your categories' });
    },
  });

  const { isValidating: gettingPlaylists, mutate: getPlaylists } = useSWR(endpoints.getMyPlaylist, {
    onSuccess: ({ success, data }) => {
      if (success) {
        setPlaylistsData(data);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your categories',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching your categories' });
    },
  });

  const { isValidating: gettingLayoutTypes, mutate: getLayoutTypes } = useSWR(endpoints.getLayoutTypes, {
    onSuccess: ({ success, data, categoryStyles, bannerPreviewStyles }) => {
      if (success) {
        setBannerTypes(data);
        setCategoryBoxes(categoryStyles);
        setPreviewType(bannerPreviewStyles);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching baner types',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching banner types' });
    },
  });

  const { isValidating: gettingUserLayouts, mutate: getUserLayouts } = useSWR(endpoints.getUserLayouts, {
    onSuccess: ({ success, layout_type_id, layouts, category_style_id, banner_preview_style_id }) => {
      if (success) {
        setSavedBannerType(layout_type_id);
        selectCategoryBox(category_style_id);
        if (layout_type_id && (layout_type_id === '3' || layout_type_id === '4' || layout_type_id === '5')) {
          setSavedContent(layouts);
        }
        selectBannerType(layout_type_id);
        if (banner_preview_style_id) {
          selectPreviewSettings(parseInt(banner_preview_style_id, 10));
        } else {
          selectPreviewSettings(1);
        }
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching baner types',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching banner types' });
    },
  });
  const { isValidating: gettingContentType, mutate: getContentType } = useSWR([endpoints.getContentType, browserLang], {
    fetcher: (url, lang) => dataFetcher(url, { lang }),
    onSuccess: ({ success, data }) => {
      if (success) {
        setContentTypes([...data, { 'id': '#$%', 'content_type': 'Subcategories', 'order_number': '4' }]);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const { isValidating: savingSettings, mutate: saveSettings } = useSWR([endpoints.settings, settingsType, settingsType === 1 ? transitionSpeed : selectedTransitionType], {
    fetcher: (url, id, value) => dataFetcher(url, { id, value }),
    onSuccess: ({ success }) => {
      setSettingsType(undefined);
      if (success) {
        // getAlbum();
        setAppSnackbar({
          isVisible: true,
          message: 'Album settings updated',
        });

        setSavedSettings({
          type: selectedTransitionType,
          speed: transitionSpeed
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating settings',
        });
      }
      setUpdatingSettings(false);
    },
    onError: () => {
      setUpdatingSettings(true);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating settings' });
    },
  });

  const { isValidating: updatingAllAlbumSettings, mutate: updateAllAlbumSettings } = useSWR([endpoints.updateAllAlbumSettings, transitionSpeed, selectedTransitionType], {
    fetcher: (url, value1, value2) => dataFetcher(url, { value1, value2, settingId1: 1, settingId2: 2 }),
    onSuccess: ({ success }) => {
      setSettingsType(undefined);
      if (success) {
        // getAlbum();
        setAppSnackbar({
          isVisible: true,
          message: 'Album settings updated',
        });

        setSavedSettings({
          type: selectedTransitionType,
          speed: transitionSpeed
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating settings',
        });
      }
      setUpdatingSettings(false);
    },
    onError: () => {
      setUpdatingSettings(true);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating settings' });
    },
  });

  const { isValidating: updatingBannerType, mutate: updateBannerType } = useSWR([endpoints.updateUserLayoutType, selectedBannerType, selectedContent?.map((content) => content.id)], {
    fetcher: (url, layout_id, ids) => dataFetcher(url, { layout_id, ids }),
    onSuccess: ({ success }) => {
      if (success) {
        // selectContent([]);
        setAppSnackbar({
          isVisible: true,
          message: 'Banner settings updated',
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating settings',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating settings' });
    },
  });

  const { isValidating: updatingCategory, mutate: updateCategory } = useSWR([endpoints.updateUserPreferences, updateType, updateType === 'category_style_id' ? selectedCategoryBox : selectedPreviewSettings], {
    fetcher: (url, type, value) => dataFetcher(url, { type, value }),
    onSuccess: ({ success }) => {
      if (success) {
        // selectContent([]);
        setAppSnackbar({
          isVisible: true,
          message: 'Settings updated',
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating settings',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating settings' });
    },
  });

  const { isValidating: gettingAlbumSettings, mutate: getAlbumSettings } = useSWR([endpoints.getAlbumSettings], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, transition_speed, transition_type }) => {
      if (success) {
        setTransitionSpeed(transition_speed);
        selectTransitionType(transition_type);
        setSavedSettings({
          type: transition_type,
          speed: transition_speed
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  useEffect(() => { getCategories(); getPlaylists(); getContentType(); getAlbumSettings(); getLayoutTypes(); getUserLayouts(); }, []);
  // useEffect(() => { if (settingsType) saveSettings(); }, [settingsType]);

  const transitionSpeedHandler = (event) => {
    setTransitionSpeed(event.target.value);
  };

  useEffect(() => {
    const node = document?.getElementById('transitionSpeedInput');
    if (transitionSpeed && node) {
      node.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
          if (parseInt(event.target.value, 10) < 4 || parseInt(event.target.value, 10) > 120) {
            setAppSnackbar({ isVisible: true, type: 'error', message: 'Invalid transition time' });
          } else {
            setSettingsType(1); // changing transition speed
          }
        }
      });
      return () => {
        node.removeEventListener('keyup', (event) => {
          setTransitionSpeed(undefined);
          setSettingsType(undefined);
        });
      };
    }
  }, [settingsType]);

  const transitionChange = (event) => {
    setSettingsType(2);// changing transition speed
    selectTransitionType(event.target.value);
  };

  const bannerChange = (event) => {
    selectBannerType(event.target.value);
  };

  const categoryChange = (event) => {
    selectCategoryBox(event.target.value);
    setUpdateType('category_style_id');
  };

  const previewChange = (event) => {
    setUpdateType('banner_preview_style_id');
    selectPreviewSettings(event.target.value);
  };

  useEffect(() => {
    if (savedContent?.length) {
      const result = savedContent.map((element) => ({ id: element.id, name: selectedBannerType === '3' ? element.id : element.title }));
      selectContent(result);
    }
  }, [savedContent]);

  useEffect(() => {
    if (savedBannerType) {
      selectBannerType(savedBannerType);
    }
  }, [savedBannerType]);

  useEffect(() => {
    if (updateType) {
      updateCategory();
    }
  }, [updateType, selectedCategoryBox, selectedPreviewSettings]);

  useEffect(() => {
    if (selectedBannerType !== savedBannerType) {
      selectContent([]);
    } else {
      const result = savedContent.map((element) => ({ id: element.id, name: selectedBannerType === '3' ? element.id : element.title }));
      selectContent(result);
    }
  }, [selectedBannerType, savedBannerType]);

  useEffect(() => {
    if (selectedBannerType === '1' || selectedBannerType === '2') {
      updateBannerType();
    }
  }, [selectedBannerType]);
  return (
    <Page className={styles.page} contentClassName={styles.pageContent} headerTheme={styles.headerTheme}>
      <div className={styles.contentLayoutHeader}>
        <Text.PageTitle className={styles.pageTitle}>{layoutText?.title || 'Content Layout'}</Text.PageTitle>
      </div>
      <div className={styles.buttonDiv}>
          <div className={styles.buttonGroups}>
            <CreateCategoryPage />
            <CreatePlaylistPage />
          </div>
      </div>
      <div className={styles.contentWrapper}>
        {
          contentTypes && contentTypes.length > 0 ? (
            <div className={styles.contentGroupContainer}>
              <div className={styles.contentGroupWrapper}>
                <div className={styles.chooseOrder}>
                  {layoutText?.chooseOrder || 'Choose Order/Edit'}
                </div>
                <ContentGroupButton
                  groups={contentTypes}
                  groupType="category"
                  isLoading={gettingContentType}
                  onChange={getCategories}
                  EditButton={EditButton}
                />
              </div>
              <div>
                <SlideShowSettings
                  transitionSpeed={transitionSpeed}
                  transitionSpeedHandler={transitionSpeedHandler}
                  settingsType={settingsType}
                  setUpdatingSettings={setUpdatingSettings}
                  saveSettings={saveSettings}
                  updatingSettings={updatingSettings}
                  transitionType={transitionType}
                  transitionChange={transitionChange}
                  selectedTransitionType={selectedTransitionType}
                  setTransitionSpeed={setTransitionSpeed}
                  savedSettings={savedSettings}
                  updateAllAlbumSettings={updateAllAlbumSettings}
                />
                <BannerSettings
                  bannerType={bannerTypes}
                  bannerChange={bannerChange}
                  selectedBannerType={selectedBannerType}
                  setTransitionSpeed={setTransitionSpeed}
                  savedSettings={savedSettings}
                  updateBannerType={updateBannerType}
                  selectedContent={selectedContent}
                  selectContent={selectContent}
                  savedBannerType={savedBannerType}
                  savedContent={savedContent}
                  gettingUserLayouts={gettingUserLayouts}
                  categoryBoxes={categoryBoxes}
                  selectedCategoryBox={selectedCategoryBox}
                  categoryChange={categoryChange}
                />
                <PreviewSettings
                  previewType={previewType}
                  previewChange={previewChange}
                  selectedPreviewSettings={selectedPreviewSettings}
                />
              </div>
            </div>
          ) : (
            <ProjectorShimmer isLight />
          )
        }

      </div>
    </Page>
  );
};
