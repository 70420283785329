/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from 'react';
import { NotificationsActive, Notifications, ArrowBackIos, Mail } from '@material-ui/icons';
import {
  Badge, IconButton, Menu, MenuItem, Grid,
  Typography, Divider, Button, Collapse, Zoom, CircularProgress, makeStyles
} from '@material-ui/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext, AuthContext } from 'Context';
import useSWR from 'swr';
import { dataFetcher, endpoints, axios } from 'Api';
import { Pathname } from 'Routes';
import { ref, off, onValue } from 'firebase/database';
import { Snackbar } from '../../snackbar';
import styles from '../index.module.css';
import UserRequest from './UserRequest';

const transitionTimeout = 300;

const useStyles = makeStyles({
  customWidth: {
    '& .MuiPaper-root': {
      minWidth: '403px',
      '&::-webkit-scrollbar': {
        width: '7px'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '25px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }
  }
});

const defaultProps = {
  color: 'secondary',
  children: <Notifications
    fontSize="large"
  />
};
export default function NotificationsComponent({ handlePushNotification, getUserConnections,
  updateNotificationStatus, clearing, setClearing }) {
  const { langText, setViewerListUpdate, firebaseDatabase, autoSignIn } = useContext(AppContext);
  const { isInActiveUser } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const notificationText = langText?.header;
  const [newNotification, setNewNotification] = useState(0);
  const [notificationNewRequestData, setNotificationNewRequestData] = useState([]);
  const [notificationsData, setNotificationsData] = useState([]);

  const [notificationsMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isNotificationMenuOpen = Boolean(notificationsMoreAnchorEl);
  const [accessRequests, setAccessRequests] = useState([]);
  const [snackbar, setSnackbar] = useState({ isVisible: false, message: '' });
  const [invitations, setInvitations] = useState([]);
  const [deleting, setDeleting] = useState(false);

  const { isValidating: loadingAccessRequests, mutate: fetchAccessRequests } = useSWR(endpoints.getAllViewRequest, {
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setAccessRequests(data?.filter(({ status }) => (status === '0')));
      } else {
        setAccessRequests([]);
      }
    },
    onError: () => {
      setAccessRequests([]);
    },
  });

  const { isValidating: loadingInvitations, mutate: fetchInvitations } = useSWR(endpoints.getAllViewRequestSent, {
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setInvitations(data?.filter(({ status }) => (status === '3')));
      } else {
        setInvitations([]);
      }
    },
    onError: () => {
      setInvitations([]);
    },
  });
  const handleNotificationMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleNotificationMenuOpen = (event) => {
    setNewNotification(0);
    setMobileMoreAnchorEl(event.currentTarget);
    updateNotificationStatus('', true);
  };

  const { isValidating: gettingMyNotifications, mutate: getMyNotifications } = useSWR([
    endpoints.getMyNotifications
  ], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, data, total_count, unread_count }) => {
      if (success) {
        getUserConnections();
        setNotificationsData(data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
        if (unread_count > 0) {
          setNewNotification(unread_count);
        } else {
          setNewNotification(0);
        }
        setDeleting(false);
        setClearing(false);
      }
    },
    onError: (err) => {
      setDeleting(false);
      // reload page if invalid token
      if (parseInt(err?.response?.status, 10) === 401) {
        window.location.reload();
      }
    },
  });

  const deleteAllNotifications = async () => {
    setDeleting(true);
    await axios
      .post(endpoints.deleteAllMyNotifications)
      .then(({ success }) => {
        setDeleting(false);
        setClearing(false);
        setNotificationsData([]);
        getMyNotifications();
        // if (success) {
        // }
      })
      .catch((error) => {
        getMyNotifications();
        setDeleting(false);
        setClearing(false);
      });
  };

  useEffect(() => {
    if (isNotificationMenuOpen) {
      getMyNotifications();
      fetchAccessRequests();
      fetchInvitations();
    }
  }, [isNotificationMenuOpen]);

  useEffect(() => {
  if (!localStorage.getItem('id')) {
    autoSignIn();
  } else {
      const databaseRefGetMyNotifications = ref(firebaseDatabase, `notifications_updates/${localStorage.getItem('id')}`);
      const databaseRefFetchRequests = ref(firebaseDatabase, `friend_requests_updates/${localStorage.getItem('id')}`);
      const databaseRefFetchTempRequests = ref(firebaseDatabase, `temp_requests_updates/${localStorage.getItem('id')}`);
      const dataCallbackNoti = (snapshot) => {
        if (snapshot.exists()) {
          getMyNotifications();
        }
      };
      const dataCallbackReq = (snapshot) => {
        if (snapshot.exists()) {
          getUserConnections();
        }
      };
      const dataCallbackTempReq = (snapshot) => {
        if (snapshot.exists()) {
          fetchInvitations();
        }
      };
      onValue(databaseRefGetMyNotifications, dataCallbackNoti);
      onValue(databaseRefFetchRequests, dataCallbackReq);
      onValue(databaseRefFetchTempRequests, dataCallbackTempReq);
      return () => {
        off(databaseRefGetMyNotifications, 'value', dataCallbackNoti);
        off(databaseRefFetchRequests, 'value', dataCallbackReq);
        off(databaseRefFetchTempRequests, 'value', dataCallbackTempReq);
      };
    }
  }, []);

  // const MINUTE_MS = 10000;
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchAccessRequests();
  //     fetchInvitations();
  //   }, MINUTE_MS);
  //   return () => clearInterval(interval);
  //   // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  // }, []);

  useEffect(() => {
    if (accessRequests?.length) {
      setViewerListUpdate(true);
    }
  }, [accessRequests]);
  const renderNotificationMenu = (
    <Menu
      anchorEl={notificationsMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      // keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isNotificationMenuOpen}
      onClose={handleNotificationMenuClose}
      className={classes.customWidth}
      PopoverClasses={{ paper: styles.notificationAnimation }}
    >
      {
      [
        <div className={styles.notificationDropDown}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Typography className={styles.notfHeading} variant="h6" color="primary">
              {notificationText?.notification || 'Notifications'}
            </Typography>
          </Grid>
          <Grid item xs={2} className={styles.notficatonIcon}>
            <Badge
              badgeContent={newNotification}
              {...defaultProps}
            />
          </Grid>
        </Grid>
        <hr className={styles.dividerAlign} />
        <div className={styles.notificationContainer}>
          {
            !isInActiveUser ? (
              <>
                <div>
                  {
                    accessRequests.length > 0 && (
                      <Typography className={styles.invitationHeading} variant="subtitle2" color="primary">
                        {notificationText?.requests || 'Requests'}
                      </Typography>
                    )
                  }
                  <div>
                    {accessRequests?.map(({ id, firstname, lastname, email }, idx) => (
                      <UserRequest
                        email={email}
                        id={id}
                        key={idx}
                        firstname={firstname}
                        lastname={lastname}
                        setSnackbar={setSnackbar}
                        fetchAccessRequests={fetchAccessRequests}
                        setAccessRequests={setAccessRequests}
                        accessType="request"
                      />
                    ))}
                  </div>
                </div>
                <div className={styles.divSpace}>
                  {
                    invitations.length > 0 && (
                      <Typography className={styles.invitationHeading} variant="subtitle2" color="primary">
                        {notificationText?.invitations || 'Invitations'}
                      </Typography>
                    )
                  }
                  <div>
                    {invitations?.map(({ id, firstname, lastname, email }, idx) => (
                      <UserRequest
                        email={email}
                        id={id}
                        key={idx}
                        firstname={firstname}
                        lastname={lastname}
                        setSnackbar={setSnackbar}
                        setAccessRequests={setInvitations}
                        fetchInvitations={fetchInvitations}
                        accessType="invitation"

                      />
                    ))}
                  </div>
                </div>
              </>
            ) : isInActiveUser && (invitations.length || accessRequests.length) ? (
              <>
                <div className={styles.generalMessage}>Invitation/Request actions are not available for inactive users</div>
                <div className={styles.updatePaymentButton} onClick={() => navigate(Pathname.getSettingsPath(Pathname.settings.billings))}>
                  Update payment method
                </div>
              </>
            ) : null
          }
          {
            notificationsData.length > 0 && (
              deleting || clearing ? (
                <div className={styles.loaderDiv}>
                  <CircularProgress />
                </div>
              ) : (
                <div className={styles.divSpace}>
                  <Grid container spacing={1} className={styles.headingSpace}>
                    <Grid item xs={9}>
                      <Typography className={styles.invitationHeading} variant="subtitle2" color="primary">
                        {notificationText?.general || 'General'}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={styles.clearAllIconAlign}>
                      <Button
                        className={styles.clearAllIcon}
                        onClick={() => { deleteAllNotifications(); }}
                        size="large"
                      >
                        {notificationText?.clearAll || 'Clear All'}
                      </Button>
                    </Grid>
                  </Grid>
                  {notificationsData?.map(({ id, message }, index) => (
                    <div key={index}>
                      <Grid container spacing={1}>
                        <Grid item xs={9} className={styles.generalMessage}>
                          {message}
                        </Grid>
                        <Grid item xs={3} className={styles.clearAllIconAlign}>
                          <Button
                            className={styles.clearIcon}
                            onClick={() => { updateNotificationStatus(id, 2, getMyNotifications()); }}
                          // style={{ justifyContent: 'flex-end' }}
                          >
                            {notificationText?.clear || 'Clear'}
                          </Button>
                        </Grid>
                      </Grid>
                      <hr className={styles.notfDivider} />
                    </div>
                  ))}
                </div>
              )
            )
          }
        </div>
        </div>
      ]
      }
    </Menu>
  );

  // useEffect(() => {
  //   if (notificationsData.length > 0) {
  //     const newData = notificationsData.filter((x) => x.status === '1');
  //     setNewNotification(newData.length);
  //     // newData.map((x) => (handlePushNotification(x)));
  //   } else {
  //     setNewNotification(0);
  //   }
  // }, [notificationsData]);
  return (
    <>
      {/* {(notificationsData.length > 0 || accessRequests.length > 0 || invitations.length > 0)
        && ( */}
      <IconButton
        className={styles.uploadButton}
        aria-label="show more"
        aria-haspopup="true"
        onClick={handleNotificationMenuOpen}
        color="inherit"
      >
        {' '}
        <Badge className={styles.notificationIcon} badgeContent={newNotification} color="secondary">
          <Notifications
            fontSize="large"
            className={styles.notificationIconHover}
          />
          {/* <Notifications /> */}
        </Badge>
      </IconButton>
      {/* )} */}
      {renderNotificationMenu}
      <Snackbar {...snackbar} onClose={() => { setSnackbar({ ...snackbar, isVisible: false }); }} />
    </>
  );
}
