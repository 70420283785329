import React, { useState, useContext } from 'react';
import axios from 'axios';
import PasswordValidator from 'password-validator';
import PhoneInput from 'react-phone-number-input';
import { ReactComponent as VisibleIcon } from 'Assets/eye.svg';
import { ReactComponent as HiddenIcon } from 'Assets/eye.slash.svg';
import 'react-phone-number-input/style.css';
import { AppContext, AuthContext } from 'Context';
import './createUser.css';
import cn from 'classnames';
import { baseUrl } from 'Api';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import authStyles from '../Pages/public/authentication/auth.module.css';
import Logo from '../Assets/logoProjector.png';
import styles from '../Pages/public/authentication/components/input-field/index.module.css';

function CreateUser() {
  const navigate = useNavigate();
  const { getToken } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setloader] = useState(false);
  const { apiCALL, setapiCALL } = useContext(AppContext);
  const [emailExists, setEmailExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [err, seterr] = useState('');
  const [numberErr, setNumberErr] = useState('');
  const [password, setPassword] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const myToken = getToken();
  const spinerSmall = 'spinex';
  const passwordSchema = (new PasswordValidator()).is().min(12).has()
  .symbols(1)
  .has()
  .digits(1)
  .has()
  .uppercase(1)
  .lowercase(1);
  const hasUpperCase = /[A-Z]/.test(password);
  const passwordHealth = (
    password
      ? password.length < 12 || !hasUpperCase
        ? 'weak'
        : passwordSchema.validate(password)
          ? password.length >= 12
            ? 'strong'
            : 'medium'
          : 'weak'
      : ''
  );
  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
    if (value.includes('@')) {
      setIsLoading(true); // Activate spinner only when '@' is typed
      axios
        .post(`${baseUrl}/signupEmail`, {
          email: value,
        })
        .then((response) => {
          setEmailExists(response.data);
          setIsLoading(false); // Deactivate spinner when response is received
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false); // Deactivate spinner in case of error
        });
    }
  };

  const validateText = (text) => {
    const textRegex = /^[a-zA-Z\s]*$/;
    return textRegex.test(text);
  };

  const handleFirstNameChange = (event) => {
    const { value } = event.target;
    if (validateText(value)) {
      setFirstName(value);
    }
  };

  const handleLastNameChange = (event) => {
    const { value } = event.target;
    if (validateText(value)) {
      setLastName(value);
    }
  };
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    if (newPassword.length <= 30) {
      setPassword(newPassword);
    } else {
      // alert('Password trimmer to first 30 characters.');
    }
  };
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value || '');
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validateEmail = (email) => emailRegex.test(email);

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const updateProfile = async (phoneNumber, emails, myToken) => {
    const apiUrl = `${baseUrl}/updateMyProfile`;
    const requestBody = {
      mobile: phoneNumber,
      email: emails,
      token: myToken,
    };
    try {
      const response = await axios.post(apiUrl, requestBody);
      return response.data;
    } catch (error) {
      console.error('API error:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setNumberErr(error.response.data.message);
      } else {
        setNumberErr('An error occurred while updating the profile.');
      }
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    try {
      // const updateProfileResponse = await updateProfile(phoneNumber, email, myToken);
      // if (!updateProfileResponse.success) {
      //   console.error('Profile update failed:', updateProfileResponse.message);
      //   setNumberErr(updateProfileResponse);
      //   setloader(false);
      //   return; // Stop the function execution
      // }
      // Your existing validation checks here...
      if (!validateEmail(email)) {
        seterr('Please enter a valid email address.');
        setloader(false);
        return;
      }
      setloader(true);
      const payload = {
        token: myToken,
        email,
        password,
        firstname: firstName,
        lastname: lastName,
        mobile: phoneNumber,
      };
      axios
        .post(`${baseUrl}/userRegistration`, payload)
        .then((response) => {
          // Handle the API response
          setapiCALL(response.data);
          navigate('/affiliate');
          setloader(false);
        })
        .catch((error) => {
          // Handle errors
          console.error(error);
        });
    } catch (error) {
      // Handle errors from the updateProfile function
      console.error(error);
      setloader(false);
    }
  };
  return (
    <div className="widthController">
      <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => navigate('/settings/manage-users')}
      >
       <p className="backOut">BACK</p>
      </div>
        <div>
      <div className="flexSyntdom">
      <img className="Logosz" src={Logo} alt="" />
      </div>
      <div className="middleFledge">
        <p className="newAccount">Create account for new user</p>
        <p className="Enter">Enter the information of the new user</p>
        <div style={{ position: 'relative' }}>
        <input
        placeholder="Email"
        className="inputFull"
        // eslint-disable-next-line
        isLoading={emailExists}
        type="email"
        value={email}
        onChange={handleEmailChange}
        />
        {email.includes('@')
         && email.includes('.')
         && email.split('.').pop().length >= 2
         && isLoading
         && (
      <div className="positionOfLoader">
        <div className={cn({ [styles.spinnerContainer]: true, [styles.loading]: true })}>
          <div style={{ borderColor: '#ffffff44' }} className={styles.spinner} />
          <div style={{ borderColor: '#5aa5ef' }} className={cn({ [styles.spinner]: true, [styles.animatedSpinner]: true })} />
        </div>
      </div>
       )}

        </div>
        {emailExists?.message === 'Email already exists'
        && <label className="redisher">{emailExists?.message || err}</label>}
        <label className="redisher">{err}</label>
        <div className="flexMan">
        <input
        placeholder="First Name"
        className="inputFull"
        type="text"
        value={firstName}
        onChange={handleFirstNameChange}
        />
        <input
        placeholder="Last Name"
        className="inputFull"
        type="text"
        value={lastName}
        onChange={handleLastNameChange}
        />
        </div>
        <PhoneInput
          placeholder="Phone Number"
          value={phoneNumber}
          className="inputFull"
          onChange={handlePhoneNumberChange}
          countryCallingCodeEditable={false}
          defaultCountry="US"
        />
        {numberErr
        && <label className="redisher">{numberErr}</label>}
        <div className="relativez">
        <input
        placeholder="Password"
        className="inputFull"
        label="Enter a password"
        type={showPassword ? 'text' : 'password'}
        minLength={12}
        maxLength={30}
        autoComplete="new-password"
        value={password}
        onChange={handlePasswordChange}
        />
      <span
      role="button"
      tabIndex={0}
      className="eyeCatcher"
      onKeyDown={() => {}}
      onClick={handleTogglePasswordVisibility}
      >
      {showPassword ? <HiddenIcon /> : <VisibleIcon />}
      </span>
        </div>
            <div className={authStyles.conditons}>
              <p style={{ color: password && password.length >= 12 ? '#23e223' : 'white' }}>12 - 30 Characters</p>
              <p style={{ color: password && hasUpperCase ? '#23e223' : 'white' }}>Minimum of one uppercase</p>
              <p style={{ color: password && password.match(/[a-z]/) ? '#23e223' : 'white' }}>Minimum of one lowercase</p>
              <p style={{ color: password && password.match(/[0-9]/) ? '#23e223' : 'white' }}>Minimum of one number</p>
              <p style={{ color: password && password.match(/[!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/) ? '#23e223' : 'white' }}>Minimum of one special character</p>
            </div>
        <div className="Thebg">
        <span>
        Projector will use your data to personalize and improve your Projector experience and to send you information about Projector. You can change your communication preferences anytime. We may use your date as described in our
        <span className="privacy">
          {' '}
          <a className="privacy" href="">Privacy Policy</a>
        </span>
        , including saying it with the family of companies. By clicking “Agree and Continue”, you agree to our
        <span className="privacy">
          {' '}
          <a className="privacy" href="">Terms of Use, Subscriber Agreement</a>
          {' '}
        </span>
         and acknowledge that you have read our Privacy Policy.
        </span>
        <button
          onClick={passwordHealth === 'strong'
          || emailRegex.test(email)
          ? handleSubmit
          : null}
          className={
            !emailRegex.test(email)
            || emailExists?.message === 'Email already exists'
            || passwordHealth !== 'strong'
            || firstName?.length < 1
            || lastName?.length < 1
            || phoneNumber?.length < 1
              ? 'disabledaggre'
              : 'aggre'
          }
        >
          {loader === true ? (
            <svg className="loading-circle" viewBox="0 0 50 50">
              <circle className="circle" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
            </svg>
          ) : 'AGREE AND CONTINUE'}
        </button>
        </div>
      </div>
        </div>
    </div>
  );
}

export default CreateUser;
