/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Checkbox, Collapse, Fade } from '@material-ui/core';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import cn from 'classnames';
import { encryptedKeyForCredentials, GOOGLE_RECAPTCHA_KEY } from 'Commons';
import { Button } from 'Components';
import { AppContext, AuthContext } from 'Context';
import { validate as isValidEmail } from 'email-validator';
import { debounce } from 'lodash';
import PasswordValidator from 'password-validator';
import { useContext, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Pathname } from 'Routes';
import useSWR from 'swr';
import authStyles from '../auth.module.css';
import { Input } from '../components/input-field';
import { AuthPage } from '../components/page';
import styles from './index.module.css';

const passwordSchema = (new PasswordValidator()).is().min(6).has()
  .symbols(1)
  .has()
  .digits(1);
const transitionTimeout = 300;

export const SignUp = () => {
  const { refferedBy } = useParams();

  const navigate = useNavigate();
  const { setAppSnackbar, plansPage, setPlansPage, langText } = useContext(AppContext);
  const signUpText = langText?.signInUp;
  const { preferredEmail, forceAutoSignin, setToken, setPreferredEmail, preferredUserDetails, setPreferredUserDetails } = useContext(AuthContext);
  const [email, setEmail] = useState(isValidEmail(preferredEmail) ? preferredEmail : '');
  const [password, setPassword] = useState('');
  // fixEmail to true to continue to password page
  const [emailIsFixed, fixEmail] = useState(false);
  const [emailIsValid, setEmailValidity] = useState(false);
  const [accountDoNotExists, setAccountDoNotExists] = useState(true);
  const [checkingAccountExistence, setAccountExistenceCheckingStatus] = useState(false);
  const [cancelToken, setCancelToken] = useState(undefined);
  const location = useLocation();

  const [termsAreAgreed, setTermsAgreement] = useState(false);
  const [referenceCode, setReferenceCode] = useState(undefined);
  const passwordHealth = (
    password
      ? passwordSchema.validate(password)
        ? password.length > 10
          ? 'strong'
          : 'medium'
        : 'weak'
      : ''
  );

  const [selectedReCAPTCHA, selectReCAPTCHA] = useState(false);
  const { isValidating: signingInUser, mutate: signInUser } = useSWR([endpoints.signin, email, password], {
    fetcher: (url, inputEmail, inputPassword) => dataFetcher(url, { email: inputEmail, password: inputPassword }),
    onSuccess: ({ success, data, message }) => {
      if (success) {
        setToken(data?.token);
        forceAutoSignin();
        setAppSnackbar({ message: 'Logged in successfully', isVisible: true });
        if (plansPage) {
          setPlansPage(false);
          navigate(Pathname.plans);
        } else if (!parseInt(data?.is_email_verified, 10)) {
          navigate(Pathname.authentication.verifyEmail);
        } else if (parseInt(data?.verify_mobile, 10) === 0) {
          navigate(Pathname.authentication.mobileVerification);
        }
      } else {
        setAppSnackbar({ type: 'error', message: message || 'Incorrect password', isVisible: true });
      }
    },
    onError: (error) => {
      setAppSnackbar({ type: 'error', message: error?.response?.data?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: signingUpUser, mutate: signUpUser } = useSWR([endpoints.signup, email, password, refferedBy, preferredUserDetails.firstName, preferredUserDetails.lastName, referenceCode], {
    fetcher: (url, inputEmail, inputPassword, refferedLink, firstname, lastname, referenceCode) => dataFetcher(url, {
      email: inputEmail, password: inputPassword, refferedBy: refferedLink || null, firstname, lastname, reference_code: referenceCode || null
    }),
    onSuccess: ({ success }) => {
      if (success) {
        sessionStorage.setItem(encryptedKeyForCredentials.password, password);
        sessionStorage.setItem(encryptedKeyForCredentials.email, email);
        setPlansPage(false);
        navigate(Pathname.authentication.mobileVerification);
      }
    },
    onError: () => { },
  });

  const { mutate: checkAccountExistence } = useSWR([endpoints.signupEmail, email, cancelToken?.token], {
    fetcher: (url, inputEmail, uniqueCancelToken) => dataFetcher(url, { email: inputEmail }, uniqueCancelToken),
    onSuccess: ({ success }) => {
      setAccountExistenceCheckingStatus(false);

      if (success) {
        setAccountDoNotExists(true);

        if (isValidEmail(preferredEmail)) { fixEmail(true); }
      } else {
        setAccountDoNotExists(false);
      }

      setPreferredEmail(undefined);
    },
    onError: () => {
      setAccountExistenceCheckingStatus(false);
    },
  });

  const emailInputHandler = debounce(({ target: { value: inputEmail } }) => {
    if (isValidEmail(inputEmail)) {
      setEmail(inputEmail);
      setEmailValidity(true);
    } else {
      setEmail('');
      setAccountDoNotExists(undefined);
      setEmailValidity(false);
    }
  }, 750, { trailing: true });

  const inputHandlerForUserDetails = (key, value) => {
    const userDetailsCopy = { ...preferredUserDetails };
    userDetailsCopy[key] = value;
    setPreferredUserDetails(userDetailsCopy);
  };

  useEffect(() => {
    if (email) {
      setAccountExistenceCheckingStatus(true);
      checkAccountExistence();
    }
  }, [email, setAccountExistenceCheckingStatus, checkAccountExistence]);

  useEffect(() => {
    if (preferredEmail && isValidEmail(preferredEmail)) {
      setAccountExistenceCheckingStatus(true);
      setEmailValidity(true);
      checkAccountExistence();
    }
  }, [preferredEmail, setAccountExistenceCheckingStatus, checkAccountExistence]);

  useEffect(() => {
    if (location?.search) {
      const searchParams = new URLSearchParams(location.search);
      setReferenceCode(searchParams.get('reference_code'));
    }
  }, [location?.search]);

  const reCaptchaHandler = (value) => {
    if (value) {
      selectReCAPTCHA(true);
    } else {
      selectReCAPTCHA(false);
    }
  };
  return (
    <AuthPage>
      <Link to={Pathname.authentication.newsignIn} className={styles.signInLink}>{signUpText?.logIn || 'Log In'}</Link>
      <div aria-hidden="true" onClick={() => { navigate(-1); }} className={styles.backLink}>{signUpText?.back || 'BACK'}</div>
      {!emailIsFixed
        ? (
          <form onSubmit={(e) => { e.preventDefault(); }}>
            {/* <StepStatus currentStep="1" totalSteps="2" langText={signUpText} /> */}
            <Input
              defaultValue={email}
              isDisabled={(email === preferredEmail) && checkingAccountExistence}
              isLoading={checkingAccountExistence}
              label="Account Creation"
              onInput={(e) => {
                e.persist();
                emailInputHandler(e);
                setAccountExistenceCheckingStatus(false);

                if (cancelToken) { cancelToken.cancel(); }

                if (!e?.target?.value) {
                  setEmail('');
                  setAccountDoNotExists(undefined);
                  setEmailValidity(false);
                } else {
                  setCancelToken(AxiosCancelToken.source());
                }
              }}
              placeholder={signUpText?.email || 'Email'}
              type="email"
            />
            {
                accountDoNotExists ? (
                  <div className={styles.nameFields}>
                    <Input
                      onInput={(e) => {
                        e.persist();
                        inputHandlerForUserDetails('firstName', e?.target?.value);
                      }}
                      placeholder={signUpText?.firstName || 'First Name'}
                      type="code"
                      noLabel
                    />
                    <Input
                      onInput={(e) => {
                        e.persist();
                        inputHandlerForUserDetails('lastName', e?.target?.value);
                      }}
                      placeholder={signUpText?.lastName || 'Last Name'}
                      type="code"
                      noLabel
                    />
                  </div>
                ) : null
            }
            <Collapse in={accountDoNotExists} timeout={transitionTimeout}>
              <Fade in={accountDoNotExists} timeout={transitionTimeout}>
                <div className={styles.newsletter}>
                  <Checkbox
                    className={styles.newsletterCheckbox}
                    color="primary"
                    id="newsletter-sub"
                    onChange={({ target: { checked: termsAgreed } }) => { setTermsAgreement(termsAgreed); }}
                  />
                  <label className={styles.newsletterText} htmlFor="newsletter-sub">
                    {signUpText?.signUpCheckBox || 'I have read and understood the Terms of Use, Subscriber Agreement  and Privacy Policy.'}
                  </label>
                </div>
              </Fade>
            </Collapse>
            {
                accountDoNotExists ? (
                  <div className={styles.reCaptchaContainer}>
                    <ReCAPTCHA
                      sitekey={GOOGLE_RECAPTCHA_KEY}
                      onChange={reCaptchaHandler}
                      theme="dark"
                    />
                  </div>
                ) : null
            }
            <Collapse in={!accountDoNotExists} timeout={transitionTimeout}>
              <Fade in={!accountDoNotExists} timeout={transitionTimeout}>
                <>
                  <br />
                  <br />
                </>
              </Fade>
            </Collapse>
            <div className={styles.termsContainer}>
              <div className={styles.terms}>
                <Collapse in={accountDoNotExists} timeout={transitionTimeout}>
                  <Fade in={accountDoNotExists} timeout={transitionTimeout}>
                    {
                      signUpText?.signUpContent ? (
                        <span className={styles.linkClass} dangerouslySetInnerHTML={{ __html: signUpText?.signUpContent }} />
                      ) : (
                        <span>
                          Projector will use your data to personalize and improve your Projector experience and to send you information about Projector. You can change your communication preferences anytime. We may use your data as described in our
                          <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/privacy"> Privacy Policy </a>
                          , including sharing it with the family of companies.  By clicking “Agree &amp; Continue”, you agree to our &nbsp;
                          <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/terms-of-use">Terms of Use </a>
                          ,&nbsp;
                          <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/subscriber-agreement">Subscriber Agreement </a>
                          and acknowledge that you have read our Privacy Policy.
                        </span>
                      )
                    }
                  </Fade>
                </Collapse>
                <Collapse in={!accountDoNotExists} timeout={transitionTimeout}>
                  <Fade in={!accountDoNotExists} timeout={transitionTimeout}>
                    <span>{signUpText?.existingAccountText || 'You seem to have an existing projector account, Login instead?'}</span>
                  </Fade>
                </Collapse>
              </div>

              {accountDoNotExists === false
                ? (
                  <Button
                    blockText
                    isBlue
                    isDisabled={checkingAccountExistence
                      || !(emailIsValid && (accountDoNotExists !== undefined) && (accountDoNotExists === false))}
                    isFullWidth
                    isLarge
                    onClick={() => { setPreferredEmail(email); navigate(Pathname.authentication.signIn); }}
                  >
                    {signUpText?.login || 'login'}
                  </Button>
                )
                : (
                  <Button
                    blockText
                    isBlue
                    isDisabled={checkingAccountExistence
                      || !(termsAreAgreed && emailIsValid && (accountDoNotExists !== undefined) && (accountDoNotExists === true) && selectedReCAPTCHA)}
                    isFullWidth
                    isLarge
                    submit
                    onClick={() => { fixEmail(true); }}
                  >
                    {signUpText?.agreeAndContinue || 'Agree and Continue'}
                  </Button>
                )}
            </div>
          </form>
        )
        : (
          <form onSubmit={(e) => { e.preventDefault(); signUpUser(); }}>
            {/* <StepStatus currentStep="2" totalSteps="2" langText={signUpText} /> */}
            <input className={authStyles.hiddenFormField} name="email" onChange={() => { }} type="email" value={email} />
            <Input
              label={signUpText?.enterAPassword || 'Enter a password'}
              maxLength={16}
              minLength={6}
              onInput={({ target: { value: inputPassword } }) => { setPassword(inputPassword); }}
              placeholder={signUpText?.password || 'Password'}
              type="SignUpPass"
            />
            <div className={authStyles.passwordStrengthBar}>
              <div
                className={cn({
                  [authStyles.passwordStrengthBarContent]: true,
                  ...passwordHealth && { [authStyles[passwordHealth]]: true }
                })}
                style={{
                  width: passwordHealth === 'weak'
                    ? '30%'
                    : passwordHealth === 'medium'
                      ? '55%'
                      : passwordHealth === 'strong'
                        ? '100%'
                        : '0%'
                }}
              />
            </div>
            <div className={authStyles.passwordStrengthNotes}>
              <div>{signUpText?.passwordStrength || 'Use a minimum of 6 characters (case sensitive) with at lease one number and one special character.'}</div>
              <div
                className={cn({
                  [authStyles.currentPasswordStrength]: true,
                  ...passwordHealth && { [authStyles[passwordHealth]]: true }
                })}
              >
                {passwordHealth}
              </div>
            </div>
            <div className={styles.notes}>
              <>{signUpText?.usingEmail || 'You’ll be using this email to log in:'}</>
              <br />
              <div className={styles.notesEmail}>{email}</div>
            </div>
            <div>
              <Button
                blockText
                isBlue
                isDisabled={(!((passwordHealth === 'strong') || (passwordHealth === 'medium')))
                  || signingInUser || signingUpUser}
                isFullWidth
                isLarge
                submit
              >
                {(signingUpUser || signingInUser) ? (`${signUpText?.signingUp}...` || 'Signing Up...') : (signUpText?.continue || 'Continue')}
              </Button>
            </div>
          </form>
        )}
    </AuthPage>
  );
};
