import Skeleton from '@material-ui/lab/Skeleton';
import { TableRow } from '@material-ui/core';
import cn from 'classnames';
import { TableCell } from './components/CustomDesigns';
import styles from './index.module.css';

export const DummyRow = () => ((
  <TableRow className={cn({ [styles.tableRow]: true })}>
    <TableCell className={styles.bodyCell}>
      <Skeleton animation="wave" variant="circle" width={45} height={45} />
    </TableCell>
    <TableCell
      align="left"
      className={cn({ [styles.bodyCell]: true, [styles.videoPreviewCell]: true })}
    >
      <div className={styles.previewContainer}>
        <Skeleton variant="rect" width={160} height={85} />
        <div className={styles.previewText}><Skeleton variant="text" className={styles.dummyLoaderImageCaption} /></div>
      </div>
    </TableCell>
    <TableCell align="center" className={styles.bodyCell}>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell align="center" className={styles.bodyCell}>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell align="center" className={styles.bodyCell}>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell align="center" className={styles.bodyCell}>
      <Skeleton variant="text" />
    </TableCell>
  </TableRow>
));
