import React, { useMemo, useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import InputGrid from '../input-grid';
import InputField from '../input-field';

function NameField({
  checked,
  afflitedDetails,
  onFirstNameChange,
  onLastNameChange,
  setValidity,
  userFirstName,
  userLastName,
  isDisabled,
}) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { langText, userDetails } = useContext(AppContext);
  const purchaseDetailsText = langText?.settings?.billPayment?.purchaseDetails;
  const firstNameIsValid = useMemo(() => (
    firstName?.length >= 2 && firstName?.length <= 70
  ), [firstName]);
  const lastNameIsValid = useMemo(() => (
    lastName?.length >= 2 && lastName?.length <= 70
  ), [lastName]);

  useEffect(() => {
    setValidity(firstNameIsValid && lastNameIsValid);
  }, [firstNameIsValid, lastNameIsValid]);

  useEffect(() => {
    if (checked === true) {
      setFirstName(userDetails?.firstname);
      setLastName(userDetails?.lastname);
    } else {
      setFirstName(userFirstName);
      setLastName(userLastName);
    }
  }, [checked]);

  return (
    <InputGrid>
      <InputField
      isDisabled={isDisabled}
      maxLength={70}
      onChange={(value) => {
      setFirstName(value);
      // onFirstNameChange(value);
      }}
      placeholder={purchaseDetailsText?.firstname || 'First name'}
      type="text"
      defaultValue={firstName}
      />
      <InputField
        isDisabled={isDisabled}
        maxLength={70}
        onChange={(value) => {
          setLastName(value);
          // onLastNameChange(value);
        }}
        placeholder={purchaseDetailsText?.lastname || 'Last name'}
        type="text"
        defaultValue={lastName}
      />
    </InputGrid>
  );
}

export default NameField;
