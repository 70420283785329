import axios from 'axios';
import { dataFetcher, endpoints, baseUrl } from 'Api';

const getMyContentsBasedOnUploadDate = async (userId, page = 1, search = '', sortBy = 'date', orderBy = 0) => {
const getToken = () => localStorage.getItem('token');
const apiUrl = `${baseUrl}/V1/getMyContentsBasedOnUploadDate`; // Replace `baseUrl` with your actual base URL.
const tokenx = getToken();
const payload = {
    user_id: userId,
    token: tokenx,
    page,
    search,
    sort_by: sortBy,
    order_by: orderBy
};
    // Headers to pass with the request
    const headers = {
        'Content-Type': 'application/json', // Ensure the server expects JSON payload
    };

    try {
        // Include the headers in the POST request
        const response = await axios.post(apiUrl, payload, { headers });
        return response.data;
    } catch (error) {
        console.error('Error fetching content:', error.response?.data || error.message);
        throw error;
    }
};

export default getMyContentsBasedOnUploadDate;
