/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  CircularProgress,
  DialogContent,
} from '@material-ui/core';
import cn from 'classnames';
import { AppContext, AuthContext } from 'Context';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as PlusButton } from 'Assets/plus-button.svg';
import AlterImage from 'Assets/alter-image.png';
import heic2any from 'heic2any';
import Spinner from 'Components/Spinner';
import { DialogFooter } from './Material-reDesign';
import styles from '../index.module.css';
import '../loader.css';
// The component displays a dialog box with the selected images and two buttons:
// Cancel and Next (or Upload). The dialog box also includes a place to add more images by clicking the
// Add Photo text or the plus icon. If the fromContentLayout prop is true, the Next button will change to Upload.
// If the addingPhotos prop is true, the button text will change to "Uploading".
// The text displayed in the component is localized based on the uploadText and commonText props.

function PhotoPreview({
  photoFile,
  removePhoto,
  photoUpload,
  handleClose,
  setChooseAlbumStep,
  fromContentLayout = false,
  uploadPhoto = null,
  getRandomImages = null,
  addingPhotos = false,
  uploadText,
  commonText
}) {
  const { addMorePhoto, setaddMorePhoto } = useContext(AppContext);
  const [images, setImages] = useState([]);
  const [convertingHeic, setConvertingHeic] = useState([]);
  const spinerSmall = 'spinex';
  useEffect(() => {
    (async () => {
      if (photoFile?.length === 0) {
        setImages([]);
        return;
      }

      const newImages = [];
      const promises = [];
      for (let i = 0; i < photoFile?.length; i += 1) {
        const file = photoFile[i]?.file;
        const alreadyConverted = images.find((image) => image.file?.name === file?.name && image.converted);
        if (file.type === 'image/heic' || file?.name?.includes('.HEIC')) {
          if (!alreadyConverted) {
            setConvertingHeic((c) => c.concat({ index: i }));
            setImages((images) => images.concat({ file, converted: false }));
            promises.push(
              heic2any({ blob: file, toType: 'image/jpeg' })
                .then((blob) => URL.createObjectURL(blob))
                .then((url) => newImages.push({ url, file, converted: true }))
            );
          } else {
            newImages.push(alreadyConverted);
          }
        } else {
          newImages.push({ url: URL.createObjectURL(file), file, converted: true });
        }
      }
      await Promise.all(promises).then(() => { });
      setConvertingHeic([]);
      setImages(newImages);
    })();
  }, [photoFile]);

  return (
    <>
      <DialogContent className={styles.content} style={{ position: 'relative', overflow: 'hidden' }}>
        <div className={styles.alignRow}>
          {images?.map((file, index) => (
            <div className={styles.imageColumn}>
              {!file.converted
                ? (
                  <Box className={styles.progressContainer}>
                    <CircularProgress />
                  </Box>
                )
                : (
                  <>
                    <CloseIcon className={styles.closeIcon} onClick={() => removePhoto(index)} />
                    <img
                      src={file.url || AlterImage}
                      className={styles.selectedImage}
                      onError={(e) => { e.target.src = AlterImage; }}
                    />

                  </>
                )}
            </div>
          ))}
          <div className={styles.imageColumn}>
            <input
              accept=".png,.jpg,.jpeg,.heif,.heic"
              multiple
              id="more-file"
              onChange={photoUpload}
              style={{ width: '75%', height: '70%', position: 'absolute', opacity: 0, zIndex: 1, cursor: 'pointer' }}
              className="inputDragDrop"
              type="file"
            />
            <div className={styles.addMorePhoto}>
              <div className={styles.addIconContainer}>
                <PlusButton className={styles.addIcon} />
                <div className={styles.addPhotoText}>{uploadText?.addPhoto || 'Add Photo'}</div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogFooter>
        <div className={cn(styles.previewFilesSectionButton, styles.cancelButton)} onClick={handleClose}>
          {commonText?.cancel || 'Cancel'}
        </div>
        <div
          style={images?.length === 0 || addMorePhoto ? { pointerEvents: 'none', opacity: '0.4' } : {}}
          className={cn(styles.previewFilesSectionButton, styles.nextButton)}
          onClick={() => { setaddMorePhoto(true); (fromContentLayout ? uploadPhoto() : getRandomImages()); }}
        >
          {fromContentLayout ? (addMorePhoto ? (commonText?.uploading || 'Uploading') : (commonText?.upload || 'Upload')) : (commonText?.next || 'Next')}
          {addMorePhoto && <Spinner spinex={spinerSmall} />}
        </div>
      </DialogFooter>
    </>
  );
}
export default PhotoPreview;
