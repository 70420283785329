/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AppContext } from 'Context';
import { Page, Text, ProjectorShimmer } from 'Components';
import { dataFetcher, endpoints } from 'Api';
import { Pathname } from 'Routes';
import RotatingLoader from 'Components/RotatingLoader';
import { ReactComponent as AddIcon } from 'Assets/add.svg';
import { ReactComponent as DragIcon } from 'Assets/drag.svg';
import { ReactComponent as EditIcon } from 'Assets/edit.svg';
import { ReactComponent as DropdownIcon } from 'Assets/chevron-down.svg';
import AlterImage from 'Assets/alter-image.png';
import { Box, Menu } from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import { InfoModal } from '../dashboard/info-modal';
import styles from './index.module.css';

const CreateButton = ({
  bottomLeftComponent: BLC,
  buttonLabel,
  isCompact,
  onCreate,
  title,
}) => {
  const { setAppSnackbar } = useContext(AppContext);

  const [val, setVal] = useState(undefined);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [creating, setCreatingStatus] = useState(false);
  const { mutate: addNewPlaylist } = useSWR([endpoints.addEditPlaylist, val, onCreate], {
    fetcher: (url, playlistName) => dataFetcher(url, { title: playlistName }),
    onSuccess: ({ success }) => {
      if (success) {
        if (onCreate) {
          onCreate();
        }
        setCreatingStatus(false);
        setVal(undefined);
        setMenuOpen(false);
        setAppSnackbar({
          isVisible: true,
          message: `Playlist '${val}' was created`,
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your playlist' });
      }
    },
    onError: (err) => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your playlist' });
    },
  });

  return (
    <div className={styles.createButtonWrapper}>
      <button
        className={cn({ [styles.createButton]: true, [styles.compact]: isCompact })}
        onClick={() => { if (!isMenuOpen) { setMenuOpen(true); } }}
      >
        <span>{buttonLabel}</span>
        {!isCompact && <AddIcon className={styles.addIcon} />}
      </button>
      {isMenuOpen && (
        <div className={styles.createMenuContainer}>
          <div
            className={styles.createButtonScrim}
            onClick={() => { if (!creating) { setVal(undefined); setMenuOpen(false); } }}
            onKeyPress={undefined}
            role="button"
            tabIndex={0}
          />
          <div className={styles.createMenu}>
            <div className={styles.createMenuTitle}>{title}</div>
            <input
              className={styles.createMenuInput}
              defaultValue={val}
              onInput={(e) => { setVal(e.target.value); }}
            />
            <div className={styles.createMenuButtons}>
              {BLC && (
                <div className={styles.createMenuButtonsLeft}>
                  <BLC />
                </div>
              )}
              <div className={styles.createMenuButtonsRight}>
                <button
                  className={styles.createMenuCancelButton}
                  disabled={creating}
                  onClick={() => { setVal(undefined); setMenuOpen(false); }}
                >
                  Cancel
                </button>
                <button
                  className={styles.createMenuCreateButton}
                  disabled={creating || (!val?.trim())}
                  onClick={() => {
                    setCreatingStatus(true);
                    addNewPlaylist();
                  }}
                >
                  {creating ? 'Creating ...' : 'Create'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const newOrderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ContentGroup = ({ groups, groupType, isLoading, title, onChange, contentType, itemsPerPage, selectedPage, setSelectedPage, totalItems }) => {
  const { setAppSnackbar, langText } = useContext(AppContext);
  const commonText = langText?.common;
  const layoutText = langText?.layout;
  const [items, setItems] = useState(groups);
  const [newOrder, updateNewOrder] = useState(undefined);
  const [isOpenModal, setModal] = useState(false);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setSelectedPage(selected);
  };
  const EditButton = ({ onClick }) => (
    <button className={styles.editButton} onClick={onClick}>
      <div>{commonText?.edit || 'Edit'}</div>
      <div className={styles.editButtonDropdown}>▾</div>
    </button>
  );
  const [orderLoading, setOrderLoading] = useState(false);
  const cancelOrder = () => {
    updateNewOrder(undefined);
    setItems(groups);
  };
  const fetchData = async (url, newItems) => {
      const response = await axios.post(url, { items: newItems });
      return response.data;
  };

  // Usage in your component
  // const updateOrder = async () => {
  //   try {
  //     // Show loader or set a loading state here if needed
  //     setOrderLoading(true);
  //     // Construct the API URL based on groupType
  //     const apiUrl = groupType === 1
  //         ? endpoints.updateCategoryOrder
  //         : groupType === 2
  //         ? endpoints.updatePlaylistOrder
  //         : endpoints.reOrderMyAlbum;

  //     // Make the Axios request
  //     const response = await fetchData(apiUrl, newOrder?.items);
  //     // Handle the response
  //     if (response.success) {
  //       setAppSnackbar({
  //         isVisible: true,
  //         message: 'Order updated',
  //       });
  //     } else {
  //       setAppSnackbar({
  //         isVisible: true,
  //         type: 'error',
  //         message: 'Oops! Something went wrong while updating your order',
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //     // Handle errors here
  //   } finally {
  //     // Hide the loader when the request completes (whether success or error)
  //     setOrderLoading(false);
  //   }
  // };
  const { isValidating: updatingNewOrder, mutate: updateOrder } = useSWR(
    [
      groupType === 1 ? endpoints.updateCategoryOrder : groupType === 2
        ? endpoints.updatePlaylistOrder : endpoints.reOrderMyAlbum,
      newOrder?.items,
    ],
    {
      fetcher: (url, newItems) => dataFetcher(url, { items: newItems }),
      onSuccess: ({ success }) => {
        if (success) {
          setAppSnackbar({
            isVisible: true,
            message: 'Order updated',
          });
          setOrderLoading(false);
        } else {
          setAppSnackbar({
            isVisible: true,
            type: 'error',
            message: 'Oops! Something went wrong while updating your order',
          });
          setOrderLoading(false);
        }
      },
      onError: (err) => {
        cancelOrder();
        setOrderLoading(false);
        setAppSnackbar({ isVisible: true, type: 'error', message: err.response.data.message });
      },
    }
  );
  const [currentId, setCurrentId] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setCurrentId(null);
    setMobileMoreAnchorEl(null);
  };

  const { isValidating: deletingMyPlayList, mutate: deleteGroup } = useSWR([
    groupType === 1 ? endpoints.deleteMyCategory : groupType === 2
      ? endpoints.deleteMyPlayList : endpoints.deleteMyAlbum, currentId
  ], {
    fetcher: (url, id) => dataFetcher(url,
      groupType === 1 ? { catId: id } : groupType === 2 ? { id } : { album_id: id }),
    onSuccess: ({ success }) => {
      handleMobileMenuClose();
      if (success) {
        setAppSnackbar({
          isVisible: true,
          message: 'Deleted Successfully',
        });
        onChange();
        if (groupType !== 2) {
          setModal(false);
        }
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating your order',
        });
      }
    },
    onError: () => {
      handleMobileMenuClose();
    },
  });

  const onDragEnd = (result) => {
    if (!result.destination) { return; }
    const newItems = newOrderList(items, result.source.index, result.destination.index);
    const changedItems = {};

    // items?.forEach((item, idx) => {
    //   if (item?.id !== newItems[idx]?.id) {
    //     changedItems[newItems[idx]?.id] = (idx + 1);
    //   }
    // });
    newItems?.forEach((newItem, idx) => {
      changedItems[newItem?.id] = idx + 1;
    });
    if (result.source.index !== result.destination.index) {
      updateNewOrder({ items: changedItems });
    }
    setItems(newItems);
  };

  useEffect(() => { setItems(groups); }, [groups]);
  useEffect(() => {
    if (newOrder) {
      // setOrderLoading(true);
      // updateOrder();
    }
  }, [newOrder]);

  const handleMobileMenuOpen = (event, id) => {
    setCurrentId(id);
    setMobileMoreAnchorEl(event.currentTarget);
  };
  return (
    <div className={styles.contentGroup}>
            {newOrder !== undefined
      && (
            <div className={styles.accessedCompny}>
              <button onClick={() => { setOrderLoading(true); updateNewOrder(undefined); updateOrder(); }} className={styles.existButtun}>
                Update
              </button>
              <button onClick={() => cancelOrder()} className={styles.discard}>
                Discard
              </button>
            </div>
      )}
      <div className={styles.contentGroupHead}>
        <div className={styles.contentGroupHeadRow}>
          <div className={cn({ [styles.contentGroupHeadCell]: true, [styles.contentGroupText]: true })}>
            {title}
          </div>
          <div
            className={cn({ [styles.contentGroupHeadCell]: true, [styles.contentGroupText]: true })}
            style={{ textAlign: 'center' }}
          >
            {commonText?.action || 'Actions'}
          </div>
        </div>
      </div>
      <Box position="relative">

        {isLoading
          ? (
            <ProjectorShimmer isLight />
          ) : ((items?.length)
            ? (
              <>
              { orderLoading === true && <RotatingLoader />}
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={groupType}>
                    {(droppableProvided) => (
                      <div
                        className={styles.contentGroupBody}
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {items.map(({ id, title: name, thumbnails: images, totalImages, icon, photos }, idx) => (
                          <Draggable key={id} draggableId={id} index={idx}>
                            {(draggableProvided, draggableSnapshot) => (
                              <div
                                className={cn({
                                  [styles.contentGroupBodyRow]: true,
                                  [styles.dragged]: draggableSnapshot.isDragging,
                                })}
                                key={idx}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                style={draggableProvided.draggableProps.style}
                              >
                                <div className={styles.contentGroupBodyCell}>
                                  <div className={cn({ [styles.name]: true, [styles.contentGroupText]: true })}>{name}</div>
                                  <div className={styles.categoryContainer}>
                                    <div className={styles.categoryContainerImages}>
                                      <div className={styles.dragIconContainer} {...draggableProvided.dragHandleProps}>
                                        <DragIcon className={styles.dragIcon} />
                                      </div>
                                      <div className={styles.imageContainer}>
                                        {
                                          groupType === 3 ? (
                                            // <img
                                            //   key={idx}
                                            //   src={icon || AlterImage}
                                            //   alt={name}
                                            //   className={styles.image}
                                            //   onError={(e) => {
                                            //     e.target.src = AlterImage;
                                            //   }}
                                            // />
                                            photos?.slice(0, 4).map((imageURL, idx2) => (
                                              <img
                                                key={idx2}
                                                src={imageURL || AlterImage}
                                                alt={name}
                                                className={styles.image}
                                                onError={(e) => {
                                                  e.target.src = `${imageURL}?tr=orig`;
                                                }}
                                              />
                                            ))
                                          ) : (
                                            images?.map((imageURL, idx2) => (
                                              <img
                                                key={idx2}
                                                src={imageURL || AlterImage}
                                                alt={name}
                                                className={styles.image}
                                                onError={(e) => {
                                                  e.target.src = `${imageURL}?tr=orig`;
                                                }}
                                              />
                                            ))
                                          )
                                        }
                                        {((totalImages - images?.length) > 0) && (
                                          <div className={styles.image}>
                                            <div className={styles.totalImages}>{`+${totalImages - images?.length}`}</div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {/* <Link to={Pathname.getContentLayoutPath(groupType, id)}> */}
                                    <EditIcon
                                      className={styles.editIcon}
                                      style={{ color: '#818181' }}
                                      onClick={(event) => handleMobileMenuOpen(event, id)}
                                    />
                                    {/* </Link> */}
                                  </div>
                                </div>
                                <div style={{ textAlign: 'center' }} className={styles.contentGroupBodyCell}>
                                  {/* <Link to={Pathname.getContentLayoutPath(groupType, id)}> */}
                                  {/* <EditButton onClick={(event) => handleMobileMenuOpen(event, id)} /> */}
                                  <button className={styles.editButton} onClick={(event) => handleMobileMenuOpen(event, id)}>
                                    <div>Edit</div>
                                    <div className={styles.editButtonDropdown}>▾</div>
                                  </button>
                                  {/* </Link> */}
                                  <Menu
                                    anchorEl={mobileMoreAnchorEl}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    PaperProps={{ style: { boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)' } }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    open={isMobileMenuOpen}
                                    onClose={handleMobileMenuClose}
                                    PopoverClasses={{ paper: styles.dropDownAnimation }}
                                  >
                                    <div
                                      style={{ textAlign: 'center' }}
                                    >

                                      <Link
                                        to={Pathname.getContentLayoutPath(contentType, currentId)}
                                        className={`${styles.editButton} ${styles.editMenuButton}`}
                                      >
                                        {commonText?.view || 'View'}
                                      </Link>
                                      <button
                                        onClick={() => { groupType === 2 ? deleteGroup() : setModal(true); }}
                                        className={`${styles.editButton} ${styles.editMenuButton}`}
                                      >
                                        {commonText?.delete || 'Delete'}
                                      </button>
                                    </div>

                                  </Menu>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}

                  </Droppable>
                </DragDropContext>
                <ReactPaginate
                  nextLabel={`${window.screen.width > 900 ? 'next >' : '>'}`}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={5}
                  pageCount={totalPages}
                  previousLabel={`${window.screen.width > 900 ? '< previous' : '<'}`}
                  pageClassName={styles.pageItem}
                  pageLinkClassName={styles.pageLink}
                  previousClassName={styles.pageItem}
                  previousLinkClassName={styles.pageLink}
                  nextClassName={styles.pageItem}
                  nextLinkClassName={styles.pageLink}
                  breakLabel="..."
                  breakClassName={styles.pageItem}
                  breakLinkClassName={styles.pageLink}
                  containerClassName={styles.pagination}
                  activeClassName={styles.active}
                  renderOnZeroPageCount={null}
                  forcePage={selectedPage}
                />
              </>
            )
            : (
              <div className={styles.contentGroupBody}>
                <div className={styles.contentGroupBodyRow}>
                  <br />
                  {groupType === 1
                    ? (layoutText?.categoryLoadingMsg || 'Categories you create will be displayed here')
                    : groupType === 2
                      ? (layoutText?.playlistLoadingMsg || 'Playlists you create will be displayed here')
                      : (layoutText?.albumLoadingMsg || 'Photo albums you create will be displayed here')}
                </div>
              </div>
            ))}
      </Box>

      {
        isOpenModal && (
          <InfoModal
            isVisible={isOpenModal}
            title="Are you sure you want to delete?"
            onHide={() => { setModal(false); }}
            continueButton="Go back"
            // content="You are now deleting, all videos and photos will now be a draft until you
            // Add them to a new category. Are you sure you want to continue?"
            action={deleteGroup}
            actionButton="Delete"
            isDelete
          />
        )
      }
    </div>
  );
};

// const PlaylistDropdown = ({ onSelect }) => (
//   <CreateMenuDropdown
//     options={[
//       { name: 'Public', value: 'Public' },
//       { name: 'Group', value: 'Group' },
//       { name: 'Private', value: 'Private' },
//     ]}
//     onSelect={onSelect}
//   />
// );

export const ContentLayoutDetails = () => {
  const { setAppSnackbar, langText } = useContext(AppContext);
  const navigate = useNavigate();
  const layOutDetailsText = langText?.layout;
  const commonText = langText?.common;
  const [categoriesData, setCategoriesData] = useState([]);
  const [playlistsData, setPlaylistsData] = useState([]);
  const [contentLayoutData, setContentLayoutData] = useState([]);
  const contentType = window.location.pathname.split('/').pop();
  const [groupType, setGroupType] = useState(null);
  const [title, setTitle] = useState(undefined);
  const [selectedPage, setSelectedPage] = useState(0);
  const [totalItems, setTotalItems] = useState(null);
  const itemsPerPage = 50;

  const { isValidating: gettingCategories, mutate: getCategories } = useSWR([endpoints.getMyCategory, itemsPerPage, selectedPage], // pass limit as a payload
    {
      fetcher: (url, limit, page) => dataFetcher(url, { limit, start: page }),
      onSuccess: ({ success, data, itemcount }) => {
        if (success) {
          // const d = data.sort((a, b) => parseInt(a.order_number, 10) - parseInt(b.order_number, 10));
          const d = data;
          setContentLayoutData(d);
          setCategoriesData(d);
          setTotalItems(itemcount);
        } else {
          setAppSnackbar({
            isVisible: true,
            type: 'error',
            message: 'Oops! Something went wrong while fetching your categories',
          });
        }
      },
      onError: () => {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching your categories' });
      },
    });

  const { isValidating: gettingPlaylists, mutate: getPlaylists } = useSWR([endpoints.getMyPlaylist, itemsPerPage, selectedPage], // pass limit as a payload
    {
      fetcher: (url, limit, page) => dataFetcher(url, { limit, start: page }),
      onSuccess: ({ success, data, itemcount }) => {
        if (success) {
          setContentLayoutData(data);
          setTotalItems(itemcount);
        } else {
          setAppSnackbar({
            isVisible: true,
            type: 'error',
            message: 'Oops! Something went wrong while fetching your playlist',
          });
        }
      },
      onError: () => {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching your playlist' });
      },
    });

  const { isValidating: gettingAlbums, mutate: getAlbums } = useSWR(
    [endpoints.getMyAlbum, itemsPerPage, selectedPage], // pass limit as a payload
    {
      fetcher: (url, limit, page) => dataFetcher(url, { limit, start: page }),
      onSuccess: ({ success, data, itemcount }) => {
        if (success) {
          setPlaylistsData(data);
          setContentLayoutData(data);
          setTotalItems(itemcount);
        } else {
          setAppSnackbar({
            isVisible: true,
            type: 'error',
            message: 'Oops! Something went wrong while fetching your photo albums',
          });
        }
      },
      onError: () => {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your photo albums'
        });
      },
    }
  );

  useEffect(() => {
    const contentTypeNumber = localStorage.getItem('content_type');
    if (contentType === '1') {
      getCategories();
      setGroupType(1);
      setTitle(layOutDetailsText?.categories || 'Categories');
    } else if (contentType === '2') {
      getPlaylists();
      setGroupType(2);
      setTitle(layOutDetailsText?.playlist || 'Playlists');
    } else {
      getAlbums();
      setGroupType(3);
      setTitle(layOutDetailsText?.album || 'Photo Albums');
    }
  }, [selectedPage]);

  return (
    <Page className={styles.page} contentClassName={styles.pageContent} headerTheme={styles.headerTheme}>
      <div
        aria-hidden="true"
        className={styles.backButton}
        onClick={() => { navigate(-1); }}
      >
        {commonText?.back || 'Back'}
      </div>
      <div className={styles.contentGroupContainer}>
        <ContentGroup
          groups={contentLayoutData}
          groupType={groupType}
          isLoading={groupType === 1 ? gettingCategories : groupType === 2
            ? gettingPlaylists : gettingAlbums}
          title={title}
          onChange={groupType === 1 ? getCategories : groupType === 2 ? getPlaylists : getAlbums}
          contentType={contentType}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
        />
      </div>
    </Page>
  );
};
