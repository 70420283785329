import React, { useState } from 'react';
import cn from 'classnames';
import styles from '../index.module.css';

const PrefDropDown = ({ name, isDefaultOpen, options, onClick }) => {
  const [isOpen, setOpen] = useState(isDefaultOpen);

  return (
    <div
      className={styles.prefDropDown}
      onClick={() => { setOpen(!isOpen); if (onClick) { onClick(); } }}
      onKeyDown={undefined}
      role="button"
      tabIndex={0}
    >
      <div className={styles.prefDropDownText}>{name}</div>
      <div className={cn({ [styles.prefDropDownIcon]: true, [styles.prefDropDownActive]: options && isOpen })}>▾</div>
      {isOpen && options && (
        <>
          <div
            className={styles.prefDropdownScrim}
            onClick={() => { setOpen(false); }}
            onKeyPress={undefined}
            role="button"
            tabIndex={0}
          />
          <div className={styles.optionsContainer}>
            {options.map(({ name: actionName, action }, idx) => (
              <div
                className={styles.option}
                key={idx}
                onClick={action}
                onKeyPress={undefined}
                role="button"
                tabIndex={0}
              >
                {actionName}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default PrefDropDown;
