/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import PPLLC from 'Components/Doc/ppLLC/index';
import { AppContext } from 'Context';
import { SettingsPage } from '../components/page';

export const PrivacySettings = () => {
  const { langText } = useContext(AppContext);
  const privacyText = langText?.settings?.privacy;
  return (
    <SettingsPage
      title={privacyText?.title || 'Privacy'}
      // description={privacyText?.description || 'Projector Privacy settings'}
    >
      <PPLLC />
    </SettingsPage>
  );
};
