import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import {
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import { GroupSelector } from 'Components';
import styles from '../index.module.css';
import { Radio } from '../components/CustomDesigns';

const VisibilityMenu = ({
  category,
  defaultGroup,
  id,
  onChange,
  onClose,
  setActionStatus,
  subCategory,
  value,
  setVisibilityMenu,
  type
}) => {
  const { setAppSnackbar, langText } = useContext(AppContext);
  const visibilityText = langText?.visibility;
  const commonText = langText?.common;
  const [currentValue, setCurrentValue] = useState(value);
  const [inputValue, setInputValue] = useState(value);
  const [group, setGroup] = useState(Array.isArray(defaultGroup) ? defaultGroup : []);
  const [updateData, setUpdateData] = useState(undefined);
  const videoVisibilityOptions = [
    { name: 'All Viewers' || visibilityText?.publicDescription, value: '2' },
    { name: visibilityText?.privateDescription || 'Only I can view (Private)', value: '1' },
    { name: visibilityText?.groupDescription || 'Choose a group to share with', value: '3' },
  ];
  useEffect(() => {
  }, [inputValue, group, defaultGroup]);
  const { isValidating: updating, mutate: updateContent } = useSWR([
    type ? endpoints.addPhotoContent : endpoints.addVideoContent, updateData,
  ], {
    fetcher: (url, formdata) => dataFetcher(url, formdata),
    onSuccess: ({ success }) => {
      setVisibilityMenu(undefined);
      setActionStatus(false);
      if (success) {
        setCurrentValue(inputValue);
        onChange();
        setAppSnackbar({ isVisible: true, message: 'Visibility was updated successfully' });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setActionStatus(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });
  const update = () => {
    const formData = new FormData();
    formData.append(`${type ? 'album_id' : 'video_id'}`, id);
    formData.append('visibility', inputValue);
    if (category && subCategory) {
      formData.append('status', 1);
    }

    if (inputValue === '3') {
      formData.append('group_id', group);
    }

    setActionStatus(true);
    setUpdateData(formData);
  };

  useEffect(() => { if (updateData) { updateContent(); } }, [updateData]);

  useEffect(() => {
    if (currentValue !== value) {
      onChange(id, [['visibility', currentValue], ...((category && subCategory) ? [['status', '1']] : [])]);
    }
  }, [currentValue]);

  const selectGroup = (id) => {
    if (group && group.includes(id)) {
      setGroup(group.filter((item) => item !== id));
    } else {
      setGroup([...group, id]);
    }
  };

  return (
    <div className={styles.editMenu}>
      <div className={styles.editMenuTitle}>{visibilityText?.title || 'Visibility'}</div>
      <RadioGroup
        className={styles.visibilityRadio}
        defaultValue={value}
        name="visibility"
        onChange={(e) => { setInputValue(e?.target?.value); }}
        value={inputValue}
      >
        {videoVisibilityOptions.map(({ name, value: val }, idx) => (
          <FormControlLabel value={val} key={idx} control={<Radio color="primary" />} label={name} />
        ))}
      </RadioGroup>
      {inputValue === '3' && <GroupSelector onSelect={(val) => { selectGroup(val); }} selectedOption={group} />}
      <br />
      <button
        className={styles.editMenuUpdateButton}
        disabled={updating || ((inputValue === '3') ? (!group || (group === defaultGroup)) : (inputValue === currentValue))}
        onClick={update}
      >
        {updating ? `${(commonText?.updating || 'Updating')}...` : (commonText?.update || 'Update')}
      </button>
      <input id="close-button-input" onClick={onClose} style={{ display: 'none' }} />
    </div>
  );
};
export default VisibilityMenu;
