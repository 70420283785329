import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import cn from 'classnames';
import styles from '../index.module.css';

const CategoriesMenu = ({ categoryID, defaultValue, isSubcategory, onSelect, placeholder, title }) => {
  const { setAppSnackbar } = useContext(AppContext);

  const [isOpen, setOpen] = useState(false);
  const [val, setVal] = useState(undefined);
  const [options, setOptions] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState([]);
  const [createMenuIsVisible, setCreateMenuVisibility] = useState(false);

  const showCreateMenu = () => { setCreateMenuVisibility(true); };
  const hideCreateMenu = () => { setCreateMenuVisibility(false); };

  const { isValidating: gettingCategories, mutate: getCategories } = useSWR([endpoints.getMyCategory, categoryID], {
    fetcher: (url, parent_id) => dataFetcher(url, { ...isSubcategory && { parent_id } }),
    onSuccess: ({ success, data }) => {
      if (success) {
        if (defaultValue) {
          data?.every(({ id }, idx) => {
            if (id === defaultValue) {
              setVal(data[idx]?.title);

              return false;
            }

            return true;
          });
        }
        setOptions(data?.map(({ id, title: name }) => ({ name, value: id })));
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your categories',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching your categories' });
    },
  });

  const { isValidating: addingNewCategory, mutate: addNewCategory } = useSWR([
    endpoints.addEditCategory, newCategoryName, categoryID,
  ], {
    fetcher: (url, categoryName, parentCategoryID) => dataFetcher(url, {
      title: categoryName,
      ...parentCategoryID && { parent_id: parentCategoryID }
    }),
    onSuccess: ({ success }) => {
      if (success) {
        hideCreateMenu();
        getCategories();
        setAppSnackbar({
          isVisible: true,
          message: `${isSubcategory ? 'Sub-Category' : 'Category'} '${newCategoryName}' was created`,
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your category' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your category' });
    },
  });

  useEffect(() => {
    if (isSubcategory) {
      onSelect(undefined);
    }
    setVal(placeholder);
    getCategories();
  }, [categoryID]);

  return (
    <div className={styles.categoriesMenu}>
      <div className={styles.categoriesMenuTitle}>{title}</div>
      <div
        className={styles.categoriesMenuInput}
        onClick={() => { if (isSubcategory ? categoryID : true) { setOpen(true); } }}
        onKeyPress={undefined}
        role="button"
        tabIndex={0}
      >
        <div className={styles.categoriesMenuInputText}>
          {gettingCategories ? 'Loading ...' : val || placeholder}
        </div>
        <div className={styles.categoriesMenuInputIcon}>▾</div>
      </div>
      {!gettingCategories && isOpen && (
        <>
          <div
            className={styles.prefDropdownScrim}
            onClick={() => { if (!addingNewCategory) { setCreateMenuVisibility(false); setOpen(false); } }}
            onKeyPress={undefined}
            role="button"
            tabIndex={0}
          />
          <div className={styles.categoriesMenuOptionsContainer}>
            {createMenuIsVisible
              ? (
                <div className={styles.newCategoryContainer}>
                  <div className={styles.newCategoryBar}>
                    {`Enter new ${isSubcategory ? 'Sub-Category' : 'Category'}`}
                    <button
                      className={styles.newCategoryButton}
                      disabled={addingNewCategory}
                      onClick={hideCreateMenu}
                    >
                      Back
                    </button>
                  </div>
                  <input
                    className={styles.newCategoryInput}
                    onChange={(e) => { setNewCategoryName(e?.target?.value); }}
                    type="text"
                  />
                  <button
                    className={cn({ [styles.newCategoryButton]: true, [styles.newCategoryButtonCreate]: true })}
                    disabled={addingNewCategory || !(newCategoryName.toString()?.trim())}
                    onClick={addNewCategory}
                  >
                    {addingNewCategory ? 'Creating ...' : 'Create'}
                  </button>
                </div>
              )
              : (
                <>
                  {options.map(({ name, value }, idx) => (
                    <div
                      className={styles.categoriesMenuOption}
                      key={idx}
                      onClick={() => { setVal(name); onSelect(value); setOpen(false); }}
                      onKeyPress={undefined}
                      role="button"
                      tabIndex={0}
                    >
                      {name}
                    </div>
                  ))}
                  <button
                    className={cn({ [styles.categoriesMenuOption]: true, [styles.categoriesMenuOptionAdd]: true })}
                    onClick={showCreateMenu}
                  >
                    {`Add new ${title}`}
                  </button>
                </>
              )}
          </div>
        </>
      )}
    </div>
  );
};
export default CategoriesMenu;
