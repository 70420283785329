import {
  Box as MuiBox,
  TableCell as MuiTableCell,
  withStyles,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';

export const DialogContent = withStyles({ root: { backgroundColor: '#fff', borderRadius: '0px' } })(MuiDialogContent);

export const Box = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }
})(MuiBox);
export const TableHeaderCell = withStyles({
  root: {
    fontWeight: '600',
    borderBottom: '1px solid transparentize(#707070, 0.82)',
    padding: 0,
  },
})(MuiTableCell);
export const TableBodyCell = withStyles({
  root: {
    fontWeight: '400',
    borderBottom: '1px solid transparentize(#707070, 0.82)',
    padding: 0,
  },
})(MuiTableCell);
