import React from 'react';
import cn from 'classnames';
import { ReactComponent as AddIcon } from 'Assets/add-circle.svg';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from '@material-ui/core';
import styles from './index.module.css';

export const DataView = ({ data }) => (
  <div className={styles.dataView}>
    {data.map(({ data: contentData,
      isFlex, isToggle, labelTitle, labelDescription, labelHasAddIcon, onLabelClick, infoButton, infoContent }, idx) => (
        <div className={cn({ [styles.dataRow]: true, [styles.isFlex]: isFlex })} key={idx}>
          <div className={cn({ [styles.dataCell]: true, [styles.labelCell]: true })}>
            <div className={styles.labelTitle}>
              <div className={styles.labelTitleText}>
                {labelTitle}
                {infoButton || infoContent ? (
                  <Tooltip title={infoContent}>
                    <InfoIcon size="large" />
                  </Tooltip>
                ) : null}
              </div>
              <div className={styles.labelTitleIcon}>

                {labelHasAddIcon && <AddIcon className={styles.labelTitleAddIcon} onClick={onLabelClick} />}
              </div>
            </div>
            <div className={styles.labelDescription}>
              {labelDescription}
              {/* {' '}
            <Tooltip title="Info"><InfoIcon size="large" /></Tooltip> */}
            </div>
          </div>
          <div className={cn({ [styles.dataCell]: true, [styles.isToggle]: isToggle })}>
            {contentData}
          </div>
        </div>
    ))}
  </div>
);
