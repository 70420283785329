import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import {
  CircularProgress,
  Collapse,
  Fade,
  Table,
  TableBody,
  TableRow,
  Zoom,
} from '@material-ui/core';
import { Button, Modal, Snackbar } from 'Components';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import { dataFetcher, endpoints, axios } from 'Api';
import sendEmail from 'Components/sendMail';
import { SettingsPage } from '../components/page';
import { DataView } from '../components/data-view';
import styles from './index.module.css';
import InputRow from './components/InputRow';
import { DialogContent, TableBodyCell, Box } from './components/CustomDesigns';
import UserRequest from './components/UserRequest';
import AddBox from './components/AddBox';
import GroupTableHead from './components/GroupTableHead';
import GroupRow from './components/GroupRow';
import PersonButton from './components/PersonButton';
import AddSuccessorModal from './Modals/AddSuccessorModal';
// import AddViewerModal from './modals/AddViewerModal';

const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };

export const ConnectedAccountSettings = () => {
  const navigate = useNavigate();
  const { userDetails, setAppSnackbar } = useContext(AppContext);

  const [snackbar, setSnackbar] = useState(defaultSnackbar);
  const [userConnections, setUserConnections] = useState([]);

  const [userManagementModalIsVisible, setUserManagementModalVisibility] = useState(false);
  const [secessionAccount, setSecessionAccount] = useState(false);
  const [addViewerModalIsVisible, setAddViewerModalVisibility] = useState(false);
  const [addViewerModalSettings, updateAddViewerModalSettings] = useState({ title: '', description: '' });

  const [accessRequests, setAccessRequests] = useState(undefined);
  const [accessRequestsNotAccepted, setAccessRequestsNotAccepted] = useState(undefined);
  const [groups, setGroups] = useState(undefined);
  const [viewerData, setViewerData] = useState([{ email: '', firstName: '', laseName: '' }]);
  const handalData = (row) => {
    const rows = [...viewerData];
    rows[row.idx] = { ...row };
    if (row.idx + 1 === viewerData.length) {
      rows.push({ email: '', firstName: '', laseName: '' });
    }
    setViewerData(rows);
  };
  const handalRemoveThisRow = (rowIdx) => {
    const oldRow = [...viewerData];
    const newRow = oldRow.filter((row) => row.idx !== rowIdx).map((row, index) => ({ ...row, idx: index }));

    setViewerData([]);
    setTimeout(() => {
      setViewerData(newRow);
    }, 100);
  };

  const { isValidating: addingViewers, mutate: addViewers } = useSWR([
    endpoints.addViewer, viewerData.filter((data) => data.idx !== undefined)], {
    fetcher: (url, viewers) => dataFetcher(url, { data: viewers }),
    onSuccess: ({ success }) => {
      if (success) {
        setSnackbar({
          isVisible: true,
          message: 'Viewers Added Successfully ',
        });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  // useEffect(() => { if (action) { handleAction(); } }, [handleAction, action]);

  const addViewer = async () => {
    addViewers();
    setAddViewerModalVisibility(false);
    setUserManagementModalVisibility(false);
    // await sendEmail(viewerData, userDetails.email);

    setAppSnackbar({ message: 'Viewer added successfully', isVisible: true });
  };
  const { isValidating: gettingUserConnections, mutate: getUserConnections } = useSWR([
    endpoints.getAllViewRequestSent,
    1,
    'null',
  ], {
    fetcher: (url, status) => dataFetcher(url, { status }),
    onSuccess: ({ success, data }) => {
      if (success) {
        setUserConnections(data);
      } else {
        setUserConnections([]);
      }
    },
    onError: () => { setUserConnections([]); },
  });

  const { isValidating: loadingAccessRequests, mutate: fetchAccessRequests } = useSWR([endpoints.getAllViewRequest], {
    fetcher: (url, status) => dataFetcher(url, { status }),
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setAccessRequests(data?.filter(({ status }) => (status === '1')));
        setAccessRequestsNotAccepted(data?.filter(({ status }) => (status === '0')));
      } else {
        setAccessRequests([]);
        setAccessRequestsNotAccepted([]);
      }
    },
    onError: () => {
      setAccessRequests([]);
      setAccessRequestsNotAccepted([]);
    },
  });

  const { isValidating: loadingGroups, mutate: fetchGroups } = useSWR(endpoints.getMyGroups, {
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setGroups(data);
      } else {
        setGroups([]);
      }
    },
    onError: () => {
      setGroups([]);
    },
  });
  useEffect(() => {
    fetchAccessRequests();
  }, []);
  useEffect(() => {
    if (userManagementModalIsVisible) {
      fetchAccessRequests();
      fetchGroups();
    } else {
      setAddViewerModalVisibility(false);
      // setAccessRequests([]);
      setGroups(undefined);
    }
  }, [userManagementModalIsVisible, fetchAccessRequests, fetchGroups]);
  useEffect(() => {
    if (secessionAccount) {
      fetchAccessRequests();
      fetchGroups();
    } else {
      setAddViewerModalVisibility(false);
      // setAccessRequests([]);
      setGroups(undefined);
    }
  }, [secessionAccount, fetchAccessRequests, fetchGroups]);

  useEffect(() => {
    if (!addViewerModalIsVisible) {
      updateAddViewerModalSettings({ title: '', description: '' });
    }
  }, [addViewerModalIsVisible]);

  useEffect(() => {
    getUserConnections();
  }, [getUserConnections]);
  const [accessRequestModalIsVisible, setAccessRequestModalVisibility] = useState(false);
  const requestProfileAccess = async ([onHide, setActionStatus, setOperationStatus, setSelectedUsers, setUserList, users]) => {
    setActionStatus(true);

    const { data: requestProfileAccessData } = await axios
      .post(endpoints.sendViewRequest, { users: `${users.map((userData) => userData.id)}` })
      .catch(() => ({}));

    setUserList(undefined);

    if (requestProfileAccessData?.success) {
      setSelectedUsers([]);
      setOperationStatus(true);
      onHide();
    } else {
      setOperationStatus(false);
    }

    setActionStatus(false);
  };
  const location = useLocation();
  const hideAccessRequestModal = () => { setAccessRequestModalVisibility(false); navigate(location.pathname); };
  return (
    <SettingsPage
      title="Connected accounts"
      description="Edit All associated accounts settings"
    >
      <DataView
        data={[
          {
            labelTitle: 'Add viewer / accept user',
            labelDescription: 'Add users to enjoy your home videos',
            labelHasAddIcon: true,
            onLabelClick: () => { setUserManagementModalVisibility(true); },
            data: (
              <div className={styles.addViewerData}>
                {loadingAccessRequests
                  ? 'Getting User Connections ...'
                  : (accessRequests?.length > 0) ? accessRequests.map((data) => (
                    <PersonButton
                      fetchAccessRequests={fetchAccessRequests}
                      data={data}
                    />
                  )) : 'No viewers'}
              </div>
            ),
          },
          {

            labelTitle: 'Succession Plan',
            labelDescription: 'Succession Plan',
            labelHasAddIcon: true,
            onLabelClick: () => { setSecessionAccount(true); },
            infoButton: true,
            infoContent: `Make sure your videos are preserved for future generations. 
                    Name a successor(s) who can help you preserve your treasured videos forever.
                    Your successor can then maintain your Projector Account and name their own successor(s)
                    so that your videos can be viewed and shared forever.`
          },
        ]}
      />

      <Modal
        cancelButton="Cancel"
        cancelButtonClassName={styles.modalCancelButton}
        continueButton="Add"
        continueButtonClassName={styles.modalContinueButton}
        disableBackdropClick
        isVisible={userManagementModalIsVisible}
        noFooter={!addViewerModalIsVisible}
        onContinueButtonClick={() => { addViewer(); }}
        onCancelButtonClick={() => { setAddViewerModalVisibility(false); }}
        onHide={() => { setUserManagementModalVisibility(false); }}
        title={addViewerModalIsVisible ? addViewerModalSettings.title : 'Add Viewer | Accept User'}
      >
        {!addViewerModalIsVisible
          ? (
            <>
              <div className={styles.acceptUserBox}>
                <Collapse timeout={400} in={loadingAccessRequests}>
                  <Zoom timeout={400} in={loadingAccessRequests}>
                    <Box className={styles.contentBox}>
                      <CircularProgress />
                    </Box>
                  </Zoom>
                </Collapse>
                <Collapse timeout={400} in={!loadingAccessRequests && !!accessRequestsNotAccepted?.length}>
                  <Zoom timeout={400} in={!loadingAccessRequests && !!accessRequestsNotAccepted?.length}>
                    <>
                      <div className={styles.acceptUserBoxTitle}>Accept User</div>
                      <div className={styles.acceptUserBoxDescription}>
                        All user requests will appear below, so you can decide to accept or decline
                      </div>
                      {accessRequestsNotAccepted?.map(({ id, firstname, lastname, email }) => (
                        <UserRequest
                          key={id}
                          id={id}
                          setSnackbar={setSnackbar}
                          firstname={firstname}
                          email={email}
                          lastname={lastname}
                        />
                      ))}
                    </>
                  </Zoom>
                </Collapse>
                <Collapse timeout={400} in={!loadingAccessRequests && !accessRequestsNotAccepted?.length}>
                  <Zoom timeout={400} in={!loadingAccessRequests && !accessRequestsNotAccepted?.length}>
                    <Box className={styles.contentBox}>No view requests</Box>
                  </Zoom>
                </Collapse>
              </div>
              <hr className={styles.modalDivider} />
              <div className={styles.addBoxContainer}>
                <AddBox
                  description="Add Multiple users to this group.
                  All users in this group Will have access to your uploaded content."
                  onAdd={() => {
                    accessRequests.length > 0 ? navigate(Pathname.createGroup) : setSnackbar({
                      isVisible: true,
                      message: 'You don\'t have viewers to create Groups'
                    });
                  }}
                  title={`${userDetails?.firstname ? `${userDetails?.firstname}'s ` : ''}Groups`}
                />
                <AddBox
                  alignRight
                  className={styles.individualViewerAddBox}
                  description="Add Individual viewers to view your content."
                  title="Individual Viewers"
                  onAdd={() => {
                    updateAddViewerModalSettings({
                      description: 'We will automatically add the viewer to your authorized list of viewers',
                      title: 'Add New Viewer',
                    });
                    setAddViewerModalVisibility(true);
                  }}
                />
                <div className={styles.tableContainer}>
                  <Collapse timeout={400} in={!loadingGroups}>
                    <Fade timeout={400} in={!loadingGroups}>
                      <Table className={styles.table}>
                        <AddSuccessorModal />
                        <TableBody>
                          {groups?.length
                            ? (
                              <>
                                {groups?.map(({ title, id, members_count }) => (
                                  <GroupRow
                                    title={title}
                                    id={id}
                                    members_count={members_count}
                                    setSnackbar={setSnackbar}
                                    onChange={() => fetchGroups()}
                                  />
                                ))}
                              </>
                            )
                            : (
                              <TableRow>
                                <TableBodyCell
                                  className={styles.tableBodyCell}
                                  align="center"
                                  colSpan="2"
                                >
                                  No new groups
                                </TableBodyCell>
                              </TableRow>
                            )}
                        </TableBody>
                      </Table>
                    </Fade>
                  </Collapse>
                  <Collapse timeout={400} in={loadingGroups}>
                    <Fade timeout={400} in={loadingGroups}>
                      <Table className={styles.table}>
                        <GroupTableHead />
                        <TableBody>
                          <TableRow>
                            <TableBodyCell align="center" className={styles.tableBodyCell} colSpan="2">
                              Loading...
                            </TableBodyCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Fade>
                  </Collapse>
                </div>
                <div className={styles.addViewerBox}>
                  {/* <div className={styles.addViewerBoxText}> */}

                  {
                    accessRequests && accessRequests.length > 0 ? (
                      <DialogContent style={{
                        color: '#fff',
                        display: `${!accessRequests ? 'none' : accessRequests.length > 0 ? 'flex' : 'none'}`,
                        // justifyContent: 'center',
                        flexDirection: 'column',
                        // alignItems: 'center',
                        width: '100%',
                        marginBottom: '10px',
                      }}
                      >

                        {loadingAccessRequests
                          ? 'Getting User Connections ...'
                          : accessRequests?.map((data) => (
                            <PersonButton
                              fetchAccessRequests={fetchAccessRequests}
                              data={data}
                            />
                          ))}
                      </DialogContent>
                    )
                      : (
                        <Button
                          className={styles.addViewerBoxButton}
                          isGray
                          onClick={() => {
                            updateAddViewerModalSettings({
                              description: 'We will automatically add the viewer to your authorized list of viewers',
                              title: 'Add New Viewer',
                            });
                            setAddViewerModalVisibility(true);
                          }}
                        >
                          Add Viewer
                        </Button>
                      )
                  }
                  {/* </div> */}
                  {/* <ShareScreen
                    actionButtonLoadingText="Requesting Access..."
                    actionButtonText="Request Access"
                    apiEndpoint={endpoints.searchUserByEmail}
                    errorText="Oops! Something went wrong"
                    inputPlaceholder="Search Users"
                    isVisible={accessRequestModalIsVisible}
                    multipleSelect
                    notes="Enter an email to search"
                    noUsersFoundText="No users found with this email"
                    onContinue={requestProfileAccess}
                    onHide={hideAccessRequestModal}
                    successText="Request sent"
                    title="Request Access to View"
                  /> */}
                </div>
              </div>
            </>
          )
          : (
            <>
              {/* <AddViewerModal
                addViewerModalSettings={addViewerModalSettings}
                viewerData={viewerData}
                setViewerData={setViewerData}
              /> */}
              <div className={styles.modalDescription}>{addViewerModalSettings.description}</div>
              <div className={styles.viewersCountContainer}>
                <div className={styles.viewersCountTitle}>Viewers</div>
                <div className={styles.viewersCount}>0 of 999</div>
              </div>
              <div className={styles.viewerDetailsInputGrid}>

                {viewerData.map(
                  ({ firstName, lastName, email }, idx) => (
                    <InputRow
                      key={idx}
                      idx={idx}
                      onChange={handalData}
                      removeThisRow={handalRemoveThisRow}
                      defaultFirstName={firstName}
                      defaultLastName={lastName}
                      defaultEmail={email}
                    />
                  )
                )}
              </div>
            </>
          )}
      </Modal>
      <AddSuccessorModal secessionAccount={secessionAccount} setSecessionAccount={(e) => setSecessionAccount(e)} />
      <Snackbar
        {...snackbar}
        onClose={() => {
          setSnackbar({
            ...snackbar,
            message: '',
            isVisible: false
          });
        }}
      />
    </SettingsPage>
  );
};
