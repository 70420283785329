/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { encryptedKeyForCredentials } from 'Commons';
import { Pathname } from 'Routes';
import { Button } from 'Components';
import { AppContext, AuthContext } from 'Context';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { number } from 'prop-types';
import { AuthPage } from '../components/page';
import styles from '../mobile-verification/index.module.css';

export const VerifyCode = () => {
  const navigate = useNavigate();
  const { setAppSnackbar, userDetails, setUserDetails, plansPage, setPlansPage, langText, setReferralStatus } = useContext(AppContext);
  const { forceAutoSignin, setToken, getToken, setEmailVerified } = useContext(AuthContext);
  const mobileVerificationText = langText?.mobileVerification;
  const commonText = langText?.common;
  const signUpText = langText?.signInUp;
  const RESEND_RESET_TIME = 60;
  const [expireTime, setExpireTime] = useState(RESEND_RESET_TIME);
  const [OTP, setOTP] = useState('');
  const email = sessionStorage.getItem(encryptedKeyForCredentials.email);
  const password = sessionStorage.getItem(encryptedKeyForCredentials.password);
  const [mobile, setMobile] = useState(sessionStorage.getItem(encryptedKeyForCredentials.mobile));
  const [isOtpReached, setOtpReached] = useState(sessionStorage.getItem('#fs656gfhllh@#$'));
  // sendOtpCount - no. of times otp requested by user successfully
  const [sendOtpCount, setSendOtpCount] = useState(0);
  const token = getToken();

  const { isValidating: signingInUser, mutate: signInUser } = useSWR([endpoints.signin, email, password], {
    fetcher: (url, inputEmail, inputPassword) => dataFetcher(url, { email: inputEmail, password: inputPassword }),
    onSuccess: ({ promo_code, isNewUser, success, data, referral_status, is_skip, message, userIsSignedIn, verify_mobile, mobile: userMobile }) => {
      if (success) {
        sessionStorage.removeItem(encryptedKeyForCredentials.email);
        sessionStorage.removeItem(encryptedKeyForCredentials.password);
        sessionStorage.removeItem(encryptedKeyForCredentials.mobile);
        setUserDetails({ ...userDetails, isNewUser, mobile: userMobile });
        setMobile(userMobile);
        setToken(data?.token);
        setEmailVerified(data?.is_email_verified);
        setAppSnackbar({ message: 'Logged in successfully', isVisible: true });
        setTimeout(() => {
          if (plansPage || (parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1 && !data?.billing_date)) {
            setPlansPage(false);
            parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1 ? setReferralStatus(1) : setReferralStatus(0);
            navigate(Pathname.plans);
          } else if (parseInt(data?.verify_mobile, 10) === 0) {
            // has_logged_in === '0' ? navigate(Pathname.gettingStarted) : navigate(Pathname.access);
            navigate(Pathname.authentication.mobileVerification);
          } else if (promo_code !== undefined && promo_code !== null && promo_code !== '') {
            const loginPromo = { promo_code: `${promo_code}`, promo_value: '3' };
            navigate(`${Pathname.access}${data?.encVal ? `?email=${data?.encVal}` : ''}${promo_code ? `&promo_code=${promo_code}` : ''}&promo_value=3`, { state: { promocode: loginPromo, mobile_token: data?.encVal ? data?.encVal : '' } });
          } else {
            navigate(`${Pathname.access}${data?.encVal ? `?email=${data?.encVal}` : ''}`);
          }
        }, 500);
        forceAutoSignin();
        if (plansPage || (parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1 && !data?.billing_date)) {
          setPlansPage(false);
          parseInt(referral_status, 10) === 1 && parseInt(is_skip, 10) !== 1 ? setReferralStatus(1) : setReferralStatus(0);
          navigate(Pathname.plans);
        } else if (parseInt(data?.verify_mobile, 10) === 0) {
          navigate(Pathname.authentication.mobileVerification);
        } else {
          navigate(`${Pathname.access}${data?.encVal ? `?email=${data?.encVal}` : ''}`);
        }
      } else if (!verify_mobile) {
        if (userMobile) {
          navigate(Pathname.authentication.verifyCode);
        } else {
          navigate(Pathname.authentication.mobileVerification);
        }
      } else {
        setAppSnackbar({ message: message || 'Incorrect password', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: verifyingOTP, mutate: verifyOtp } = useSWR([endpoints.verifyOtp, OTP, email], {
    fetcher: (url, code, email) => dataFetcher(url, { code, email }),
    onSuccess: ({ success, message }) => {
      if (success) {
        if (email) {
          signInUser();
        } else {
          const Items = JSON.parse(localStorage.getItem('item'));
          navigate(`${Pathname.access}${Items ? `?email=${Items}` : ''}`);
          navigate(Pathname.access);
        }
      } else {
        setAppSnackbar({ message: message || 'Oops! Something went wrong', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const { isValidating: requestingCode, mutate: requestVerificationCode } = useSWR([endpoints.sendOtpToMobile, email], {
    fetcher: (url, email) => dataFetcher(url, { email }),
    onSuccess: ({ success, message }) => {
      if (success) {
        setOtpReached(true);
        setSendOtpCount(1);
        setAppSnackbar({ message: message || 'Otp Resend', isVisible: true });
      } else {
        setAppSnackbar({ message: message || 'Oops! Something went wrong', isVisible: true });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ message: responseData?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  useEffect(() => {
    if (isOtpReached) {
      if (!expireTime) return;
      const intervalId = setInterval(() => {
        setExpireTime(expireTime - 1);
      }, 1000);
      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }
  }, [expireTime, isOtpReached]);

  useEffect(() => {
    if (!sendOtpCount && !isOtpReached) {
      requestVerificationCode();
    } else {
      setOtpReached(true);
      sessionStorage.removeItem('#fs656gfhllh@#$');
    }
  }, [sendOtpCount, isOtpReached]);

  const handleOtpInputChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setOTP(e.target.value);
    }
  };

  const resendCodeHandler = () => {
    setExpireTime(RESEND_RESET_TIME);
    setSendOtpCount(0);
    requestVerificationCode();
  };

  return (
    <>
      <AuthPage smallIcon>
        <Link to={Pathname.authentication.signIn} className={styles.signInLink}>{signUpText?.logIn || 'Log In'}</Link>
        <div aria-hidden="true" onClick={() => { navigate(-1); }} className={styles.backLink}>{commonText?.back || 'BACK'}</div>
        {
          isOtpReached ? (
            <form className={styles.alignForm} onSubmit={(e) => { e.preventDefault(); verifyOtp(); }}>
              <div className={styles.inputLabel}>{mobileVerificationText?.enterYourCode || 'Enter your code'}</div>
              <span className={styles.description}>{`A code was send to your number ${mobile || userDetails?.mobile}, please check and enter below.  If you do not see the code please try to resend in `}</span>
              <span className={styles.expireTime}>{`${expireTime}s`}</span>
              <br />
              <br />
              <div className={styles.buttonBlock}>
                <input
                  type="tel"
                  maxLength={5}
                  value={OTP}
                  onChange={handleOtpInputChange}
                  style={{ width: '200px', height: '55px', borderRadius: '10px', backgroundColor: '#31343e', border: '2px solid #6d6f76', color: '#ffffff', fontSize: '20px', fontWeight: '700', textAlign: 'center' }}
                />
              </div>
              <br />
              <div className={styles.buttonBlock}>
                <Button isFullWidth blockText isDisabled={!OTP || OTP?.length < 5 || verifyingOTP} isBlue isLarge submit>
                  {mobileVerificationText?.continue || 'Continue'}
                </Button>
              </div>
            </form>
          ) : null
        }
        <div
          className={styles.resendDiv}
        >
          <button onClick={() => { resendCodeHandler(); }}>
            {mobileVerificationText?.resendLink || 'RESEND CODE'}
          </button>
        </div>
      </AuthPage>
    </>
  );
};
