import React from 'react';
import { Header } from '../header';
import { Footer } from '../footer';
import styles from './index.module.css';

export const Page = ({ children }) => (
  <div className={styles.page}>
    <div className={styles.pageContent}>
      <Header />
      <main className={styles.mainContent}>
        {children}
      </main>
      <Footer />
    </div>
  </div>
);
