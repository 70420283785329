/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useContext } from 'react';
import { Close, ExpandLess, ExpandMore, Edit, Check, ErrorOutline, Adjust } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { AppContext } from 'Context';
import { EditModal } from '../../Pages/private/videos/components/upload-modal';
import styles from './index.module.css';

const ProgressBox = () => {
  const [isMinimize, setSize] = useState(false);
  const [openEditVideoModal, setEditVideoModal] = useState(false);
  const { uploadingVideoDetails, setProgressBox, setUploadingVideoDetails, setVideoCount, errorMsg } = useContext(AppContext);
  const [selectedId, selectId] = useState(undefined);
  const [contentDetails, setContentDetails] = useState({
    title: uploadingVideoDetails?.name,
    id: uploadingVideoDetails?.id,
    uniqueIdentifier: uploadingVideoDetails?.uniqueIdentifier
  });
  const progressBoxCloseHandler = () => {
    const tempArray = uploadingVideoDetails?.map((video) => ({ ...video, boxVisibility: 0 }));
    setUploadingVideoDetails(tempArray);
    setProgressBox(false);
    setVideoCount(0);
  };

  const hideModal = () => {
    setEditVideoModal(false);
  };
  const editHandler = (uniqueIdentifier) => {
    setContentDetails(uploadingVideoDetails?.filter((vid, indx) => vid?.fileId === uniqueIdentifier)[0]);
    setEditVideoModal(true);
    selectId(uniqueIdentifier);
  };

  return (
    <>
      <div className={styles.progressBoxWrap}>
        <EditModal isVisible={openEditVideoModal} onHide={hideModal} contentDetails={contentDetails} setContentDetails={setContentDetails} uniqueId={selectedId} uploadType="video" />
        <div className={styles.header}>
          <div className={styles.headerText}>Uploads video</div>
          {
            errorMsg ? (
              <div className={styles.errorMsg}>{errorMsg}</div>
            ) : null
          }
          <div className={styles.headerAction}>
            {
              isMinimize ? <ExpandLess onClick={() => setSize(!isMinimize)} className={styles.icon} /> : <ExpandMore onClick={() => setSize(!isMinimize)} className={styles.icon} />
            }
            {
              uploadingVideoDetails.some((el) => el.status === 'Uploading') ? (
                <div className={styles.icon} />
              ) : (
                <Close className={styles.icon} onClick={() => progressBoxCloseHandler()} />
              )
            }
          </div>
        </div>
        {
          !isMinimize ? (
            <div id="uplist">
              {
                uploadingVideoDetails?.map((video) => (
                  video?.boxVisibility ? (
                    <div className={styles.fileItem}>
                      {/* <Edit className={styles.editIcon} onClick={() => { editHandler(video?.fileId); }} /> */}

                      {/* <Adjust className={styles.editIcon} /> */}
                      <div className={styles.fileName} id={video?.fileId}>{video?.name}</div>
                      <div className={styles.uploadProgress}>{`${video?.progress} uploaded`}</div>
                      {
                        video?.status === 'Completed' ? (
                          <Check className={styles.statusIcon} />
                        ) : video?.status === 'Error' ? (
                          <ErrorOutline className={styles.errorIcon} />
                        ) : (
                          <CircularProgress size={20} />
                        )
                      }
                    </div>
                  ) : null
                ))
              }
            </div>
          ) : null
        }
      </div>
    </>
  );
};
export default ProgressBox;
