import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import uploadImg from '../assets/img/upload.svg';
import viewImg from '../assets/img/view.svg';
import preserveImg from '../assets/img/preserve.svg';

function HowToUse() {
  return (
    <div className="section">
      <div className="container">
        <h2 data-aos="fade-down" className="title title-margin">
          How to use Projector
        </h2>

        <div className="how-to-use">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            className="col"
          >
            <div>
              <img src={uploadImg} alt="" />
              <div>
                <div>Upload</div>
                <p>using projectorstream.com</p>
              </div>
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-anchor-placement="bottom-bottom"
            className="col"
          >
            <div>
              <img src={viewImg} alt="" />
              <div>
                <div>View and Share</div>
                <p>on your favorite device</p>
              </div>
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-anchor-placement="bottom-bottom"
            className="col"
          >
            <div>
              <img src={preserveImg} alt="" />
              <div>
                <div>Preserve Forever</div>
                <p>name a successor</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowToUse;
