import { axios, endpoints } from 'Api';

export const getAllActivePlans = async () => axios.post(endpoints.getAllActivePlans);

export const getUserDetailsFromCode = async ({ code }) => axios.post(endpoints.getUserDetFrmCode, { code });

export const getUserContents = async ({ content_limit, content_start, limit, start, user_id }) => axios.post(endpoints.getUserContents, { content_limit, content_start, limit, start, user_id });

export const addVideoDraftContent = async (body) => axios.post(endpoints.addVideoDraftContent, body);

export const addVideo = async (body) => axios.post(endpoints.addVideo, body);

export const addS3VideoDraftContent = async (body) => axios.post(endpoints.addS3VideoContent, body);
