/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, useContext, useEffect, useRef } from 'react';
import logo from 'Assets/logo_round.png';
import { AppContext, AuthContext } from 'Context';
import AddDotLoader from 'Pages/private/videos/AddDotLoader';
import { endpoints, baseUrl } from 'Api';
import axios from 'axios';
import styles from './index.module.css';

function PriceSectionLayout({
  planName,
  planPrice,
  planId,
  allPlans,
  intervalInDays,
  navigation,
  promoCode,
  promoErrMsg,
  setPromoErrMsg,
  setPromoCodeNew,
  promoCodeNew,
  promoLoading,
  setPromoLoading
}) {
  const { affiliateCode, setAffiliatedCode, setIsTrail, isTrail, trialVal, apiResponse } = useContext(AppContext);
  const debounceRef = useRef(null); // Ref for debounce timer
  const axiosCancelToken = useRef(null);
  const [manager, setManager] = useState(null);
  const [active, setActive] = useState(false);
  const [promoActive, setPromoActive] = useState(false);
  const [promoResponse, setPromoResponse] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [calculatedDiscount, setCalculatedDiscount] = useState('');

  const { getToken } = useContext(AuthContext);

  const calculateDiscountedPrice = (price) => {
    const priceInDollar = price / 100;
    const discountedPrice = planPrice?.amount ? (planPrice?.amount - priceInDollar) : (planPrice - priceInDollar);
    const formattedDiscountedPrice = discountedPrice.toFixed(2);
    setCalculatedDiscount(priceInDollar?.toFixed(2));
    setDiscountedPrice(formattedDiscountedPrice);
  };

  const handleAffiliatedCodeChange = async (event) => {
    const typedData = event.target.value;
    setAffiliatedCode(typedData);
    setManager('');
    if (event.target.value?.length > 4) {
      setActive(true);
      try {
        axios({
          url: baseUrl + endpoints.getAffiliateName,
          method: 'POST',
          data: { code: typedData, token: getToken() },
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((response) => {
          setManager(`${response.data?.data?.firstname} ${response.data?.data?.lastname}`);
        });
      } catch (error) {
        console.error('Error:', error);
      }
    } else if (event.target.value < 5) {
      setActive(false);
    }
  };
  const handlePromoCodeChange = (e) => {
    const newPromoCode = e.target.value?.toUpperCase();
    setPromoCodeNew(newPromoCode);

    if (newPromoCode?.length >= 6) {
      setPromoActive(true);
      setPromoLoading(true);
    }

    // Clear previous debounce timer
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    // Cancel any ongoing API request
    if (axiosCancelToken.current) {
      axiosCancelToken.current.cancel('Operation canceled due to new request.');
    }

    // Create a new CancelToken
    axiosCancelToken.current = axios.CancelToken.source();

    // Debounce logic for API call
    debounceRef.current = setTimeout(async () => {
      try {
        const response = await axios({
          url: `${baseUrl}/getPromoAmount`,
          method: 'POST',
          data: { token: getToken(), promo_code: newPromoCode, plan_id: planId },
          headers: { 'Content-Type': 'application/json' },
          cancelToken: axiosCancelToken.current.token, // Attach CancelToken
        });

        setPromoResponse(response.data);
        calculateDiscountedPrice(response.data?.data?.amount);
        setPromoErrMsg('');
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          setPromoErrMsg(error?.response?.data?.message || 'Something went wrong');
          setPromoResponse(null);
          setDiscountedPrice('');
        }
      } finally {
        setPromoLoading(false);
      }
    }, 500); // Debounce time: 1 second
  };

  useEffect(() => {
    setAffiliatedCode(localStorage.getItem('code'));
    return () => {
      setIsTrail('0'); // This will run when the component unmounts
    };
  }, []);

  return (
    <>
      <div className={styles.mainDiv}>
        {isTrail === '1'
          && (
            <p className={styles.title}>
              Projector Annual Subscription
              <br />
              You will be charged in
              {' '}
              {allPlans?.total_trial_days}
              {' '}
              {allPlans?.total_trial_days === '1' ? 'day' : 'days'}
            </p>
          )}
        <p className={styles.subTitle}>{`$${planPrice?.amount ? planPrice?.amount : planPrice}/year`}</p>
        <div className={styles.logoDiv}>
          <div className={styles.logoDivImg}>
            <img width="50px" src={logo} />
          </div>
          <div className={styles.projectorTitleDiv}>
            <p className={styles.projectorTitle}>Projector</p>
            <p className={styles.projectorSubTitle}>Billed Annually</p>
            <p className={styles.subTotal}>Subtotal</p>
          </div>
          <div className={styles.projectorPriceDiv}>
            <p className={styles.price}>{`$${planPrice?.amount ? planPrice?.amount?.toFixed(2) : planPrice?.toFixed(2)}`}</p>
            <p style={{ marginTop: '23px' }} className={styles.price}>{`$${planPrice?.amount ? planPrice?.amount?.toFixed(2) : planPrice?.toFixed(2)}`}</p>
          </div>
        </div>
        {/* <div style={{ display: 'flex' }}>
        </div> */}
        {/* {navigation !== '/settings/manage-users' && ( */}
        {localStorage.getItem('code') ? (
          <p>{localStorage.getItem('code')}</p>
        ) : (
          <>
            <input
              className={styles.affiliatedCode}
              value={affiliateCode}
              onChange={handleAffiliatedCodeChange}
              type="number"
              disabled={promoCode}
              placeholder={promoCode || 'Add Affiliate code'}
            />
            {/* {isTrail !== '1' && ( */}
            {!apiResponse?.promo
              ? (
<input
                className={styles.affiliatedCode}
                value={promoCodeNew}
                onChange={handlePromoCodeChange}
                placeholder="Add Promo code"
                maxLength={14}
/>
)
              : (
<div className={styles.affiliatedCode} style={{ background: '#EFEFEF4D' }}>
              {apiResponse?.promo}
</div>
)}
            {/* )} */}
          </>
        )}
        {promoCodeNew?.length > 0 && promoCodeNew?.length < 6 && (
          <p className={styles.promoCode} style={{ color: '#bb2124', paddingBottom: '0' }}>
            *Promo code requires at least 6 characters!
          </p>
        )}
        {promoActive && (
          promoLoading ? (
            <p className={styles.promoCode}><AddDotLoader text="Loading" /></p>
          ) : !promoErrMsg ? (
            <p className={styles.promoCode} style={{ color: '#22bb33', paddingBottom: '0' }}>{`${promoResponse?.data?.name} Applied!`}</p>
          ) : (
            <p className={styles.promoCode} style={{ color: '#bb2124', paddingTop: '12px', paddingBottom: '0' }}>
{promoErrMsg}
            </p>
          )
        )}
        {manager
          ? (
            <p className={styles.mangerx}>
              Manger Name:
              {' '}
              {manager}
            </p>
          )
          : active === true
            ? (<p className={styles.invalid}>Invalid Code</p>)
            : null}
        {isTrail === '1' && (
          <div>
          <div className={styles.regularDate}>
            <p className={styles.projectorTitle} style={{ marginBottom: '0' }}>Regular Rate </p>
            <p className={styles.totalPrice} style={{ textDecoration: 'line-through', marginBottom: '0' }}>{`$${planPrice?.amount ? planPrice?.amount?.toFixed(2) : planPrice?.toFixed(2)}`}</p>
          </div>
{discountedPrice && (
          <div className={styles.regularDate}>
            <p className={styles.promoTitle}>
{' '}
{promoResponse?.data?.name}
            </p>
            <p className={styles.promoPrice}>
{' '}
-
{`$${calculatedDiscount}`}
            </p>
          </div>
)}
          </div>
        )}
        {(isTrail !== '1' && discountedPrice !== '') && (
          <div>
          <div className={styles.regularDate}>
            <p className={styles.projectorTitle}>Regular Rate </p>
            <p className={styles.totalPrice} style={{ textDecoration: discountedPrice !== '' && 'line-through', marginBottom: '0' }}>{`$${planPrice?.amount ? planPrice?.amount?.toFixed(2) : planPrice?.toFixed(2)}`}</p>
          </div>
{discountedPrice && (
  <div className={styles.regularDate}>
    <p className={styles.promoTitle}>
{' '}
{promoResponse?.data?.name}
    </p>
    <p className={styles.promoPrice}>
{' '}
-
{`$${calculatedDiscount}`}
    </p>
  </div>
)}
          </div>
        )}
        <div className={styles.total}>
          <p style={{ marginTop: '10px' }} className={styles.projectorTitle}>Total Due Today </p>
          {isTrail === '1' || trialVal ? (<p style={{ marginTop: '10px' }} className={styles.totalPrice}>$0.00</p>)
            : (
              <p style={{ marginTop: '10px' }} className={styles.totalPrice}>{`$${discountedPrice !== '' ? discountedPrice : planPrice?.amount?.toFixed(2) || planPrice?.toFixed(2)}`}</p>
            )}
        </div>
        {trialVal && (
        <div className={styles.total}>
          <p className={styles.projectorTitle}>
          You will be charged in
{' '}
{allPlans?.total_trial_days}
{' '}
{allPlans?.total_trial_days === '1' ? 'day' : 'days'}
          </p>
          <p className={styles.totalPrice}>{`$${discountedPrice !== '' ? discountedPrice : planPrice?.amount?.toFixed(2) || planPrice?.toFixed(2)}`}</p>
        </div>
        )}
      </div>
    </>
  );
}

export default PriceSectionLayout;
