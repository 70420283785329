import React, { useState, useContext } from 'react';
import cn from 'classnames';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { Link } from 'react-router-dom';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import moment from 'moment';
import { ReactComponent as PlayIcon } from 'Assets/play.svg';
import { Header } from './Header';
import styles from '../../index.module.css';

export const VideoBanner = ({ layoutData, layoutType, profileDetails, revealProfileMenu, storageFullCondition, setStorageFull, revealUploadModal }) => {
  // eslint-disable-next-line no-script-url
  window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const { setAppSnackbar } = useContext(AppContext);
  const isTrueValue = true;
  const isFalseValue = false;
  const [updatingInWatchlist, setWatchlistUpdationStatus] = useState(false);
  const [isVideoInWatchlist, setVideoInWatchlist] = useState(false);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const { mutate: updateInWatchlist } = useSWR([endpoints.updateWatchList, layoutData?.videoID, isVideoInWatchlist], {
    fetcher: (url, id, shouldDelete) => dataFetcher(url, {
      video_id: id,
      action: shouldDelete ? 'delete' : 'add',
    }),
    onSuccess: ({ success }) => {
      if (success) {
        setVideoInWatchlist(!isVideoInWatchlist);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }

      setWatchlistUpdationStatus(false);
    },
    onError: () => {
      setWatchlistUpdationStatus(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });
  return (
    <div className={styles.videoBannerContainer}>
      <Carousel
        swipeable={isFalseValue}
        draggable={isFalseValue}
        responsive={responsive}
        showDots={isTrueValue}
        ssr={isTrueValue} // means to render carousel on server-side.
        infinite={isTrueValue}
        autoPlaySpeed={2000}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container-landing"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        autoPlay={isTrueValue}
        arrows={isFalseValue}
      >
        {
          layoutData?.map(({ thumbnails, videoID, publishDate, category, subCategory, title, pausedAt }) => (
            <>
              <img
                alt="Projector"
                className={styles.heroImage}
                src={thumbnails}
              />
              <div className={styles.videoTitle}>{title}</div>
              <div
                className={styles.videoDetails}
                style={{
                  background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, #0A0B0F 70%)'
                }}
              >
                <div className={styles.videoActions}>
                  <Link to={`${Pathname.getPlayerPath(videoID, 'play')}`} className={styles.videoPlayButton}>
                    <PlayIcon className={styles.videoPlayIcon} />
                    <>Play</>
                  </Link>
                  {
                    pausedAt > 0 ? (
                      <Link
                        to={`${Pathname.getPlayerPath(videoID, 'resumeWatching')}`}
                        className={cn({ [styles.videoPlayButton]: true, [styles.alignButton]: true })}
                      >
                        <>Resume Watching</>
                      </Link>
                    ) : null
                  }
                  <button
                    className={styles.videoAddButton}
                    disabled={updatingInWatchlist}
                    onClick={updateInWatchlist}
                  >
                    {isVideoInWatchlist ? '✓' : '+'}
                  </button>
                </div>
                <div className={styles.videoInfoWrap}>
                  <div className={styles.videoInfo}>
                    { publishDate && (
                      <>
                        {moment(publishDate).format('YYYY')}
                        <div className={styles.videoInfoSeparator} />
                      </>
                    )}
                    <>{category}</>
                    <div className={styles.videoInfoSeparator} />
                    <>{subCategory}</>
                  </div>
                </div>
              </div>
            </>
          ))
        }
      </Carousel>
      <Header
        profileDetails={profileDetails}
        revealProfileMenu={revealProfileMenu}
        storageFullCondition={storageFullCondition}
        setStorageFull={setStorageFull}
        revealUploadModal={revealUploadModal}
      />
    </div>
  );
};
