import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'Context';
import useSWR from 'swr';
import sendEmail from 'Components/sendMail';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { ShareScreen } from '../../../../../Components';

export default function AddSuccessorModal({ secessionAccount, setSecessionAccount, actionAfterAddSuccessor }) {
  const { userDetails, setAppSnackbar, langText } = useContext(AppContext);
  const commonText = langText?.common;
  const successorText = langText?.viewersSideBar?.successor;
  const [data, setData] = useState([{ email: '', firstName: '', laseName: '' }]);
  const [successorUsers, setSuccessorUsers] = useState(undefined);

  const { isValidating: addingSuccessors, mutate: addSuccessor } = useSWR([
    endpoints.addSuccessor, successorUsers], {
    fetcher: (url, users) => dataFetcher(url, { user: users }),
    onSuccess: ({ success }) => {
      if (success) {
        setAppSnackbar({
          isVisible: true,
          message: 'Successors added successfully',
        });
        if (actionAfterAddSuccessor) {
          actionAfterAddSuccessor();
        }
      } else {
        setAppSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  useEffect(() => {
    if (successorUsers) {
      addSuccessor();
    }
  }, [successorUsers]);

  const addSecessionAccount = async ([
    onHide, setActionStatus, setOperationStatus, setSelectedUsers, setUserList, users, , ,
  ]) => {
    setSecessionAccount(false);
    setSelectedUsers([]);
    setSuccessorUsers(users.map((userData) => userData.id));
    await sendEmail(data, userDetails.email);
  };

  const hideSearchSuccessorModal = () => {
    setSecessionAccount(false);
  };

  return (
    <div>
      <ShareScreen
        actionButtonLoadingText={`${successorText?.addingSuccessor || 'Adding Successors'}...`}
        actionButtonText={successorText?.addButton || 'Add Successors'}
        apiEndpoint={endpoints.searchUserByEmailFromFrendList}
        errorText={commonText?.somethingWentWrong || 'Oops! Something went wrong'}
        inputPlaceholder={successorText?.successorCreatePlaceholder || 'Search Friends'}
        isVisible={secessionAccount}
        multipleSelect
        notes={successorText?.enterEmail || 'Enter an email to search'}
        noUsersFoundText={successorText?.noFriend || 'No friends found with this email'}
        onContinue={addSecessionAccount}
        onHide={hideSearchSuccessorModal}
        successText={successorText?.successMsg || 'Successfully added successor'}
        title={successorText?.searchSuccessor || 'Search Successor'}
      />

    </div>
  );
}
