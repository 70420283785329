/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-line jsx-a11y/no-static-element-interactions
import React, { useState, useEffect, useContext } from 'react';
import { NotificationsActive, Notifications, ArrowBackIos, Mail, CloudUploadSharp, MovieCreationSharp, NoEncryptionTwoTone } from '@material-ui/icons';
import {
  Badge, IconButton, Menu, MenuItem, Grid,
  Typography, Divider, Button, Collapse, Zoom, CircularProgress, makeStyles, Box, Tooltip
} from '@material-ui/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from 'Context';
import Completed from 'Assets/success.png';
import Cancel from 'Assets/cancel.png';
import Delete from 'Assets/delete.png';
import useSWR from 'swr';
import { dataFetcher, endpoints, axios } from 'Api';
import { Pathname } from 'Routes';
import { Snackbar } from '../../snackbar';
import styles from '../index.module.css';
import UserRequest from './UserRequest';

const transitionTimeout = 300;

const useStyles = makeStyles({
  customWidth: {
    '& .MuiPaper-root': {
      minWidth: '403px',
      '&::-webkit-scrollbar': {
        width: '7px'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '25px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }
  }
});

const defaultProps = {
  color: 'secondary',
  children: <Notifications
    fontSize="large"
  />
};
export default function VideoUploadComponent() {
  const classes = useStyles();
  const { setAppSnackbar, langText } = useContext(AppContext);
  const [notificationsMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const notificationText = langText?.header;
  const isNotificationMenuOpen = Boolean(notificationsMoreAnchorEl);
  const [snackbar, setSnackbar] = useState({ isVisible: false, message: '' });
  const [videos, setVideos] = useState(undefined);
  const [mouseHover, setMouseHover] = useState(undefined);
  const [selectedVideo, selectVideo] = useState(undefined);

  const handleNotificationMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleNotificationMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const { isValidating: cancellingVideoConversion, mutate: cancelVideoConversion } = useSWR([
    endpoints.cancelVideoConversion, selectedVideo
  ], {
    fetcher: (url, content_id) => dataFetcher(url, { content_id }),
    onSuccess: ({ success, message, videos }) => {
      if (success) {
        setVideos(videos.filter((video) => video.video_id !== selectedVideo));
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const { isValidating: gettingConvertionStatus, mutate: getConversionStatus } = useSWR([
    endpoints.getVideoStatus,
  ], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, message, videos }) => {
      if (success) {
        setVideos(videos);
      } else {
        setVideos(undefined);
      }
    },
    onError: () => {
      setVideos(undefined);
    },
  });

  const { isValidating: clearingVideoNotification, mutate: clearVideoNotification } = useSWR([
    endpoints.clearVideos
  ], {
    fetcher: (url) => dataFetcher(url, { clearAll: '1' }),
    onSuccess: ({ success, message, videos }) => {
      if (success) {
        setAppSnackbar({ isVisible: true, message: 'Successfully clear notifications' });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  useEffect(() => {
    if (selectedVideo) {
      cancelVideoConversion();
    }
  }, [selectedVideo]);

  useEffect(() => {
    let interval;
    if (isNotificationMenuOpen) {
      getConversionStatus();
      interval = setInterval(() => {
        getConversionStatus();
      }, 5000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isNotificationMenuOpen]);

  const renderNotificationMenu = (
    <Menu
      anchorEl={notificationsMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isNotificationMenuOpen}
      onClose={handleNotificationMenuClose}
      className={classes.customWidth}
      PopoverClasses={{ paper: styles.notificationAnimation }}
    >
      [
      <div className={styles.notificationDropDown}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography className={styles.notfHeading} variant="h2" color="primary">
              Videos upload in progress
            </Typography>
          </Grid>
          <Grid item xs={3} className={styles.clearAllNotification}>
            <Button
              className={styles.clearAllIcon}
              onClick={() => { clearVideoNotification(); }}
              size="large"
              disabled={clearingVideoNotification || gettingConvertionStatus}
            >
              {notificationText?.clearAll || 'Clear All'}
            </Button>
          </Grid>
        </Grid>
        <hr className={styles.dividerAlign} />
        {
           clearingVideoNotification || gettingConvertionStatus ? (
             <div className={styles.loaderDiv}>
               <CircularProgress />
             </div>
           ) : (
             <div className={styles.notificationContainer}>
               {
            !videos?.length ? (
              <div className={styles.loaderDiv}>
                No video uploading
              </div>
            ) : (
              <div className={styles.divSpace}>
                {videos?.map(({ id, title, video_id, progress, duration, current_time, queue_id, status }) => (
                  <>
                    <Grid container spacing={1}>
                      <Grid item xs={1}>
                        <MovieCreationSharp className={styles.videoIcon} />
                      </Grid>
                      <Grid item xs={6} className={styles.titleforVideoUpload}>
                        {title}
                      </Grid>
                      <Grid item xs={4} className={styles.remainingtimeforVideoUpload}>
                        {/* { duration === -1 ? 'Not started' : `${(duration - current_time).toFixed(1)} sec left`} */}
                        {status}
                      </Grid>
                      <Grid item xs={1} className={styles.clearAllIconAlign}>
                        {
                          progress === 100 ? (
                            status === 'Error' ? (<img className={styles.errorIcon} src={Delete} alt="projector logo" />)
                              : <img className={styles.successImage} src={Completed} alt="projector logo" />
                          ) : (
                            mouseHover === queue_id && duration !== -1 ? (
                              <CircularProgress
                                // onMouseEnter={() => setMouseHover(queue_id)}
                                // onMouseLeave={() => setMouseHover(undefined)}
                                size={30}
                                // variant="in-determinant"
                                // value={parseInt(progress, 10)}
                                className={styles.circularProgressBar}
                              />
                              // <Tooltip title="Cancel">
                              //   <div onClick={() => selectVideo(video_id)}>
                              //     {/* <div onClick={() => selectVideo(video_id)}> */}
                              //     <img onMouseLeave={() => setMouseHover(undefined)} className={styles.cancelIcon} src={Cancel} alt="projector logo" />
                              //   </div>
                              // </Tooltip>
                            ) : (
                              <CircularProgress
                                onMouseEnter={() => setMouseHover(queue_id)}
                                onMouseLeave={() => setMouseHover(undefined)}
                                size={30}
                                variant="determinate"
                                value={progress}
                                className={styles.logoImage}
                              />
                            )
                          )
                        }
                      </Grid>
                    </Grid>
                    <hr className={styles.notfDivider} />
                  </>
                ))}
              </div>
            )
          }
             </div>
           )
        }

      </div>
      ]
    </Menu>
  );

  return (
    <>
      <IconButton
        className={styles.uploadButton}
        aria-label="show more"
        aria-haspopup="true"
        onClick={handleNotificationMenuOpen}
        color="inherit"
      >
        {' '}
        <CloudUploadSharp
          className={styles.videoUploadIcon}
          fontSize="large"
        />
      </IconButton>
      {renderNotificationMenu}
      <Snackbar {...snackbar} onClose={() => { setSnackbar({ ...snackbar, isVisible: false }); }} />
    </>
  );
}
