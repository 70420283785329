/* eslint-disable max-len */
import React from 'react';
import './SubscriberAgreement.css';

export default function SubscriberAgreement({ isDark }) {
  return (
    <div className="SAgreement">
      {/* eslint-disable-next-line */}
      <style jsx>
        {`.SAgreement
        {
          color :${isDark ? 'white' : 'black'};
          padding-top :${isDark ? '5%' : null};
          background:transparent
        }`}
      </style>
      {/* <p className="c15">

        <span className="c10">
          Subscriber Agreement
        </span>

      </p> */}

      <p className="c8">

        <span className="c12 c11">
          PLEASE READ THIS SUBSCRIBER AGREEMENT (&quot;SUBSCRIBER AGREEMENT&quot;, OR &quot;AGREEMENT&quot;) CAREFULLY BEFORE USING THE PROJECTOR SERVICE.
        </span>

      </p>

      <p className="c8">

        <span className="c12 c11">
          THIS AGREEMENT WILL GOVERN YOUR USE OF THE PROJECTOR SERVICES.
        </span>

      </p>
      <ol className="c23 lst-kix_list_2-0 start" start="1">
        <li className="c8 c21 li-bullet-0">

          <span className="c12 c11">
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>

          <span className="c10">
            Subscriber
          </span>

        </li>
      </ol>

      <p className="c8">

        <span className="c11 c12">
          You agree to the Subscriber Agreement during the registration process and ratify your agreement when you use any aspect of Projector&rsquo;s Service. If you do not agree to the Subscriber Agreement, you may not use the Projector Service. We may amend this Agreement. &nbsp;Any such amendment will be effective thirty (30) days following either our dispatch of a notice to you or our posting of the amendment on the Projector Service Website. If you do not agree to any changes to the Subscriber Agreement, then you must discontinue using the Projector Service. &nbsp;
        </span>

      </p>

      <p className="c8">

        <span className="c11">
          2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>

        <span className="c22">
          Subscription Terms
        </span>

        <span className="c12 c11">
          &nbsp;
        </span>

      </p>

      <p className="c19">

        <span className="c11">
          a. Subscription and Auto-Renewal. Your subscription to the Projector Paid Service includes enrollment into an ongoing/recurring payment plan. Your subscription(s) will automatically renew at the end of the disclosed billing period, unless cancelled in accordance with the instructions for cancellation below. Payment will be charged to your chosen payment method at confirmation of purchase and at the start of every new billing period, unless cancelled. We reserve the right to change our pricing. In the event of a price change, we will attempt to notify you thirty (30) days in advance of the change by sending an email to the email address you have registered for your account. If you do not wish to accept a price change, you may cancel your subscription in accordance with the instructions included in that email and below. If you do not cancel your subscription after the price change takes effect and prior to the start of your new subscription period, your subscription will be renewed at the price in effect at the time of the renewal, without any additional action by you, and you authorize us to charge your payment method for these amounts.
        </span>

        <span className="c4">
          the payment method on file on the first day of each billing period for the relevant subscription, and if the payment method on file becomes invalid due to an expired credit card or other similar reason and we are unable to charge you on the next billing period, Projector reserves the right to immediately revoke your access to any Paid Service you have ordered until you update your payment method. If you fail to update your payment method within a reasonable amount of time, Projector may cancel your subscription. &nbsp;
        </span>

      </p>

      <p className="c8 c20">

        <span className="c12 c11" />

      </p>

      <p className="c1">

        <span className="c4">
          b. &nbsp;Free Trials:&nbsp;Projector may offer free trials to new Paid Service subscribers. If you purchase a subscription to a Paid Service that includes a free trial, you will receive free access to such Paid Service for the duration of the free trial period. At the end of the applicable free trial period, you will be charged the price of the subscription and will continue to be charged until you cancel your subscription. To avoid any charges, you must cancel before the end of the free trial period.
        </span>

      </p>

      <p className="c8">

        <span className="c12 c11">
          c. &nbsp;Cancellation and Refund Policy. You can cancel your subscription at any time before the end of the current billing period or free trial. Cancellation will take effect at the end of the current billing period or free trial. To cancel your Projector Service please email us at
        </span>

        <span className="c16 c11 c27">
          <a className="c13" href="mailto:support@projectorstream.com">support@projectorstream.com</a>
        </span>

        <span className="c12 c11">
          . &nbsp;Cancellation will only take effect at the end of your current billing period (or end of your free trial), and you will still be able to access Projector until then. We do not refund or credit for partially used billing periods.
        </span>

      </p>

      <p className="c6">

        <span className="c3">
          3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accessing Paid Services.
        </span>

      </p>

      <p className="c19">

        <span className="c4">
          Device and Usage Requirements.&nbsp;Certain types of Paid Services may require you to use a device that meets the system and compatibility requirements for such Paid Service, which may change from time to time.
        </span>

      </p>

      <p className="c6">

        <span className="c3">
          4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;License
        </span>

      </p>

      <p className="c1">

        <span className="c4">
          Your use of the Paid Services must be only for personal, non-commercial use. You agree not to use any Paid Service, in whole or in part in connection with any public presentation even if no fee is charged (except where such use would not constitute a copyright infringement). You are receiving a non-exclusive license to access the Paid Service and all rights, title and interest in the Paid Services. &nbsp;If Projector reasonably determines that you violated any of the terms and conditions of the Paid Service Terms, your rights under this Section 4 will immediately terminate and Projector may terminate your access to the Paid Service and/or your Projector &nbsp;account without notice and without refund to you.
        </span>

      </p>

      <p className="c6">

        <span className="c3">
          5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Restrictions
        </span>

      </p>

      <p className="c1">

        <span className="c4">
          When you use the Paid Service, you may not (or attempt to):
        </span>

      </p>
      <ul className="c23 lst-kix_list_3-0 start">
        <li className="c0 li-bullet-0">

          <span className="c4">
            use the Paid Service in an illegal manner or for an illegal purpose;
          </span>

        </li>
        <li className="c0 li-bullet-0">

          <span className="c4">
            share your Projector account password with someone else to allow them to access any Paid Service that such person did not order;
          </span>

        </li>
        <li className="c0 li-bullet-0">

          <span className="c4">
            copy, sell, rent, or sublicense the Paid Services to any third party;
          </span>

        </li>
        <li className="c0 li-bullet-0">

          <span className="c4">
            circumvent, reverse-engineer, modify, disable, or otherwise tamper with any security technology that Projector uses to protect the Paid Service or encourage or help anyone else to do so;
          </span>

        </li>
        <li className="c0 li-bullet-0">

          <span className="c4">
            access the Paid Service other than by means authorized by Projector; or
          </span>

        </li>
      </ul>

      <p className="c6">

        <span className="c3">
          6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Changes
        </span>

      </p>

      <p className="c1">

        <span className="c4">
          Changes or Discontinuation of Paid Services.&nbsp;Projector reserves the right to change the availability of Paid Services. In addition, we reserve the right to modify, suspend, or discontinue any Paid Service with or without notice to you and we will not be liable to you or any third party for any such modifications, suspension, or termination. However, this will not affect your ability to request a refund as described above.
        </span>

      </p>

      <p className="c1">

        <span className="c4">
          Removal or Unavailability of Content.&nbsp;In certain cases, content available within a Paid Service may become unavailable due to restrictions from our partners who license us such content or other legal or policy reasons. Projector will have no liability to you for any such unavailability.
        </span>

      </p>

      <p className="c19">

        <span className="c4">
          Changes to the Paid Service Terms.&nbsp;We may also change these Paid Service Terms from time to time. If the Paid Service Terms change, you will have the opportunity to review the new terms when you next purchase any Paid Service. By completing the purchase, you signify your agreement to the new Paid Service Terms, and further, that they will apply to your use of the Service as a whole (including any Paid Service you have purchased in the past) and all subsequent purchases (until the Paid Service Terms change again). If you refuse to accept the updated Paid Service Terms then you may not buy any additional Paid Services and the latest version of the Paid Service Terms that you accepted will continue to apply to your use of previously purchased Paid Services.
        </span>

      </p>

      <p className="c17">

        <span className="c4" />

      </p>

      <p className="c6">

        <span className="c3">
          7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Communications
        </span>

      </p>

      <p className="c19">

        <span className="c9">
          Communications.&nbsp;By using the Paid Services, you consent to receiving communications from us including marketing communications such as newsletters about Projector features and content, special offers, promotional announcements, and customer surveys, to your registered email address or via other methods. If you no longer want to receive such communications, simply email us at
        </span>

        <span className="c11 c16">
          <a className="c13" href="mailto:support@projectorstream.com">support@projectorstream.com</a>
        </span>

        <span className="c4">
          .
        </span>

      </p>

      <p className="c17">

        <span className="c4" />

      </p>

      <p className="c6">

        <span className="c3">
          8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Other Terms
        </span>

      </p>
      <ol className="c23 lst-kix_list_4-0 start" start="1">
        <li className="c0 li-bullet-0">

          <span className="c4">
            You agree to use the Paid Services at your own risk and, subject to applicable laws,
          </span>

        </li>
        <li className="c0 li-bullet-0">

          <span className="c9">
            The Paid Services are being provided by Projector LLC. &nbsp;Complaints and/or feedback about your Service experience may be sent to the to our customer support team at
          </span>

          <span className="c16 c11">
            <a className="c13" href="mailto:support@projectorstream.com">support@projectorstream.com</a>
          </span>

          <span className="c4">
            .
          </span>

        </li>
        <li className="c0 li-bullet-0">

          <span className="c4">
            You acknowledge and agree that you are responsible for paying all fees in a timely manner and for providing us with a valid credit card or other form of payment acceptable to Projector. &nbsp;IN ADDITION, YOU ACKNOWLEDGE THAT YOUR ONLINE ACCEPTANCES CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND PROJECTOR &nbsp;AND SIGNIFY YOUR INTENT TO BE BOUND TO SUCH ONLINE ACCEPTANCES.
          </span>

        </li>
        <li className="c0 li-bullet-0">

          <span className="c4">
            SUBJECT TO APPLICABLE LAWS, IN NO EVENT WILL PROJECTOR &nbsp;OR ITS AFFILIATES&#39; AGGREGATE LIABILITY FOR ANY AND ALL CAUSES OF ACTION BROUGHT BY YOU OR YOUR AGENTS ARISING FROM OR RELATED TO THE SERVICES EXCEED THE PAYMENTS ACTUALLY RECEIVED AND RETAINED BY PROJECTOR &nbsp;FROM YOU FOR THE PAID SERVICES.
          </span>

        </li>
        <li className="c2 li-bullet-0">

          <span className="c7">
            THE SERVICES AND SOFTWARE ARE PROVIDED &quot;AS IS.&quot; TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT AS EXPRESSLY STATED IN THE AGREEMENT, NEITHER CUSTOMER NOR PROJECTOR AND ITS AFFILIATES, SUPPLIERS, AND DISTRIBUTORS MAKE ANY WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, OR NON-INFRINGEMENT. CUSTOMER IS RESPONSIBLE FOR USING THE SERVICES OR SOFTWARE IN ACCORDANCE WITH THE TERMS SET FORTH HEREIN AND BACKING UP ANY STORED DATA ON THE SERVICES.
          </span>

        </li>
        <li className="c2 li-bullet-0">

          <span className="c11 c27 c28">
            Arbitration
          </span>

          <span className="c7">
            . Customer and Projector agree to resolve any claims relating to the Agreement or the Services through final and binding arbitration, except as set forth below. The&nbsp;
          </span>

          <span className="c5">
            <a className="c13" href="https://www.google.com/url?q=https://www.adr.org/&amp;sa=D&amp;source=editors&amp;ust=1616572073661000&amp;usg=AOvVaw2T1oLTStMy3hWeM3RSJ6QG">American Arbitration Association</a>
          </span>

          <span className="c7">
            &nbsp;(AAA) will administer the arbitration under its Commercial Arbitration Rules. The arbitration will be held in Cleveland, Ohio or any other location both parties agree to in writing.
          </span>

        </li>
        <li className="c0 li-bullet-0">

          <span className="c4">
            Unless not permitted by applicable law, you acknowledge and agree that that these Paid Service Terms, your transaction for the Paid Service, and your relationship with Projector &nbsp;under these Paid Service Terms shall be governed by the laws of the State of Ohio, &nbsp;U.S.A., without respect to its conflict of laws principles, and any claim or dispute that arises in whole or in part from your use of the Projector &nbsp;Website and the Paid Services will be decided exclusively by a court of competent jurisdiction located in Cuyahoga County, Ohio, U.S.A.
          </span>

        </li>
        <li className="c2 li-bullet-0">

          <span className="c7">
            The Agreement supersedes any prior agreements or understandings between the Parties, and constitutes the entire agreement between the Parties related to this subject matter.&nbsp;
          </span>

        </li>
        <li className="c2 li-bullet-0">

          <span className="c7">
            Unenforceable provisions will be modified to reflect the parties&#39; intention and only to the extent necessary to make them enforceable, and the remaining provisions of the Agreement will remain in full effect.
          </span>

        </li>
      </ol>

      <p className="c20 c24">

        <span className="c4" />

      </p>

      <p className="c20 c25">

        <span className="c12 c14" />

      </p>
    </div>

  );
}
