import React from 'react';
import cn from 'classnames';
import { Text } from '../../text';
import styles from '../index.module.css';

export const ContentBoxTitle = ({ title, description }) => (
  <div className={styles.contentBoxTitleContainer}>
    <Text.SectionTitle className={styles.contentBoxTitle}>{title}</Text.SectionTitle>
    {description && <p className={styles.contentBoxDescription}>{description}</p>}
  </div>
);

export const ContentBox = ({ children, className, flex }) => (
  <section className={cn({ [styles.contentBoxContainer]: true, [className]: className })}>
    <div className={cn({ [styles.contentBox]: true, [styles.flex]: flex })}>
      {children}
    </div>
  </section>
);

export const ContentBoxSection = ({ children, className, title, description }) => (
  <section className={cn({ [styles.contentBoxSection]: true, [className]: className })}>
    <ContentBoxTitle title={title} description={description} />
    <div className={styles.contentBoxSectionWrapper}>
      {children}
    </div>
  </section>
);
