import React, { useContext, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import useSWR from 'swr';
import { blue } from '@material-ui/core/colors';
import { dataFetcher, endpoints } from 'Api';
import { AppContext } from 'Context';
import { SettingsPage } from '../components/page';
import { DataView } from '../components/data-view';
import styles from './index.module.css';

const Switch = ({ isDisabled, isOn, onChange }) => (
  <div
    className={cn({ [styles.switch]: true })}
    onClick={() => {
      if (!isDisabled) {
        onChange(!isOn);
      }
    }}
    onKeyPress={() => { }}
    role="button"
    style={{
      backgroundColor: isOn ? blue[200] : 'rgba(0, 0, 0, 0.4)',
      opacity: isDisabled ? '0.7' : '1',
    }}
    tabIndex={0}
  >
    <div
      className={cn({ [styles.button]: true, [styles.isOn]: isOn })}
      style={{ backgroundColor: isOn ? blue[500] : '#ffffff' }}
    />
  </div>
);

const NSSwitch = ({ type }) => {
  const { setAppSnackbar, setUserDetails, userDetails } = useContext(AppContext);

  const [isOn, setOn] = useState(userDetails[type === 'email' ? 'emailNS' : 'subscriptionNS']);
  const [setting, updateSettingStatus] = useState(false);

  const { mutate: updateNS } = useSWR([
    endpoints.updateMyProfile, isOn ? '1' : '0', Math.random(), userDetails, type, isOn,
  ], {
    fetcher: (url, notificationIsOn) => dataFetcher(url, {
      [type === 'email' ? 'email_notification' : 'sub_notification']: notificationIsOn
    }),
    onSuccess: ({ success }) => {
      if (success) {
        setUserDetails({ ...userDetails, [type === 'email' ? 'emailNS' : 'subscriptionNS']: isOn });
        setAppSnackbar({ isVisible: true, message: 'Email settings updated' });
      } else {
        setOn(!isOn);
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
      updateSettingStatus(false);
    },
    onError: () => {
      setOn(!isOn);
      updateSettingStatus(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  useEffect(() => {
    if (userDetails[type === 'email' ? 'emailNS' : 'subscriptionNS'] !== isOn) {
      updateSettingStatus(true);
      updateNS();
    }
  }, [isOn]);

  return (
    <Switch
      isDisabled={setting}
      isOn={isOn}
      onChange={(on) => { setOn(on); }}
    />
  );
};

export const NotificationSettings = () => {
  const { langText } = useContext(AppContext);
  const notifications = langText?.settings?.notifications;

  return (
    <SettingsPage
      title={notifications?.title || 'Notifications'}
      description={notifications?.description || 'Edit Notification settings'}
    >
      <DataView
        data={[
          {
            labelTitle: notifications?.email || 'Email notifications',
            data: <NSSwitch type="email" />,
            isFlex: true,
            isToggle: true,
          },
          {
            labelTitle: notifications?.subscription || 'Subscription notification',
            data: <NSSwitch type="subscription" />,
            isFlex: true,
            isToggle: true,
          },
        ]}
      />
    </SettingsPage>
  );
};
