/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { debounce } from 'lodash';
import { Checkbox, Collapse, Fade } from '@material-ui/core';
import { encryptedKeyForCredentials, GOOGLE_RECAPTCHA_KEY } from 'Commons';
import { validate as isValidEmail } from 'email-validator';
import PasswordValidator from 'password-validator';
import { Pathname } from 'Routes';
import { Button } from 'Components';
// import ReCAPTCHA from 'react-google-recaptcha';
import { AppContext, AuthContext } from 'Context';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { isMobile } from 'react-device-detect';
import { Input } from '../components/input-field';
import { AuthPage } from '../components/page';
import { StepStatus } from '../components/step-status';
import styles from './index.module.css';
import authStyles from '../auth.module.css';

const passwordSchema = (new PasswordValidator()).is().min(12).has()
  .symbols(1)
  .has()
  .digits(1)
  .has()
  .uppercase(1)
  .lowercase(1);
const transitionTimeout = 300;

export const NewSignUP = () => {
  const { refferedBy } = useParams();

  const navigate = useNavigate();
  const { setAppSnackbar, plansPage, setPlansPage, langText } = useContext(AppContext);
  const signUpText = langText?.signInUp;
  const { preferredEmail, forceAutoSignin, setToken, setPreferredEmail, preferredUserDetails, setPreferredUserDetails } = useContext(AuthContext);
  const [email, setEmail] = useState(isValidEmail(preferredEmail) ? preferredEmail : '');
  const [password, setPassword] = useState('');
  const [recaptcha, setRecaptcha] = useState('');
  const [emailIsFixed, fixEmail] = useState(false);
  const [emailIsValid, setEmailValidity] = useState(false);
  const [accountExists, setAccountExistence] = useState(undefined);
  const [checkingAccountExistence, setAccountExistenceCheckingStatus] = useState(false);
  const [cancelToken, setCancelToken] = useState(undefined);
  const [userDetails, setUserDetails] = useState({
    lastName: '',
    firstName: ''
  });
  const location = useLocation();

  const [termsAreAgreed, setTermsAgreement] = useState(false);
  const [referenceCode, setReferenceCode] = useState(undefined);
  const hasUpperCase = /[A-Z]/.test(password);
  const passwordHealth = (
    password
      ? password.length < 12 || !hasUpperCase
        ? 'weak'
        : passwordSchema.validate(password)
          ? password.length >= 12
            ? 'strong'
            : 'medium'
          : 'weak'
      : ''
  );

  const [selectedReCAPTCHA, selectReCAPTCHA] = useState(false);
  const { isValidating: signingInUser, mutate: signInUser } = useSWR([endpoints.signin, email, password], {
    fetcher: (url, inputEmail, inputPassword) => dataFetcher(url, { email: inputEmail, password: inputPassword }),
    onSuccess: ({ success, data, message }) => {
      if (success) {
        setToken(data?.token);
        forceAutoSignin();
        setAppSnackbar({ message: 'Logged in successfully', isVisible: true });
        if (plansPage) {
          setPlansPage(false);
          navigate(Pathname.plans);
        } else if (!parseInt(data?.is_email_verified, 10)) {
          navigate(Pathname.authentication.verifyEmail);
        } else if (parseInt(data?.verify_mobile, 10) === 0) {
          navigate(Pathname.authentication.mobileVerification);
        }
        // setTimeout(() => {
        //   if (plansPage) {
        //     setPlansPage(false);
        //     navigate(Pathname.plans);
        //   } else {
        //     navigate(Pathname.gettingStarted);
        //   }
        // }, 500);
      } else {
        setAppSnackbar({ type: 'error', message: message || 'Incorrect password', isVisible: true });
      }
    },
    onError: (error) => {
      setAppSnackbar({ type: 'error', message: error?.response?.data?.message || 'Oops! Something went wrong', isVisible: true });
    },
  });

  const searchParams = new URLSearchParams(location.search);

  const { isValidating: signingUpUser, mutate: signUpUser } = useSWR([endpoints.signup, email, password, refferedBy, preferredUserDetails.firstName, preferredUserDetails.lastName, recaptcha, searchParams.get('reference_code')], {
    fetcher: (url, inputEmail, inputPassword, refferedLink, firstname, lastname, recaptcha, referenceCode) => dataFetcher(url, {
      email: inputEmail, password: inputPassword, refferedBy: refferedLink || null, firstname, lastname, recaptchaResponse: recaptcha, affiliateCode: referenceCode || null
    }),
    onSuccess: ({ success }) => {
      if (success) {
        // sessionStorage.setItem('emailForValidation_projectoruser', email);
        // sessionStorage.setItem('password_projectoruser', password);
        sessionStorage.setItem(encryptedKeyForCredentials.password, password);
        sessionStorage.setItem(encryptedKeyForCredentials.email, email);
        setPlansPage(false);
        // navigate(Pathname.authentication.signIn);
        // signInUser();
        navigate(Pathname.authentication.newmobileVerification);
        // navigate(Pathname.authentication.verifyEmail);
        // navigate(Pathname.authentication.verifyEmail);
      }
    },
    onError: () => { },
  });
  const handlePasswordInput = (event) => {
    const inputPassword = event.target.value;
    if (inputPassword.length > 30) {
      // alert('Password must be 30 characters or lesszzz.');
    } else {
      setPassword(inputPassword);
    }
  };
  const reCaptchaHandler = () => {
    window.grecaptcha.execute(GOOGLE_RECAPTCHA_KEY, { action: 'submit' }).then((value) => {
      if (value) {
        setRecaptcha(value);
        selectReCAPTCHA(true);
      } else {
        selectReCAPTCHA(false);
      }
    });
  };
  const { mutate: checkAccountExistence } = useSWR([endpoints.signupEmail, email, cancelToken?.token], {
    fetcher: (url, inputEmail, uniqueCancelToken) => dataFetcher(url, { email: inputEmail }, uniqueCancelToken),
    onSuccess: ({ success }) => {
      setAccountExistenceCheckingStatus(false);

      if (success) {
        setAccountExistence(false);
        reCaptchaHandler();
        if (isValidEmail(preferredEmail)) { fixEmail(true); }
      } else {
        setAccountExistence(true);
        setPreferredUserDetails({
          firstName: '',
          lastName: ''
        });
      }

      setPreferredEmail(undefined);
    },
    onError: () => {
      setAccountExistenceCheckingStatus(false);
    },
  });

  const emailInputHandler = debounce(({ target: { value: inputEmail } }) => {
    if (isValidEmail(inputEmail)) {
      setEmail(inputEmail);
      setEmailValidity(true);
    } else {
      setEmail('');
      setAccountExistence(undefined);
      setEmailValidity(false);
    }
  }, 750, { trailing: true });

  const inputHandlerForUserDetails = (key, value) => {
    const userDetailsCopy = { ...preferredUserDetails };
    userDetailsCopy[key] = value;
    setPreferredUserDetails(userDetailsCopy);
  };

  useEffect(() => {
    if (email) {
      setAccountExistenceCheckingStatus(true);
      checkAccountExistence();
    }
  }, [email, setAccountExistenceCheckingStatus, checkAccountExistence]);

  useEffect(() => {
    if (preferredEmail && isValidEmail(preferredEmail)) {
      setAccountExistenceCheckingStatus(true);
      setEmailValidity(true);
      checkAccountExistence();
    }
  }, [preferredEmail, setAccountExistenceCheckingStatus, checkAccountExistence]);

  useEffect(() => {
    if (location?.search) {
      const searchParams = new URLSearchParams(location.search);
      setReferenceCode(searchParams.get('reference_code'));
    }
  }, [location?.search]);
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
      if (isScriptExist && callback) callback();
    };
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_KEY}`);
  }, []);
  return (
    <AuthPage disableRedirect={!isMobile}>
      <Link to={Pathname.authentication.newsignIn} className={styles.signInLink}>{signUpText?.logIn || 'Log In'}</Link>
      {
        !isMobile
        && <div aria-hidden="true" onClick={() => { navigate(-1); }} className={styles.backLink}>{signUpText?.back || 'BACK'}</div>
      }
      {!emailIsFixed
        ? (
          <form onSubmit={(e) => { e.preventDefault(); }}>
            {/* <StepStatus currentStep="1" totalSteps="2" langText={signUpText} /> */}
            <Input
              className={styles.userInput}
              defaultValue={email}
              isDisabled={(email === preferredEmail) && checkingAccountExistence}
              isLoading={checkingAccountExistence}
              label="Account Creation"
              onInput={(e) => {
                e.persist();
                emailInputHandler(e);
                setAccountExistenceCheckingStatus(false);

                if (cancelToken) { cancelToken.cancel(); }

                if (!e?.target?.value) {
                  setEmail('');
                  setAccountExistence(undefined);
                  setEmailValidity(false);
                } else {
                  setCancelToken(AxiosCancelToken.source());
                }
              }}
              placeholder={signUpText?.email || 'Email'}
              type="email"
            />
            {
              !accountExists ? (
                <div className={styles.nameFields}>
                  <Input
                    className={styles.userInput}
                    onInput={(e) => {
                      e.persist();
                      inputHandlerForUserDetails('firstName', e?.target?.value);
                    }}
                    placeholder={signUpText?.firstName || 'First Name'}
                    type="code"
                    noLabel
                  />
                  <Input
                    className={styles.userInput}
                    onInput={(e) => {
                      e.persist();
                      inputHandlerForUserDetails('lastName', e?.target?.value);
                    }}
                    placeholder={signUpText?.lastName || 'Last Name'}
                    type="code"
                    noLabel
                  />
                </div>
              ) : null
            }
            <Collapse in={!accountExists} timeout={transitionTimeout}>
              <Fade in={!accountExists} timeout={transitionTimeout}>
                <div className={styles.newsletter}>
                  <Checkbox
                    className={styles.newsletterCheckbox}
                    color="primary"
                    id="newsletter-sub"
                    onChange={({ target: { checked: termsAgreed } }) => { setTermsAgreement(termsAgreed); }}
                  />
                  <label className={styles.newsletterText} htmlFor="newsletter-sub">
                    Yes! I would like to receive updates, special offers and other information  from Projector App.
                  </label>
                </div>
              </Fade>
            </Collapse>
            {/* {
              !accountExists ? (
                <div className={styles.reCaptchaContainer}>
                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_KEY}
                    onChange={reCaptchaHandler}
                    theme="dark"
                  />
                </div>
              ) : null
              } */}
            <Collapse in={accountExists} timeout={transitionTimeout}>
              <Fade in={accountExists} timeout={transitionTimeout}>
                <>
                  <br />
                  <br />
                </>
              </Fade>
            </Collapse>
            <div className={styles.termsContainer}>
              <div className={styles.terms}>
                <Collapse in={!accountExists} timeout={transitionTimeout}>
                  <Fade in={!accountExists} timeout={transitionTimeout}>
                    {
                      signUpText?.signUpContent ? (
                        <span className={styles.linkClass} dangerouslySetInnerHTML={{ __html: signUpText?.signUpContent }} />
                      ) : (
                        <span>
                          Projector will use your data to personalize and improve your Projector experience and to send you information about Projector. You can change your communication preferences anytime. We may use your data as described in our
                          <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/privacy"> Privacy Policy </a>
                          , including sharing it with the family of companies.  By clicking “Agree &amp; Continue”, you agree to our &nbsp;
                          <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/terms-of-use">Terms of Use </a>
                          ,&nbsp;
                          <a style={{ color: '#5AA5EF' }} target="_blank" rel="noreferrer" href="http://app.projectorstream.com.s3-website-us-west-2.amazonaws.com/settings/subscriber-agreement">Subscriber Agreement </a>
                          and acknowledge that you have read our Privacy Policy.
                        </span>
                      )
                    }
                  </Fade>
                </Collapse>
                <Collapse in={accountExists} timeout={transitionTimeout}>
                  <Fade in={accountExists} timeout={transitionTimeout}>
                    <span>{signUpText?.existingAccountText || 'You seem to have an existing projector account, Login instead?'}</span>
                  </Fade>
                </Collapse>
              </div>
              {accountExists === true
                ? (
                  <Button
                    blockText
                    isBlue
                    isDisabled={checkingAccountExistence
                      || !(emailIsValid && (accountExists !== undefined) && (accountExists === true))}
                    isFullWidth
                    isLarge
                    onClick={() => { setPreferredEmail(email); navigate(Pathname.authentication.newsignIn); }}
                  >
                    {signUpText?.login || 'login'}
                  </Button>
                )
                : (
                  <Button
                    blockText
                    isBlue
                    isDisabled={checkingAccountExistence
                      || !(emailIsValid && (accountExists !== undefined) && (accountExists === false) && selectedReCAPTCHA)
                      || (!preferredUserDetails.firstName || !preferredUserDetails.lastName)}
                    isFullWidth
                    isLarge
                    submit
                    onClick={() => { fixEmail(true); }}
                  >
                    {signUpText?.agreeAndContinue || 'Agree and Continue'}
                  </Button>
                )}
            </div>
          </form>
        )
        : (
          <form onSubmit={(e) => { e.preventDefault(); signUpUser(); }}>
            {/* <StepStatus currentStep="2" totalSteps="2" langText={signUpText} /> */}
            <input className={authStyles.hiddenFormField} name="email" onChange={() => { }} type="email" value={email} />
            <Input
              className={styles.userInput}
              label={signUpText?.enterAPassword || 'Enter a password'}
              minLength={12}
              id="password-input"
              onInput={handlePasswordInput}
              onPaste={(e) => {
                const pastedText = (e.clipboardData || window.clipboardData).getData('text');
                if (pastedText.length > 30) {
                    e.preventDefault();
                }
                const totalLength = e.target.value.length + pastedText.length;
                // Check if the total length (including pasted text) exceeds 30 characters
                if (totalLength > 30) {
                  // alert('Password cannot exceed 30 characters.');
                  e.preventDefault();
                }
            }}
            onChange={(e) => {
              const inputPassword = e.target.value;
              if (inputPassword.length > 30) {
                const trimmedText = inputPassword.slice(0, 30);
                e.target.value = trimmedText;
                setPassword(trimmedText);
              }
            }}
              placeholder={signUpText?.password || 'Password'}
              type="SignUpPass"
            />
            <div className={authStyles.conditons}>
              <p style={{ color: password && password.length >= 12 ? '#23e223' : 'white' }}>12 - 30 Characters</p>
              <p style={{ color: password && hasUpperCase ? '#23e223' : 'white' }}>Minimum of one uppercase</p>
              <p style={{ color: password && password.match(/[a-z]/) ? '#23e223' : 'white' }}>Minimum of one lowercase</p>
              <p style={{ color: password && password.match(/[0-9]/) ? '#23e223' : 'white' }}>Minimum of one number</p>
              <p style={{ color: password && password.match(/[!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/) ? '#23e223' : 'white' }}>Minimum of one special character</p>
            </div>
            {/* <div className={authStyles.passwordStrengthBar}>
              <div
                className={cn({
                  [authStyles.passwordStrengthBarContent]: true,
                  ...passwordHealth && { [authStyles[passwordHealth]]: true }
                })}
                style={{
                  width: passwordHealth === 'weak'
                    ? '30%'
                    : passwordHealth === 'medium'
                      ? '55%'
                      : passwordHealth === 'strong'
                        ? '100%'
                        : '0%'
                }}
              />
            </div> */}
            {/* <div className={authStyles.passwordStrengthNotes}>
              <div>Use a minimum of 12 characters (case sensitive) & maximum of 30 with at least one [a-z, A-Z], [0-9] and special character.</div>
              <div
                className={cn({
                  [authStyles.currentPasswordStrength]: true,
                  ...passwordHealth && { [authStyles[passwordHealth]]: true }
                })}
              >
                {passwordHealth}
              </div>
            </div> */}
            <div className={styles.notes}>
              <>{signUpText?.usingEmail || 'You’ll be using this email to log in:'}</>
              <br />
              <div className={styles.notesEmail}>{email}</div>
            </div>
            <div>
              <Button
                blockText
                isBlue
                isDisabled={(!((passwordHealth === 'strong') || (passwordHealth === 'medium')))
                  || signingInUser || signingUpUser}
                isFullWidth
                isLarge
                submit
              >
                {(signingUpUser || signingInUser) ? (`${signUpText?.signingUp}...` || 'Signing Up...') : (signUpText?.continue || 'Continue')}
              </Button>
            </div>
          </form>
        )}
    </AuthPage>
  );
};
