import React, { useMemo, useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import * as EmailValidator from 'email-validator';
import InputGrid from '../input-grid';
import InputField from '../input-field';

function CredentialField({
  onEmailChange,
  checked,
  afflitedDetails,
  onPasswordChange,
  setValidity,
  userEmail }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const { langText, userDetails } = useContext(AppContext);
  const purchaseDetailsText = langText?.settings?.billPayment?.purchaseDetails;
  const emailIsValid = useMemo(() => (EmailValidator.validate(email)), [email]);
  const passwordIsValid = useMemo(() => (
    (password.length >= 8) && (password === confirmationPassword)
  ), [password, confirmationPassword]);

  useEffect(() => {
    setValidity(emailIsValid && passwordIsValid);
  }, [emailIsValid, passwordIsValid]);
  useEffect(() => {
    if (checked === true) {
      setEmail(userDetails?.email);
    } else setEmail(userEmail);
    }, [checked]);
  return (
    <InputGrid>
      <InputField
        onChange={(value) => {
          setEmail(value);
          // onEmailChange(value);
        }}
        placeholder={purchaseDetailsText?.email || 'Email'}
        type="email"
        defaultValue={email}
        isDisabled
      />
      {/* <InputField
        onChange={(value) => {
          setPassword(value);
          onPasswordChange(value);
        }}
        placeholder="Password"
        type="password"
      />
      <InputField
        onChange={(value) => {
          setConfirmationPassword(value);
        }}
        placeholder="Confirm Password"
        type="password"
      /> */}
    </InputGrid>
  );
}

export default CredentialField;
