/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AppContext } from 'Context';
import { dataFetcher, endpoints } from 'Api';
import { Pathname } from 'Routes';
import { ReactComponent as DragIcon } from 'Assets/drag.svg';
import RotatingLoader from 'Components/RotatingLoader';
import { Menu } from '@material-ui/core';
import CreateSubCategory from './CreateSubCategory';
import styles from './index.module.css';

const ContentGroupButton = ({ groups, isLoading, groupType }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { setAppSnackbar, langText } = useContext(AppContext);
  const commonText = langText?.common;
  const [items, setItems] = useState(groups);
  const [newOrder, updateNewOrder] = useState(undefined);
  const [currentId, setCurrentId] = useState(null);
  const [conType, setConType] = useState(undefined);
  const [loader, setloader] = useState(false);
  const [isOpenSubCategoryDiv, setSubCategoryDiv] = useState(false);
  const handleClick = (content_type, event) => {
    setAnchorEl(event.currentTarget);
    setConType(content_type === 'Photos Albums' ? 'Photoalbum' : content_type);
  };

  const { isValidating: updatingNewOrder, mutate: updateOrder } = useSWR([
    endpoints.reOrderMyContentType,
    newOrder?.items,
  ], {
    fetcher: (url, newItems) => dataFetcher(url, { items: newItems }),
    onSuccess: ({ success }) => {
      if (success) {
        setAppSnackbar({
          isVisible: true,
          message: 'Order updated',
        });
        setloader(false);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating your order',
        });
        setloader(false);
      }
    },
    onError: () => { },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const newOrderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) { return; }

    const newItems = newOrderList(items, result.source.index, result.destination.index);
    const changedItems = {};

    items?.forEach((item, idx) => {
      if (item?.id !== newItems[idx]?.id) {
        changedItems[newItems[idx]?.id] = (idx + 1);
      }
    });

    if (result.source.index !== result.destination.index) {
      updateNewOrder({ items: changedItems });
    }

    setItems(newItems);
  };

  useEffect(() => { setItems(groups); }, [groups]);
  useEffect(() => { if (newOrder) { setloader(true); updateOrder(); } }, [newOrder]);
  return (
    <>
    {loader && <RotatingLoader />}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={groupType}>
          {(droppableProvided) => (
            <div
              className={styles.contentGroupBody}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {items
  .filter((item) => item.id !== '#$%')
  .map(({ id, content_type, content_value }, idx) => (
                <Draggable key={id} draggableId={id} index={idx}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      className={cn({
                        [styles.dragged]: draggableSnapshot.isDragging,
                      })}
                      key={idx}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={draggableProvided.draggableProps.style}
                    >
                      <div className={styles.createButtonWrapper}>
                        {
                          id === '#$%' && isOpenSubCategoryDiv ? (
                            <CreateSubCategory DragIcon={DragIcon} setSubCategoryDiv={setSubCategoryDiv} content_type={content_type} isOpenSubCategoryDiv={isOpenSubCategoryDiv} />
                          ) : (
                            <button
                              className={cn({ [styles.contentGroupBox]: true, [styles.contentGroupButton]: true })}
                            >
                              {
                          id === '#$%' ? (
                            <>
                              <div
                                className={cn({ [styles.dragIconContainer]: true, [styles.dragIconContainerType]: true })}
                              >
                                <DragIcon className={styles.dragIcon} style={{ opacity: 0 }} />
                              </div>
                              <div
                                className={styles.contentTypeTitle}
                                onClick={() => { setSubCategoryDiv(true); }}
                              >
                                <div className={styles.titleStyle}>{content_type}</div>
                              </div>
                              <div className={styles.contentGroupBody}>
                                <div style={{ textAlign: 'center' }} className={styles.contentGroupBodyCell}>
                                  <div
                                    className={cn({ [styles.editContentTypeRef]: true, [styles.editContentTypeRefPosition]: true })}
                                    onClick={() => { setSubCategoryDiv(!isOpenSubCategoryDiv); }}
                                  >
                                    <div className={styles.editContentType}>{isOpenSubCategoryDiv ? (commonText?.back || 'Back') : (commonText?.edit || 'Edit')}</div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className={cn({ [styles.dragIconContainer]: true, [styles.dragIconContainerType]: true })}
                                {...draggableProvided.dragHandleProps}
                              >
                                <DragIcon className={styles.dragIcon} />
                              </div>
                              <Link
                                to={
                                  Pathname.getContentLayoutType(id)
                                }
                                // onClick={() => { localStorage.setItem('content_type', content_value); }}
                                className={styles.contentTypeTitle}
                              >
                                <div className={styles.titleStyle}>{content_type}</div>
                              </Link>
                              <div className={styles.contentGroupBody}>
                                <div style={{ textAlign: 'center' }} className={styles.contentGroupBodyCell}>
                                  <Link
                                    to={
                                      Pathname.getContentLayoutType(id)
                                    }
                                    className={cn({ [styles.editContentTypeRef]: true, [styles.editContentTypeRefPosition]: true })}
                                    // onClick={() => { localStorage.setItem('content_type', content_value); }}
                                  >
                                    <div className={styles.editContentType}>{commonText?.edit || 'Edit'}</div>
                                  </Link>
                                </div>
                              </div>
                            </>
                          )
                        }
                            </button>
                          )
                        }
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={groupType}>
          {(droppableProvided) => (
            <div
              className={styles.contentGroupBody}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
           {items
  .filter((item) => item.id === '#$%')
  .map(({ id, content_type, content_value }, idx) => (
                <Draggable key={id} draggableId={id} index={idx}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      className={cn({
                        [styles.dragged]: draggableSnapshot.isDragging,
                      })}
                      key={idx}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={draggableProvided.draggableProps.style}
                    >
                      <div className={styles.createButtonWrapper}>
                        {
                          id === '#$%' && isOpenSubCategoryDiv ? (
                            <CreateSubCategory DragIcon={DragIcon} setSubCategoryDiv={setSubCategoryDiv} content_type={content_type} isOpenSubCategoryDiv={isOpenSubCategoryDiv} />
                          ) : (
                            <button
                              className={cn({ [styles.contentGroupBox]: true, [styles.contentGroupButton]: true })}
                            >
                              {
                          id === '#$%' ? (
                            <>
                              <div
                                className={cn({ [styles.dragIconContainer]: true, [styles.dragIconContainerType]: true })}
                              >
                                <DragIcon className={styles.dragIcon} style={{ opacity: 0 }} />
                              </div>
                              <div
                                className={styles.contentTypeTitle}
                                onClick={() => { setSubCategoryDiv(true); }}
                              >
                                <div className={styles.titleStyle}>{content_type}</div>
                              </div>
                              <div className={styles.contentGroupBody}>
                                <div style={{ textAlign: 'center' }} className={styles.contentGroupBodyCell}>
                                  <div
                                    className={cn({ [styles.editContentTypeRef]: true, [styles.editContentTypeRefPosition]: true })}
                                    onClick={() => { setSubCategoryDiv(!isOpenSubCategoryDiv); }}
                                  >
                                    <div className={styles.editContentType}>{isOpenSubCategoryDiv ? (commonText?.back || 'Back') : (commonText?.edit || 'Edit')}</div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className={cn({ [styles.dragIconContainer]: true, [styles.dragIconContainerType]: true })}
                                {...draggableProvided.dragHandleProps}
                              >
                                <DragIcon className={styles.dragIcon} />
                              </div>
                              <Link
                                to={
                                  Pathname.getContentLayoutType(id)
                                }
                                // onClick={() => { localStorage.setItem('content_type', content_value); }}
                                className={styles.contentTypeTitle}
                              >
                                <div className={styles.titleStyle}>{content_type}</div>
                              </Link>
                              <div className={styles.contentGroupBody}>
                                <div style={{ textAlign: 'center' }} className={styles.contentGroupBodyCell}>
                                  <Link
                                    to={
                                      Pathname.getContentLayoutType(id)
                                    }
                                    className={cn({ [styles.editContentTypeRef]: true, [styles.editContentTypeRefPosition]: true })}
                                    // onClick={() => { localStorage.setItem('content_type', content_value); }}
                                  >
                                    <div className={styles.editContentType}>{commonText?.edit || 'Edit'}</div>
                                  </Link>
                                </div>
                              </div>
                            </>
                          )
                        }
                            </button>
                          )
                        }
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
export default ContentGroupButton;
