import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { debounce } from 'lodash';
import { Collapse, Fade, Checkbox } from '@material-ui/core';
import { validate as isValidEmail } from 'email-validator';
import { Pathname } from 'Routes';
import { Button, Logo } from 'Components';
import { AppContext, AuthContext } from 'Context';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosCancelToken, dataFetcher, endpoints } from 'Api';
import { isMobile } from 'react-device-detect';
import { clearAllDummys } from 'store/store';
import logo from 'Assets/Login Logo.png';
import styles from './index.module.css';
import Togther from '../../../../Assets/together.png';
import UploadCloud from '../../../../Assets/uploadCloud.png';

const transitionTimeout = 300;

export const Welcome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAppSnackbar, langText } = useContext(AppContext);
  const { forceAutoSignin } = useContext(AuthContext);
  const signInText = langText?.signInUp;
  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  const suffix = getQueryParam('email');
  const frontEnd = [
    {
    title: 'Start Viewing',
    subtitle: 'If you want to view someone’s Projector \n(you were invited), click here to create a free account to view.  You can always decide to upload your own media later.',
    image: Togther,
    buttonText: 'Start Viewing',
    navigation: `${Pathname.access}${suffix ? `?email=${suffix}` : ''}`
  },
  {
    title: 'Create your Projector',
    subtitle: 'Create a Projector and have the ultimate viewing experiance for your own \nmemories like never before! \nClick here to start uploading.',
    image: UploadCloud,
    buttonText: 'Start uploading',
    navigation: Pathname.plans
  }
];
  return (
    <div className={styles.authPage}>
      {/* {
        !isMobile
      && <div aria-hidden="true" onClick={() => { navigate(-1); }} className={styles.backLink}>{signInText?.back || 'BACK'}</div>
      } */}
<div>
<Link to={Pathname.home}>
<div className={styles.logoDiv}>
<img className={styles.logoImage} src={logo} alt="projector logo" />
</div>
</Link>
</div>
<div className={styles.flexico}>
{frontEnd?.map((obj, index) => (
  <div className={styles.each}>
    <img src={obj?.image} alt="" />
    <h1
        className={`${styles.headx} ${
          index === 1 ? styles.marginTop44 : ''
        }`}
    >
  {obj?.title}
    </h1>
<p className={styles.subheadx}>
  {obj?.subtitle}
</p>
<Button link={obj?.navigation} blockText isBlue isFullWidth alignCenter MobWidth>
         {obj?.buttonText}
</Button>
  </div>
 ))}
</div>
    </div>
  );
};
