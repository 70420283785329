/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useRef, useState, useEffect } from 'react';
import { AppContext } from 'Context';
import useSWR from 'swr';
// import PlaylistInput from '../components/PlaylistInput';
// import CategoryInput from '../components/CategoryInput';
import AlterImage from 'Assets/alter-image.png';
import cn from 'classnames';
import { dataFetcher, endpoints } from 'Api';
import { Typography } from '@material-ui/core';
import Cancel from '../../../Assets/cancel.png';
import { MenuItem, DialogActions, Radio, Menu } from './Material-reDesign';
import styles from '../index.module.css';

const CategoryInput = ({
  categoryID,
  className,
  defaultName,
  defaultValue,
  description,
  isDisabled,
  isRequired,
  isOptional,
  isSubcategory = false,
  label,
  onSelect,
  selectSubCatOptionName,
  selectSubCatOptionValue
}) => {
  const { setAppSnackbar, langText } = useContext(AppContext);
  const commonText = langText?.common;
  const uploadText = langText?.upload;
  const [selectedOptionValue, selectOptionValue] = useState(defaultValue || '');
  const [selectedOptionName, selectOptionName] = useState(defaultName || '');
  const [createMenuIsVisible, setCreateMenuVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [backGroundImages, setBackgroundImages] = useState(undefined);
  const [selectedBackGroundImage, selectBackGroundImage] = useState(undefined);
  const [categoryAddingInProgress, setCategoryAddingInProgress] = useState(false);
  const onValueSelect = (selectedVal, selectedName) => {
    selectOptionValue(selectedVal);
    selectOptionName(selectedName);
    onSelect(selectedVal, selectedName);
    // setAnchorEl(null);
  };
  const hideCreateMenu = () => { onValueSelect('delete', ''); selectSubCatOptionName(''); selectSubCatOptionValue(''); setCreateMenuVisibility(false); };

  const showCreateMenu = (selectedVal, selectedName) => {
    onValueSelect(selectedVal, selectedName);
    if (!selectedVal) {
      setCreateMenuVisibility(true);
    } else {
      setAnchorEl(null);
    }
  };
  const { isValidating: gettingCategories, mutate: getCategories } = useSWR([endpoints.getMyCategories, categoryID], {
    fetcher: (url, parent_id) => dataFetcher(url, { ...categoryID && { parent_id } }),
    onSuccess: ({ success, data }) => {
      if (success) {
        setOptions(data?.map(({ id, title, icon_id }) => ({ name: title, value: id, icon: icon_id })));
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your categories',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching your categories' });
    },
  });

  const { isValidating: addingNewCategory, mutate: addNewCategory } = useSWR([
    endpoints.addEditCategory, selectedOptionValue ? selectedOptionName : newCategoryName, categoryID, selectedBackGroundImage, selectedOptionValue
  ], {
    fetcher: (url, categoryName, parentCategoryID, icon, category_id) => dataFetcher(url, {
      title: categoryName,
      ...parentCategoryID && { parent_id: parentCategoryID },
      icon,
      category_id
    }),
    onSuccess: ({ success, category_id }) => {
      setCategoryAddingInProgress(false);
      if (success) {
        setAnchorEl(null);
        hideCreateMenu();
        getCategories();
        onValueSelect(category_id, selectedOptionValue ? selectedOptionName : newCategoryName);
        setAppSnackbar({
          isVisible: true,
          message: `${isSubcategory ? 'Sub-Category' : 'Category'} '${selectedOptionValue ? selectedOptionName : newCategoryName}' was created`,
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your category' });
      }
    },
    onError: () => {
      setCategoryAddingInProgress(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your category' });
    },
  });

  const { isValidating: gettingBackgroundImages, mutate: getBackGroundImages } = useSWR(endpoints.getBackgroundImages, {
    onSuccess: ({ success, data }) => {
      if (success) {
        setBackgroundImages(data.filter((item) => parseInt(item.is_default, 10) === 0));
        // setDefaultBackGroundImage(data.filter((item) => parseInt(item.is_default, 10) === 1)[0]);
      }
    },
    onError: () => { },
  });

  useEffect(() => { getCategories(); getBackGroundImages(); }, []);

  useEffect(() => { if (isSubcategory) { getCategories(); } }, [categoryID]);

  return (
    <div className={cn({ [styles.selectBoxContainer]: true, [className]: className, [styles.disabled]: isDisabled })}>
      <div
        aria-controls="select-box"
        aria-label={label}
        className={styles.selectBox}
        onClick={(e) => {
          if (!isDisabled) {
            setAnchorEl(e.currentTarget);
            document.body.style.setProperty('--selectbox-menu-width', `${e.currentTarget.clientWidth}px`);
          }
        }}
        onKeyPress
        role="menu"
        tabIndex={0}
      >
        <div className={styles.selectBoxText}>
          <div className={styles.selectBoxLabel}>
            {label}
            {isOptional && <span className={styles.optionalInput}> (Optional)</span>}
            {isRequired && <span className={styles.optionalInput}> (Required)</span>}
          </div>
          <div className={styles.selectBoxDescription}>
          {!(selectedOptionValue && selectedOptionValue !== 'delete' && defaultValue) ? description : selectedOptionName}
          {selectedOptionValue && selectedOptionName && (
            <div
              onKeyPress={undefined}
              role="button"
              tabIndex={0}
              onClick={(e) => { e.stopPropagation(); hideCreateMenu(); setAnchorEl(null); }}
            >
              <img src={Cancel} className={styles.Cancelxx} alt="" />
            </div>
          )}
          </div>
        </div>
        <div className={styles.selectBoxDropdownIcon}>▾</div>
      </div>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 2 }}
        getContentAnchorEl={null}
        id="select-box"
        // keepMounted
        MenuListProps={{ className: styles.selectBoxMenuContent }}
        onClose={() => { hideCreateMenu(); setAnchorEl(null); }}
        open={Boolean(anchorEl)}
        PaperProps={{ className: styles.selectBoxMenu }}
        transitionDuration={0}
        PopoverClasses={{ paper: styles.dropDownAnimation }}
      >
        {
        [
        <div>
          {!createMenuIsVisible
            ? (
              <div className={styles.selectBoxOptionsList}>
                <MenuItem className={styles.selectBoxOptionsTitle} disableRipple disableTouchRipple>{label}</MenuItem>

                {gettingCategories && (
                  <MenuItem className={styles.selectBoxOption} disableRipple disableTouchRipple>{`${commonText?.loading || 'Loading'} ...`}</MenuItem>
                )}

                {!gettingCategories && options?.map(({ name: optionName, value: optionValue, icon }) => (optionName && (
                  <MenuItem
                    className={styles.selectBoxOption}
                    disableRipple
                    disableTouchRipple
                    key={optionValue}
                    // onClick={() => { onValueSelect(optionValue, optionName); }}
                    onClick={() => { showCreateMenu(optionValue, optionName); }}
                  >
                    <Typography variant="inherit" noWrap>{optionName}</Typography>
                  </MenuItem>
                )))}

                <MenuItem
                  className={cn({ [styles.selectBoxOption]: true, [styles.addButton]: true })}
                  disableRipple
                  disableTouchRipple
                  onClick={() => { showCreateMenu('', ''); }}
                >
                  <Typography variant="inherit" noWrap>{commonText?.addNew || 'Add new'}</Typography>
                </MenuItem>
              </div>
            )
            : (
              <div className={styles.selectBoxCreate}>
                <div className={styles.selectBoxCreateHeader}>
                  {
                    !selectedOptionValue ? (
                      <span className={styles.selectBoxCreateTitle}>{`${commonText?.addNew || 'Add new'} ${label}`}</span>
                    ) : (
                      <span className={styles.selectBoxCreateTitle}>{uploadText?.editBackGround || 'Edit background'}</span>
                    )
                  }
                  <button
                    className={styles.selectBoxCreateBackButton}
                    disabled={categoryAddingInProgress}
                    onClick={hideCreateMenu}
                  >
                    {commonText?.back || 'Back'}
                  </button>
                </div>
                {
                  !selectedOptionValue ? (
                    <input
                    className={styles.selectBoxCreateInput}
                    value={newCategoryName}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) => { setNewCategoryName(e.target.value); }}
                    maxLength="16"
                    />
                  ) : null
                }
                <div className={styles.selectBackgroundText}>{uploadText?.selectBackGround || 'Select Background'}</div>
                <div className={styles.backgroundImageList}>
                  {
                    gettingBackgroundImages ? <div className={styles.loaderContent}>{`${commonText?.loading || 'Loading'} ...`}</div> : (
                      backGroundImages?.map(({ image, id, imagekit_image }) => ((
                        <div
                          className={styles.backGroundImage}
                          onClick={() => { selectBackGroundImage(id); }}
                        >
                          <img
                            src={imagekit_image || image || AlterImage}
                            style={{
                              cursor: 'pointer',
                              border: `${selectedBackGroundImage === id ? '4px solid #5aa5ef' : ''}`,
                              width: '177px',
                              height: '62px',
                              borderRadius: '1vh',
                              margin: '5px 3px'
                            }}
                            onError={(e) => {
                              e.target.src = AlterImage;
                            }}
                          />
                        </div>
                      )))
                    )
                  }
                </div>
                <div className={styles.selectBoxCreateFooter}>
                  <button
                    className={styles.selectBoxCreateAddButton}
                    disabled={categoryAddingInProgress || (!selectedOptionValue && !newCategoryName?.trim())}
                    onClick={() => { setCategoryAddingInProgress(true); addNewCategory(); }}
                    type="submit"
                  >
                    {categoryAddingInProgress ? (selectedOptionValue ? `${commonText?.updating || 'Updating'} ...` : `${commonText?.adding || 'Adding'} ...`) : (selectedOptionValue ? (commonText?.update || 'Update') : (commonText?.add || 'Add'))}
                  </button>
                </div>
              </div>
            )}
        </div>
        ]
        }
      </Menu>
    </div>
  );
};
export default CategoryInput;
