import React, { useState, useContext, useEffect } from 'react';
import './UserM.css';
import { baseUrl } from 'Api';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContext, AuthContext } from 'Context';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import Spinner from 'Components/Spinner';
import { InfoModal } from '../private/dashboard/info-modal/index';
import SimpleSnackBar from '../../Components/SimpleSnackBar';
import UserList from './UserList';
import ManagerList from './ManagerList';
import RegisterPage from './RegisterPage';
import InviteUser from './InviteUser';
import Copy from '../../Assets/link.png';
import AddBusiness from './AddBusiness';

function Affliated({ apiResponse }) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openBusinessModal, setOpenBusinessModal] = useState(false);
  const { getToken } = useContext(AuthContext);
  const { setAppSnackbar, setmangerFunctionData, setmessaged, messaged } = useContext(AppContext);
  const [data, setdata] = useState([]);
  const spinerSmall = 'spinex';
  const [userListData, setUserListData] = useState([]);
  const [giveResponse, setGiveResponse] = useState(false);
  const [hiddenIndices, setHiddenIndices] = useState([]);
  const [isOpenModal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clickLoading, setClickLoading] = useState(false);
const frontEndUrl = window.location.origin;
  // if (frontEndUrl === 'https://projector.app') {
  //   frontEndUrl = 'https://projectorstream.com';
  // }

  const fetchData = async () => {
    try {
      const myToken = await getToken();
      const apiUrl = `${baseUrl}/inviteUsersList`;
      const requestBody = {
        token: myToken,
      };
      const response = await axios.post(apiUrl, requestBody);
      setdata(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const fetchMangerData = async () => {
    setIsLoading(true);
    try {
      const myToken = await getToken();
      const apiUrl = `${baseUrl}/managerUsersList`;
      const requestBody = {
        token: myToken,
      };
      const response = await axios.post(apiUrl, requestBody);
      setUserListData(response.data);
      setIsLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false); // Set loading to false in case of an error
    }
  };
  const showAll = () => {
    setClickLoading(true);
    const API_URL = `${baseUrl}/showAllUsers`;
    const data = {
      token: getToken()
    };
    axios.post(API_URL, data)
      .then((response) => {
        setClickLoading(false);
        setAppSnackbar({ isVisible: true, message: response?.data?.message, type: 'error' });
        setHiddenIndices([]);
        fetchMangerData();
      })
      .catch((error) => {
        setClickLoading(false);
        setAppSnackbar({ isVisible: true, message: error?.response?.message, type: 'error' });
      });
  };
  const handleClick = (code) => {
    const textToCopy = `${frontEndUrl}/newSign-up?reference_code=${code}`;
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setmangerFunctionData(true);
    setmessaged('Affiliate link copied to clipboard');
  };
  return (
    <div
      className="relativez"
      // role="button"
      // tabIndex={0}
      // onClick={() => setIsOpen(false)}
      // onKeyDown={() => {}}
    >
      {isOpen && (
        <InviteUser
          fetchData={fetchData}
          getToken={getToken}
          setIsOpen={setIsOpen}
        />
      )}
      {openBusinessModal && (
        <AddBusiness
          setOpenBusinessModal={setOpenBusinessModal}
          name={apiResponse?.data[0]?.business_name}
        />
      )}
      <div className="gridssz relativez">
        <div>
          <p className="boldtext">User Permissions</p>
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0px' }}>
            <p
              className="nonBoldxtext"
              style={{
                visibility: apiResponse?.data[0]?.business_name ? '' : 'hidden',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              {apiResponse?.data[0]?.business_name}
            </p>
            {apiResponse?.data[0]?.affiliateCode && (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => { }}
                className="invite alignleft"
                onClick={() => setOpenBusinessModal(true)}
                // style={{ margin: '10px 0px' }}
              >
                {apiResponse?.data[0]?.business_name ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Edit Business name
                    <EditIcon style={{ marginLeft: '8px', fontSize: '1rem' }} />
                  </div>
                ) : 'Add Business name +'}
              </div>
            )}
          </div>
          {(apiResponse?.data?.manager_status === 'Pending' || apiResponse?.data?.manager_status === 'Rejected') && (
            <div className="flexStarter">
              <p className="nonBoldxtext">Your Affiliate Code:</p>
              <button style={{ opacity: '0.5', cursor: 'default' }} className="buttonrEG">
                {apiResponse?.data?.manager_status}
                ....
              </button>
            </div>
          )}
          {apiResponse?.data[0]?.affiliateCode != null && (
            <span className="modelFelx">
              <p className="nonBoldxtext">
              Your Affiliate Code:
              {' '}
              {apiResponse?.data[0]?.affiliateCode}
              </p>
              <div
                onKeyPress={undefined}
                role="button"
                tabIndex={0}
                onClick={() => handleClick(apiResponse?.data[0]?.affiliateCode)}
              >
                <img
                className="copy"
                src={Copy}
                alt=""
                />
              </div>
            </span>
          )}
          {apiResponse?.data[0]?.affiliateCode !== undefined
          && (
            <p className="linkSelectText">{`${frontEndUrl}/newSign-up?reference_code=${apiResponse?.data[0]?.affiliateCode}`}</p>
          )}
          <p className="nonColurxtext">Create and manage users</p>
        </div>
        <div className="mt-[37px]">
          <p className="nonBoldxtext">Connected Accounts:</p>
          <p className="nonColurxtext">Allow people access to your account</p>
          <button className="inviteer" onClick={() => setIsOpen(true)}>
            Invite User+
          </button>
          <div className="marginTopic">
            <UserList fetchData={fetchData} data={data} getToken={getToken} />
          </div>
        </div>
        {/* <p className="invite absolute">Create a new user +</p> */}
        <div>
          <div className="flexManager">
            <p className="nonBoldxtext">Access to:</p>
            {apiResponse?.paymentMethod === true ? (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                className="invite alignleft"
                onClick={() => navigate('/create-user')}
              >
                Create a new user +
              </div>
            ) : (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() => {
                  setGiveResponse(true);
                  setmessaged(
                    'Please attach a payment method to create a user. Go to Settings > Billing and Payments > Manage payment info, to add your card details'
                  );
                }}
                style={{ opacity: '0.5' }}
                className="invite alignleft"
              >
                Create a new user +
              </div>
            )}
          </div>
          <div className="flexManager">
          <p className="nonColurxtext">Accounts that you have access to</p>
          {hiddenIndices?.length > 0 || userListData?.show_all
            ? (
              <button className="unhidePls" onClick={() => showAll()}>
                {clickLoading ? <Spinner spinex={spinerSmall} /> : 'Show All'}
              </button>
            )
          : ''}
          </div>
          <div className="marginTopic">
            <ManagerList isLoading={isLoading} fetchMangerData={fetchMangerData} userListData={userListData} setUserListData={setUserListData} setModal={setModal} hiddenIndices={hiddenIndices} setHiddenIndices={setHiddenIndices} getToken={getToken} />
          </div>
        </div>
      </div>
      {giveResponse === true && (
        <SimpleSnackBar setOpen={setGiveResponse} message={messaged} />
      )}
    </div>
  );
}

export default Affliated;
