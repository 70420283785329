import React, { useState, useContext } from 'react';
import useSWR from 'swr';
import { Modal, Snackbar } from 'Components';
import { AppContext } from 'Context';
import { dataFetcher, endpoints } from 'Api';
import { Input } from '../Input';
import styles from './styles.module.css';

const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };

const NameEditModal = ({ isVisible, onHide: onClose, title, firstNameLabel, lastNameLabel }) => {
  const { userDetails, setUserDetails, langText } = useContext(AppContext);
  const commonText = langText?.common;
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  const [snackbar, setSnackbar] = useState(defaultSnackbar);

  const onHide = () => {
    setFirstname('');
    setLastname('');
    setSnackbar(defaultSnackbar);
    onClose();
  };

  const { isValidating: updatingName, mutate: updateName } = useSWR([endpoints.updateMyProfile, firstname, lastname], {
    fetcher: (url, inputFirstname, inputLastname) => dataFetcher(url, { firstname: inputFirstname, lastname: inputLastname }),
    onSuccess: ({ success }) => {
      if (success) {
        setSnackbar({ isVisible: true, type: 'success', message: 'Name was updated successfully' });
        setUserDetails({ ...userDetails, firstname, lastname });
        setTimeout(onHide, 1500);
      } else {
        setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating name' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating name' });
    },
  });

  return (
    <>
      <Modal
        isVisible={isVisible}
        title={title}
        onHide={onHide}
        cancelButton={commonText?.cancel || 'Cancel'}
        onCancelButtonClick={onHide}
        continueButton={updatingName ? `${commonText?.updating || 'Updating'}...` : (commonText?.update || 'Update')}
        continueButtonClassName={styles.actionButton}
        onContinueButtonClick={updateName}
        continueButtonProps={{ isDisabled: !(firstname || lastname) || updatingName }}
      >
        <br />
        <Input
          className={styles.inputField}
          containerClassName={styles.inputFieldContainer}
          label={firstNameLabel}
          labelClassName={styles.inputFieldLabel}
          onInput={(e) => {
            const { target: { value: inputFirstname } } = e;

            setFirstname(inputFirstname);
          }}
          type="code"
          placeholder={userDetails?.firstname}
        />
        <br />
        <Input
          className={styles.inputField}
          containerClassName={styles.inputFieldContainer}
          label={lastNameLabel}
          labelClassName={styles.inputFieldLabel}
          onInput={(e) => {
            const { target: { value: inputLastname } } = e;

            setLastname(inputLastname);
          }}
          type="code"
          placeholder={userDetails?.lastname}
        />
      </Modal>

      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
    </>
  );
};
export default NameEditModal;
