import React, { useContext, useRef, useState, useEffect } from 'react';
import { AppContext } from 'Context';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import cn from 'classnames';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  RadioGroup,
  Slide,
  Typography,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { MenuItem, DialogActions, Radio, Menu } from './Material-reDesign';
import styles from '../index.module.css';

const PlaylistInput = ({
  className,
  defaultNames = [],
  defaultValues = [],
  description,
  isDisabled,
  label,
  onSelect,
}) => {
  const { setAppSnackbar } = useContext(AppContext);
  const defaultOptions = {};
  const [options, setOptions] = useState([]);
  if (Array.isArray(defaultValues)) {
    defaultValues.forEach((key, i) => { defaultOptions[key] = defaultNames[i]; });
  } else {
    const arrayMap = defaultValues.split(',');
    arrayMap.forEach((key, i) => { defaultOptions[key] = defaultNames[i]; });
  }

  const [selectedOptions, selectOptions] = useState(defaultOptions);
  const updatedData = { ...selectedOptions };

  options.forEach((option) => {
    if (option.value in updatedData) {
          updatedData[option.value] = option.name; // Add the missing value to the updatedData object
      }
  });
  const [createMenuIsVisible, setCreateMenuVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newPlaylistName, setNewPlaylistName] = useState('');

  const showCreateMenu = () => { setCreateMenuVisibility(true); };
  const hideCreateMenu = () => { setCreateMenuVisibility(false); };

  const onValueSelect = (selectedVal, selectedName) => {
    // const currentOptions = { ...selectedOptions };
    const currentOptions = { ...updatedData };

    if (currentOptions[selectedVal]) {
      delete currentOptions[selectedVal];
    } else {
      currentOptions[selectedVal] = selectedName;
    }

    selectOptions(currentOptions);
    onSelect(Object.keys(currentOptions), Object.values(currentOptions));
  };

  const { isValidating: gettingPlaylists, mutate: getPlaylists } = useSWR(endpoints.getMyPlaylist, {
    onSuccess: ({ success, data }) => {
      if (success) {
        setOptions(data?.map(({ id, title }) => ({ name: title, value: id })));
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching you playlists' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching you playlists' });
    },
  });

  const { isValidating: addingNewPlaylist, mutate: addNewPlaylist } = useSWR([
    endpoints.addEditPlaylist, newPlaylistName,
  ], {
    fetcher: (url, playlistName) => dataFetcher(url, { title: playlistName }),
    onSuccess: ({ success }) => {
      if (success) {
        hideCreateMenu();
        getPlaylists();
        setAppSnackbar({
          isVisible: true,
          message: `Playlist '${newPlaylistName}' was created`,
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your playlist' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your playlist' });
    },
  });

  useEffect(() => { getPlaylists(); }, []);

  return (
    <div className={cn({ [styles.selectBoxContainer]: true, [className]: className })}>
      <div
        aria-controls="select-box"
        aria-label={label}
        className={styles.selectBox}
        onClick={(e) => {
          if (!isDisabled) {
            setAnchorEl(e.currentTarget);
            document.body.style.setProperty('--selectbox-menu-width', `${e.currentTarget.clientWidth}px`);
          }
        }}
        onKeyPress
        role="menu"
        tabIndex={0}
      >
        <div className={styles.selectBoxText}>
          <div className={styles.selectBoxLabel}>
            {label}
            <span className={styles.optionalInput}> (Optional)</span>
          </div>
          <div className={styles.selectBoxDescription}>
            {/* {!(Object.keys(selectedOptions)?.length) ? description : Object.values(selectedOptions).join(', ')} */}
            {!(Object.keys(updatedData)?.length) ? description : Object.values(updatedData)[0] === undefined ? '' : Object.values(updatedData).join(', ')}
          </div>
        </div>
        <div className={styles.selectBoxDropdownIcon}>▾</div>
      </div>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 2 }}
        getContentAnchorEl={null}
        id="select-box"
        // keepMounted
        MenuListProps={{ className: styles.selectBoxMenuContent }}
        onClose={() => { hideCreateMenu(); setAnchorEl(null); }}
        open={Boolean(anchorEl)}
        PaperProps={{ className: styles.selectBoxMenu }}
        transitionDuration={0}
        PopoverClasses={{ paper: styles.dropDownAnimation }}
      >
        <div>
          {!createMenuIsVisible
            ? (
              <div className={styles.selectBoxOptionsList}>
                <MenuItem className={styles.selectBoxOptionsTitle} disableRipple disableTouchRipple>{label}</MenuItem>

                {gettingPlaylists && (
                  <MenuItem className={styles.selectBoxOption} disableRipple disableTouchRipple>Loading ...</MenuItem>
                )}

                {!gettingPlaylists && options?.map(({ name: optionName, value: optionValue }) => (optionName && (
                  <MenuItem
                    className={cn({
                      [styles.selectBoxOption]: true,
                      [styles.selected]: Object.keys(updatedData).includes(optionValue),
                    })}
                    disableRipple
                    disableTouchRipple
                    key={optionValue}
                    onClick={() => { onValueSelect(optionValue, optionName); }}
                  >
                    {Object.keys(updatedData).includes(optionValue)
                      ? <CheckBoxIcon />
                      : <CheckBoxOutlineBlankIcon />}
                    &nbsp;&nbsp;&nbsp;
                    <Typography variant="inherit" noWrap>
                      {optionName}
                    </Typography>
                  </MenuItem>
                )))}

                <MenuItem
                  className={cn({ [styles.selectBoxOption]: true, [styles.addButton]: true })}
                  disableRipple
                  disableTouchRipple
                  onClick={showCreateMenu}
                >
                  <Typography variant="inherit" noWrap>Add new</Typography>
                </MenuItem>
              </div>
            )
            : (
              <div className={styles.selectBoxCreate}>
                <div className={styles.selectBoxCreateHeader}>
                  <span className={styles.selectBoxCreateTitle}>{`Add new ${label}`}</span>
                  <button
                    className={styles.selectBoxCreateBackButton}
                    disabled={addingNewPlaylist}
                    onClick={hideCreateMenu}
                  >
                    Back
                  </button>
                </div>
                <input
                  className={styles.selectBoxCreateInput}
                  value={newPlaylistName}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) => { setNewPlaylistName(e.target.value); }}
                />
                <div className={styles.selectBoxCreateFooter}>
                  <button
                    className={styles.selectBoxCreateAddButton}
                    disabled={addingNewPlaylist || !newPlaylistName?.trim()}
                    onClick={addNewPlaylist}
                    type="submit"
                  >
                    {addingNewPlaylist ? 'Adding ...' : 'Add'}
                  </button>
                </div>
              </div>
            )}
        </div>
      </Menu>
    </div>
  );
};
export default PlaylistInput;
