import React, { useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.css';

function BottomBar() {
const navigate = useNavigate();
const menuPage = window.location.pathname;
  return (
    <div className={styles.bottomBar}>
    <div className={styles.menu} onKeyPress={undefined} role="button" tabIndex={0} onClick={() => navigate('/profile')}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20.328" height="18.399" viewBox="0 0 20.328 18.399">
      <path id="Path_111" data-name="Path 111" d="M10.754,20.639V15.116h4.419v5.524a1.108,1.108,0,0,0,1.1,1.1h3.314a1.108,1.108,0,0,0,1.1-1.1V12.906h1.878a.548.548,0,0,0,.365-.961L13.7,3.627a1.113,1.113,0,0,0-1.48,0L2.988,11.945a.55.55,0,0,0,.365.961H5.23v7.733a1.108,1.108,0,0,0,1.1,1.1H9.649A1.108,1.108,0,0,0,10.754,20.639Z" transform="translate(-2.802 -3.345)" fill={menuPage === '/profile' ? '#1172D2' : '#fff'} />
    </svg>
    <p style={{ color: menuPage === '/profile' ? '#1172D2' : '#fff' }}>Home</p>
    </div>
    <div className={styles.menu} onKeyPress={undefined} role="button" tabIndex={0} onClick={() => navigate('/search')}>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.87" height="17.88" viewBox="0 0 17.87 17.88">
      <path id="Path_9" data-name="Path 9" d="M16.1,14.53h-.828l-.293-.283a6.832,6.832,0,1,0-.734.734l.283.293V16.1l4.455,4.455A1.1,1.1,0,0,0,20.547,19Zm-6.289,0A4.717,4.717,0,1,1,14.53,9.813,4.711,4.711,0,0,1,9.813,14.53Z" transform="translate(-2.999 -2.999)" fill={menuPage === '/search' ? '#1172D2' : '#fff'} />
    </svg>
    <p style={{ color: menuPage === '/search' ? '#1172D2' : '#fff' }}>Search</p>
    </div>
    <div className={styles.menu} onKeyPress={undefined} role="button" tabIndex={0} onClick={() => navigate('/watchlist')}>
    <svg id="turned_in_not-24px" xmlns="http://www.w3.org/2000/svg" width="21.736" height="21.736" viewBox="0 0 21.736 21.736">
      <path id="Path_234" data-name="Path 234" d="M0,0H21.736V21.736H0Z" fill="none" />
      <path id="Path_235" data-name="Path 235" d="M15.868,3H6.811a1.809,1.809,0,0,0-1.8,1.811L5,19.3l6.34-2.717L17.68,19.3V4.811A1.817,1.817,0,0,0,15.868,3Zm0,13.585L11.34,14.611,6.811,16.585V4.811h9.057Z" transform="translate(-0.472 -0.283)" fill={menuPage === '/watchlist' ? '#1172D2' : '#fff'} />
    </svg>
    <p style={{ color: menuPage === '/watchlist' ? '#1172D2' : '#fff' }}>Watchlist</p>
    </div>
    </div>
  );
}

export default BottomBar;
