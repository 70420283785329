/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import { AppContext } from 'Context';
import TermsAndCondition from 'Components/Doc/TermsAndCondition/index';
import { SettingsPage } from '../components/page';

export const TermsAndConditionSettings = () => {
  const { langText } = useContext(AppContext);
  const termsText = langText?.settings?.terms;
  return (
    <SettingsPage
      title={termsText?.title || 'Terms and Conditions'}
      // description={termsText?.description || 'Terms and Conditions settings'}
    >
      <TermsAndCondition />
    </SettingsPage>
  );
};
