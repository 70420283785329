/* eslint-disable no-script-url */
import React, { useContext, useEffect, useState } from 'react';
import { axios, endpoints } from 'Api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from 'Routes';
import { AppContext } from 'Context';
import { ShareScreen } from '../../../share-screen';
import 'react-notifications-component/dist/theme.css';

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAppSnackbar, langText, toastForGroup, setToastForGroup } = useContext(AppContext);
  const [groupCreationModalIsVisible, setGroupCreationModalVisibility] = useState(false);
  const [tempGroupCreated, setTempGroupCreationStatus] = useState(false);
  const [tempGroupID, setTempGroupID] = useState(undefined);
  const [tempGroupTitle, setTempGroupTitle] = useState(undefined);
  const [oldMembers, setOldMembers] = useState([]);
  const groupsText = langText?.viewersSideBar?.groups;
  const commonText = langText?.common;
  // testing
  useEffect(() => {
  }, [tempGroupCreated, tempGroupID, tempGroupTitle]);
  const revealGroupCreationModal = () => { setGroupCreationModalVisibility(true); };
  const hideGroupCreationModal = () => {
    setTempGroupID(undefined);
    setTempGroupTitle(undefined);
    setTempGroupCreationStatus(false);
    setGroupCreationModalVisibility(false);
    navigate(location.pathname);
  };

  const createGroup = async ([, setActionStatus, setOperationStatus, , , , groupTitle, groupIcon]) => {
    setTempGroupCreationStatus(true);
  };
  const getOldGroupMemebers = async () => {
    const resp = await axios.post(endpoints.getMyGroups, { tempGroupID }).catch(() => { });
    const getGroupMembers = resp?.data;
    if (getGroupMembers?.success) {
      setTempGroupTitle(getGroupMembers?.data?.filter(({ id }) => tempGroupID === id)[0]?.title);
      setOldMembers(
        getGroupMembers?.data
          .filter(({ id }) => tempGroupID === id)[0] ? getGroupMembers.data.filter(({ id }) => tempGroupID === id)[0].members
          : []
      );
    }
  };
  useEffect(() => {
    if (tempGroupID) {
      getOldGroupMemebers();
    }
  }, [tempGroupID]);

  const createNewGroup = async ([onHide, setActionStatus, setOperationStatus, setSelectedUsers, setUserList, users, groupTitle, groupIcon]) => {
    setActionStatus(true);
      await axios
        .post(endpoints.addNewGroup, { title: groupTitle, image: groupIcon })
        .then(async ({ data }) => {
          setToastForGroup(data);
          if (data?.success === true) {
            setTempGroupID(data?.id);
            setTempGroupCreationStatus(true);
          } else {
            setTempGroupCreationStatus(false);
            setAppSnackbar({ isVisible: true, type: 'error', message: data?.message });
          }
        })
        .catch(() => {
          setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
        });
    setActionStatus(false);
  };

  const addMembersToGroup = async ([
    onHide, setActionStatus, setOperationStatus, setSelectedUsers, setUserList, users, groupTitle, groupIcon,
  ]) => {
    setActionStatus(true);
    if (tempGroupID) {
      const { data: createGroupMemberData } = await axios
        .post(endpoints.addMemberToGroup, { group_id: tempGroupID, users: `${users.map((userData) => userData.id)}` })
        .catch(() => {
          setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
        });
      setUserList([]);
      if (createGroupMemberData?.success) {
        setSelectedUsers([]);
        setOperationStatus(true);
        onHide();
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: createGroupMemberData?.message });
        setOperationStatus(false);
      }
    } else {
      await axios
        .post(endpoints.addNewGroup, { title: groupTitle, image: groupIcon })
        .then(async ({ data }) => {
          if (data?.success) {
            setTempGroupID(data?.id);
            setToastForGroup(data);
            const { data: createGroupMemberData } = await axios
              .post(endpoints.addMemberToGroup, { group_id: data?.id, users: `${users.map((userData) => userData.id)}` })
              .catch(() => {
                setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
              });
            setUserList([]);
            if (createGroupMemberData?.success) {
              setSelectedUsers([]);
              setOperationStatus(true);
              onHide();
            } else {
              setAppSnackbar({ isVisible: true, type: 'error', message: createGroupMemberData?.message });
              setOperationStatus(false);
            }
          } else {
            setAppSnackbar({ isVisible: true, type: 'error', message: data?.message });
          }
        })
        .catch(() => {
          setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
        });
    }
    setActionStatus(false);
  };

  useEffect(() => {
    if (location.search?.includes(Pathname.createGroup)) {
      revealGroupCreationModal();
      setTempGroupTitle(undefined);
    } else if (location.search?.includes(Pathname.updateGroup)) {
      const selectedId = location.search.split('?');
      if (selectedId[2]) {
        setTempGroupID(selectedId[2]);
        setTempGroupCreationStatus(true);
        revealGroupCreationModal();
      }
    }
  }, [location.search]);

  return (
    <div>
      <ShareScreen
        toastForGroup={toastForGroup}
        setToastForGroup={setToastForGroup}
        actionButtonLoadingText={tempGroupCreated ? `${groupsText?.addingUsers || 'Adding Users'}...` : `${groupsText?.creatingGroup || 'Creating Group'}...`}
        actionButtonText={tempGroupCreated ? (groupsText?.addUser || 'Add Users') : (groupsText?.createGroup || 'Create Group')}
        apiEndpoint={endpoints.searchUserByEmailFromFrendList}
        errorText={tempGroupCreated ? (commonText?.somethingWentWrong || 'Oops! Something went wrong') : (groupsText?.groupNameNotAvailable || 'This group name is not available')}
        hideContent={!tempGroupCreated}
        imageEditable
        inputPlaceholder={groupsText?.searchFriend || 'Search by name or email'}
        isVisible={groupCreationModalIsVisible}
        multipleSelect
        notes={groupsText?.enterEmail || 'Enter an email to search'}
        noUsersFoundText={groupsText?.noFriend || 'No friends found with this email'}
        onContinue={tempGroupID ? (tempGroupCreated ? addMembersToGroup : createGroup) : createNewGroup}
        onHide={hideGroupCreationModal}
        successText={tempGroupCreated ? (groupsText?.successMsg || 'Group created successfully') : (groupsText?.userSuccessMsg || 'Successfully added users to group')}
        titleEditable={!tempGroupTitle}
        titlePlaceholder={groupsText?.groupCreatePlaceholder || 'Enter Name for the Group'}
        tempGroupTitle={tempGroupTitle}
        title={tempGroupTitle}
        oldGroupMember={oldMembers}
      />
    </div>
  );
}
