/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { DeleteOutline, Cancel } from '@material-ui/icons';
import cn from 'classnames';
import styles from '../index.module.css';

export default function InputRow({
  onChange, idx, removeThisRow, defaultFirstName, defaultLastName,
  defaultEmail }) {
  const [data, setData] = useState({ email: '', firstName: '', lastName: '', idx });
  const { email, firstName, lastName } = data;
  useEffect(() => {
    if (email || firstName || lastName) {
      onChange(data);
    }
  }, [data]);
  const [removeStatus, setRemoveStatus] = useState(false);

  return (
    <>
      <div
        className={styles.viewerDetailsIndex}
        style={{ width: '30px', cursor: 'pointer', justifyContent: 'center' }}
        onMouseEnter={() => setRemoveStatus(true)}
        onMouseLeave={() => setRemoveStatus(false)}
        onClick={() => { removeStatus ? removeThisRow(idx) : null; }}
      >
        {removeStatus ? <Cancel color="error" /> : (idx + 1)}
      </div>
      <div className={styles.viewerDetailsInputBox}>
        <input
          defaultValue={defaultEmail}
          onChange={(e) => setData({ ...data, email: e.target.value })}
          className={styles.viewerDetailsInput}
          placeholder="Email"
        />
      </div>
      <div className={styles.viewerDetailsInputBox}>
        <input
          defaultValue={defaultFirstName}
          onChange={(e) => setData({ ...data, firstName: e.target.value })}
          className={styles.viewerDetailsInput}
          placeholder="First Name"
        />
      </div>
      <div className={styles.viewerDetailsInputBox}>
        <input
          defaultValue={defaultLastName}
          onChange={(e) => setData({ ...data, lastName: e.target.value })}
          className={styles.viewerDetailsInput}
          placeholder="Last Name"
        />
      </div>

      <hr className={cn({ [styles.modalDivider]: true, [styles.viewerDetailsInputGridDivider]: true })} />
    </>
  );
}
