import styles from './index.module.css';

function FormSection({ children, title }) {
  return (
    <div className={styles.formSection}>
      <h4 className={styles.title}>
        {title}
      </h4>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

export default FormSection;
