/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import {
  DialogContent,
  Grid,
  Divider
} from '@material-ui/core';
import { ReactComponent as SelectPhoto } from 'Assets/select-photo.svg';
import { DialogFooter } from './Material-reDesign';
import styles from '../index.module.css';
import '../loader.css';

const VideoSelect = ({ fileInputRef, PhotoFile, uploadText }) => (
  <>
    <DialogContent
      dropEffect="copy"
      className={styles.content}
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      <input
        accept=".png,.jpg,.jpeg,.heif,.heic,"
        multiple
        id="image-file"
        ref={fileInputRef}
        onChange={(e) => {
          PhotoFile(e);
        }}
        style={
          {
            width: '90%',
            height: '100%',
            position: 'absolute',
            opacity: 0
          }
        }
        className="inputDragDrop"
        type="file"
      />
      <label htmlFor="video-file" className={styles.addFilesSection}>

        <div className={styles.addFilesSectionImageContainer}>
          <SelectPhoto className={styles.addFilesSectionImage} />
        </div>
        <div className={styles.addFilesSectionText}>
          <div className={styles.addFilesSectionTextTitle}>{ 'Drag and drop photo/s files to upload' || uploadText?.selectPhotoDescription || ''}</div>
          <div className={styles.addFilesSectionTextDescription}>
            {uploadText?.selectPhotoPrivacy || 'Your photos will be private until you publish them.'}
          </div>
        </div>
        <div className={styles.addFilesSectionButton}>{uploadText?.selectFiles || 'Select Files'}</div>
      </label>
    </DialogContent>

    <DialogFooter>
      <div className={styles.uploadTerms}>
        {uploadText?.footer || 'By submitting your videos to Projector, you acknowledge that you agree to Projector’s Terms of Service and Community Guidelines. Please make sure that you do not violate others’ copyright or privacy rights. Learn more'}
      </div>
    </DialogFooter>
  </>
);
export default VideoSelect;
