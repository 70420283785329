import React, { useState, useEffect } from 'react';
import 'Pages/userManagement/UserM.css';

const MessageComponent = ({ message, setmangerFunctionData }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsVisible(false);
        setmangerFunctionData(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <div className={`notification ${isVisible ? 'show' : ''}`}>
        {message}
      </div>
    );
  };
  export default MessageComponent;
