import React, { useState, useContext } from 'react';
import useSWR from 'swr';
import { Modal, Snackbar } from 'Components';
import { AppContext } from 'Context';
import { dataFetcher, endpoints } from 'Api';
import PasswordValidator from 'password-validator';
import authStyles from 'Pages/public/authentication/auth.module.css';
import cn from 'classnames';
import { Input } from '../Input';
import styles from './styles.module.css';

const passwordSchema = (new PasswordValidator()).is().min(12).has()
  .symbols(1)
  .has()
  .digits(1)
  .has()
  .uppercase(1)
  .lowercase(1);
const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };
const PasswordEditModal = ({ isVisible, onHide: onClose, title, currentPasswordLabel, newPasswordLabel, passwordRule }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const { langText } = useContext(AppContext);
  const commonText = langText?.common;
  const [snackbar, setSnackbar] = useState(defaultSnackbar);
  const hasUpperCase = /[A-Z]/.test(newPassword);
  const passwordHealth = (
    newPassword
      ? newPassword.length < 12 || !hasUpperCase
        ? 'weak'
        : passwordSchema.validate(newPassword)
          ? newPassword.length >= 12
            ? 'strong'
            : 'medium'
          : 'weak'
      : ''
);

  const onHide = () => {
    setCurrentPassword('');
    setNewPassword('');
    setSnackbar(defaultSnackbar);
    onClose();
  };

  const {
    isValidating: updatingPassword,
    mutate: updatePassword,
  } = useSWR([endpoints.updatePassword, currentPassword, newPassword], {
    fetcher: (url, inputCurrentPassword, inputNewPassword) => dataFetcher(url, {
      old_password: inputCurrentPassword,
      new_password: inputNewPassword,
    }),
    onSuccess: ({ success }) => {
      if (success) {
        setSnackbar({ isVisible: true, type: 'success', message: 'Password was updated successfully' });
        setTimeout(onHide, 1500);
      } else {
        setSnackbar({ isVisible: true, type: 'error', message: 'Incorrect current password' });
      }
    },
    onError: (error) => {
      setSnackbar({ isVisible: true, type: 'error', message: error?.response?.data?.message });
    },
  });

  return (
    <>
      <Modal
        makeItSmall="yes"
        isVisible={isVisible}
        cancelButton={commonText?.cancel || 'Cancel'}
        cancelButtonClassName={styles.actionButton}
        continueButton={updatingPassword ? `${commonText?.updating || 'Updating'} ...` : (commonText?.update || 'Update')}
        continueButtonClassName={styles.actionButton}
        continueButtonProps={{
          isDisabled: (!((passwordHealth === 'strong') || (passwordHealth === 'medium'))) || updatingPassword
        }}
        onCancelButtonClick={onHide}
        onContinueButtonClick={updatePassword}
        onHide={onHide}
        title={title}
      >
        <br />
        <Input
          className={styles.inputField}
          containerClassName={styles.inputFieldContainer}
          label={currentPasswordLabel}
          labelClassName={styles.inputFieldLabel}
          maxLength={30}
          minLength={12}
          onInput={(e) => {
            const { target: { value: inputCurrentPassword } } = e;

            setCurrentPassword(inputCurrentPassword);
          }}
          type="password"
        />
        <br />
        <Input
          className={styles.inputField}
          containerClassName={styles.inputFieldContainer}
          label={newPasswordLabel}
          labelClassName={styles.inputFieldLabel}
          maxLength={newPassword && 30}
          minLength={12}
          onPaste={(e) => {
            const pastedText = (e.clipboardData || window.clipboardData).getData('text');
            // Check if the pasted text exceeds 30 characters
            if (pastedText.length > 30) {
                // alert('Password cannot exceed 30 characters.');
                e.preventDefault();
            }
            const totalLength = e.target.value.length + pastedText.length;
            if (totalLength > 30) {
              e.preventDefault();
            }
        }}
        onInput={(e) => {
          const { target: { value: inputNewPassword } } = e;
          // Check if the length of inputNewPassword exceeds 30 characters
          if (inputNewPassword.length > 30) {
            // alert('Password cannot exceed 30 characters.');
            // Trim the password to the maximum length
            setNewPassword(inputNewPassword.slice(0, 30));
          } else {
            setNewPassword(inputNewPassword);
          }
        }}
          type="password"
        />
          <div className={authStyles.conditons}>
              <p style={{ fontWeight: '400', color: newPassword && newPassword.length >= 12 ? '#23e223' : 'black' }}>12 - 30 Characters</p>
              <p style={{ fontWeight: '400', color: newPassword && hasUpperCase ? '#23e223' : 'black' }}>Minimum of one uppercase</p>
              <p style={{ fontWeight: '400', color: newPassword && newPassword.match(/[a-z]/) ? '#23e223' : 'black' }}>Minimum of one lowercase</p>
              <p style={{ fontWeight: '400', color: newPassword && newPassword.match(/[0-9]/) ? '#23e223' : 'black' }}>Minimum of one number</p>
              <p style={{ fontWeight: '400', color: newPassword && newPassword.match(/[!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/) ? '#23e223' : 'black' }}>Minimum of one special character</p>
          </div>
        {/* <div className={cn({ [authStyles.passwordStrengthBar]: true, [styles.passwordStrengthBar]: true })}>
          <div
            className={cn({
              [authStyles.passwordStrengthBarContent]: true,
              ...passwordHealth && { [authStyles[passwordHealth]]: true }
            })}
            style={{
              width: passwordHealth === 'weak'
                ? '30%'
                : passwordHealth === 'medium'
                  ? '55%'
                  : passwordHealth === 'strong'
                    ? '100%'
                    : '0%'
            }}
          />
        </div> */}
        {/* <div className={cn({ [authStyles.passwordStrengthNotes]: true, [styles.passwordStrengthNotes]: true })}>
          <div style={{ color: 'black' }}>{passwordRule}</div>
          <div
            className={cn({
              [authStyles.currentPasswordStrength]: true,
              ...passwordHealth && { [authStyles[passwordHealth]]: true },
            })}
            onKeyPress={undefined}
            role="button"
            tabIndex={0}
          >
            {passwordHealth}
          </div>
        </div> */}
      </Modal>

      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
    </>
  );
};
export default PasswordEditModal;
