import React from 'react';
import TermsAndCondition from 'Components/Doc/TermsAndCondition';
import { Page } from './landing/components/page';

export function TermsAndConditions() {
  return (
    <Page>
      <TermsAndCondition isDark />
    </Page>
  );
}
