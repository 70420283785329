import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { Collapse, Zoom } from '@material-ui/core';
import { Pathname } from 'Routes';
import { AppContext } from 'Context';
import AlterImage from 'Assets/alter-image.png';
import styles from './index.module.css';

const transitionTimeout = 300;

const UserRequest = ({ email, id, firstname, lastname, image, setSnackbar, hasAccess, fetchAccessRequests, setAccessRequests,
  setHasAccess, type, fetchViewerList }) => {
  const navigate = useNavigate();
  const [action, setAction] = useState(undefined);
  const [isVisible, setVisibility] = useState(true);
  const { subscriptionDetails, langText, getSubscription } = useContext(AppContext);
  const viewerText = langText?.viewersSideBar?.viewers;
  const commonText = langText?.common;
  const viewerLimitCondition = parseInt(subscriptionDetails?.subscription?.is_unlimited, 10) !== 1 && parseInt(subscriptionDetails?.total_viewers, 10) >= parseInt(subscriptionDetails?.subscription?.max_view_request, 10);
  const [disabled, setDisabled] = useState({ isDisabled: false, error: undefined });
  useEffect(() => {
    // const total_viewer = 20;
    const total_viewer = subscriptionDetails?.total_viewer;
    const current_plan = subscriptionDetails?.current_plan;
    if (current_plan) {
      if (current_plan === 'Standard') {
        if (total_viewer >= 10) {
          // current_plan === standard
          setDisabled({ isDisabled: true, error: 'You must upgrade your plan to accept this request' });
        } else {
          // current_plan === standard
          setDisabled({ isDisabled: false, error: undefined });
        }
      } else {
        // current_plan === premium
        setDisabled({ isDisabled: false, error: undefined });
      }
    } else {
      // current_plan === free
      setDisabled({ isDisabled: true, error: 'You must have a plan to accept this request' });
    }
  }, [subscriptionDetails]);
  const { isValidating: handlingAction, mutate: handleAction } = useSWR([endpoints.updateViewRequest, id, action], {
    fetcher: (url, user, status) => dataFetcher(url, { user, status }),
    onSuccess: ({ success }) => {
      if (success) {
        // setVisibility(false);
        getSubscription();
        setAccessRequests([]);
        fetchAccessRequests();
        fetchViewerList();
        setSnackbar({
          isVisible: true,
          message: `${firstname ? `${firstname}'s r` : 'R'}equest ${action === 1 ? 'accepted' : 'declined'}`
        });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  const { isValidating: deletingUser, mutate: deleteUser } = useSWR([endpoints.deleteMyViewer, id], {
    fetcher: (url, userId) => dataFetcher(url, { id: userId }),
    onSuccess: ({ success }) => {
      if (success) {
        setHasAccess([]);
        // setVisibility(false);
        getSubscription();
        fetchAccessRequests();
        setSnackbar({
          isVisible: true,
          message: `${firstname ? `${firstname}` : 'User'} removed`
        });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
      setVisibility(false);
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });
  const [cancelMe, setCancelMe] = useState(false);
  const { isValidating: cancelling, mutate: cancelInvitation } = useSWR([
    endpoints.cancelInvitation, email], {
    fetcher: (url, email) => dataFetcher(url, { email }),
    onSuccess: ({ success, data }) => {
      if (success) {
        fetchAccessRequests();
        getSubscription();
           const timer = setTimeout(() => {
          setCancelMe(false);
        }, 2000);
        return () => clearTimeout(timer);
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  const handleAccept = () => {
    if (disabled.isDisabled) {
      setSnackbar({ isVisible: true, message: disabled.error, type: 'error' });
    } else {
      viewerLimitCondition ? navigate(Pathname.upgradePlan) : setAction(1);
    }
  };
  useEffect(() => { if (action) { handleAction(); } }, [handleAction, action]);

  return (
    <Collapse in={isVisible} timeout={transitionTimeout}>
      <Zoom in={isVisible} timeout={transitionTimeout}>
        <div className={styles.userRequest}>
          <div>
            {
              image ? (
                <img
                  className={styles.profileIcon}
                  src={image || AlterImage}
                  onError={(e) => {
                    e.target.src = AlterImage;
                  }}
                />
              ) : (
                <div className={styles.profileIcon}>
                  {`${firstname ? firstname.charAt(0).toUpperCase() : email.charAt(0).toUpperCase()}`}
                </div>
              )
            }
            <div className={styles.userRequestText}>
              <div className={styles.userRequestName}>{`${firstname} ${lastname}`}</div>
              <div className={styles.userRequestEmail}>{email}</div>
            </div>
          </div>
          {hasAccess
            ? (
              <div className={styles.userRequestActionsContainer}>
                <button
                  className={cn({ [styles.userRequestActionButton]: true, [styles.delete]: true, [styles.deleteActive]: true })}
                  onClick={deleteUser}
                >
                  {deletingUser ? `${(commonText?.removing || 'Removing')}...` : (commonText?.remove || 'Remove')}
                </button>
              </div>
            )
            : (
              type === 'invitation' ? (
                <div className={styles.userRequestActionsContainer}>
                  <button
                    className={cn({
                      [styles.pendingInvite]: true,
                    })}
                    style={{ cursor: cancelMe === true ? 'default' : 'pointer' }}
                    onClick={() => {
                      if (cancelMe === false) {
                        setCancelMe(true);
                        cancelInvitation();
                      }
                    }}
                  >
                {cancelMe === false ? viewerText?.cancelInvite || 'Cancel invite' : 'Canceling'}
                  </button>
                </div>
              ) : (
                <div className={styles.userRequestActionsContainer}>
                  <button
                    className={styles.userRequestActionButton}
                    onClick={() => { handleAccept(); }}
                  >
                    {(handlingAction && (action === 1)) ? `${(commonText?.accepting || 'Accepting')}...` : (commonText?.accept || 'Accept')}
                  </button>
                  <button
                    className={cn({
                      [styles.userRequestActionButton]: true,
                      [styles.delete]: true,
                      [styles.deleteActive]: true
                    })}
                    onClick={() => { setAction(2); }}
                  >
                    {(handlingAction && (action === 2)) ? `${(commonText?.declining || 'Declining')}...` : (commonText?.decline || 'Decline')}
                  </button>
                </div>
              )
            )}
        </div>
      </Zoom>
    </Collapse>
  );
};
export default UserRequest;
