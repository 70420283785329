import React, { useContext, useEffect } from 'react';
import useMyAxios from 'hooks/useMyAxios';
import { getAllActivePlans } from 'Api/apiFunctions';
import Plan from 'Pages/public/landing/plans/plan';
import { AppContext, AuthContext } from 'Context';
import PlansCard from 'Components/PlansCard';
import { Box, CircularProgress } from '@material-ui/core';
import markWhite from '../assets/img/mark-white.svg';
import markBlack from '../assets/img/mark-black.svg';
import Button from '../common/Button';

const Pricing = () => {
  const { userIsSignedIn, getToken } = useContext(AuthContext);
if (userIsSignedIn === false && getToken() === null) {
    localStorage.setItem('storedPath', window.location.pathname);
}

  const {
    response: plans,
    error: plansErr,
    loading: plansLoading,
    refetch: refetchPlans,
  } = useMyAxios(getAllActivePlans, null, 'ALL_ACTIVE_PLANS');

  return (
    <div className="pricing section">
      <div className="container">
        <h2 data-aos="fade-down" className="title">
          Pricing
        </h2>
        <Box marginBottom={7}>

          <p data-aos="fade-down" className="subtitle">
          Stream Generations of Home Movies. It&apos;s Netflix for your family.
          </p>
        </Box>

        <div className="plans">
          <div
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-anchor-placement="bottom-bottom"
            className="clearWidfth plan"
          >
            {!plansLoading && plans && plans?.data && plans?.data?.length > 0
              ? plans?.data?.map((plan, index) => (
                <PlansCard
                  key={index}
                  description={plan?.description}
                  price_description={plan?.price_description}
                  amount={plan?.amount}
                  has_trial={plan?.has_trial}
                  product_name={plan?.product_name}
                  subtitle={plan?.sub_title}
                />
              ))
              : plansLoading && <CircularProgress />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
