/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useContext, useRef } from 'react';
import AWS from 'aws-sdk';
import { initializeApp } from 'firebase/app';
import { ref, get, getDatabase, off, onValue, update } from 'firebase/database';
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { saveUploadInfo, saveUploadDetails, saveDummy, removeDummy } from 'store/store';
import fileSize from 'filesize';
import { addS3VideoDraftContent, addVideoDraftContent } from 'Api/apiFunctions';
import {
  DialogContent,
  CircularProgress as MuiCircularProgress, withStyles
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Pathname } from 'Routes';
import { AppContext, AuthContext } from 'Context';
import { ReactComponent as SelectVideo } from 'Assets/select-video.svg';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { firebaseCredentials, axios, baseUrl } from 'Api';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from 'Components/Spinner';
import { DialogFooter } from './Material-reDesign';
import styles from '../index.module.css';
import '../loader.css';
import { awsConfig, generateS3Url, getBucketName, getBucketNamePath } from '../../../Api/awsConfig';

const iteratorNum = [];
const totalNum = [];
let tempPercentages;
const formDataJsonString = [];

AWS.config.update({
  region: awsConfig.region,
  accessKeyId: awsConfig.accessKey,
  secretAccessKey: awsConfig.secretKey,
});
AWS.config.httpOptions.timeout = 0;
const s3 = new AWS.S3();
async function initMultipartUpload(bucket, key) {
  const params = {
    Bucket: bucket,
    Key: key,
  };

  return s3.createMultipartUpload(params).promise();
}

const VideoSelect = ({ handleClose, fileInputRef, VideoFile, uploadText, onHide, hideModal }) => {
  const { startingFlowEvent, setIamUploading, isSingleUpload, closeModal,
    videoCount, setS3Data, setVideoCount, setSingleUpload, userDetails,
    setVideoUploadedParts, setUploadContainer, setS3UploadComplete, setfetchDraftID,
    setFormData, firebaseDatabase, disableDrag, setdisableDrag, setSelectedFiles,
    setAppSnackbar, MAX_VIDEOS, setUploadContainer2, subscriptionDetails, setTrailPopUpModalVisible } = useContext(AppContext);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const userId = userDetails?.isSubscription?.user_id;
  const uploadPercentages = useSelector((state) => state.upload);
  const dispatch = useDispatch();
  const [loadingForDrag, setloadingForDrag] = useState(false);
  const [dragText, setdragText] = useState(`Drag and drop up to ${MAX_VIDEOS} video/s to upload`);
  const updateUploadPercentages = (draft_id, data) => {
    dispatch(saveUploadInfo({ [draft_id]: data }));
  };
  const updateUploadDetails = (draft_id, data) => {
    dispatch(saveUploadDetails({ [draft_id]: data }));
  };
  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  // useEffect(() => {
  //   const dataCallback = (snapshot) => {
  //     if (snapshot.exists()) {
  //       const dataObj = snapshot.val();
  //       setData(dataObj);
  //     }
  //   };
  //   onValue(databaseRef, dataCallback);
  //   return () => {
  //     off(databaseRef, 'value', dataCallback);
  //   };
  // }, []);
  const closeToast = (draft_id, message, type) => {
    toast.update(draft_id, { closeButton: false, render: message, type: type === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR, autoClose: 3000 });
    setUploadContainer((prevUploadContainer) => prevUploadContainer.filter((id) => id !== draft_id));
  };
  const updateDummy = (dummy, status) => {
    if (status === 1) {
      dispatch(saveDummy({ [dummy?.draft_id]: dummy }));
    } else {
      dispatch(removeDummy(dummy));
    }
  };
  const changeFieldFromFirebase = (draft_id, statusString) => {
    const dataToUpdate = {
      [draft_id]: {
        status: statusString,
      },
    };
    update(databaseRef, dataToUpdate);
  };
  const { getToken } = useContext(AuthContext);
  const spinerSmall = 'spinex';
  const CustomCloseloader = () => (
    <button className={styles.closeMeBro}>
    <Spinner spinex={spinerSmall} />
    </button>
  );
  const handleEncouter = ({ draft_id }) => {
    toast.update(draft_id, {
      closeButton: <CustomCloseloader />,
    });
    if (localStorage.getItem('opened_draft_id') === draft_id) {
      hideModal();
    }

    changeFieldFromFirebase(draft_id, 'DELETED');
    updateDummy(draft_id, 0);
    updateUploadPercentages(draft_id, 'Cancelled');
    closeToast(draft_id, 'Upload has been canceled by user', 'error');
    // const deletePromise = axios
    //       .post(`${baseUrl}/deleteDraftContent`, {
    //         token: getToken(),
    //         draft_id: [draft_id],
    //         content_id: [],
    //       })
    //       .then((response) => {
    //         changeFieldFromFirebase(draft_id, 'DELETED');
    //         updateDummy(draft_id, 0);
    //         closeToast(draft_id, 'Upload has been canceled by user', 'error');
    //         return response;
    //       })
    //       .catch((error) => {
    //         console.error(`Error deleting items for draftId ${draft_id}:`, error);
    //         throw error;
    //       });
  };

  const backToOld = ({ draft_id }) => {
    toast.update(draft_id, {
      closeButton: <CustomCloseButton draft_id={draft_id} />,
    });
  };
  const CustomCloseDiv = (draft_id) => (
    <div className={styles.customDiv}>
      Are you sure you want to cancel ?
      <div className={styles.fleo}>
        <button className={styles.cancelPlz} onClick={() => backToOld(draft_id)}>
        Exit
        </button>
        <button className={styles.noCancel} onClick={() => handleEncouter(draft_id)}>
          Continue to cancel
        </button>
      </div>
    </div>
  );
  const handleClick = (draft_id) => {
    toast.update(draft_id, {
      closeButton: <CustomCloseDiv draft_id={draft_id} />,
    });
  };
  const CustomCloseButton = ({ draft_id }) => (
    <button className={styles.closeMeBro} onClick={() => handleClick(draft_id)}>
    <CloseIcon />
    </button>
  );
  const notify = (filename, draft_id, uploadPercentages, updateUploadPercentages) => {
    setTimeout(() => {
      const newPercentages = { ...uploadPercentages, [draft_id]: 'Starting Upload' };
      tempPercentages = newPercentages;
      updateUploadPercentages(draft_id, 'Starting Upload');
      const trimmedFilename = filename.length > 26 ? `${filename.substring(0, 26)}...` : filename;
      toast(trimmedFilename, {
        autoClose: false,
        toastId: draft_id,
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        toastClassName: 'custom-toast'
      });
    }, 0);
  };
  // const hide = () => {
  //   for (i = 0; i <= uploadPercentages.length; i+=1) {
  //     toast.hide(uploadPercentages[i].draft_id);
  //   }
  // };
  // const show = () => {
  //   for (i = 0; i <= uploadPercentages.length; i+=1) {
  //     toast.show(uploadPercentages[i].draft_id);
  //   }
  // };
  const updateItem = (percentage, draft_id, filename, uploadPercentages, updateUploadPercentages) => {
  const percent = parseInt((percentage / totalNum[draft_id]) * 100, 10);
  const trimmedFilename = filename.length > 26 ? `${filename.substring(0, 26)}...` : filename;
    const newPercentages = { ...uploadPercentages, [draft_id]: percent };
    tempPercentages = newPercentages;
    updateUploadPercentages(draft_id, percent);
    toast.update(draft_id, { render: `${trimmedFilename} ${percent}%`, autoClose: false });
  };
  async function uploadPart(bucket, key, partNumber, partBody, uploadId, setVideoUploadedParts, filename, draft_id, uploadPercentages, updateUploadPercentages, partSize, startTime, fileSize) {
    const params = {
      Bucket: bucket,
      Key: key,
      PartNumber: partNumber,
      UploadId: uploadId,
      Body: partBody,
    };
    return s3.uploadPart(params)
      .promise()
      .then((data) => {
        const currentTime = new Date().getTime();
        const elapsedSize = (partNumber * partSize) / 1024 / 1024;
        const elapsedTime = (currentTime - startTime) / 1000;
        const remainingSize = (fileSize / 1024 / 1024) - elapsedSize;
        updateUploadDetails(draft_id, { elapsed_size: elapsedSize, elapsed_time: elapsedTime, remaining_size: remainingSize > 0 ? remainingSize : 0 });
        iteratorNum[draft_id] += 1;
        updateItem(iteratorNum[draft_id], draft_id, filename, uploadPercentages, updateUploadPercentages);
        setVideoUploadedParts((prevState = []) => [...prevState, partNumber]);
        return data; // Return the result of the successful upload
      })
      .catch((error) => {
        console.error(`AWS Multipart Upload : Error uploading part ${partNumber}:`, error);
        throw error; // Rethrow the error to be handled by the caller
      });
  }
  async function completeMultipartUpload(bucket, key, parts, uploadId) {
    const params = {
      Bucket: bucket,
      Key: key,
      MultipartUpload: {
        Parts: parts,
      },
      UploadId: uploadId,
    };
    return s3.completeMultipartUpload(params).promise();
  }
  // temp add
  useEffect(() => {
    // const allPropertiesAreNumericOrString = Object.values(uploadPercentages).some((value) => typeof value === 'number' || value === 'Starting Upload' || value === 'Initializing');
    // if (allPropertiesAreNumericOrString) {
    //   setIamUploading(false);
    // } else setIamUploading(true);
    const handleBeforeUnload = (e) => {
      const allPropertiesAreNumericOrString = Object.values(uploadPercentages).some((value) => typeof value === 'number' || value === 'Starting Upload' || value === 'Initializing');
      if (allPropertiesAreNumericOrString) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadPercentages]);
  // temp end
  const CircularProgress = withStyles({
    circle: {
      color: '#000 !important'
    }
  })(MuiCircularProgress);
  const handleCompleteS3Upload = (data, draft_id) => {
    setfetchDraftID(draft_id);
    if (formDataJsonString[draft_id]) {
      let formDataArray = JSON.parse(formDataJsonString[draft_id]);
      formDataArray = formDataArray.map(([key, value]) => {
        if (key === 's3_url') {
          return [key, data?.Location];
        }
        return [key, value];
      });
      // Create a video element to load the video file
      try {
        const videoPlayer = document.createElement('video');
        videoPlayer.src = data?.Location;
        videoPlayer.addEventListener('loadedmetadata', () => {
          // eslint-disable-next-line
          const videoHeight = videoPlayer.videoHeight;
          // eslint-disable-next-line
          const videoWidth = videoPlayer.videoWidth;
          formDataArray.push(['videoHeight', videoHeight]);
          formDataArray.push(['videoWidth', videoWidth]);
        });
        videoPlayer.load();
      } catch (error) {
        //
      }
      formDataArray.push(['s3_status', 'Completed']);
      const retrievedFormData = new FormData();
      formDataArray.forEach(([key, value]) => {
        retrievedFormData.append(key, value);
      });
      setFormData(retrievedFormData);
      setS3UploadComplete((prevStatus) => ({
       ...prevStatus,
       [draft_id]: true,
     }));
    }
  };
  async function uploadPartSequentially(file, bucket, key, uploadId, partNumber, start, partSize, parts, draft_id, startTime) {
    const end = Math.min(start + partSize, file.size);
    const data = {};
    const dataCallback = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        data[draft_id] = dataObj[draft_id];
      }
    };
    onValue(databaseRef, dataCallback);
    const unsubscribe = () => {
      off(databaseRef, 'value', dataCallback);
    };
    if (data[draft_id]?.status !== 'DELETED') {
      await uploadPart(getBucketName(userId), key, partNumber, file.slice(start, end), uploadId, setVideoUploadedParts, file.name, draft_id, uploadPercentages, updateUploadPercentages, partSize, startTime, file.size)
      .then((item) => {
        parts.push({ PartNumber: partNumber, ETag: item.ETag });
      });
    } else {
      unsubscribe();
    }
    unsubscribe();
    if (end < file.size) {
      await uploadPartSequentially(file, bucket, key, uploadId, partNumber + 1, end, partSize, parts, draft_id, startTime);
    }
  }
  async function uploadLargeFile(bucket, key, file, draft_id) {
    setUploadContainer((prevUploadContainer) => [...prevUploadContainer, draft_id]);
    setUploadContainer2((prevUploadContainer) => [...prevUploadContainer, draft_id]);

    try {
      notify(file.name, draft_id, uploadPercentages, updateUploadPercentages);
      const { UploadId } = await initMultipartUpload(bucket, key);
      const minimumPartSize = 5 * 1024 * 1024; // 5MB -- aws s3 bucket requires atlease 5MB/packet currently
      let partSize = file.size / 100; // splitting to 100 packets to perform 1 packet as for 1 percent
      if (partSize < minimumPartSize) {
        partSize = minimumPartSize;
      }
      const fileSize = file.size;
      const partCount = Math.ceil(fileSize / partSize);
      const startTime = new Date().getTime();
      const parts = [];
      iteratorNum[draft_id] = 0;
      totalNum[draft_id] = partCount;
      await uploadPartSequentially(file, bucket, key, UploadId, 1, 0, partSize, parts, draft_id, startTime);
      const data = {};
      const dataCallback = (snapshot) => {
        if (snapshot.exists()) {
          const dataObj = snapshot.val();
          data[draft_id] = dataObj[draft_id];
        }
      };
      onValue(databaseRef, dataCallback);
      const unsubscribe = () => {
        off(databaseRef, 'value', dataCallback);
      };
      if (data[draft_id]?.status !== 'DELETED') {
        const uploadedData = await completeMultipartUpload(bucket, key, parts, UploadId);
        handleCompleteS3Upload(uploadedData, draft_id);
        closeToast(draft_id, 'Uploading completed', 'success');
        const newPercentages = { ...uploadPercentages, [draft_id]: 'Please wait' };
        tempPercentages = newPercentages;
        updateUploadPercentages(draft_id, 'Please wait');
        iteratorNum[file.name] = 0;
        totalNum[draft_id] = 0;
        setIamUploading(true);
      } else {
        updateUploadPercentages(draft_id, 'Cancelled');
        closeToast(draft_id, 'Upload has been canceled by user', 'error');
        unsubscribe();
      }
      unsubscribe();
    } catch (error) {
      if (localStorage.getItem('opened_draft_id') === draft_id) {
        hideModal();
      }
      updateUploadPercentages(draft_id, 'Cancelled');
      closeToast(draft_id, 'Upload has been canceled by user', 'error');
      const newPercentages = { ...uploadPercentages, [draft_id]: 'Cancelled' };
      tempPercentages = newPercentages;
      updateUploadPercentages(draft_id, 'Cancelled');
      updateUploadDetails(draft_id, { elapsed_size: 0, elapsed_time: 0, remaining_size: 0 });
      const allPropertiesAreNumericOrString = Object.values(uploadPercentages).some((value) => typeof value === 'number' || value === 'Starting Upload' || value === 'Initializing');
      if (allPropertiesAreNumericOrString) {
        setIamUploading(false);
      } else setIamUploading(true);
    }
  }

  function changeSize(size) {
    const newSize = size / 1024 / 1024;
    return newSize.toFixed(2);
  }

// ------------------------------------AWS Multipart Upload Code --------------------------------------------------
  useEffect(() => {
    setVideoCount(0);
    startingFlowEvent(true);
    // if (closeModal) {
    //   setModalVisibility(false);
    // }
  }, []);

  useEffect(() => {
    if (isSingleUpload) {
      VideoFile(isSingleUpload);
    }
  }, [isSingleUpload]);
  useEffect(() => {
    if (closeModal) {
      sessionStorage.setItem('tabTitle', 2);
      navigate(Pathname.yourVideos);
      onHide();
    }
  }, [closeModal]);

  // A array is created to store files which will be used in app.jsß
  function generateThumbnails(videoFile) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 100;
    canvas.height = 100;
    context.fillStyle = 'red';
    context.fillRect(0, 0, canvas.width, canvas.height);
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve([blob]); // Wrap the blob in an array to ensure it is iterable
      });
    });
  }
const tryToPurchase = () => {
  handleClose();
  setTrailPopUpModalVisible(true);
};
  async function handleUploadFiles(e) {
    const allowedFormats = ['.mp4', '.mov', '.mpg', '.m4v', '.m2ts'];
    const MAX_SIZE_GB = 30;
    const MAX_SIZE_BYTES = MAX_SIZE_GB * 1024 * 1024 * 1024;
    const files = Array.from(e.target.files);
    if (files.length > MAX_VIDEOS && subscriptionDetails?.subscription?.is_trial === '1') {
      setAppSnackbar({ isVisible: true,
type: 'error',
message: (<>
            You can select a maximum of
{' '}
{MAX_VIDEOS}
{' '}
videos.
{' '}
            <span
              style={{ color: '#0075FF', cursor: 'pointer' }}
              onClick={() => tryToPurchase()}
            >
              {' '}
              Upgrade
            </span>
          </>
        ),
        duration: 5000,
      });

      return; // Don't proceed with the upload
    }
    if (files.length > MAX_VIDEOS) {
      setAppSnackbar({ isVisible: true, type: 'error', message: `You can select a maximum of ${MAX_VIDEOS} videos.` });
      return; // Don't proceed with the upload
    }
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (totalSize > MAX_SIZE_BYTES) {
      setAppSnackbar({ isVisible: true, type: 'error', message: `Total file size should not exceed ${MAX_SIZE_GB}GB.` });
      return; // Don't proceed with the upload
    }

    if (e.target.files.length === 1) {
      const file = e.target.files[0];
      if (!file) {
        return; // No file selected
      }
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(`.${fileExtension}`)) {
        setAppSnackbar({ isVisible: true, type: 'error', message: `Invalid file format. Allowed formats are: ${allowedFormats.join(', ')}` });
        return; // Don't proceed with the upload
      }
      const blobFile = []; // Array to store blob files
      const thumbnails = await generateThumbnails(file); // Wait for thumbnail generation
      // Create blob for the video file
      const videoBlob = new Blob([file], { type: file.type });
      blobFile.push(videoBlob);
      // Create blob for each thumbnail and push to blobFile array
      thumbnails.forEach((thumbnail) => {
        const thumbnailBlob = new Blob([thumbnail], { type: thumbnail.type });
        blobFile.push(thumbnailBlob);
      });
      const video = videoRef.current;
      setSingleUpload(file);
      const number = Math.floor(Math.random() * 10000) + 1;
      const fileName = (`${Date.now()}-${userId}-video-${number}.${fileExtension}`);
      const formData = new FormData();
      const initialData = new FormData();
      const s3Url = generateS3Url();
      if (file && video) {
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {
          formData.append('videoDuration', video.duration);
        };
      }
      const draftId = uuid();
      localStorage.setItem('opened_draft_id', draftId);
      initialData.append('draft_id', draftId);
      initialData.append('s3_url', `${s3Url}${fileName}`);
      setS3Data(initialData);
      formData.append('draft_id', draftId);
      formData.append('file_name', fileName);
      // formData.append('videoOreintation', 1);
      formData.append('video_size', changeSize(file.size));
         // Append each thumbnail blob to FormData
         const thumbanilsBlob = [];
         blobFile.forEach((file, index) => {
           thumbanilsBlob.push(file);
         });
      //    blobFile.forEach((file, index) => {
      //      formData.append('thumbnails[]', file, `thumbnail_${index + 1}.jpg`);
      //  });
         // formData.append('category_id', uploadedData?.category_id);
        //  formData.append('thumbnail', blobFile[0]);
      formData.append('s3_image_bucket_url', s3Url);
      formData.append('s3_url', `${s3Url}${fileName}`);
      formData.append('bucket_folder_path', `${getBucketNamePath(userId)}/${fileName}`);
      formDataJsonString[draftId] = JSON.stringify([...formData.entries()]);
      uploadLargeFile(getBucketName(userId), fileName, file, draftId);
    } else if (e.target.files?.length > 1) {
      setSelectedFiles(e.target.files);
      handleClose();
    }
  }
  const handleDragOver = (event) => {
    event.preventDefault();
    if (event.dataTransfer.items.length > 1) {
      setdisableDrag(true);
      // handleClose();
    }
  };
  const dropMe = (event) => {
    // event.preventDefault();
    handleClose();
  };

  // useEffect(() => {
  //   setdisableDrag(false);
  //   return () => {
  //     setdisableDrag(true);
  //   };
  // }, []);
  useEffect(() => {
    if (loadingForDrag) {
      setdragText('Loading');
    }
  }, [loadingForDrag]);
  return (
    <>
      <DialogContent
        dropEffect="copy"
        // onDrop={handleDrop}
        className={styles.content}
        style={{ position: 'relative', overflow: 'hidden' }}
      >
        <input
          onDragOver={handleDragOver}
          onDrop={(event) => {
            setloadingForDrag(true);
            if (disableDrag) {
              dropMe(event);
            }
          }}
          accept=".mp4,.mov,.mpg,.m4v,.m2ts"
          capture="user"
          id="video-file"
          ref={fileInputRef}
          multiple
          onChange={handleUploadFiles}
          style={
            {
              width: '90%',
              height: '100%',
              position: 'absolute',
              // visibility: 'hidden',
              opacity: 0
            }
          }
          className="inputDragDrop"
          type="file"
        />
        <video ref={videoRef} style={{ display: 'none' }} controls />
        <label htmlFor="video-file" className={styles.addFilesSection}>
          <div className={styles.addFilesSectionImageContainer}>
            {
              videoCount ? (
                <CircularProgress />
              ) : <SelectVideo className={styles.addFilesSectionImage} />
            }
          </div>
          <div className={styles.addFilesSectionText}>
            <div className={styles.addFilesSectionTextTitle}>{dragText}</div>
            <div className={styles.addFilesSectionTextDescription}>
              {uploadText?.selectVideoPrivacy || 'Your videos will be private until you publish them.'}
            </div>
          </div>
          <div className={styles.addFilesSectionButton}>{uploadText?.selectFiles || 'Select Files'}</div>
        </label>
      </DialogContent>

      <DialogFooter>
        <div className={styles.uploadTerms}>
          {uploadText?.footer || 'By submitting your videos to Projector, you acknowledge that you agree to Projector’s Terms of Service and Community Guidelines. Please make sure that you do not violate others’ copyright or privacy rights. Learn more'}
        </div>
      </DialogFooter>
    </>
  );
};
export default VideoSelect;
