/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Context';
import cn from 'classnames';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { Grid } from '@material-ui/core';
import styles from './index.module.css';

const CreateSubCategory = ({ DragIcon, setSubCategoryDiv, content_type, isOpenSubCategoryDiv }) => {
  const [val, setVal] = useState(undefined);
  const { setAppSnackbar, langText } = useContext(AppContext);
  const [subCategory, setSubCategory] = useState(undefined);
  const commonText = langText?.common;
  const layoutText = langText?.layout;
  const [subCatId, setSubCatId] = useState(undefined);
  const [editing, setEditing] = useState(false);
  const { isValidating: gettingSubCategories, mutate: getSubCategories } = useSWR([endpoints.getMySubCategory], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, data }) => {
      if (success) {
        setSubCategory(data?.map(({ id, title }) => ({ name: title, value: id })));
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your subcategories',
        });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData || 'Oops! Something went wrong while fetching your subcategories' });
    },
  });

  const { isValidating: addingNewSubCategory, mutate: addOrEditSubCategory } = useSWR([
    endpoints.addUpdateMySubCategory, val, subCatId
  ], {
    fetcher: (url, categoryName, subcategory_id) => dataFetcher(url, {
      title: categoryName,
      subcategory_id
    }),
    onSuccess: ({ success }) => {
      if (success) {
        setSubCategoryDiv(false);
        setAppSnackbar({
          isVisible: true,
          message: `Sub-Category ${val}' was ${subCatId ? 'updated' : 'created'}`,
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
      setEditing(false);
    },
    onError: (error) => {
      setEditing(false);
      const responseData = error?.response?.data?.message;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData || 'Oops! Something went wrong' });
    },
  });

  const { isValidating: removingSubCategory, mutate: deleteSubCategory } = useSWR([
    endpoints.deleteMySubCategory, subCatId
  ], {
    fetcher: (url, subCatId) => dataFetcher(url, {
      subCatId
    }),
    onSuccess: ({ success }) => {
      if (success) {
        setSubCategoryDiv(false);
        setAppSnackbar({
          isVisible: true,
          message: `Sub-Category ${val}' was ${subCatId ? 'updated' : 'created'}`,
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }
    },
    onError: (error) => {
      const responseData = error?.response?.data?.message;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData || 'Oops! Something went wrong' });
    },
  });

  useEffect(() => { getSubCategories(); }, []);

  const editSubCategory = (name, value) => {
    setVal(name);
    setSubCatId(value);
    setEditing(true);
  };

  const removeSubCategory = (name, value) => {
    setVal(name);
    setSubCatId(value);
    if (subCatId) {
      deleteSubCategory();
    }
  };

  return (
    <div className={styles.subCategoryGroup}>
      <div className={styles.subCategoryHeader}>
        <div
          className={cn({ [styles.dragIconContainer]: true, [styles.dragIconContainerType]: true })}
        >
          <DragIcon className={styles.dragIcon} style={{ opacity: 0 }} />
        </div>
        <div
          className={styles.contentTypeTitle}
          onClick={() => { setSubCategoryDiv(true); }}
        >
          <div className={styles.titleStyle}>{content_type}</div>
        </div>
        <div className={styles.contentGroupBody}>
          <div style={{ textAlign: 'center' }} className={styles.contentGroupBodyCell}>
            <div
              className={cn({ [styles.editContentTypeRef]: true, [styles.editContentTypeRefPosition]: true })}
              onClick={() => { setSubCategoryDiv(!isOpenSubCategoryDiv); }}
            >
              <div className={styles.editContentType}>{isOpenSubCategoryDiv ? (commonText?.back || 'Back') : (commonText?.edit || 'Edit')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.addNewCategoryWrap}>
        <div className={styles.addTitle}>{editing ? (layoutText?.editSubCategory || 'Edit Subcategory') : (layoutText?.addNewSubCategory || 'Add New Subcategory')}</div>
        <input
          className={styles.createMenuInput}
        //   defaultValue={val}
          value={val}
          onChange={(e) => { setVal(e.target.value); }}
          maxLength="16"
        />
        <div className={styles.addButtonWrap}>
          <div
            style={{
              opacity: addingNewSubCategory || !val ? 0.25 : 1,
              pointerEvents: addingNewSubCategory || !val ? 'none' : 'initial'
            }}
            onClick={() => { addOrEditSubCategory(); }}
            className={styles.addButton}
          >
            {editing ? (commonText?.save || 'Save') : (commonText?.add || 'Add')}
          </div>
        </div>
        <div className={styles.existingSubCatWrap}>
          {
            subCategory?.map(({ name, value }) => (
              <Grid container className={styles.subCatDiv}>
                <Grid item xs={7}>
                  {name}
                </Grid>
                <Grid onClick={() => { editSubCategory(name, value); }} item xs={2} className={styles.editSubCatButton}>
                  {commonText?.edit || 'Edit'}
                </Grid>
                <Grid onClick={() => { removeSubCategory(name, value); }} item xs={3} className={styles.removeSubCatButton}>
                  {removingSubCategory && value === subCatId ? (commonText?.removing || 'Removing') : (commonText?.remove || 'Remove')}
                </Grid>
              </Grid>
            ))
        }
        </div>
      </div>

    </div>
  );
};

export default CreateSubCategory;
