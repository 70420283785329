import React, { useContext, useRef, useState, useEffect } from 'react';
import { AppContext } from 'Context';
import useSWR from 'swr';
import cn from 'classnames';
import { dataFetcher, endpoints } from 'Api';
import { Typography } from '@material-ui/core';
import Cancel from '../../../Assets/cancel.png';
import { MenuItem, Menu } from './Material-reDesign';
import styles from '../index.module.css';

const SubCategoryInput = ({
  className,
  defaultName,
  defaultValue,
  description,
  isDisabled,
  label,
  onSelect,
  selectSubCatOptionName,
  selectedSubCatOptionName,
  selectedSubCatOptionValue,
  selectSubCatOptionValue
}) => {
  const { setAppSnackbar } = useContext(AppContext);
  const [createMenuIsVisible, setCreateMenuVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState([]);

  const [newCategoryName, setNewCategoryName] = useState('');

  const showCreateMenu = () => { setCreateMenuVisibility(true); };

  const onValueSelect = (selectedVal, selectedName) => {
    selectSubCatOptionValue(selectedVal);
    selectSubCatOptionName(selectedName);
    onSelect(selectedVal, selectedName);
    setAnchorEl(null);
  };

  const hideCreateMenu = () => { onValueSelect('delete', ''); setCreateMenuVisibility(false); };

  const { isValidating: gettingSubCategories, mutate: getSubCategories } = useSWR([endpoints.getMySubCategory], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, data }) => {
      if (success) {
        setOptions(data?.map(({ id, title }) => ({ name: title, value: id })));
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while fetching your categories',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching your categories' });
    },
  });

  const { isValidating: addingNewSubCategory, mutate: addNewSubCategory } = useSWR([
    endpoints.addUpdateMySubCategory, newCategoryName
  ], {
    fetcher: (url, subCategoryName) => dataFetcher(url, {
      title: subCategoryName,
    }),
    onSuccess: ({ success, subcategory_id }) => {
      if (success) {
        hideCreateMenu();
        getSubCategories();
        onValueSelect(subcategory_id, newCategoryName);
        setAppSnackbar({
          isVisible: true,
          message: `Sub-Category '${newCategoryName}' was created`,
        });
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your category' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while creating your category' });
    },
  });

  useEffect(() => { getSubCategories(); }, []);

  return (
    <div className={cn({ [styles.selectBoxContainer]: true, [className]: className, [styles.disabled]: isDisabled })}>
      <div
        aria-controls="select-box"
        aria-label={label}
        className={styles.selectBox}
        onClick={(e) => {
          if (!isDisabled) {
            setAnchorEl(e.currentTarget);
            document.body.style.setProperty('--selectbox-menu-width', `${e.currentTarget.clientWidth}px`);
          }
        }}
        onKeyPress
        role="menu"
        tabIndex={0}
      >
        <div className={styles.selectBoxText}>
          <div className={styles.selectBoxLabel}>
            {label}
            <span className={styles.optionalInput}> (Optional)</span>
          </div>
          <div className={styles.selectBoxDescription}>
            {!(selectedSubCatOptionValue && selectedSubCatOptionValue !== 'delete' && defaultValue) ? description : selectedSubCatOptionName}
            {selectedSubCatOptionValue && selectedSubCatOptionName && (
            <div
              onKeyPress={undefined}
              role="button"
              tabIndex={0}
              onClick={(e) => { e.stopPropagation(); hideCreateMenu(); setAnchorEl(null); }}
            >
              <img src={Cancel} className={styles.Cancelxx} alt="" />
            </div>
          )}
          </div>
        </div>
        <div className={styles.selectBoxDropdownIcon}>▾</div>
      </div>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 2 }}
        getContentAnchorEl={null}
        id="select-box"
        // keepMounted
        MenuListProps={{ className: styles.selectBoxMenuContent }}
        onClose={() => { hideCreateMenu(); setAnchorEl(null); }}
        open={Boolean(anchorEl)}
        PaperProps={{ className: styles.selectBoxMenu }}
        transitionDuration={0}
        PopoverClasses={{ paper: styles.dropDownAnimation }}
      >
        <div>
          {!createMenuIsVisible
            ? (
              <div className={styles.selectBoxOptionsList}>
                <MenuItem className={styles.selectBoxOptionsTitle} disableRipple disableTouchRipple>{label}</MenuItem>

                {gettingSubCategories && (
                  <MenuItem className={styles.selectBoxOption} disableRipple disableTouchRipple>Loading ...</MenuItem>
                )}

                {!gettingSubCategories && options?.map(({ name: optionName, value: optionValue }) => (optionName && (
                  <MenuItem
                    className={styles.selectBoxOption}
                    disableRipple
                    disableTouchRipple
                    key={optionValue}
                    onClick={() => { onValueSelect(optionValue, optionName); }}
                  >
                    <Typography variant="inherit" noWrap>{optionName}</Typography>
                  </MenuItem>
                )))}

                <MenuItem
                  className={cn({ [styles.selectBoxOption]: true, [styles.addButton]: true })}
                  disableRipple
                  disableTouchRipple
                  onClick={showCreateMenu}
                >
                  <Typography variant="inherit" noWrap>Add new</Typography>
                </MenuItem>
              </div>
            )
            : (
              <div className={styles.selectBoxCreate}>
                <div className={styles.selectBoxCreateHeader}>
                  <span className={styles.selectBoxCreateTitle}>{`Add new ${label}`}</span>
                  <button
                    className={styles.selectBoxCreateBackButton}
                    disabled={addingNewSubCategory}
                    onClick={hideCreateMenu}
                  >
                    Back
                  </button>
                </div>
                <input
                  className={styles.selectBoxCreateInput}
                  value={newCategoryName}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) => { setNewCategoryName(e.target.value); }}
                  maxLength="16"
                />
                <div className={styles.selectBoxCreateFooter}>
                  <button
                    className={styles.selectBoxCreateAddButton}
                    disabled={addingNewSubCategory || !newCategoryName?.trim()}
                    onClick={addNewSubCategory}
                    type="submit"
                  >
                    {addingNewSubCategory ? 'Adding ...' : 'Add'}
                  </button>
                </div>
              </div>
            )}
        </div>
      </Menu>
    </div>
  );
};
export default SubCategoryInput;
