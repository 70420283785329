import React from 'react';

function RotatingLoader() {
  return (
    <div className="theFiexedWidth">
    <div className="loader-container">
      <div className="rotatingloader" />
    </div>
    </div>
  );
}

export default RotatingLoader;
