/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { CheckRounded } from '@material-ui/icons';
import cn from 'classnames';
import blackLogo from 'Assets/logo-black-bg.png';
import whiteLogo from 'Assets/Login Logo.png';
import AlterImage from 'Assets/alter-image.png';
import styles from './index.module.css';

function Page({
  children,
  continueButtonText,
  formIsFilled,
  onContinue,
  subTitle,
  title,
  goBack,
  handleClose,
  addingCard

}) {
  return (
    <>
      <header className={styles.header}>
        <img onError={(e) => { e.target.src = AlterImage; }} style={{ cursor: 'pointer' }} onClick={() => handleClose()} className={styles.logo} src={whiteLogo || AlterImage} />
        <div className={styles.helpCenterButton}>Help Center</div>
      </header>
      <div className={styles.page}>
        <h1 className={styles.title}>
          {title}
        </h1>
        <h4 className={styles.subTitle}>
          {subTitle}
        </h4>
        {children}
        <div>
          <button
            className={styles.continueButton}
            disabled={!formIsFilled || addingCard}
            onClick={onContinue}
          >
            {continueButtonText}
          </button>
        </div>
      </div>
    </>
  );
}

export default Page;
