import React, { useContext } from 'react';
import { Dialog, Slide, makeStyles } from '@material-ui/core';
import MobileViewContent from './mobileViewContent';

export const MobileModal = ({ onHide, isVisible }) => {
  const useStyles = makeStyles({
    content: {
      width: '100%',
      height: '50px',
      marginTop: '20px',
      background: '#323232',
      transform: 'translate(0px, 50px)',
    },
    dots: true,
  });
  const classes = useStyles();
  return (
    <Dialog
      classes={{ root: classes.content }}
      disableBackdropClick
      onClose={onHide}
      open={isVisible}
      PaperComponent={MobileViewContent}
      PaperProps={{ onHide, isVisible }}
      TransitionComponent={Slide}
    />
  );
};
