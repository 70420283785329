import React from 'react';
import styles from './Drope.module.css';
import Cloud from '../../Assets/cloud-upload.png';

function DropMe({ dragOver, dragLeave, handleDrop }) {
  return (
    <div
    onDrop={handleDrop}
    onDragOver={(e) => dragOver(e)}
    onDragLeave={(e) => dragLeave(e)}
    className={styles.iamfixed}
    >
      <img className={styles.cloud} src={Cloud} alt="" />
    </div>
  );
}

export default DropMe;
