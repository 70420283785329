import React, { useContext, useState } from 'react';
import {
  Dialog, Slide,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  RadioGroup,
  makeStyles,
} from '@material-ui/core';
import './loader.css';
import { AppContext } from 'Context';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import { useNavigate } from 'react-router-dom';
import { Pathname } from 'Routes';
import styles from './index.module.css';
import Modal, { SAVE_TYPE } from './Modal';
import { Text } from '../text';
import LoaderContent from './model-dialog-content/LoaderContent';
import promotionModalContent from './promotionModal';

const Modal2 = ({ onHide, isVisible }) => {
  setTimeout(() => {
    onHide();
  }, 5000);
  return (
    <Fade in={isVisible} timeout={isVisible ? 300 : 250}>
      <div className={styles.body}>
        <DialogTitle className={styles.header}>
          <div className={styles.headerContent}>
            <Text.PageTitle className={styles.headerText}>
              Video Published
            </Text.PageTitle>
            <CloseIcon className={styles.closeButton} onClick={onHide} />
          </div>
        </DialogTitle>
        <LoaderContent
          videoUploadingCompleted
          videoUploadPercentage={100}
          currentStep={3}
        />
      </div>
    </Fade>
  );
};

const useStyles = makeStyles({
  dialogPaper: {
    visibility: ({ visibility }) => (visibility ? 'visible' : 'hidden')
  }
});

export const UploadModal = ({ onHide, isVisible, imageFiles }) => {
  const { setHoldTheWeb, setaddMorePhoto, visibility, setVisibility, setSingleUpload, startingFlowEvent, setModalVisibility, setIamUploading } = useContext(AppContext);
  const classes = useStyles({ visibility });
  const navigate = useNavigate();

  const handleUploadModalClose = (saveType) => {
    setaddMorePhoto(false);
    setIamUploading(true);
    setHoldTheWeb(false);
    if (saveType === SAVE_TYPE.DRAFT) {
    navigate(`${Pathname.yourVideos}?tab=draft`);
    }
    if (saveType === SAVE_TYPE.PUBLISH) {
    navigate(`${Pathname.yourVideos}?tab=publish`);
    }
    startingFlowEvent(false);
    setModalVisibility(false);
    setSingleUpload(undefined);
    onHide();
  };

  return (
    <Dialog
      classes={{ root: classes.dialogPaper }}
      disableBackdropClick
      onClose={onHide}
      open={isVisible}
      PaperComponent={Modal}
      PaperProps={{ onHide, isVisible, handleUploadModalClose, imageFiles }}
      TransitionComponent={Slide}
      transitionDuration={isVisible ? 300 : 250}
      TransitionProps={{ direction: 'up' }}
    />
  );
};

export const PromotionModal = ({ onHide, isVisible, promoCode, loginCode, showCloseButton, promoValue }) => {
  const useStyles = makeStyles({
    // dialogPaper: {
    //   visibility: visibility ? 'visible' : 'hidden'
    // },
    content: {
      width: '100%',
      height: '50px',
      marginTop: '20px',
      background: '#323232',
      transform: 'translate(0px, 50px)',
    },
    dots: true,
  });
  const classes = useStyles();

  return (
    <Dialog
      classes={{ root: classes.dialog }}
      disableBackdropClick
      onClose={onHide}
      open={isVisible}
      PaperComponent={promotionModalContent}
      PaperProps={{ onHide, isVisible, promoCode, loginCode, showCloseButton, promoValue }}
      TransitionComponent={Slide}
      transitionDuration={isVisible ? 300 : 250}
      TransitionProps={{ direction: 'up' }}
    />
  );
};
