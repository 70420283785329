/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from 'Routes';
import { dataFetcher, endpoints, axios, baseUrl } from 'Api';
import { AuthContext, AppContext } from 'Context';
import { v4 as uuid } from 'uuid';
import useSWR from 'swr';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import PlayerControls from './PLayerControls';
import styles from './index.module.css';

const useStyles = makeStyles({
  playerWrapper: {
    width: '100%',
    position: 'relative',
    height: '100vh',
    background: 'black',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const format = (seconds) => {
  if (Number.isNaN(seconds)) {
    return '00:00';
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, '0');
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
  }
  return `${mm}:${ss}`;
};
const count = 0;
export function VideoPlayer() {
  // eslint-disable-next-line no-script-url
  window.location.href = 'javascript: $zopim.livechat.button.hide()';
  let timeout;

  const location = useLocation();
  const navigate = useNavigate();

  const { setAppSnackbar } = useContext(AppContext);
  const { getToken } = useContext(AuthContext);
  const { videoID, type } = useParams();
  const [loading, setLoading] = useState(true);
  const [videoDetails, setVideoDetails] = useState({});
  const playerRef = useRef(null);
  const [vttContent, setVttContent] = useState(undefined);
  const [spriteImageRow, setSpriteImageRow] = useState(undefined);
  const [spriteImageColumn, setSpriteImageColumn] = useState(undefined);
  const [spriteImageUrl, setSpriteImageUrl] = useState(undefined);
  const [screenSize, setScreenSize] = useState(false);
  const [showPlayAgainButton, setPlayAgainButton] = useState(false);
  const [showControllers, setControllers] = useState(true);
  const [hoverValue, setHoverValue] = useState(null);
  const [nextVideoDetails, setNextVideoDetails] = useState(undefined);
  const [countDown, setCountDown] = useState(10);
  const checkUrl = async (url) => {
    try {
      if (url !== '' && url !== null) {
        const res = await fetch(url);
        return res?.status === 200 ? url : null;
      }
      return null;
    } catch (error) {
      return null;
    }
  };
  const { mutate: getVideoDetails } = useSWR(
    [endpoints.getVideosDetail, videoID],
    {
      fetcher: (url, id) => dataFetcher(url, { id }),
      onSuccess: ({ success, data }) => {
        if (success) {
          // Create a copy of the data object to avoid modifying the original
          const newData = { ...data };

          // Check and update each URL separately
          if (data.video_file) {
            checkUrl(data.video_file)
              .then((result) => {
                newData.video_file = result;
              });
          }
          if (data.video_raw_file) {
            checkUrl(data.video_raw_file)
              .then((result) => {
                newData.video_raw_file = result;
              });
          }
          if (data.mp4_url) {
            checkUrl(data.mp4_url)
              .then((result) => {
                newData.mp4_url = result;
              });
          }
          setVideoDetails(newData);
          let nextVideo;
          if (newData?.videos?.length) {
            nextVideo = newData?.videos?.find((element) => element.order_number > data.order_number);
            if (nextVideo) {
              setNextVideoDetails(nextVideo);
            } else {
              setNextVideoDetails(newData?.videos?.[0]);
            }
          }
        } else {
          setAppSnackbar({
            isVisible: true,
            type: 'error',
            message: 'Oops! Something went wrong',
          });
        }

        setLoading(false);
      },
      onError: () => {
        setLoading(false);
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong',
        });
      },
    }
  );
  const { mutate: getVttContent } = useSWR(
    [endpoints.getVttContent, videoID],
    {
      fetcher: (url, video_id) => dataFetcher(url, { video_id }),
      onSuccess: ({ success, data, rows, columns, thumbnail_hover }) => {
        if (success) {
          setVttContent(data);
          setSpriteImageRow(rows);
          setSpriteImageColumn(columns);
          setSpriteImageUrl(thumbnail_hover);
        } else {
          setSpriteImageUrl(null);
        }
        setLoading(false);
      },
      onError: () => {
        navigate(Pathname.access);
        setSpriteImageUrl(null);
        setLoading(false);
      },
    }
  );
  const [state, setState] = useState({
    playing: true,
    muted: false,
    volume: localStorage.getItem('volume') || 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffering: true
  });
  useEffect(() => {
    getVideoDetails();
    getVttContent();
  }, [location.pathname]);
  const classes = useStyles();
  const { playing, muted, volume, playbackRate, played, seeking, buffering } = state;

  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);

  // state changing codes.....
  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };
  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 15);
  };
  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 15);
  };

  const handleUserKeyPress = (event) => {
    const { key, keyCode } = event;

    if (keyCode === 32) {
      handlePlayPause();
    }
    if (keyCode === 37) { // left arrow key
      handleRewind();
    }
    if (keyCode === 39) { // right arrow key
      handleFastForward();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });
  // show or hide controllers after 3 sec of last mouse move
  const showOrHideControllers = () => {
    if (controlsRef.current && controlsRef.current.style.visibility !== 'visible') {
      controlsRef.current && (controlsRef.current.style.visibility = 'visible');
    }

    if (showPlayAgainButton) {
      clearTimeout(timeout);
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (controlsRef.current && controlsRef.current.style.visibility !== 'hidden') {
          controlsRef.current && (controlsRef.current.style.visibility = 'hidden');
          setHoverValue(null);
        }
      }, 2000);
    }
  };

  useEffect(() => {
    // hide icon after 2 sec after start the video play (without mouse move)
    if (!showPlayAgainButton) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (controlsRef.current && controlsRef.current.style.visibility !== 'hidden') {
          controlsRef.current && (controlsRef.current.style.visibility = 'hidden');
          setHoverValue(null);
        }
      }, 2000);
      ['mousemove', 'click'].forEach((evt) => document.addEventListener(evt, showOrHideControllers, false));
    }
  }, [showPlayAgainButton, playing]);

  const handleMute = () => {
    setState({ ...state, muted: !state.muted });
  };
  const handleVolumeChange = (newValue) => {
    newValue === 0 ? setState({
      ...state,
      volume: Math.floor(newValue),
      muted: true,
    })
      : setState({
        ...state,
        volume: Math.floor(newValue),
        muted: false,
      });
  };
  const handleVolumeSeekDown = (newValue) => {
    newValue === 0 ? setState({
      ...state,
      volume: 1 - newValue,
      muted: true,
    })
      : setState({
        ...state,
        volume: newValue,
        muted: false,
      });
  };
  const handelPlaybackRate = (value) => {
    setState({
      ...state,
      playbackRate: value,
    });
  };
  const toggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current);
    setScreenSize(!screenSize);
  };
  const currentTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : '00:00';
  const duration = playerRef.current
    ? playerRef.current.getDuration()
    : '00:00';
  const elapsedTime = format(currentTime);
  const totalDuration = format(duration);

  const { mutate: updateVideoPaused } = useSWR(
    [endpoints.updateVideoPaused, videoID, localStorage.getItem('video_duration') || elapsedTime],
    {
      fetcher: (url, video_id, paused_at) => dataFetcher(url, { video_id, paused_at }),
      onSuccess: ({ success, data }) => {
        // if (success) {
        // } else {
        // }
      },
      onError: () => {
      },
    }
  );

  const handleProgress = (changeState) => {
    if (!seeking) {
      setState({ ...state, ...changeState });
    }
    // if (playing) {
    //   updateVideoPaused();
    // }
  };

  useEffect(() => {
    let count = 0;
    if (localStorage?.getItem('video_id') && localStorage?.getItem('video_duration')) {
      window.addEventListener('popstate', async () => {
        if (!count) {
          await axios
            .post(endpoints.updateVideoPaused, { video_id: localStorage.getItem('video_id'), paused_at: localStorage.getItem('video_duration') })
            .then(({ data }) => {
              localStorage.setItem('video_id', null);
              localStorage.setItem('video_duration', null);
            })
            .catch((error) => {
              localStorage.setItem('video_id', null);
              localStorage.setItem('video_duration', null);
            });
          count += 1;
        }
      });
    }
  }, []);
  const elapsedTimeNumber = parseFloat(elapsedTime);
  const apiUrl = `${baseUrl}/updateViewersDetails`;
  const video_id = videoID;
  const token = getToken();
  const [draftId] = useState(uuid());
  const formatTime = (time) => {
    const seconds = Math.floor(time);
    const milliseconds = Math.floor((time - seconds) * 100);
    return `${seconds}.${milliseconds}`;
  };
  const postData = {
    video_id,
    token,
    'uuid': draftId,
    'current': currentTime
  };

  const makeApiCall = async () => {
    if (playing) {
      try {
        const response = await axios.post(apiUrl, postData);
      } catch (error) {
        console.error('API error:', error);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(makeApiCall, 10000);
    return () => clearInterval(interval);
  }, [playing]);

  useEffect(() => {
    if (elapsedTime && playing) {
      localStorage.setItem('video_duration', elapsedTime);
      localStorage.setItem('video_id', videoID);
    }
  }, [elapsedTime]);

  const handleSeekChange = (newValue) => {
    setState({ ...state, played: Math.floor(newValue) });
  };
  const handleSeekMouseDown = (e) => {
    setState({ ...state, seeking: true });
  };
  const handleSeekMouseUp = (newValue) => {
    setState({ ...state, seeking: false });
    playerRef.current.seekTo(newValue);
  };

  const onEndBuffering = () => {
    setState({ ...state, buffering: false });
  };

  const onStarted = () => {
    type === 'resumeWatching' && playerRef.current.seekTo(playerRef.current.getCurrentTime() + videoDetails.paused_at);
  };

  useEffect(() => {
    if ((currentTime && duration) && currentTime !== '00:00' && currentTime === duration) {
      setTimeout(() => {
        // navigate(-2);
        setPlayAgainButton(true);
      }, 500);
    }
  }, [currentTime]);

  useEffect(() => {
    if (showPlayAgainButton) {
      if (countDown === 1) {
        navigate(Pathname.getPlayerPath(nextVideoDetails?.id, 'play'));
      }
      // exit early when we reach 0
      if (!countDown) return;

      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);
      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
    }
  }, [countDown, showPlayAgainButton, location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      setCountDown(10);
    }, 1000);
  }, [location.pathname]);

  useEffect(() => {
    if (showPlayAgainButton) {
      if (controlsRef.current && controlsRef.current.style.visibility !== 'visible') {
        controlsRef.current && (controlsRef.current.style.visibility = 'visible');
      }
    }
  }, [showPlayAgainButton]);

  // const onEnded = () => {
  // setPlayAgainButton(true);
  // setState({ ...state, playing: false });
  //   setTimeout(() => { navigate(-1); }, 2000);
  // };

  useEffect(() => {
    if (!showPlayAgainButton && currentTime && duration && currentTime !== '00:00' && currentTime === duration) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - playerRef.current.getCurrentTime());
      playerRef.current.player.handleReady();
      setState({ ...state, playing: true });
      setCountDown(10);
    }
  }, [showPlayAgainButton]);

  useEffect(() => {
    localStorage.setItem('volume', volume);
  }, [volume]);

  useEffect(() => {
    if (currentTime === 0) {
      setPlayAgainButton(false);
    }
  }, [currentTime]);

  return (
    <>
      <div>
        <div ref={playerContainerRef} className={classes.playerWrapper}>
          <ReactPlayer
            ref={playerRef}
            width="100%"
            height="90vh"
            className={styles.react_player}
            // url='https://www.youtube.com/watch?v=1StsoFtme-s'
            // url={videoDetails?.video_raw_file}
            url={videoDetails?.transcoded_url || videoDetails?.video_file || videoDetails?.mp4_url || videoDetails?.video_raw_file}
            muted={muted}
            playing={playing}
            volume={volume}
            playbackRate={playbackRate}
            onProgress={handleProgress}
            onBufferEnd={onEndBuffering}
            onStart={onStarted}
            autoPlay
            // onEnded={onEnded}
            progressInterval={100}
          />
          {/* style={{ display: toolsVesibility ? 'block' : 'none' }} */}
          <div>

            <PlayerControls
              ref={controlsRef}
              onPlayPause={handlePlayPause}
              playing={playing}
              onRewind={handleRewind}
              onFastForward={handleFastForward}
              muted={muted}
              onMute={handleMute}
              volume={volume}
              onVolumeChange={handleVolumeChange}
              onVolumeSeekUp={handleVolumeSeekDown}
              playbackRate={playbackRate}
              onPlaybackRateChange={handelPlaybackRate}
              onToggleFullScreen={toggleFullScreen}
              played={played}
              onSeek={handleSeekChange}
              onSeekMouseDown={handleSeekMouseDown}
              onSeekMouseUp={handleSeekMouseUp}
              currentTime={currentTime}
              elapsedTime={elapsedTime}
              totalDuration={totalDuration}
              title={videoDetails.title}
              videoID={videoID}
              buffering={buffering}
              vttContent={vttContent}
              duration={duration}
              rows={spriteImageRow}
              columns={spriteImageColumn}
              spriteImage={spriteImageUrl}
              screenSize={screenSize}
              format={format}
              showPlayAgainButton={showPlayAgainButton}
              setPlayAgainButton={setPlayAgainButton}
              showControllers={showControllers}
              setControllers={setControllers}
              hoverValue={hoverValue}
              setHoverValue={setHoverValue}
              setState={setState}
              state={state}
              nextVideoDetails={nextVideoDetails}
              countDown={countDown}
            />
          </div>

        </div>
      </div>
    </>
  );
}
