import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from 'Context';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import { Page } from 'Components';
import { Pathname } from 'Routes';
import styles from './index.module.css';

export const ViewerPage = ({ children, title, addActionName, link, subTitle, restricted }) => {
  const { langText, setTopOrBottom } = useContext(AppContext);
  useEffect(() => {
    setTopOrBottom(true);
    return () => {
      setTopOrBottom(false); // Set topOrBottom to false when leaving the component
    };
  }, []);
  const sideBarText = langText?.viewersSideBar;
  const [isMouseOver, setMouseOver] = useState(false);
  const sidebarLinks = [
    { name: (sideBarText?.viewersSidebarTitle || 'Viewers'), link: Pathname.getViewersPath(Pathname.viewers.viewer) },
    { name: (sideBarText?.groupSidebarTitle || 'Groups'), link: Pathname.getViewersPath(Pathname.viewers.groups) },
    { name: (sideBarText?.successorSidebarTitle || 'Successors'), link: Pathname.getViewersPath(Pathname.viewers.successors) },
  ];
  return (
    <Page contentClassName={restricted ? styles.pageContentRes : styles.pageContent} headerTheme={styles.headerTheme}>
      <div className={styles.settingsPage}>
        <div className={styles.sidebar}>
          <div className={styles.sidebarContent}>
          {sidebarLinks.map(({ name, link }, idx) => {
          const isActive = window.location.pathname === link;
          const navLinkClassName = cn(styles.sidebarLink, { [styles.currentSidebarLink]: isActive });
          return (
            <NavLink
              key={idx}
              to={link}
              className={navLinkClassName}
            >
             {name}
            </NavLink>
          );
        })}
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.headingWrapper}>
            <span className={styles.titleWrapper}>
              <span className={cn({ [styles.pageTitle]: true, [styles.restrictedPageTitle]: restricted })}>{subTitle ? `${title}:   ` : title}</span>
              <span className={styles.subTitle}>{subTitle}</span>
            </span>
            <NavLink to={link}>
              <span className={styles.addNew}>
                <span onMouseEnter={() => { setMouseOver(true); }} onMouseLeave={() => { setMouseOver(false); }} className={cn({ [styles.addNewTitle]: true, [styles.disabledbutton]: restricted, [styles.mouseOverClass]: isMouseOver })}>{addActionName}</span>
                <AddIcon onMouseEnter={() => { setMouseOver(true); }} onMouseLeave={() => { setMouseOver(false); }} className={cn({ [styles.addIconStyle]: true, [styles.disabledbutton]: restricted, [styles.mouseOverClass]: isMouseOver })} fontSize="small" />
              </span>
            </NavLink>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </Page>
  );
};
