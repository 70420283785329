/* eslint-disable no-plusplus */
import axios from 'axios';
import useSWR from 'swr';

const template_id = 'template_nbrmwxo';
const service_id = 'projector_email_service';
const user_id = 'user_I2L4mxpLVPNao602Zd2tk';

const sendEmail = (array, myEmail) => {
  let i = 0;
  while (i + 1 < array.length) {
    const oldData = [...array];
    const newData = oldData.map((user) => {
      const formData = new FormData();
      formData.append('template_id', template_id);
      formData.append('service_id', service_id);
      formData.append('user_id', user_id);
      formData.append('recivers_email', user.email);
      formData.append('from_name', myEmail);

      formData.append('subject', 'Join Projector Commnuity');
      formData.append('recivers_name',
        `${user.firstName ? user.firstName : 'Sir'}
        ${(user.firstName && user.laseName) ? user.laseName : ''}`);
      formData.append('message',
        `I love to inform you that now you are eligible to join Projector Stream and get access to view collections of
         ${myEmail}`);
      return formData;
    });
    axios.post('https://api.emailjs.com/api/v1.0/email/send-form', newData[i])
      .then((res) => res)
      .catch((err) => err);
    i += 1;
  }
};

export default sendEmail;
