import React from 'react';
import {
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TableHeaderCell, TableBodyCell } from './CustomDesigns';
import styles from '../index.module.css';

const GroupTableHead = () => (
  <TableHead>
    <TableRow>
      <TableHeaderCell className={styles.tableHeaderCell}>Groups Name</TableHeaderCell>
      <TableHeaderCell className={styles.tableHeaderCell} align="right">Viewers</TableHeaderCell>
    </TableRow>
  </TableHead>
);
export default GroupTableHead;
