/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Pathname } from 'Routes';
import { useNavigate } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import AlterImage from 'Assets/alter-image.png';
import styles from './index.module.css';

export const ShowNextVideo = ({ nextVideoDetails, countDown }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.nextVideoWrapper}
      onClick={(e) => {
        navigate(Pathname.getPlayerPath(nextVideoDetails.id, 'play'));
      }}
    >
      <Image
        onError={(e) => {
          e.target.src = AlterImage;
        }}
        src={nextVideoDetails?.thumbnails || AlterImage}
        className={styles.imageGroupImage}
      />
      <div className={styles.titleContainer} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4>
          {nextVideoDetails?.title}
        </h4>
        <h6>
          {nextVideoDetails?.SubCategory}
        </h6>
      </div>
      <div className={styles.nextVideoText}>
        <h3>
          {`Play Next Video in: ${countDown}s`}
        </h3>
      </div>
    </div>
  );
};
