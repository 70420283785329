import React from 'react';
import SubscriberAgreement from 'Components/Doc/SubscriberAgreement/index';
import { Page } from './landing/components/page';

const SubscriberAgreementDarkMode = () => (
  <Page>
    <SubscriberAgreement isDark />
  </Page>
);

export default SubscriberAgreementDarkMode;
