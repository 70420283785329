import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { Pathname } from 'Routes';
import { dataFetcher, endpoints } from 'Api';
import { Collapse, Zoom } from '@material-ui/core';

import { AppContext } from 'Context';
import styles from './index.module.css';

const transitionTimeout = 300;

const UserRequest = ({ email, id, firstname, lastname, setSnackbar, hasAccess, fetchAccessRequests, setAccessRequests,
  setHasAccess, fetchInvitations, accessType }) => {
  const navigate = useNavigate();
  const [action, setAction] = useState(undefined);
  const [isVisible, setVisibility] = useState(true);
  const { subscriptionDetails, langText, getUserConnections, getSubscription, setViewerListUpdate } = useContext(AppContext);
  const [disabled, setDisabled] = useState({ isDisabled: false, error: undefined });
  const [invitationStatus, setInvitationStatus] = useState(undefined);
  const viewerLimitCondition = parseInt(subscriptionDetails?.subscription?.is_unlimited, 10) !== 1 && parseInt(subscriptionDetails?.total_viewers, 10) >= parseInt(subscriptionDetails?.subscription?.max_view_request, 10);
  const commonText = langText?.common;
  useEffect(() => {
    // const total_viewer = 20;
    const total_viewer = subscriptionDetails?.total_viewer;
    const current_plan = subscriptionDetails?.current_plan;
    if (accessType !== 'invitation') {
      if (current_plan) {
        if (current_plan === 'Standard') {
          if (total_viewer >= 10) {
            // current_plan === standard
            setDisabled({ isDisabled: true, error: 'You must upgrade your plan to accept this request' });
          } else {
            // current_plan === standard
            setDisabled({ isDisabled: false, error: undefined });
          }
        } else {
          // current_plan === premium
          setDisabled({ isDisabled: false, error: undefined });
        }
      } else {
        // current_plan === free
        setDisabled({ isDisabled: true, error: 'You must have a plan to accept this request' });
      }
    }
  }, [subscriptionDetails]);
  const { isValidating: handlingAction, mutate: handleAction } = useSWR([endpoints.updateViewRequest, id, action], {
    fetcher: (url, user, status) => dataFetcher(url, { user, status }),
    onSuccess: ({ success }) => {
      if (success) {
        // setVisibility(false);
        setViewerListUpdate(true);
        getSubscription();
        setAccessRequests([]);
        fetchAccessRequests();
        setSnackbar({
          isVisible: true,
          message: `${firstname ? `${firstname}'s r` : 'R'}equest ${action === 1 ? 'accepted' : 'declined'}`
        });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  const { isValidating: deletingUser, mutate: deleteUser } = useSWR([endpoints.deleteMyViewer, id], {
    fetcher: (url, userId) => dataFetcher(url, { id: userId }),
    onSuccess: ({ success }) => {
      if (success) {
        setHasAccess([]);
        getSubscription();
        // setVisibility(false);
        setViewerListUpdate(true);
        fetchAccessRequests();
        setSnackbar({
          isVisible: true,
          message: `${firstname ? `${firstname}` : 'User'} removed`
        });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
      setVisibility(false);
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  const { isValidating: updatingInvitation, mutate: updateInvitation } = useSWR([endpoints.deleteFromViewer,
    id, invitationStatus], {
    fetcher: (url, userId, status) => dataFetcher(url, { id: userId, status }),
    onSuccess: ({ success }) => {
      if (success) {
        fetchInvitations();
        getUserConnections();
        getSubscription();
        setViewerListUpdate(true);
        setSnackbar({
          isVisible: true,
          message: `${firstname ? `${firstname}'s i` : 'I'}nvitation ${invitationStatus === 1 ? 'accepted' : 'declined'}`
        });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
      setVisibility(false);
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  const handleAccept = () => {
    if (disabled.isDisabled) {
      setSnackbar({ isVisible: true, message: disabled.error, type: 'error' });
    } else {
      viewerLimitCondition ? navigate(Pathname.upgradePlan) : setAction(1);
    }
  };

  useEffect(() => { if (action) { handleAction(); } }, [handleAction, action]);
  useEffect(() => { if (invitationStatus) { updateInvitation(); } }, [updateInvitation, invitationStatus]);

  return (
    <div className={styles.userRequest}>
      <div className={styles.userRequestText}>
        <div className={styles.userRequestName}>
          {`${firstname} ${lastname}`}
        </div>
        <div className={styles.userRequestEmail}>{email}</div>
      </div>
      {hasAccess
        ? (
          <div className={styles.userRequestActionsContainer}>
            <button
              className={cn({ [styles.userRequestActionButton]: true, [styles.delete]: true })}
              onClick={deleteUser}
            >
              {deletingUser ? `${(commonText?.deleting || 'Deleting')}...` : (commonText?.delete || 'Delete')}
            </button>
          </div>
        )
        : (
          accessType === 'request' ? (
            <div className={styles.userRequestActionsContainer}>
              <button
                className={styles.userRequestActionButton}
                onClick={() => { handleAccept(); }}
              >
                {(handlingAction && (action === 1)) ? `${(commonText?.accepting || 'Accepting')}...` : (commonText?.accept || 'Accept')}
              </button>
              <button
                className={cn({
                  [styles.userRequestActionButton]: true,
                  [styles.delete]: true,
                  [styles.deleteActive]: true
                })}
                onClick={() => { setAction(2); }}
              >
                {(handlingAction && (action === 2)) ? `${(commonText?.declining || 'Declining')}...` : (commonText?.decline || 'Decline')}
              </button>
            </div>
          ) : (
            <div className={styles.userRequestActionsContainer}>
              <button
                className={styles.userRequestActionButton}
                onClick={() => { setInvitationStatus(1); }}
              >
                {(updatingInvitation && (invitationStatus === 1)) ? `${(commonText?.accepting || 'Accepting')}...` : (commonText?.accept || 'Accept')}
              </button>
              <button
                className={cn({
                  [styles.userRequestActionButton]: true,
                  [styles.delete]: true,
                  [styles.deleteActive]: true
                })}
                onClick={() => { setInvitationStatus(2); }}
              >
                {(updatingInvitation && (invitationStatus === 2)) ? `${(commonText?.declining || 'Declining')}...` : (commonText?.decline || 'Decline')}
              </button>
            </div>
          )

        )}
    </div>
  );
};
export default UserRequest;
