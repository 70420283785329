import React, { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import noPhotoAvailable from 'Assets/no-image-available.png';
import { AppContext } from 'Context';
import copyLinkImage from 'Assets/copy-link@2x.png';
import AlterImage from 'Assets/alter-image.png';
import ThumbFailImg from 'Assets/imageFail.png';
import filesize from 'filesize';
import styles from '../index.module.css';

const VideoPreview = ({ choosedFile, defaultVideoThumbnail, isThumbnailCreationFailed, details, type, link, modalType, editFlow = false, percentage }) => {
  const { setAppSnackbar, langText } = useContext(AppContext);
  const uploadText = langText?.upload;

  const minutes = Math.floor(details?.duration / 60);
  const seconds = Math.floor(details?.duration % 60);

  const handleImageError = (e) => {
    e.target.src = AlterImage;
  };

  function changeSize(size) {
    const newSize = size / 1024 / 1024;
    return newSize.toFixed(2);
  }

  const handleCopyLink = () => {
    setAppSnackbar({ isVisible: true, message: 'Link Copied' });
  };
  const bytes = choosedFile?.size;
  const gigabytes = bytes / (1024 * 1024 * 1024);
  const theGb = `${Math.floor(gigabytes * 10) / 10}GB`;
    /* eslint-disable  */
    function formatDuration(durationInSeconds) {
      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      const seconds = Math.floor(durationInSeconds % 60);
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    
    /* eslint-enable  */
  return (
    <div className={styles.videoPreview}>
      <div style={{ display: 'grid', placeItems: 'center', position: 'relative' }}>
        <img
          className={styles.videoPreviewThumbnail}
          alt="Video Thumbnail"
          src={(modalType === 'upload' && isThumbnailCreationFailed && (defaultVideoThumbnail === noPhotoAvailable)) ? ThumbFailImg : defaultVideoThumbnail || AlterImage}
          onError={handleImageError}
        />
        {modalType === 'upload' && (!isThumbnailCreationFailed && (defaultVideoThumbnail === noPhotoAvailable)) && (
          <div className={styles.uploadpercentage}>
            <div className={styles.customloader} />
            Generating thumbnails from the video. It could take few minutes for a large video file.
          </div>
        )}
      </div>
      <div className={styles.videoPreviewDetails}>
        <h3 className={styles.videoPreviewDetailsTitle}>
          {type === 'link' ? uploadText?.videoLink || 'Video link' : uploadText?.videoDetail || 'Video details'}
        </h3>
        {type === 'link' && (
          <div className={styles.videoPreviewLink}>
            <span
              style={{
                display: 'inline',
                flexGrow: '0',
                flexShrink: '0',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: 'calc(100% - 2.2em)',
              }}
            >
              {link}
            </span>
            <CopyToClipboard text={link} onCopy={handleCopyLink}>
              <button
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  height: '2em',
                  margin: '0',
                  padding: '0',
                  position: 'relative',
                  width: '2em',
                }}
              >
                <img
                  style={{
                    height: '100%',
                    left: '0',
                    position: 'absolute',
                    top: '0',
                    width: '100%',
                  }}
                  src={copyLinkImage || AlterImage}
                  onError={handleImageError}
                  alt="Copy Link"
                />
              </button>
            </CopyToClipboard>
          </div>
        )}
{type === 'details' && (
          <table className={styles.tableBody}>
            <tbody>
              <tr>
                <td>{uploadText?.videpPlayTime || 'Video play time'}</td>
                <td>
                {details?.duration && formatDuration(details.duration)}
                </td>
              </tr>
              {details?.size
              && (
              <tr>
                <td>{uploadText?.videoSize || 'Video size'}</td>
                {editFlow
                  ? (
                  <>
                    {parseFloat(details.size) >= 1024
                    ? `${(parseFloat(details.size) / 1024).toFixed(2)} GB`
                    : parseFloat(details.size) >= 1
                    ? `${parseFloat(details.size).toFixed(2)} MB`
                    : `${(parseFloat(details.size) / 1024).toFixed(2)} KB`}
                  </>
                  )
                 : (
                <>
                  {details.size >= 1024 * 1024 * 1024
                  ? `${(details.size / (1024 * 1024 * 1024)).toFixed(2)} GB`
                  : details.size >= 1024 * 1024
                  ? `${(details.size / (1024 * 1024)).toFixed(2)} MB`
                  : `${(details.size / 1024).toFixed(2)} KB`}
                </>
              )}
              </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default VideoPreview;
