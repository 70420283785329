import { Dialog, Slide } from '@material-ui/core';
import Modal from './Modal';

export const EditModal = ({ onHide, isVisible, contentDetails, uploadType, uniqueId, setContentDetails, makeEveryThingNull }) => (
  <Dialog
    disableBackdropClick
    onClose={onHide}
    open={isVisible}
    PaperComponent={Modal}
    PaperProps={{ onHide, isVisible, contentDetails, uploadType, uniqueId, setContentDetails, makeEveryThingNull }}
    TransitionComponent={Slide}
    transitionDuration={isVisible ? 300 : 250}
    TransitionProps={{ direction: 'up' }}
  />
);
