import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import cn from 'classnames';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { Collapse, Zoom } from '@material-ui/core';

import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import styles from './index.module.css';

const transitionTimeout = 300;

const UserRequest = ({ email, id, firstname, lastname, setSnackbar, hasAccess, fetchAccessRequests, setAccessRequests,
  setHasAccess, type, fetchViewerList, marginDown }) => {
  const [action, setAction] = useState(undefined);
  const [isVisible, setVisibility] = useState(true);
  const { subscriptionDetails, langText, getSubscription, setViewerListUpdate } = useContext(AppContext);
  const commonText = langText?.common;
  const viewerText = langText?.viewersSideBar?.viewers;
  const [disabled, setDisabled] = useState({ isDisabled: false, error: undefined });
  const viewerLimitCondition = parseInt(subscriptionDetails?.subscription?.is_unlimited, 10) !== 1 && parseInt(subscriptionDetails?.total_viewers, 10) >= parseInt(subscriptionDetails?.subscription?.max_view_request, 10);
  const navigate = useNavigate();

  useEffect(() => {
    // const total_viewer = 20;
    const total_viewer = subscriptionDetails?.total_viewer;
    const current_plan = subscriptionDetails?.current_plan;
    if (current_plan) {
      if (current_plan === 'Standard') {
        if (total_viewer >= 10) {
          // current_plan === standard
          setDisabled({ isDisabled: true, error: 'You must upgrade your plan to accept this request' });
        } else {
          // current_plan === standard
          setDisabled({ isDisabled: false, error: undefined });
        }
      } else {
        // current_plan === premium
        setDisabled({ isDisabled: false, error: undefined });
      }
    } else {
      // current_plan === free
      setDisabled({ isDisabled: true, error: 'You must have a plan to accept this request' });
    }
  }, [subscriptionDetails]);
  const { isValidating: handlingAction, mutate: handleAction } = useSWR([endpoints.updateViewRequest, id, action], {
    fetcher: (url, user, status) => dataFetcher(url, { user, status }),
    onSuccess: ({ success }) => {
      if (success) {
        getSubscription();
        // setVisibility(false);
        setAccessRequests([]);
        fetchAccessRequests();
        fetchViewerList();
        setSnackbar({
          isVisible: true,
          message: `${firstname ? `${firstname}'s r` : 'R'}equest ${action === 1 ? 'accepted' : 'declined'}`
        });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });
const [cancelMe, setCancelMe] = useState(false);

const { isValidating: cancelling, mutate: cancelInvitation } = useSWR(
  [endpoints.cancelInvitation, email],
  {
    fetcher: (url, email) => dataFetcher(url, { email }),
    onSuccess: ({ success, data }) => {
      if (success) {
        fetchAccessRequests();
        setCancelMe(false);
      }
    },
    onError: () => {
      setSnackbar({
        isVisible: true,
        message: 'Oops! Something went wrong',
        type: 'error',
      });
    },
  }
);
const initialCancelStatus = {}; // You can initialize this as an empty object or an array
const [cancelStatus, setCancelStatus] = useState(initialCancelStatus);
  const { isValidating: deletingUser, mutate: deleteUser } = useSWR([endpoints.deleteMyViewer, id], {
    fetcher: (url, userId) => dataFetcher(url, { id: userId }),
    onSuccess: ({ success }) => {
      if (success) {
        setHasAccess([]);
        // setVisibility(false);
        getSubscription();
        fetchAccessRequests();
        setViewerListUpdate(true);
        setSnackbar({
          isVisible: true,
          message: `${firstname ? `${firstname}` : 'User'} removed`
        });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
      setVisibility(false);
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });
  const handleAccept = () => {
    if (disabled.isDisabled) {
      setSnackbar({ isVisible: true, message: disabled.error, type: 'error' });
    } else {
      viewerLimitCondition ? navigate(Pathname.upgradePlan) : setAction(1);
    }
  };
  useEffect(() => { if (action) { handleAction(); } }, [handleAction, action]);

  const handleCancelClick = (id) => {
    if (!cancelStatus[id]) {
      setCancelStatus({ ...cancelStatus, [id]: true });
      cancelInvitation(id);
    }
  };

  return (
    <Collapse in={isVisible} timeout={transitionTimeout}>
      <Zoom in={isVisible} timeout={transitionTimeout}>
        <div style={{ marginTop: marginDown && '10px' }} className={styles.userRequest}>
          <div className={styles.userRequestText}>
            <div className={styles.userRequestName}>{`${firstname} ${lastname}`}</div>
            <div className={styles.userRequestEmail}>{email}</div>
          </div>
          {hasAccess
            ? (
              <div className={styles.userRequestActionsContainer}>
                <button
                  className={cn({ [styles.userRequestActionButton]: true, [styles.delete]: true })}
                  onClick={deleteUser}
                >
                  {deletingUser ? `${(commonText?.deleting || 'Deleting')}...` : (commonText?.delete || 'Delete')}
                </button>
              </div>
            )
            : (
              type === 'invitation' ? (
                <div className={styles.userRequestActionsContainer}>
                  <button
                    className={cn({
                      [styles.pendingInvite]: true,
                    })}
                    key={id}
                    style={{ cursor: cancelMe === true ? 'default' : 'pointer' }}
                    onClick={() => handleCancelClick(id)}
                  >
                   {cancelStatus[id] ? 'Canceling' : 'Cancel Invite'}
                  </button>
                </div>
              ) : (
                <div className={styles.userRequestActionsContainer}>
                  <button
                    className={styles.userRequestActionButton}
                    onClick={() => { handleAccept(); }}
                  >
                    {(handlingAction && (action === 1)) ? `${(commonText?.accepting || 'Accepting')}...` : (commonText?.accept || 'Accept')}
                  </button>
                  <button
                    className={cn({ [styles.userRequestActionButton]: true, [styles.delete]: true })}
                    onClick={() => { setAction(2); }}
                  >
                    {(handlingAction && (action === 2)) ? `${(commonText?.declining || 'Declining')}...` : (commonText?.decline || 'Decline')}
                  </button>
                </div>
              )
            )}
        </div>
      </Zoom>
    </Collapse>
  );
};
export default UserRequest;
