import cn from 'classnames';
import styles from './index.module.css';

function InputField({ className, onChange, defaultValue, isDisabled, ...props }) {
  return (
    <input
      className={cn({
        [styles.inputField]: true,
        [className]: className,
      })}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      disabled={isDisabled}
      defaultValue={defaultValue}
      {...props}
    />
  );
}

export default InputField;
