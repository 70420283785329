import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography, Button as MuiButton } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import logo from '../assets/img/logo.svg';
import logoP from '../assets/img/logo-p.svg';
import help from '../assets/img/help-icon.svg';
import Button from '../common/Button';
import styles from '../landingPage.module.css';

const Navbar = () => (
  <nav>
    <Box width="100%">
      <Box className={styles.openInAppContainer} display="none" justifyContent="space-between" padding={1} alignItems="center">
        <Box>
          <Link to="/">
            <img data-aos="fade-right" className={styles.logoMini} src={logoP} alt="" />
          </Link>
        </Box>
        <Box data-aos="fade-left">
          <Typography className={styles.openAppTitle}>Projector: Watch, Listen, Stream</Typography>
          <Typography className={styles.openAppCaption}>Open in the Projector app</Typography>
        </Box>

        <Box data-aos="fade-left">
          <Button text="Open" link="https://projector.page.link/home" btnClass={`blue ${styles.openButton}`} redirect />

          {/* <MuiButton
            className={`${styles.openButton} blue`}
            variant="contained"
            href="https://projector.page.link/home"
          >
              Open
          </MuiButton> */}
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" paddingX={2} paddingBottom={3}>
        <Link to="/">
          <img data-aos="fade-right" className="logo" src={isMobile ? logoP : logo} alt="" />
        </Link>

        <div data-aos="fade-left">
          <a
            className="help-btn hide"
            href="https://projectorstream.zendesk.com/hc/en-us"
            target="_blank"
            rel="noreferrer"
          >
            <img src={help} alt="" />
            Help Center
          </a>

          <div className="signup-btns">
            <Grid container spacing={2}>
              <Grid item>
                <Button text="Sign Up Now" link="newSign-up" btnClass="blue" />

              </Grid>

              <Grid item>
                <Button
                  text="Log In"
                  link="newSign-in"
                  btnClass="transparent"
                />
              </Grid>
            </Grid>

          </div>
        </div>
      </Box>

    </Box>
  </nav>
);

export default Navbar;
