/* eslint-disable max-len */
export const transitionType = [
  {
    class: 'fadeIn',
    name: 'Fade in'
  },
  {
    class: 'spinner',
    name: 'Spinner'
  },
  {
    class: 'moveDown',
    name: 'Move Down'
  },
  {
    class: 'moveUp',
    name: 'Move Up'
  },
  {
    class: 'moveLeftToRight',
    name: 'Move Right'
  },
  {
    class: 'moveRightToLeft',
    name: 'Move Left'
  },
  {
    class: 'slideIn',
    name: 'Slide In'
  }
];

export const bannerType = [
  {
    id: 1,
    name: 'Type 1'
  },
  {
    id: 2,
    name: 'Type 2'
  },
  {
    id: 3,
    name: 'Type 3'
  }
];

export const encryptedKeyForCredentials = {
  password: 'RpML0qe+o6atUKszlp7YmckLjX2uIR8sNyvxiTwlYOE=',
  email: 'IoJd4EcEZWigNoiSV1JzKp9dnw5ualsg1YpUxwZygu0=',
  mobile: 'SVuq5XhuNX491KJ9DTVyzHvQ8OyjCZEkmRTZn1Jf+pE='
};

export const ALLOWED_UPLOADS_COUNT = 1;

// TODO change key to env
export const GOOGLE_RECAPTCHA_KEY = '6Lc2vFgoAAAAAN7M6Qh2CrW2RJQYXlwOSCojWXRT';

export const PLANS = {
  free: 'Free',
  best: 'Best'
};
