import React, { useState, useContext } from 'react';
import cn from 'classnames';
import useSWR from 'swr';
import { AppContext } from 'Context';
import { dataFetcher, endpoints } from 'Api';
import { Collapse, Zoom } from '@material-ui/core';
import AlterImage from 'Assets/alter-image.png';
import styles from './index.module.css';

const transitionTimeout = 300;

const GroupMemberList = ({ email, id, firstname, lastname, setSnackbar, group, image }) => {
  const [isVisible, setVisibility] = useState(true);
  const [selectedSuccessor, selectSuccessor] = useState(undefined);
  const { langText } = useContext(AppContext);
  const commonText = langText?.common;
  const { isValidating: deletingUser, mutate: deleteUser } = useSWR([endpoints.removeMemberFromGroup, group, id], {
    fetcher: (url, groupId, userId) => dataFetcher(url, { users: userId, group_id: groupId }),
    onSuccess: ({ success }) => {
      if (success) {
        setSnackbar({
          isVisible: true,
          message: `${firstname ? `${firstname}` : 'User'} removed`
        });
      } else {
        setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
      }
      setVisibility(false);
    },
    onError: () => {
      setSnackbar({ isVisible: true, message: 'Oops! Something went wrong', type: 'error' });
    },
  });

  return (
    <Collapse in={isVisible} timeout={transitionTimeout}>
      <Zoom in={isVisible} timeout={transitionTimeout}>
        <div className={styles.userRequest}>
          <div className={styles.groupMemberStyle}>
            {
              image ? (
                <img
                  className={styles.profileIcon}
                  src={image || AlterImage}
                  onError={(e) => {
                    e.target.src = AlterImage;
                  }}
                />
              ) : (
                <div className={styles.profileIcon}>
                  {`${firstname ? firstname?.charAt(0).toUpperCase() : email ? email?.charAt(0).toUpperCase() : ''}`}
                </div>
              )
            }
            <div className={styles.userRequestText}>
              <div className={styles.userRequestName}>{`${firstname} ${lastname}`}</div>
              <div className={styles.userRequestEmail}>{email}</div>
            </div>
          </div>
          <div className={styles.userRequestActionsContainer}>
            <button
              className={cn({ [styles.userRequestActionButton]: true, [styles.delete]: true, [styles.deleteActive]: true })}
              onClick={() => { deleteUser(); }}
            >
              {deletingUser ? `${(commonText?.removing || 'Removing')}...` : (commonText?.remove || 'Remove')}
            </button>
          </div>
        </div>
      </Zoom>
    </Collapse>
  );
};
export default GroupMemberList;
