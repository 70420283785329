/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import moment from 'moment';
import { Box, CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import { dataFetcher, endpoints } from 'Api';
import { VideoPreview } from 'Components';
import { Pathname } from 'Routes';
import cn from 'classnames';
import { AppContext } from 'Context';
import ImageGroup from 'Components/ImageGroup/ImageGroup';
import AlterImage from 'Assets/alter-image.png';
import { ReactComponent as BackIcon } from 'Assets/chevron-left.svg';
import { ReactComponent as PlayIcon } from 'Assets/play.svg';
import backArrowIcon from 'Assets/backArrowIcon.svg';
import styles from './index.module.css';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important'
  }
})(MuiCircularProgress);

export const AlbumDetails = () => {
  // eslint-disable-next-line no-script-url
  window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const { setAppSnackbar, setContextLoading, setVisible, userDetails } = useContext(AppContext);
  const navigate = useNavigate();
  const { albumID } = useParams();

  const [loading, setLoading] = useState(true);
  const [albumDetails, setAlbumDetails] = useState({});
  const [previewType, setPreviewType] = useState(1);
  const { isValidating: gettingAlbums, mutate: getAlbumDetails } = useSWR([endpoints.getMyAlbumDetail, albumID], {
    fetcher: (url, album_id) => dataFetcher(url, { album_id }),
    onSuccess: ({ success, data, banner_preview_style_id }) => {
      if (success) {
        setAlbumDetails(data[0]);
        // setPreviewType(parseInt(banner_preview_style_id, 10));
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }

      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  useEffect(() => {
    getAlbumDetails();
  }, []);

  return (
    <>
      <Box className={styles.bgDarkLayer} />
      {loading
          ? (
            <div className={styles.centerCirlce}>
              <CircularProgress />
            </div>
            )
          : (
      <VideoPreview
        className={styles.page}
        containerClassName={styles.pageContainer}
        mainClassName={styles.main}
        noHeader
        noDefaultBg
      >
            <>
              <div className={styles.previewContainer}>
                <div
                  // style={{
                  //   backgroundImage: previewType === 1 ? `linear-gradient(to bottom, rgba(0,0,0,0) 70%, rgba(0,0,0,1)), url(${albumDetails?.icon})` : null
                  // }}
                  className={cn({ [styles.topContainer]: true, [styles.bgLayer]: true })}
                >
                  <div className={styles.containerx}>
                    <div className={styles.gradient_overlay} />
                      <img src={albumDetails?.icon} className={styles.backImg} alt="" />
                  </div>
                  <div className={styles.backButtonBlock} onClick={() => { setVisible(true); navigate(Pathname.getFriendProfilePath(userDetails?.myId === albumDetails?.user_id ? undefined : albumDetails?.user_id)); setContextLoading(true); }} aria-hidden="true">
                    <img
                      className={styles.videoBackButton}
                      src={backArrowIcon || AlterImage}
                      onError={(e) => {
                        e.target.src = AlterImage;
                      }}
                    />
                  </div>
                  <div
                    className={styles.videoDetails}
                  >
                  <div className={styles.videoTitle}>{albumDetails?.title}</div>
                    <div className={styles.videoActions}>
                      <Link to={`${Pathname.getAlbumPlayerPath(albumID, albumDetails?.photos?.[0]?.id)}`} className={styles.videoPlayButton}>
                        <PlayIcon className={styles.videoPlayIcon} />
                        <>Play</>
                      </Link>
                    </div>
                    <div className={styles.videoInfoWrap}>
                      <p className={styles.videoDescription}>{albumDetails?.description}</p>
                    </div>
                  </div>
                  {
                    previewType === 2 ? (
                      <div
                        className={styles.thumbnailDiv}
                        style={{
                          backgroundImage: `linear-gradient(to top right, #000 4%, #121215 8%, #1F1F20 15%, rgba(0,0,0,0)), url(${albumDetails?.icon})`
                        }}
                      />
                    ) : null
                  }
                </div>
                <div className={styles.bottomContainer}>
                  {
                    gettingAlbums ? (
                      <div className={styles.loadingText}>
                        Loading...
                      </div>
                    ) : (
                      albumDetails?.photos?.length === 0 ? (
                        <div className={styles.loadingText}>
                          No photos
                        </div>
                      ) : (
                        <div className={styles.imageGroup}>
                          <ImageGroup
                            isPreview
                            isGrid
                            albums={albumDetails?.photos}
                            type="photo_album"
                            key={albumDetails?.id}
                          />
                        </div>
                      )
                    )
                  }
                </div>
              </div>
            </>
      </VideoPreview>
          )}
    </>
  );
};
