import React from 'react';
import 'Styles/site-global.css';

const ProgressBar = ({ fullProgress }) => (
  <div className="moving-progress-bar-container">
    <div className="moving-progress-bar">
      <div className={fullProgress ? 'fullProgress' : 'progress'} />
    </div>
  </div>
);

export default ProgressBar;
