import React from 'react';
import cn from 'classnames';
import {
  FormControlLabel,
  Radio as MuiRadio,
  Checkbox as MuiCheckbox, ListItemText,
  RadioGroup,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  withStyles,
  Check as CheckIcon
} from '@material-ui/core';
import styles from '../index.module.css';

export const StyledCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black !important',
    },
  },
  checked: {},
})(MuiCheckbox);
export const Radio = withStyles({ root: { color: 'black' }, checked: { color: 'black !important' } })(MuiRadio);
export const TableCell = withStyles({
  root: {
    borderColor: '#E1E2EB',
    color: '#02071A',
    fontFamily: 'Poppins, sans-serif',
  }
})(MuiTableCell);

export const TableHeaderCell = withStyles({
  root: {
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    fontWeight: '600',
    paddingBottom: '0 !important',
    paddingTop: '0 !important',
    whiteSpace: 'nowrap',
  }
})(TableCell);
export const Checkbox = ({ header, status, selected, onClick, disabled, setSelectAllChecked }) => (
  <div
    className={cn({
      [styles.checkbox]: true,
      [styles.header]: header,
      [styles.checked]: status || selected, // Apply the 'checked' class if 'status' is true or 'selected' is true
      [styles.indeterminate]: status === undefined,
      [styles.blackCheckbox]: setSelectAllChecked, // Apply the 'blackCheckbox' class when setSelectAllChecked is true
    })}
    // onClick={() => (disabled ? () => { } : onClick())}
    onClick={disabled ? undefined : onClick}
    onKeyPress={undefined}
    role="button"
    tabIndex={0}
  />
);
