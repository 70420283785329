import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { config } from 'Config';
import { useTheme } from '@material-ui/core/styles';
import CheckoutForm from './CheckoutForm/index';

const promise = loadStripe(config.stripePublicTest);
export default function FormDialog({ isTrail, handleClose, open, price, name, type, setIsToken, selected, purchasingProduct, setOpen,
  selectedPlan, totalTrialDays, updateDetails, contentId, setUploadModalVisibility, promoCode,
  intervalInDays }) {
  const fullScreen = true;

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >

        <Elements stripe={promise}>
          <CheckoutForm
            setOpen={setOpen}
            price={price}
            isTrail={isTrail}
            name={name}
            type={type}
            setIsToken={setIsToken}
            selected={selected}
            purchasingProduct={purchasingProduct}
            selectedPlan={selectedPlan}
            totalTrialDays={totalTrialDays}
            updateDetails={updateDetails}
            contentId={contentId}
            setUploadModalVisibility={setUploadModalVisibility}
            promoCode={promoCode}
            intervalInDays={intervalInDays}
          />
        </Elements>
      </Dialog>
    </>
  );
}
